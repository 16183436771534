import { signInWithEmailAndPassword } from "firebase/auth";
import { auth as firbaseAuth } from "@/services/firebase";
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import routes from "@/config/routes";
import Modal from "@/components/common/Modal";
import {
  dataProcess,
  emailValidations,
  passwordValidations,
} from "@/helper/common";
import { AppContext } from "@/context/createContext";
import { getFireStoreDoc, updateUserData } from "@/services/firebase/fireStore";
import { logoLogin, password1, hidePassword } from "@/config/svg";
import Recaptcha from "../common/Recaptcha";
import React from "react";

const LoginForm: React.FC<{}> = () => {
  const [searchParams] = useSearchParams();
  const get_user_email = searchParams.get("email");
  const captchaRef = useRef(null);
  const [ModalShow, setModalShow] = useState(false);
  let navigate = useNavigate();
  const {
    consentDataLoad,
    state,
    settingsState,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    saveMessageRegistrsationToken,
    showToastMessage,
    translated,
    updatePrefetchData
  } = useContext(AppContext);
  const [canSubmit, setCanSubmit] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValidate, setIsEmailValidate] = useState(false);
  const [isMock] = useState(false);
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const [showMock] = useState(false);
  const configState = state?.config_state
  useEffect(() => {
    localStorage.removeItem("ref");
    localStorage.removeItem("isOtpSubmit");
    localStorage.removeItem("isOtpVerify");
    localStorage.removeItem("authToken");
    const loggedEmail = localStorage.getItem("login_email");
    const user_email = searchParams.get("email");
    if (loggedEmail) {
      setEmail(loggedEmail);
    }
    if (user_email) {
      setEmail(atob(String(user_email)));
    }
  }, [searchParams]);

  // This variable determines whether password is shown or not
  const [isShown, setIsSHown] = useState(false);

  // This function is called when the checkbox is checked or unchecked
  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  useEffect(() => {
    if (email.length > 0) {
      setIsEmailValidate(
        emailValidations(email, configState?.email_rules?.regex_validation)
      );
    } else {
      setIsEmailValidate(false);
    }
  }, [configState?.email_rules?.regex_validation, email]);

  useEffect(() => {
    if (password.length > 0) {
      setIsPasswordValidate(
        passwordValidations(
          password,
          configState?.password_rules?.regex_validation
        )
      );
    } else {
      setIsPasswordValidate(false);
    }
  }, [configState?.password_rules?.regex_validation, password]);

  const handleAction = () => {
    if (email.length === 0) {
      setCanSubmit(false);
      showToastMessage("Email not empty!", true);
    } else if (password.length === 0) {
      setCanSubmit(false);
      showToastMessage("Password not empty!", true);
    } else if (canSubmit) {
      setRootLoader();
      signInWithEmailAndPassword(firbaseAuth, email, password)
        .then(async (userCredential: any) => {
          setTimeout(async () => {
            localStorage.removeItem("wrongAttemp");
            localStorage.setItem("login_email", email);

            localStorage.setItem("token", userCredential.user.accessToken);
            localStorage.setItem("uid", userCredential.user.uid);
            localStorage.setItem(
              "authToken",
              userCredential._tokenResponse.refreshToken
            );
            const consentStepModules = await consentDataLoad()
            await getFireStoreDoc("users/" + userCredential.user.uid).then(
              async (data: any) => {
                if (data && userCredential.user.uid && showMock) {
                  unSetRootLoader();
                  updateUserData(userCredential.user.uid, { is_mock: isMock });
                }

                if (
                  data?.user_data?.pwd_expiration_date &&
                  Math.round(
                    +new Date(data?.user_data?.pwd_expiration_date) / 1000
                  ) <= Math.round(Date.now() / 1000)
                ) {
                  setModalShow(true);
                } else if (consentStepModules && !data.hasOwnProperty(consentStepModules?.store_path)) {
                  localStorage.setItem("temppass", btoa(password));
                  unSetRootLoader();
                  navigate(routes.consent);
                } else if (data?.user_data?.temp_pwd_changed === false) {
                  localStorage.setItem("temppass", btoa(password));
                  unSetRootLoader();
                  navigate(routes.updateUserStep);
                } else if (data?.user_data?.sign_flow_completed === false) {
                  localStorage.setItem("temppass", btoa(password));
                  unSetRootLoader();
                  navigate(routes.updateUserStep);
                } else if (data?.user_data?.sign_flow_completed === true) {
                  unSetRootLoader();
                  const localOrgCode = localStorage.getItem("orgCode");
                  localStorage.setItem("isLogin", "true");
                  localStorage.removeItem("isOtpSubmit");
                  localStorage.setItem("isOtpVerify", "true");
                  saveMessageRegistrsationToken();
                  updatePrefetchData(localOrgCode)
                  navigate(routes.root);
                }
              }
            );
          }, 3000);
        })
        .catch((error) => {
          console.log('err', error)
          unSetRootLoader();
          localStorage.setItem("isOtpSubmit", "false");
          localStorage.setItem(
            "wrongAttemp",
            String(
              localStorage.getItem("wrongAttemp")
                ? Number(localStorage.getItem("wrongAttemp")) + 1
                : 1
            )
          );
          if (error.code === "auth/weak-password") {
            showToastMessage("Password should be at least 6 characters", true);
          } else if (error.code === "auth/wrong-password") {
            showToastMessage("Password don't match!", true);
          } else if (error.code === "auth/user-not-found") {
            showToastMessage("User not exist! Please signup", true);
          } else if (error.code === "auth/email-already-in-use") {
            showToastMessage("Email Already in Use", true);
          } else if (error.code === "auth/too-many-requests") {
            showToastMessage(
              "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later",
              true
            );
          } else {
            showToastMessage(error?.message, true);
          }
        });
    }
  };

  return (
    <div className="login-form-container">
      <div className="logo-login text-center">
        <img
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer cursor-pointer"
              : "cursor-pointer"
          }
          onClick={() => {
            navigate(routes.root);
          }}
          width={207}
          src={logoLogin}
          alt="logo"
        />
      </div>

      <div className="custom-mb-50">
        <div
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer input-wrapper"
              : "input-wrapper"
          }
        >
          <input
            type="text"
            id="login-email"
            name="login-email"
            autoComplete="off"
            value={email}
            onChange={(e) =>
              setEmail(dataProcess("lowercased", e.target.value))
            }
          />
          <label htmlFor="login-email" className={email !== "" ? "" : "hide"}>
            {translated("app_copy.email")}
          </label>
        </div>
        <div
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer input-wrapper"
              : "input-wrapper"
          }
        >
          <input
            type={isShown ? "text" : "password"}
            id="login-password"
            name="login-password"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword((e.target.value).trim())}
          />
          <label
            htmlFor="login-password"
            className={
              password !== "" ? "input-placeholder" : "input-placeholder hide"
            }
          >
            {get_user_email === null || get_user_email === undefined
              ? translated("app_copy.password")
              : translated("app_copy.sign_up_password_placeholder")}
          </label>
          <span className="show-hide-psw" onClick={togglePassword}>
            <img src={isShown ? hidePassword : password1} alt="hide"></img>
          </span>
        </div>
      </div>

      {Number(localStorage.getItem("wrongAttemp") ?? 0) >
        settingsState?.app_config?.login_max_retries && (
          <Recaptcha captchaRef={captchaRef} />
        )}

      <div className="text-center">
        <button
          disabled={
            !isEmailValidate ||
            !isPasswordValidate ||
            mainLoader ||
            remainingLoaderTime
          }
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer standard-btn"
              : "standard-btn"
          }
          onClick={handleAction}
        >
          <p className="text-18 bold color-blue">
            {get_user_email === null || get_user_email === undefined
              ? translated("cta.login")
              : translated("cta.signup")}
          </p>
        </button>
        {(get_user_email === null || get_user_email === undefined) && (
          <Link
            to={routes.forgotPassword}
            className={
              mainLoader || remainingLoaderTime
                ? "common-shimmer cursor-pointer text-18 bold color-blue text-underline custom-mt-28"
                : "cursor-pointer text-18 bold color-blue text-underline custom-mt-28"
            }
          >
            {translated("app_copy.forgot_password")}
          </Link>
        )}
      </div>

      <Modal
        modalTitle={translated("app_copy.alert_temp_pwd_expired_title")}
        show={ModalShow}
        size={"sm"}
        handleClose={() => {
          setModalShow(false);
        }}
      >
        <div className="modal-details">
          <p className="modal-data">
            {translated("app_copy.alert_temp_pwd_expired_msg")}
          </p>
        </div>
        <div className="custom-mt-20 custom-mb-30 row mx-2 d-flex justify-content-center custom-mb-24 custom-mt-50">
          <div className="custom-mb-15  width-236">
            <button
              className="custom-btn btn-ice-gradient"
              onClick={() => {
                setModalShow(false);
              }}
            >
              <p className="btn-text bold small-text blue">
                {translated("cta.ok")}
              </p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LoginForm;
