// eslint-disable-next-line import/no-anonymous-default-export
export default {
    root: '/',
    login: '/login',
    signup: '/signup',
    otpVerify: '/otp-verify',
    forgotPassword: '/forgot-password',
    userProfile: '/profile',
    settings: '/settings',
    action: '/action',
    category: '/category',
    subCategory: '/sub-category/:parent',
    posts: '/posts/:category',
    schedulingDeeplink: '/scheduling',
    singlePost: '/post-details/:slug',
    shopCart: '/shop-cart',
    postList: '/post-list',
    productList: '/product-list',
    productListItem: '/product-list/:id',
    productDetails: '/product-details/:slug',
    threedanimation: '/3d-animation',
    addresslist: '/addresses',
    cartCheckout: '/cart-checkout/:product_id',
    orderList: '/order-list',
    orderListPDF: '/order-PDF/:data',
    labReport: '/lab-report',
    onBoarding: '/on-boarding',
    fcm: '/fcm',
    essentialStep: '/essential-step',
    changePasswordStep: '/change-password-step',
    updateUserStep: '/update-user-step',
    authenticationStep: '/authentication',
    feedbackStep: '/feedback-step/:order_id',
    feedbackSubmit: '/feedback-success/:order_id',
    nonEssentialStep: '/non-essential-step',
    consent: '/consent',
    compoentscom: '/components',
    cantChooseProduct: '/cant-choose-product',
    emailVerify: '/email-verify',
    welcomeMember: '/welcome-member',
    wildhealth: '/respiratory/ky',
    preferences: '/preferences',
    passwordResetSuccess: '/password-reset-success',
    paymentEdit: '/payment-edit',
    resultView: '/results/:id',
    bioMarkerDetails: '/biomarker-details/:id/:order',
    bioMarkerPages: '/biomarker/:id',
    instruction: '/how-to-get-prepared/:order_id',
    orderComplete: '/order-complete',
    preTest: '/pre-test',
    // orderPaymentSuccess: '/order-payment-complete/:order_id',
    resultBoiMarker: '/result-biomarker-details/:order_id/:lab_item_id/:result_id',
    duser: '/temp_user',
    testTempUserCreate: '/ehr-mock-init',
    testTempUserCreateV2: '/ehr-mock-init-v2',
    addressValidation: '/address-validation',
    orderDetails: '/order-details/:order_id',
    appclip: '/appclip',
    chat: '/chat',
    azureChat: '/bot',
    confirmPayment: '/confirm',
}
