/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppContext } from "@/context/createContext";
import {
  base64Encode,
  findItemFromArray,
  fixScrollBarVisibility,
} from "@/helper/common";
import Pages from "@/layouts/Pages";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { back, calenderSvg, clockSvg, confirmCheck } from "@/config/svg";
import {
  createBookingSlot,
  getScheduleList,
  updateOrderData,
} from "@/services/api/fireFunction";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import LeftArrow from "@/components/svg/LeftArrow";
import RightArrow from "@/components/svg/RightArrow";
import DaySlot from "@/components/schedule_session/DaySlot";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SchedulingShimmer } from "@/components/shimmer";
import { Modal, OrderItem, Payment } from "@/components/index";
const PrevIcon = (props: any) => <LeftArrow {...props} />;
const NextIcon = (props: any) => <RightArrow {...props} />;

const Scheduling: React.FC = () => {
  const {
    modules,
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const [order, setOrder] = useState<any>(null);
  const [formData, setFormData] = useState<any>({
    name: userInfo?.user_data?.first_name
      ? userInfo?.user_data?.first_name +
      " " +
      userInfo?.user_data?.last_name
      : userInfo?.user_data?.name ?? "",
    email: userInfo?.user_data?.email ?? "",
    phone: userInfo?.user_data?.phone ?? "",
  });
  const [searchParams] = useSearchParams();
  const [stepNumber, setStepNumber] = useState(1);
  let navigate = useNavigate();
  const [ModalShow, setModalShow] = useState(false);

  let count = 0;
  const [selectedSlot, setSelectedSlot] = useState<any>(null);
  const [currentDateIndex, setCurrentDateIndex] = useState<any>(null);
  const [timeSlotList, setTimeSlotList] = useState<any>(null);
  const [selectedPayment, setSelectedPayment] = useState<string>("");
  const [paymentInfo, setPaymentInfo] = useState<any>(null);

  useEffect(() => {
    if (order) {
      if (order?.booking && ModalShow === false) {
        navigate("/order-details/" + base64Encode(order?.order_id));
      } else {
        let address = {
          state: order?.address?.state,
          street: order?.address?.street,
          city: order?.address?.city,
          zip_code: order?.address?.postal_code,
        };

        if (userInfo?.is_mock === true) {
          address = {
            state: "AZ", //orderItem?.address?.state,
            street: "8907 Legacy Park Dr", //orderItem?.address?.street,
            city: "Pheonix", //orderItem?.address?.city,
            zip_code: "85003", //orderItem?.address?.postal_code,
          };
        }
        const payload = {
          type: "GETLABS",
          output_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          from: moment().format("YYYY-MM-DD"),
          days: "5",
          address: address,
        };
        setRootLoader();
        getScheduleList(payload)
          .then((scheduleList: any) => {
            unSetRootLoader();
            if (scheduleList?.data?.data.length > 0) {
              setTimeSlotList(scheduleList?.data);
              setCurrentDateIndex(0);
            } else {
              setTimeSlotList(null);
              setCurrentDateIndex(null);
            }
          })
          .catch((err) => {
            if (err?.code === "ERR_NETWORK")
              showToastMessage(
                translated("error.network_connection_error"),
                true
              );
            unSetRootLoader();
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  const selectSlot = (timeSlot: any) => {
    setSelectedSlot(timeSlot);
  };

  const handleBooking = async () => {
    setRootLoader();
    var payload = {
      type: "GETLABS",
      user_id: userInfo?.authUId,
      order_id: String(order?.order_id),
      resource: {
        name: selectedSlot?.resource?.name,
        id: selectedSlot?.resource?.id,
        timezone: timeSlotList?.output_timezone,
      },
      start: selectedSlot.start,
      end: selectedSlot.end,
    };
    createBookingSlot(payload)
      .then((response: any) => {
        unSetRootLoader();
      })
      .catch((err) => {
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        unSetRootLoader();
      });
  };
  useEffect(() => {
    if (userInfo?.authUId && userInfo?.orders && count === 0) {
      try {
        const order_data: any = atob(String(searchParams.get("metadata")));
        const orderItem = findItemFromArray(
          userInfo?.orders,
          "order_id",
          JSON.parse(order_data)?.order_id
        );
        if (orderItem !== undefined) {
          count++;
          setOrder(orderItem);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [userInfo]);

  const handleBack = () => {
    if (stepNumber === 1) {
      navigate(-1);
    } else if (stepNumber === 2 && order?.status === "pending") {
      setStepNumber(3);
    } else if (stepNumber === 2 && order?.status !== "pending") {
      setStepNumber(1);
    } else if (stepNumber === 3 && order?.status === "pending") {
      setStepNumber(1);
    } else if (stepNumber === 3 && order?.status !== "pending") {
      setStepNumber(2);
    } else if (stepNumber === 4) {
      setStepNumber(3);
    }

    fixScrollBarVisibility();
  };

  const handleContinue = () => {
    if (stepNumber === 1 && order?.status !== "pending") {
      setStepNumber(2);
    } else if (stepNumber === 1 && order?.status === "pending") {
      setStepNumber(3);
    } else if (stepNumber === 2) {
      handleBooking().then(() => {
        updateOrderData(order?.order_id, {
          payment_info: { ...paymentInfo },
          status: "ph_visit_scheduled_success",
        });
        setModalShow(true);
      });
    } else if (stepNumber === 3) {
      setStepNumber(4);
    }

    fixScrollBarVisibility();
  };

  const orderSubmit = (payment: any) => {
    setPaymentInfo(payment);
    if (
      payment?.payment_status === "failed" ||
      payment?.status !== "succeeded"
    ) {
      showToastMessage("Payment failed!", true);
      unSetRootLoader();
    } else {
      updateOrderData(order?.order_id, {
        payment_info: { ...payment },
        status: "payment_success",
      }).then(() => {
        handleBooking().then(() => {
          //showToastMessage("Order purchase successfully!");
          setModalShow(true);
        });
      });
    }
  };

  useEffect(() => {
    if (modules?.scheduling === false) {
      navigate(routes.root);
    }
  }, []);

  const submitAppionment = () => {
    if (modules?.payment === false) {
      handleBooking().then(() => {
        //showToastMessage("Order purchase successfully!");
        setModalShow(true);
      });
    } else {
      setSelectedPayment("Stripe");
      setStepNumber(4);
      fixScrollBarVisibility();
    }
  }
  return (
    <Pages>
      <section className="schedule-appointment-section">
        {stepNumber === 4 ? (
          <Payment
            selectedPayment={selectedPayment}
            handleBack={handleBack}
            product={null}
            order={order}
            submitedOrder={orderSubmit}
          />
        ) : (
          <div className="two-columns-layout">
            <div className="half-col left-col">
              {stepNumber !== 2 && order?.status === "pending" && (
                <button className="go-back-button" onClick={handleBack}>
                  <img className="forward-icon" src={back} alt="back" />
                </button>
              )}
              <div className="orders-wrapper">
                <p className="text-18 bold color-blue custom-mb-20">
                  {(stepNumber === 1 || stepNumber === 3) &&
                    translated("app_copy.we_come_to_you")}
                </p>
                <h2 className="text-36 custom-mb-50">
                  {stepNumber === 1 &&
                    translated("app_copy.first_choose_date_and_time")}
                  {stepNumber === 3 &&
                    translated("app_copy.confirm_your_order")}
                </h2>
                <OrderItem
                  order={order}
                  showDetails={() => {
                    navigate("/order-details/" + base64Encode(order?.order_id));
                  }}
                />
              </div>
            </div>
            <div className="sub-separator-vertical"></div>
            <div className="half-col right-col">
              {mainLoader || remainingLoaderTime ? (
                <SchedulingShimmer />
              ) : order ? (
                <>
                  {stepNumber === 1 &&
                    timeSlotList &&
                    timeSlotList?.data?.length > 0 ? (
                    <div className="scheduling-slots-wrapper">
                      <p className="text-center">
                        {translated("app_copy.your_date")}
                      </p>
                      <div className="scheduling-slots-header">
                        <button
                          disabled={currentDateIndex === 0}
                          onClick={() =>
                            setCurrentDateIndex(currentDateIndex - 1)
                          }
                          className="small-btn"
                        >
                          <PrevIcon />
                        </button>
                        <h5 className="slot-date text-25 bold">
                          {moment(
                            timeSlotList?.data[currentDateIndex]?.date
                          ).format("LL")}
                        </h5>
                        <button
                          disabled={
                            currentDateIndex + 1 === timeSlotList?.data?.length
                          }
                          onClick={() =>
                            setCurrentDateIndex(currentDateIndex + 1)
                          }
                          className="small-btn"
                        >
                          <NextIcon />
                        </button>
                      </div>
                      <DaySlot
                        timeSlot={timeSlotList?.data[currentDateIndex]}
                        selectSlot={selectSlot}
                      />
                    </div>
                  ) : (
                    stepNumber === 1 && (
                      <h2 className="h-100">
                        {translated("app_copy.no_slot_available")}
                      </h2>
                    )
                  )}
                  {stepNumber === 2 && (
                    <div className="container-fluid day-slot width-400">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          readOnly
                          autoComplete="off"
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({
                              name: e.target.value,
                              ...formData,
                            })
                          }
                          placeholder="Your name."
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          readOnly
                          autoComplete="off"
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({
                              email: e.target.value,
                              ...formData,
                            })
                          }
                          placeholder={translated("app_copy.email_address")}
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="phone"
                          id="phone"
                          readOnly
                          autoComplete="off"
                          value={formData.phone}
                          onChange={(e) =>
                            setFormData({
                              phone: e.target.value,
                              ...formData,
                            })
                          }
                          placeholder="Your phone."
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}

                  {stepNumber === 3 && (
                    <>
                      <PerfectScrollbar>
                        <div className="order-checkout-wrapper">
                          <div className="flex-center-between custom-mb-15">
                            <p className="text-16 bold color-blue flex-center-between">
                              <img
                                src={calenderSvg}
                                alt="Calendar"
                                className="calendar custom-mr-15"
                              />
                              {moment(selectedSlot.start).format("LL")}
                            </p>
                            <p className="text-16 bold color-blue flex-center-between">
                              <img
                                src={clockSvg}
                                alt="Clock"
                                className="clock custom-mr-15"
                              />
                              {moment(selectedSlot.start).format("LT")} -{" "}
                              {moment(selectedSlot.end).format("LT")}
                            </p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <h2 className="text-32 custom-mb-30">
                              {translated("app_copy.order_with_id").replace(
                                "{#ORDERID#}",
                                order?.order_number
                              )}
                            </h2>
                          </div>

                          <div className="custom-mb-30">
                            {/* <p className="text-16 bold">We come to you</p> */}
                            <p className="address-details text-16">
                              <span>{order?.address?.street} </span>
                              <br />
                              <span>{order?.address?.city}, </span>

                              <span>{order?.address?.state} </span>
                              <span>{order?.address?.postal_code}</span>
                              <br />
                              <span>
                                {userInfo?.address[0]?.country}
                              </span>
                            </p>
                          </div>

                          <div className="sub-separator"></div>

                          <div className="custom-mt-30">
                            <div className="d-flex justify-content-between custom-mb-10">
                              <p className="text-16">
                                {" "}
                                {translated("app_copy.mobile_testing_fee")}
                              </p>
                              <p className="text-16">
                                ${order?.order_fee?.getlabs_fee.toFixed(2)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between custom-mb-10">
                              <p className="text-16">
                                {translated("app_copy.claire_convenience_fee")}
                              </p>
                              <p className="text-16">
                                ${order?.order_fee?.connivence_fee.toFixed(2)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between custom-mb-10">
                              <p className="text-16">
                                {translated("app_copy.stripe_fee")}
                              </p>
                              <p className="text-16">
                                ${order?.order_fee?.stripe_fee.toFixed(2)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between custom-mb-20">
                              <p className="text-16 bold">
                                {translated("app_copy.total")}
                              </p>
                              <p className="text-16 bold">
                                ${order?.total_price.toFixed(2)}
                              </p>
                            </div>
                            <p className="text-12 text-center ">
                              {translated(
                                "app_copy.claire_convenience_fee_instruction"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="btn-wrapper text-center">
                          <button
                            className="standard-btn"
                            onClick={submitAppionment}
                          >
                            <p className="text-18 bold color-blue">
                              {translated("cta.at_home_checkout_cta")}
                            </p>
                          </button>
                        </div>
                      </PerfectScrollbar>
                    </>
                  )}

                  {(stepNumber === 1 || stepNumber === 2) &&
                    timeSlotList &&
                    timeSlotList?.data?.length > 0 && (
                      <div className="btn-wrapper text-center">
                        <button
                          disabled={selectedSlot === null}
                          className="standard-btn"
                          onClick={handleContinue}
                        >
                          <p className="text-18 bold color-blue">
                            {translated("cta.confirm")}
                          </p>
                        </button>
                      </div>
                    )}
                </>
              ) : (
                <p className="text-center">Order not found</p>
              )}
            </div>
          </div>
        )}

        <Modal
          size={"sm"}
          modalTitle={""}
          show={ModalShow}
          modalClosingButton={false}
          handleClose={() => {
            setModalShow(false);
            navigate(routes?.root);
          }}
        >
          <div className="confirm-modal text-center">
            <img className="confirm-icon" src={confirmCheck} alt="confirm" />
            <h2 className="text-18 bold black custom-mt-30 custom-mb-30">
              {translated("app_copy.order_confirmed")}
            </h2>
            <div className="sub-separator"></div>
            <p className="text-16 custom-mb-30">
              {translated("app_copy.order_confirmed_description")}
            </p>
            <div className="width-236 custom-mt-20">
              <button
                className="standard-btn"
                onClick={() => {
                  setModalShow(false);
                  navigate(routes?.root);
                }}
              >
                <p className="text-18 color-blue">{translated("cta.ok")}</p>
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </Pages>
  );
};

export default Scheduling;
