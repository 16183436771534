/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { back } from "@/config/svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import routes from "@/config/routes";
import { AttributeType } from "@/interface/index";
import {
  DateInput,
  EmailInput,
  NumberInput,
  PhoneInput,
  // ProgressBar,
  SelectedOption,
  StringInput,
  ConsentLink,
  CheckBoxInput,
  SelectionCheckbox,
  SelectedDropdown,
  CapturedImage,
  ZipcodeInput,
  SelectionSwitch,
  SwitchSelection,
  TitleType,
  BioMarkerInput,
  PasswordInput,
  OTPInput,
  GenderInput,
  ConsentCheckbox,
} from "@/components/index";
import {
  getFireStoreDoc,
  setUserData,
  updateUserData,
} from "@/services/firebase/fireStore";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
} from "firebase/auth";
// import jsonStep from "../../onboarding.json";
// import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { toast } from "react-toastify";
import {
  commonSentOTP,
  commonVerifyOTP,
  createStripeCustomer,
} from "@/services/api/fireFunction";

import {
  ShimmerButton,
  ShimmerTitle,
  ShimmerText,
} from "../../packages/Shimmer";
const ConsentStep: React.FC = () => {
  const auth = getAuth();
  const {
    modules,
    showToastMessage,
    userInfo,
    stepData,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    translated,
    updatePrefetchData
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isContinue, setIsContinue] = useState(false);
  const [onConsentStep, setOnConsentStep] = useState<any>([]);
  const [previousData, setPreviousData] = useState<any>(null);
  // const [previousUserData, setPreviousUserData] = useState<any>({});
  const [isLoader, setIsLoader] = useState(false);
  const [consentStep, setConsentSteps] = useState<any>([]);
  const [modulePath, setModulePath] = useState<string>("");
  const [progress, setProgress] = useState(
    Number(searchParams.get("step") ? searchParams.get("step") : 1)
  );
  const [currentStep, setCurrentStep] = useState<any>();
  const [currentStepValue, setCurrentStepValue] = useState<any>({});
  const { search } = useLocation();
  useEffect(() => {
    const currentStep = Number(
      searchParams.get("step") ? searchParams.get("step") : 1
    );
    setProgress(currentStep);
    if (currentStep !== 1) {
      if (
        stepData?.consent?.steps &&
        stepData?.consent?.steps[currentStep - 1]?.conditions !== undefined
      ) {
        var conditions: any =
          stepData?.consent?.steps[currentStep - 1]?.conditions;
        if (conditions.length > 0) {
          setRootLoader();
          conditions.forEach((item: any) => {
            getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
              if (
                keys !== undefined &&
                keys[item.scope] !== undefined &&
                keys[item.scope]
              ) {
                if (keys[item.scope][item.attribute] !== item.value) {
                  handleContinue();
                  unSetRootLoader();
                  return false;
                } else {
                  unSetRootLoader();
                }
              } else {
                unSetRootLoader();
              }
            });
          });
        }
      }

      if (
        stepData?.consent?.steps &&
        stepData?.consent?.steps[currentStep - 1]?.is_active !== undefined &&
        stepData?.consent?.steps[currentStep - 1]?.is_active === false
      ) {
        nextStep();
      }
    }
  }, [search, stepData?.consent]);
  useEffect(() => {
    if (stepData?.consent === null) {
      setIsLoader(true);
    }
    if (stepData?.consent?.steps?.length > 0) {
      setIsLoader(false);
      setConsentSteps(stepData?.consent?.steps);
      setCurrentStep(stepData?.consent?.steps[progress - 1]);
      setModulePath(stepData?.consent?.store_path);
    }

    if (stepData?.consent?.steps?.length < progress) {
      setIsLoader(true);
      // showToastMessage("!", true);
      navigate(routes.root);
      setIsLoader(false);
    }

    if (modulePath !== "") {
      // setIsLoader(true);
      const consentData = localStorage.getItem(
        String(userInfo?.authUId + "consent")
      )
        ? JSON.parse(
          atob(
            String(
              localStorage.getItem(String(userInfo?.authUId + "consent"))
            )
          )
        )
        : {};
      setPreviousData(consentData);
      // getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
      //   if (
      //     keys !== undefined &&
      //     keys[modulePath] !== undefined &&
      //     keys[modulePath]
      //   ) {
      //     setIsLoader(false);
      //     setPreviousData(keys[modulePath]);
      //   } else {
      //     setIsLoader(false);
      //   }
      // });
    }
  }, [stepData?.consent, progress, searchParams, modulePath, currentStep]);

  useEffect(() => {
    setIsContinue(false);
  }, [progress]);

  useEffect(() => {
    // getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
    // })
    if (modules?.consent === false) {
      if (
        userInfo?.user_data?.sign_flow_completed === false
      ) {
        navigate(routes.updateUserStep);
      } else if (
        userInfo?.user_data?.sign_flow_completed === true
      ) {
        localStorage.removeItem("temppass")
        localStorage.setItem("isLogin", "true");
        localStorage.removeItem("isOtpSubmit");
        localStorage.setItem("isOtpVerify", "true");
        const localOrgCode = localStorage.getItem("orgCode");
        updatePrefetchData(localOrgCode)
        navigate(routes.root);
      }
    }
    document.body.className = "essential-area consent";

    return () => {
      document.body.className = "";
    };
  }, []);
  const sentOTP = () => {
    setRootLoader();
    var OTPPayload = {
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    };

    commonSentOTP(OTPPayload)
      .then((response: any) => {
        if (response?.data?.success) {
          showToastMessage("OTP sent successfully!");
          nextStep();
          unSetRootLoader();
        }
        if (response?.data?.error) {
          unSetRootLoader();
          showToastMessage(response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage("Something went wrong", true);
      });
  };

  const verifyOTP = () => {
    setRootLoader();
    commonVerifyOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
      otp: currentStepValue?.otp ?? previousData.otp,
    })
      .then((res: any) => {
        if (res?.data?.success) {
          showToastMessage("OTP verified successfully!");
          const credential = EmailAuthProvider.credential(
            currentStepValue?.email ?? previousData.email,
            currentStepValue?.password ?? previousData.password
          );
          if (auth?.currentUser) {
            linkWithCredential(auth?.currentUser, credential)
              .then((userCredential: any) => {
                if (userInfo.authUId) {
                  setTimeout(() => {
                    createStripeCustomer({
                      user_id: userInfo?.authUId,
                    });
                  }, 1000);
                  nextStep();
                  unSetRootLoader();
                }
              })
              .catch((error) => {
                unSetRootLoader();
                //console.log("error", error);
                if (error.code === "auth/weak-password") {
                  showToastMessage(
                    "Password should be at least 6 characters",
                    true
                  );
                }
                if (error.code === "auth/wrong-password") {
                  showToastMessage("Please check the Password.", true);
                }
                if (error.code === "auth/user-not-found") {
                  showToastMessage("User not exist! Please signup", true);
                }
                if (error.code === "auth/email-already-in-use") {
                  showToastMessage("Email already in use", true);
                }
                if (error.code === "auth/provider-already-linked") {
                  showToastMessage("Same email provider already in use", true);
                }
                // navigate(routes.signup);
              });
          }
        }
        if (res?.data?.error) {
          unSetRootLoader();
          showToastMessage(res?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  const resentOTP = () => {
    setRootLoader();

    commonSentOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    })
      .then((res: any) => {
        unSetRootLoader();
        //console.log(res?.response.data.error.message);
        if (res?.data?.success) {
          showToastMessage("OTP Resent successfully!");
        }
        if (res?.response?.data?.error) {
          showToastMessage(res?.response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  const checkEmailExist = () => {
    setRootLoader();
    fetchSignInMethodsForEmail(
      auth,
      currentStepValue?.email ?? previousData.email
    )
      .then((signInMethods) => {
        unSetRootLoader();
        if (
          signInMethods.indexOf(
            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          ) !== -1 ||
          signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !==
          -1
        ) {
          toast.error("Email already exist!");

          // User can sign in with email/password.
        } else {
          if (userInfo?.authUId) {
            if (
              userInfo?.user_data !== null ||
              Object.keys(previousData).length > 0
            ) {
              updateUserData(userInfo?.authUId, {
                [modulePath]: { ...previousData, ...currentStepValue },
              });
            } else {
              setUserData(userInfo?.authUId, {
                [modulePath]: { ...previousData, ...currentStepValue },
              });
            }
          }
          nextStep();
        }
      })
      .catch((error) => {
        unSetRootLoader();
        toast.error("Something went wrong!");
        // Some error occurred, you can inspect the code: error.code
      });
  };

  const saveConsent = async () => {
    const consentData = localStorage.getItem(
      String(userInfo?.authUId + "consent")
    )
      ? JSON.parse(
        atob(
          String(localStorage.getItem(String(userInfo?.authUId + "consent")))
        )
      )
      : null;
    if (userInfo?.authUId) {
      await updateUserData(userInfo?.authUId, {
        [modulePath]: {
          ...previousData,
          ...consentData,
        },
        [stepData?.onboarding?.store_path + ".consent"]: true,
      });
    }
    // else {
    //   if (userInfo?.authUId) {
    //     await setUserData(userInfo?.authUId, {
    //       [modulePath]: {
    //         ...consentData,
    //       },
    //       [stepData?.onboarding?.store_path + ".consent"]: true,
    //     });
    //   }
    // }
  };

  const nextStep = () => {
    if (progress === stepData?.consent?.steps?.length) {
      setRootLoader();
      saveConsent().then(() => {
        unSetRootLoader();
        if (userInfo?.user_data?.temp_pwd_changed === false) {
          navigate(routes.updateUserStep);
        } else if (
          userInfo?.user_data?.sign_flow_completed === false
        ) {
          navigate(routes.updateUserStep);
        } else if (
          userInfo?.user_data?.sign_flow_completed === true
        ) {
          localStorage.removeItem("temppass")
          localStorage.setItem("isLogin", "true");
          localStorage.removeItem("isOtpSubmit");
          localStorage.setItem("isOtpVerify", "true");
          const localOrgCode = localStorage.getItem("orgCode");
          updatePrefetchData(localOrgCode)
          navigate(routes.root);
        }
      });
    } else {
      const nextStep = progress + 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(routes.consent + "?step=" + nextStep);
    }
  };
  const handleContinue = () => {
    if (currentStep?.id === "id_phone") {
      sentOTP();
    } else if (
      currentStepValue["otp"] !== undefined &&
      currentStep?.id === "id_otp"
    ) {
      verifyOTP();
    } else if (
      currentStepValue["email"] !== undefined &&
      currentStep?.template === "input"
    ) {
      checkEmailExist();
    } else {
      nextStep();
      // setIsLoader(true)
      // setTimeout(() => {
      //   setIsLoader(false)

      //   nextStep();
      // }, 1000)
      if (userInfo?.authUId) {
        // if (
        //   userInfo?.user_data !== null ||
        //   Object.keys(previousData).length > 0
        // ) {
        //   updateUserData(userInfo?.authUId, {
        //     [modulePath]: { ...previousData, ...currentStepValue },
        //   });
        // } else {
        //   setUserData(userInfo?.authUId, {
        //     [modulePath]: { ...previousData, ...currentStepValue },
        //   });
        // }
        // if (
        //   userInfo?.user_data?.consent === undefined ||
        //   !userInfo?.user_data?.consent
        // ) {
        //   setRootLoader();
        //   updateUserData(userInfo?.authUId, {
        //     user_data: { ...userInfo?.user_data, consent: true },
        //   })
        //     .then(() => {
        //       unSetRootLoader();
        //       nextStep();
        //     })
        //     .catch(() => {
        //       unSetRootLoader();
        //       nextStep();
        //     });
        // } else {
        //   nextStep();
        // }
      }
    }
  };

  const handleSkip = () => {
    if (
      currentStep.skip_to_finish !== undefined &&
      currentStep.skip_to_finish === true
    ) {
      navigate(routes.root); //removed product
    } else if (currentStep?.skip_to && currentStep?.skip_to !== "") {
      stepData?.consent?.steps?.map((step: any, index: number) => {
        if (step.id === currentStep?.skip_to) {
          const nextStep = index + 1;
          setProgress(nextStep);
          setIsContinue(false);
          navigate(routes.consent + "?step=" + nextStep);
        }
      });
    } else {
      navigate(routes.essentialStep); //removed product
    }
  };

  const submitData = (isContinue: boolean, key: string, value: any) => {
    if (
      value !== undefined &&
      value !== "" &&
      key !== "attestation_of_no_emergency" &&
      key !== "terms_of_use" &&
      key !== "privacy_policy" &&
      key !== "your_telehealth_informed_consent" &&
      key !== "notice_of_privacy_practices"
    ) {
      localStorage.setItem(
        String(userInfo?.authUId + "consent"),
        btoa(
          JSON.stringify({
            ...previousData,
            ...currentStepValue,
            [key]: value,
          })
        )
      );
    }

    if (
      key !== "biomarker" &&
      key !== "password" &&
      key !== "confirm_password"
    ) {
      setCurrentStepValue({
        ...currentStepValue,
        [key]: value,
      });
    }

    const newData = [
      ...onConsentStep.filter((data: any) => {
        return data.key !== key;
      }),
      {
        isContinue,
        key,
        inputId: currentStep?.id,
        value,
      },
    ];
    setOnConsentStep(newData);
    let continueActive: boolean = isContinue;
    newData.map((item: any) => {
      if (item.isContinue === false && item.inputId === currentStep?.id) {
        continueActive = false;
      }
    });
    setIsContinue(continueActive);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Pages>
      <section className="terms-conditions-section">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            {currentStep?.back_enabled !== false && (
              <button className="go-back-button">
                <img
                  className={
                    mainLoader || remainingLoaderTime ? "common-shimmer" : ""
                  }
                  src={back}
                  alt="back"
                  onClick={handleBack}
                />
              </button>
            )}
            <p
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-18 bold color-blue custom-mb-20"
                  : "text-18 bold color-blue custom-mb-20"
              }
            >
              {currentStep?.header}
            </p>
            <h2
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-36 custom-mb-25"
                  : "text-36 custom-mb-25"
              }
            >
              {currentStep?.title}
            </h2>
            <p
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-16"
                  : "text-16"
              }
            >
              {currentStep?.description}
            </p>
          </div>
          <div className="half-col right-col align-block-center">
            {previousData !== null && consentStep.length > 0 && (
              <div className="right-clinical-inner">
                <div>
                  {isLoader ? (
                    <div className="form-group">
                      <ShimmerTitle line={1} gap={15} variant="primary" />
                      <ShimmerText line={1} gap={10} />
                      <div className="mb-3  ">
                        <ShimmerTitle line={1} gap={15} variant="primary" />
                        <ShimmerText line={5} gap={10} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {currentStep?.template === "input" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              key={index.toString() + attribute.key}
                              className={`input-wrapper custom-mb-15 ${attribute.type
                                } ${attribute.type === "separator"
                                  ? "custom-mt-30 custom-mb-30"
                                  : ""
                                }`}
                            >
                              {attribute.type === "string" && (
                                <StringInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute?.type === "gender" && (
                                <GenderInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "email" && (
                                <EmailInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "zip_code" && (
                                <ZipcodeInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "date" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <DateInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                />
                              )}
                              {attribute.type === "number" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <NumberInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "phone" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <PhoneInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "text_label" && (
                                <ConsentLink
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    // setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key] ?? false
                                  }
                                  isShimmer={
                                    mainLoader || remainingLoaderTime
                                  }
                                />
                              )}
                              {attribute.type === "boolean" && (
                                <CheckBoxInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key] ?? false
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "dropdown" && (
                                <SelectedDropdown
                                  attribute={attribute}
                                  config={currentStep?.config}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                />
                              )}
                              {attribute.type === "section_title" && (
                                <TitleType
                                  isShimmer={
                                    mainLoader || remainingLoaderTime
                                  }
                                  attribute={attribute}
                                />
                              )}
                              {attribute.type === "switch_selection" && (
                                <SwitchSelection
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                />
                              )}
                              {attribute.type === "check_box" && (
                                <ConsentCheckbox
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  isShimmer={
                                    mainLoader || remainingLoaderTime
                                  }
                                  autoFocus={!index}
                                />
                              )}
                            </div>
                          )
                        )}
                      {currentStep?.template === "input_checkbox" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30"
                              key={index.toString() + attribute.key}
                            >
                              {attribute.type === "string" && (
                                <StringInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "check_box" && (
                                <ConsentCheckbox
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute?.type === "gender" && (
                                <GenderInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "email" && (
                                <EmailInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "zip_code" && (
                                <ZipcodeInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "date" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <DateInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                />
                              )}
                              {attribute.type === "number" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <NumberInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "phone" && (
                                // eslint-disable-next-line react/jsx-no-undef
                                <PhoneInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  autoFocus={!index}
                                />
                              )}

                              {attribute.type === "boolean" && (
                                <CheckBoxInput
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key] ?? false
                                  }
                                  autoFocus={!index}
                                />
                              )}
                              {attribute.type === "dropdown" && (
                                <SelectedDropdown
                                  attribute={attribute}
                                  config={currentStep?.config}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                />
                              )}
                              {attribute.type === "section_title" && (
                                <TitleType attribute={attribute} />
                              )}
                              {attribute.type === "switch_selection" && (
                                <SwitchSelection
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                />
                              )}
                            </div>
                          )
                        )}
                      {currentStep?.template === "selection_checkbox" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12  custom-mb-30"
                              key={index.toString() + attribute.key}
                            >
                              <SelectionCheckbox
                                multiSelection={currentStep.multi_selection}
                                attribute={attribute}
                                config={currentStep?.config}
                                oldSelection={
                                  previousData[attribute.key]
                                    ? previousData[attribute.key]
                                    : ""
                                }
                                submitData={(iContinue, key, value) => {
                                  setIsContinue(iContinue);
                                  submitData(iContinue, key, value);
                                }}
                              />
                            </div>
                          )
                        )}
                      {currentStep?.template === "selection_switch" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div className="col-sm-12" key={index.toString() + attribute.key}>
                              <SelectionSwitch
                                multiSelection={currentStep.multi_selection}
                                attribute={attribute}
                                config={currentStep?.config}
                                oldSelection={
                                  previousData[attribute.key]
                                    ? previousData[attribute.key]
                                    : ""
                                }
                                submitData={(iContinue, key, value) => {
                                  setIsContinue(iContinue);
                                  submitData(iContinue, key, value);
                                }}
                              />
                            </div>
                          )
                        )}
                      {currentStep?.template === "selection" &&
                        currentStep?.config?.options !== undefined &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30"
                              key={index.toString() + attribute.key}
                            >
                              <SelectedOption
                                multiSelection={currentStep.multi_selection}
                                attribute={attribute}
                                config={currentStep?.config}
                                fetch_options={currentStep?.config?.fetch_options ?? false}
                                oldSelection={
                                  previousData[attribute.key]
                                    ? previousData[attribute.key]
                                    : ""
                                }
                                submitData={(iContinue, key, value) => {
                                  setIsContinue(iContinue);
                                  submitData(iContinue, key, value);
                                }}
                              />
                            </div>
                          )
                        )}

                      {currentStep?.template === "capture_image" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30 "
                              key={index.toString() + attribute.key}
                            >
                              <CapturedImage
                                attribute={attribute}
                                submitData={(iContinue, key, value) => {
                                  setIsContinue(iContinue);
                                  submitData(iContinue, key, value);
                                }}
                                oldSelection={
                                  previousData[attribute.key]
                                    ? previousData[attribute.key]
                                    : ""
                                }
                              />
                            </div>
                          )
                        )}

                      {
                        currentStep?.template === "input_biomarker" && (
                          // currentStep?.attributes?.map(
                          //   (attribute: AttributeType, index: number) => (
                          <div
                            className="col-sm-12 custom-mb-30"
                          //key={index.toString()}
                          >
                            <BioMarkerInput
                              attribute={currentStep?.attributes[0]}
                              oldSelection={""}
                              submitData={(iContinue, key, value) => {
                                setIsContinue(iContinue);
                                submitData(iContinue, key, value);
                              }}
                            />
                          </div>
                        )
                        // )
                        //)
                      }
                      {currentStep?.template === "input_password" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30"
                              key={index.toString() + attribute.key}
                            >
                              {attribute.type === "password" && (
                                <PasswordInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  compareValidationData={
                                    attribute?.validation?.compare_attribute &&
                                      localStorage.getItem("creadValue")
                                      ? atob(
                                        String(
                                          localStorage.getItem("creadValue")
                                        )
                                      )
                                      : null
                                  }
                                  autoFocus={!index}
                                />
                              )}
                            </div>
                          )
                        )}
                      {currentStep?.template === "static_component" &&
                        currentStep?.attributes?.map(
                          (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30"
                              key={index.toString() + attribute.key}
                            >
                              {attribute.type === "otp" && (
                                <OTPInput
                                  attribute={attribute}
                                  index={index}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute.key]
                                      ? previousData[attribute.key]
                                      : ""
                                  }
                                  resentOTP={resentOTP}
                                  autoFocus={!index}
                                />
                              )}
                            </div>
                          )
                        )}
                    </>
                  )}
                </div>

                {isLoader ? (
                  <div className="w-100 text-center">
                    <div className="col text-center">
                      <ShimmerButton />
                    </div>
                  </div>
                ) : (
                  <div className="custom-mt-30">
                    <button
                      disabled={
                        !isContinue || mainLoader || remainingLoaderTime
                      }
                      className={
                        mainLoader || remainingLoaderTime
                          ? "common-shimmer standard-btn"
                          : "standard-btn"
                      }
                      onClick={handleContinue}
                    >
                      <p className="text-18 bold color-blue">
                        {currentStep?.primary_button_text ??
                          translated("cta.next")}
                      </p>
                    </button>

                    {currentStep?.skip_to !== undefined && (
                      <button
                        className={"standard-btn"}
                        onClick={() => {
                          handleSkip();
                        }}
                      >
                        <p className="text-18 bold color-blue">
                          {currentStep?.skip_button_text ??
                            translated("cta.skip")}
                        </p>
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default ConsentStep;
