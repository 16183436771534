/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
// import { camera, gallery } from "@/config/svg";
// import CropEasy from "@/components/profile_image/CropEasy";
// import DeviceCamera from "@/components/profile_image/DeviceCamera";
// import { Modal, Spiner, StringInput } from "@/components/index";
import {
  // uploadImageToStorage,
  storageDownloadURL,
} from "@/services/firebase/fireStorage";
// import noProfileImage from "../../assets/img/no_profile.png";
import profileCameraImage from "../../assets/img/profile_camera.png";

// import moment from "moment";
import { ProfileShimmer } from "@/components/shimmer";
// const MB = 1024 * 1024;
// const MAX_FILE_SIZE = 1;
// const FILE_TYPE = ["image/jpeg", "image/png", "image/jpg"];

const Profile: React.FC = () => {
  const { userInfo } = useContext(AppContext);
  const [photoURL, setPhotoURL] = useState("");
  const [loader, setLoader] = useState(true);
  const [shimmer, setShimmer] = useState(true);
  // const fileInput = useRef<any>();
  // const [openCamera, setopenCamera] = useState(false);
  // const [openCrop, setOpenCrop] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [isLoader, setIsLoader] = useState(false);
  // const [ModalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (userInfo?.user_data?.first_name) {
      setFirstName(userInfo?.user_data?.first_name);
    }
    if (userInfo?.user_data?.last_name) {
      setLastName(userInfo?.user_data?.last_name);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.user_data) {
      setTimeout(() => {
        setShimmer(false);
      }, 500);
    }
  }, [userInfo]);

  useEffect(() => {
    if (photoURL === "") {
      if (
        userInfo?.non_essential?.profile_picture !== undefined &&
        userInfo?.non_essential?.profile_picture &&
        userInfo?.non_essential?.profile_picture !== ""
      ) {
        setLoader(true);
        storageDownloadURL(
          "users/" + userInfo?.authUId + "/profile_picture"
        ).then((url: any) => {
          setPhotoURL(url);
          setLoader(false);
        });
      } else if (
        userInfo?.non_essential?.profile_picture === undefined
      ) {
        setPhotoURL(profileCameraImage);
        setLoader(false);
      }
    }
  }, [photoURL]);

  // const handleFileChange = async (event: any) => {
  //   const picture = event.target.files[0];
  //   if (picture?.size / MB > MAX_FILE_SIZE) {
  //     showToastMessage(`Maximum file size ${MAX_FILE_SIZE} MB!`, true);
  //   } else if (!FILE_TYPE.includes(picture?.type)) {
  //     showToastMessage(`File not allowed!`, true);
  //   } else {
  //     if (picture) {
  //       setPhotoURL(URL.createObjectURL(picture));
  //     }
  //     setModalShow(false);
  //     setOpenCrop(true);
  //   }
  // };

  // const fileUpload = async (file: any) => {
  //   if (userInfo?.authUId) {
  //     const fileUrl = "users/" + userInfo?.authUId + "/profile_picture";
  //     uploadImageToStorage(file, fileUrl).on(
  //       "state_changed",
  //       async (snapshot) => {
  //         // Observe state change events such as progress, pause, and resume
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         setIsLoader(true);
  //         setPhotoURL("");
  //         await getFireStoreDoc("users/" + userInfo?.authUId).then(
  //           (keys: any) => {
  //             if (
  //               keys !== undefined &&
  //               keys?.non_essential !== undefined &&
  //               userInfo?.authUId
  //             ) {
  //               updateUserData(userInfo?.authUId, {
  //                 non_essential: {
  //                   ...keys?.non_essential,
  //                   profile_picture: "",
  //                 },
  //               });
  //             } else {
  //               if (userInfo?.authUId) {
  //                 setUserData(userInfo?.authUId, {
  //                   non_essential: {
  //                     profile_picture: "",
  //                   },
  //                   ...keys,
  //                 });
  //               }
  //             }
  //           }
  //         );
  //         const progress =
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         //console.log("Upload is " + progress + "% done");
  //         switch (snapshot.state) {
  //           case "paused":
  //             console.log("Upload is paused");
  //             break;
  //           case "running":
  //             console.log("Upload is running");
  //             break;
  //         }

  //         if (progress === 100) {
  //           console.log("Uploaded");
  //           await storageDownloadURL(fileUrl).then((url: any) => {
  //             setPhotoURL(url);
  //             getFireStoreDoc("users/" + userInfo?.authUId).then(
  //               (keys: any) => {
  //                 if (
  //                   keys !== undefined &&
  //                   keys?.non_essential !== undefined &&
  //                   userInfo?.authUId
  //                 ) {
  //                   updateUserData(userInfo?.authUId, {
  //                     non_essential: {
  //                       ...keys?.non_essential,
  //                       profile_picture: fileUrl,
  //                     },
  //                   });
  //                   showToastMessage(
  //                     translated("error.Profile_image_updated_successfully")
  //                   );
  //                   //window.location.reload();
  //                   setIsLoader(false);
  //                 } else {
  //                   if (userInfo?.authUId) {
  //                     setUserData(userInfo?.authUId, {
  //                       non_essential: {
  //                         profile_picture: fileUrl,
  //                       },
  //                       ...keys,
  //                     }).then(() => {
  //                       showToastMessage(
  //                         translated("error.Profile_image_updated_successfully")
  //                       );
  //                       //window.location.reload();
  //                       setIsLoader(false);
  //                     });
  //                   }
  //                 }
  //               }
  //             );
  //           });
  //         }
  //       },
  //       (error) => {
  //         switch (error.code) {
  //           case "storage/unauthorized":
  //             // User doesn't have permission to access the object
  //             break;
  //           case "storage/canceled":
  //             // User canceled the upload
  //             break;
  //           case "storage/unknown":
  //             // Unknown error occurred, inspect error.serverResponse
  //             break;
  //         }
  //       }
  //     );
  //   }
  // };
  // const handleClick = () => {
  //   fileInput.current.click();
  // };
  useEffect(() => {
    document.body.className = "user-area ";

    return () => {
      document.body.className = "";
    };
  }, []);

  // const saveUserInfo = () => {
  //   setLoader(true);
  //   getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
  //     if (
  //       keys !== undefined &&
  //       keys?.user_data !== undefined &&
  //       userInfo?.authUId
  //     ) {
  //       updateUserData(userInfo?.authUId, {
  //         user_data: {
  //           ...keys?.user_data,
  //           first_name: firstName,
  //           last_name: lastName,
  //         },
  //       });
  //       setLoader(false);

  //       showToastMessage("Information updated successfully!");
  //     }
  //   });
  // };

  return (
    <div className="personal-info-wrapper">
      {loader || shimmer ? (
        <ProfileShimmer />
      ) : (
        <>
          <div className="input-wrapper">
            <input
              type="text"
              autoFocus
              readOnly
              autoComplete="off"
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label
              htmlFor="firstName"
              className={firstName !== "" ? "" : "hide"}
            >
              First name
            </label>
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              autoFocus
              readOnly
              autoComplete="off"
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label htmlFor="lastName" className={lastName !== "" ? "" : "hide"}>
              First name
            </label>
          </div>

          <div className="two-inputs">
            <div className="input-wrapper">
              <input
                type="text"
                autoFocus
                readOnly
                autoComplete="off"
                id="email"
                name="email"
                value={userInfo?.user_data?.email}
              />
              <label
                htmlFor="email"
                className={
                  userInfo?.user_data?.email !== "" ? "" : "hide"
                }
              >
                Email address
              </label>
            </div>

            <div className="input-wrapper">
              <input
                type="text"
                autoFocus
                readOnly
                autoComplete="off"
                id="phoneNumber"
                name="phoneNumber"
                value={userInfo?.user_data?.phone}
              />
              <label
                htmlFor="phoneNumber"
                className={
                  userInfo?.user_data?.phone !== "" ? "" : "hide"
                }
              >
                Phone number
              </label>
            </div>
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              autoFocus
              readOnly
              autoComplete="off"
              id="address"
              name="address"
              value={userInfo?.address[0]?.street}
            />
            <label
              htmlFor="address"
              className={
                userInfo?.address[0]?.street !== "" ? "" : "hide"
              }
            >
              Address
            </label>
          </div>

          <div className="input-wrapper">
            <input
              type="text"
              autoFocus
              readOnly
              autoComplete="off"
              id="state"
              name="state"
              value={userInfo?.address[0]?.state}
            />
            <label
              htmlFor="state"
              className={
                userInfo?.address[0]?.state !== "" ? "" : "hide"
              }
            >
              State
            </label>
          </div>

          <div className="two-inputs">
            <div className="input-wrapper">
              <input
                type="text"
                autoFocus
                readOnly
                autoComplete="off"
                id="country"
                name="country"
                value={userInfo?.address[0]?.country}
              />
              <label
                htmlFor="country"
                className={
                  userInfo?.address[0]?.country !== "" ? "" : "hide"
                }
              >
                Country
              </label>
            </div>

            <div className="input-wrapper">
              <input
                type="text"
                autoFocus
                readOnly
                autoComplete="off"
                id="postalCode"
                name="postalCode"
                value={userInfo?.address[0]?.postal_code}
              />
              <label
                htmlFor="postalCode"
                className={
                  userInfo?.address[0]?.postal_code !== ""
                    ? ""
                    : "hide"
                }
              >
                Zip code
              </label>
            </div>
          </div>

          <p className="text-16 text-underline text-center color-blue">
            Contact support for any change
          </p>
        </>
      )}
    </div>
  );
};

export default Profile;
