/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
// import DatePicker from "./DatePicker";
// import moment from "moment";
import { AppContext } from "@/context/createContext";

const DateBoxInput: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
  oldSelection: any;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string, validation_only: boolean) => void;
}> = ({ attribute, isShimmer, oldSelection, index, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const [month, setmonth] = useState("");
  const [day, setday] = useState("");
  const [year, setyear] = useState("");
  const dayInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);

  const [inputFormat, setInputFormat] = useState<string | null>(null);
  const { userInfo } = useContext(AppContext);
  const [oldData, setOldData] = useState(oldSelection);
  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };

  useEffect(() => {
    if (inputFormat === null && attribute?.format?.input_format) {
      setInputFormat(attribute?.format?.input_format);
    }
    // if (oldSelection !== "" && !isNaN(Number(oldSelection)) && attribute?.validation?.user_validate === undefined) {
    //   setInputValue(moment.unix(Number(oldSelection)).format("L"));
    // }
  }, [oldSelection, attribute]);

  useEffect(() => {
    // console.log({ inputValue, oldData })

    submitData(
      inputValidation(attribute.validation, inputValue, oldData),
      attribute.key,
      inputValue,
      attribute?.validation?.user_validate ?? false
    );
  }, [inputValue]);

  useEffect(() => {
    updateInputValue(month + "/" + day + "/" + year)
  }, [month, day, year]);

  useEffect(() => {
    if (userInfo?.user_data && attribute?.validation?.user_validate === true) {
      setOldData((userInfo?.user_data !== null
        && Object.keys(userInfo?.user_data).length > 0
        && userInfo?.user_data[attribute?.key] !== undefined)
        ? userInfo?.user_data[attribute?.key] : oldSelection)
    }
  }, [userInfo]);

  return inputFormat !== null ? (
    <>
      {attribute?.instruction && (
        <p className="text-16">{attribute?.instruction}</p>
      )}
      <p className="text-16">{attribute.placeholder_text} </p>
      <br />
      <div className="d-flex justify-content-between mt-1 dob-box-wrapper" key={index?.toString()}>

        <div className="input-wrapper">
          <input
            type="number" inputMode="numeric"
            id={"date-input-m" + index}
            autoComplete="off"
            maxLength={2}
            max={12}
            value={month.trim()}
            // readOnly={
            //   attribute?.enabled !== undefined && attribute?.enabled === false
            // }
            onChange={(e) => {
              setmonth(e.target.value)
              if (e.target.value.length >= 2 && dayInput.current !== null) {
                dayInput.current.focus()
              }
            }}
            className={
              isShimmer ? "common-shimmer form-control" : "form-control"
            }
          />

          <label htmlFor={"date-input-m" + index}>
            MM
          </label>
        </div>
        <div className="input-wrapper">
          <input type="number" inputMode="numeric"
            id={"date-input-d" + index}
            max={31}
            maxLength={2}
            autoComplete="off"
            value={day.trim()}
            // readOnly={
            //   attribute?.enabled !== undefined && attribute?.enabled === false
            // }
            ref={dayInput}
            onChange={(e) => {
              setday(e.target.value)
              if (e.target.value.length >= 2 && yearInput.current !== null) {
                yearInput.current.focus()
              }
            }}
            className={
              isShimmer ? "common-shimmer form-control" : "form-control"
            }
          />


          <label htmlFor={"date-input-d" + index}>
            DD
          </label>
        </div >
        <div className="input-wrapper">
          <input
            type="number" inputMode="numeric"
            id={"date-input-y" + index}
            autoComplete="off"
            value={year.trim()}
            maxLength={4}
            ref={yearInput}
            onChange={(e) => setyear(e.target.value)}
            className={
              isShimmer ? "common-shimmer form-control" : "form-control"
            }
          />
          <label htmlFor={"date-input-y" + index} id="box-year">YYYY
          </label>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default DateBoxInput;
