import React, { MutableRefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";
const Recaptcha: React.FC<{
  captchaRef: MutableRefObject<null>;
}> = ({ captchaRef }) => {
  const handleChange = (value:any) => {
    console.log("Captcha value:", value);
  };

  const asyncScriptOnLoad = () => {
    console.log("scriptLoad - reCaptcha Ref-", captchaRef);
  };
  return (
    <>
      <ReCAPTCHA
        style={{ display: "inline-block" }}
        sitekey={String("6LfFnJoiAAAAAO3e7fnEq3Brny-pfq-dKP2JiY0z")}
        ref={captchaRef}
        onChange={handleChange}
        asyncScriptOnLoad={asyncScriptOnLoad}
      />
    </>
  );
};

export default Recaptcha;
