/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { logoLogin } from "@/config/svg";
const CountryValidation: React.FC<{}> = () => {
  useEffect(() => {
    document.body.className = "auth-page";

    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <section className="h-100 h-custom login">
      <div className="py-3 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12">
            <div
              id="login-row"
              className="row justify-content-center align-items-center"
            >
              <div id="login-column" className="col-4 col-md-6">

                <div id="login-box" className="col-md-12">
                  <div className=" logo-login text-center">
                    <img className=" shimmer-logo m-2 custom-mb-60" src={logoLogin} alt="logo" />
                  </div>
                  <div className="form-group text-center">
                    <div className="row mx-2 d-flex justify-content-center">
                      <p>Sorry, Claire isn't available in your region.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountryValidation;
