/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { backArrow, confirmCheck } from "@/config/svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import routes from "@/config/routes";
import { AttributeType } from "@/interface/index";
import {
  // DateInput,
  // EmailInput,
  // NumberInput,
  // PhoneInput,
  ProgressBar,
  // SelectedOption,
  // StringInput,
  // ConsentLink,
  // CheckBoxInput,
  // SelectionCheckbox,
  // SelectedDropdown,
  // CapturedImage,
  // ZipcodeInput,
  // SelectionSwitch,
  // SwitchSelection,
  // // TitleType,
  // BioMarkerListInput,
  // PasswordInput,
  // OTPInput,
  // GenderInput,
  ImageSectionInput,
  TextareaInput,
  RatingSlider,
  Modal,
} from "@/components/index";
import { getFireStoreDoc } from "@/services/firebase/fireStore";
import { isLogin } from "@/helper/common";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
} from "firebase/auth";
import { toast } from "react-toastify";
import {
  commonSentOTP,
  commonVerifyOTP,
  submitOrderFeedBack,
} from "@/services/api/fireFunction";
// import { ShimmerButton, ShimmerTitle, ShimmerText } from "../../packages/Shimmer";

// import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams } from "react-router-dom";

const FeedbackSteps: React.FC = () => {
  const auth = getAuth();
  const {
    showToastMessage,
    userInfo,
    fedbackStepModules,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    translated,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isContinue, setIsContinue] = useState(false);
  // const [isMock, setIsMock] = useState(false);
  // const [showMock, setShowMock] = useState(false);
  const [onEssentialData, setOnEssentialData] = useState<any>([]);
  const [previousData, setPreviousData] = useState<any>({});
  const [isNext, setIsNext] = useState(false);
  const [essentialStep, setEssentialSteps] = useState<any>([]);
  const [modulePath, setModulePath] = useState<string>("");
  const [progress, setProgress] = useState(
    Number(searchParams.get("step") ? searchParams.get("step") : 1)
  );
  const [currentStep, setCurrentStep] = useState<any>();
  const [currentStepValue, setCurrentStepValue] = useState<any>({});
  const [orderId, setOrderId] = useState<string>("");
  const { search } = useLocation();
  const [ModalShow, setModalShow] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (params?.order_id) {
      setOrderId(atob(String(params?.order_id)));
    }
  }, [params]);

  useEffect(() => {
    const currentStep = Number(
      searchParams.get("step") ? searchParams.get("step") : 1
    );
    setProgress(currentStep);
    if (params?.order_id) {
      if (
        fedbackStepModules?.steps &&
        fedbackStepModules.steps[currentStep - 1]?.conditions !== undefined
      ) {
        var conditions: any =
          fedbackStepModules.steps[currentStep - 1]?.conditions;
        if (conditions.length > 0) {
          setRootLoader();
          conditions.forEach((item: any) => {
            getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
              if (
                keys !== undefined &&
                keys[item.scope] !== undefined &&
                keys[item.scope]
              ) {
                if (keys[item.scope][item.attribute] !== item.value) {
                  handleContinue();
                  unSetRootLoader();
                  return false;
                } else {
                  unSetRootLoader();
                }
              } else {
                unSetRootLoader();
              }
            });
          });
        }
      }
      if (
        fedbackStepModules?.steps &&
        fedbackStepModules.steps[currentStep - 1]?.is_active !== undefined &&
        fedbackStepModules.steps[currentStep - 1]?.is_active === false
      ) {
        nextStep();
      }
    }
  }, [search, fedbackStepModules]);
  useEffect(() => {
    // console.log('fedbackStepModules', fedbackStepModules)

    if (fedbackStepModules?.steps?.length > 0) {
      setEssentialSteps(fedbackStepModules?.steps);
      setCurrentStep(fedbackStepModules?.steps[progress - 1]);
      setModulePath(fedbackStepModules?.store_path);
    }

    if (fedbackStepModules?.steps?.length < progress) {
      navigate(routes.root);
    }

    if (modulePath !== "") {
      const feedbackData = localStorage.getItem(
        String(userInfo?.authUId) + String(params?.order_id)
      )
        ? JSON.parse(
          atob(
            String(
              localStorage.getItem(
                String(userInfo?.authUId) + String(params?.order_id)
              )
            )
          )
        )
        : {};
      setPreviousData(feedbackData);
    }
  }, [fedbackStepModules, progress, searchParams, currentStep]);

  useEffect(() => {
    setIsContinue(false);
  }, [progress]);

  useEffect(() => {
    setTimeout(() => {
      unSetRootLoader();
    }, 3000);

    document.body.className = "essential-area";

    return () => {
      document.body.className = "";
    };
  }, []);
  const sentOTP = () => {
    setRootLoader();
    var OTPPayload = {
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    };

    commonSentOTP(OTPPayload)
      .then((response: any) => {
        if (response?.data?.success) {
          showToastMessage("OTP sent successfully!");
          nextStep();
          unSetRootLoader();
        }
        if (response?.data?.error) {
          unSetRootLoader();
          showToastMessage(response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage("Something went wrong", true);
      });
  };

  const verifyOTP = () => {
    setRootLoader();
    commonVerifyOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
      otp: currentStepValue?.otp ?? previousData.otp,
    })
      .then((res: any) => {
        if (res?.data?.success) {
          showToastMessage("OTP verified successfully!");
          unSetRootLoader();
          nextStep();
        }
        if (res?.data?.error) {
          unSetRootLoader();
          showToastMessage(res?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  // const resentOTP = () => {
  //   setRootLoader();

  //   commonSentOTP({
  //     email: currentStepValue?.email ?? previousData.email,
  //     phone: currentStepValue?.phone ?? previousData.phone,
  //   })
  //     .then((res: any) => {
  //       unSetRootLoader();
  //       if (res?.data?.success) {
  //         showToastMessage("OTP Resent successfully!");
  //       }
  //       if (res?.response?.data?.error) {
  //         showToastMessage(res?.response?.data?.error?.message, true);
  //       }
  //     })
  //     .catch((error) => {
  //       unSetRootLoader();
  //       showToastMessage(error, true);
  //     });
  // };

  const checkEmailExist = () => {
    setRootLoader();
    fetchSignInMethodsForEmail(
      auth,
      currentStepValue?.email ?? previousData.email
    )
      .then((signInMethods) => {
        unSetRootLoader();
        if (
          signInMethods.indexOf(
            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          ) !== -1 ||
          signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !==
          -1
        ) {
          toast.error("This email already used, please try other!");

          // User can sign in with email/password.
        } else {
          nextStep();
        }
      })
      .catch((error) => {
        unSetRootLoader();
        toast.error("Something went wrong!");
        // Some error occurred, you can inspect the code: error.code
      });
  };

  const nextStep = async () => {
    if (progress === fedbackStepModules?.steps?.length) {
      setIsContinue(false);
      setRootLoader();
      const feedbackData = localStorage.getItem(
        String(userInfo?.authUId) + String(params?.order_id)
      )
        ? JSON.parse(
          atob(
            String(
              localStorage.getItem(
                String(userInfo?.authUId) + String(params?.order_id)
              )
            )
          )
        )
        : {};
      submitOrderFeedBack(orderId, feedbackData)
        .then((data: any) => {
          if (data?.response?.data?.error) {
            unSetRootLoader();

            toast.error(data?.response?.data?.error?.message);
          } else {
            // navigate('/feedback-success/' + String(params?.order_id));
            setModalShow(true);
            showToastMessage("Feedback submitted successfully!");
          }
          //navigate(routes?.productList);
        })
        .catch((err) => {
          if (err?.code === "ERR_NETWORK")
            showToastMessage(
              translated("error.network_connection_error"),
              true
            );
        });
    } else {
      const nextStep = progress + 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(
        "/feedback-step/" + String(params?.order_id) + "?step=" + nextStep
      );
    }
  };
  const handleContinue = async () => {
    if (currentStep?.id === "id_phone") {
      sentOTP();
    } else if (currentStep?.id === "id_otp") {
      verifyOTP();
    } else if (
      currentStepValue["email"] !== undefined &&
      currentStep?.id === "id_email"
    ) {
      checkEmailExist();
    } else {
      await nextStep();

      setIsNext(true);
      setTimeout(() => {
        setIsNext(false);
      }, 300);
    }
  };

  const handleBack = () => {
    setIsNext(true);
    setIsContinue(false);
    if (userInfo?.authUId && progress === 1 && isLogin(userInfo?.authUId)) {
      navigate(routes.userProfile);
    } else if (
      userInfo?.authUId &&
      progress === 1 &&
      !isLogin(userInfo?.authUId)
    ) {
      navigate(routes.root);
    } else {
      const nextStep = progress - 1;
      setProgress(nextStep);
      setIsContinue(false);
      setTimeout(() => {
        setIsNext(false);
      }, 300);
      if (
        fedbackStepModules?.steps &&
        fedbackStepModules.steps[nextStep - 1]?.is_active !== undefined &&
        fedbackStepModules.steps[nextStep - 1]?.is_active === false
      ) {
        navigate(routes.root);
      } else {
        navigate(
          "/feedback-step/" + String(params?.order_id) + "?step=" + nextStep
        );
      }
    }
  };

  const handleSkip = () => {
    if (
      currentStep.skip_to_finish !== undefined &&
      currentStep.skip_to_finish === true
    ) {
      navigate(routes.root);
    } else if (currentStep?.skip_to && currentStep?.skip_to !== "") {
      fedbackStepModules?.steps?.map((step: any, index: number) => {
        if (step.id === currentStep?.skip_to) {
          const nextStep = index + 1;
          setProgress(nextStep);
          setIsContinue(false);
          navigate(
            "/feedback-step/" + String(params?.order_id) + "?step=" + nextStep
          );
        }
      });
    } else {
      if (userInfo?.authUId && isLogin(userInfo?.authUId)) {
        navigate(routes.userProfile);
      } else if (userInfo?.authUId && !isLogin(userInfo?.authUId)) {
        navigate(routes.root);
      }
    }
  };

  const submitData = (isContinue: boolean, key: string, value: any) => {
    if (
      key !== "biomarker" &&
      key !== "biomarker_values" &&
      key !== "password" &&
      key !== "confirm_password" &&
      key !== "image_view"
    ) {
      // console.log("test", key, value);
      setCurrentStepValue({
        ...currentStepValue,
        [key]: value,
      });
      if (value !== undefined && value !== "" && key !== "otp") {
        localStorage.setItem(
          String(userInfo?.authUId) + String(params?.order_id),
          btoa(
            JSON.stringify({
              ...previousData,
              ...currentStepValue,
              [key]: value,
            })
          )
        );
      }
    }

    let continueActive: boolean = isContinue;
    if (key === "password") {
      localStorage.setItem("creadValue", btoa(value));
    }

    if (key === "confirm_password") {
      localStorage.setItem("ccreadValue", btoa(value));
    }

    const newData = [
      ...onEssentialData.filter((data: any) => {
        return data.key !== key;
      }),
      {
        isContinue,
        key,
        inputId: currentStep?.id,
        value,
      },
    ];
    setOnEssentialData(newData);
    newData.map((item: any) => {
      if (item.isContinue === false && item.inputId === currentStep?.id) {
        continueActive = false;
      } else if (key === "password") {
        const cpassword = atob(String(localStorage.getItem("ccreadValue")));

        if (cpassword === value) {
          continueActive = true;
        } else {
          continueActive = false;
        }
      }
    });
    setIsContinue(continueActive);
  };

  return (
    <Pages>
      <section className="feedback-section ">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            <div className="go-back-button">
              {currentStep?.back_enabled !== false && (
                <img
                  className={
                    mainLoader || remainingLoaderTime
                      ? "common-shimmer cursor-pointer "
                      : "cursor-pointer "
                  }
                  src={backArrow}
                  width="70"
                  height="70"
                  alt="back"
                  onClick={handleBack}
                />
              )}
            </div>

            <div className="container-progress">
              <div className="cnt-bar custom-mb-20">
                {currentStep?.progress_enabled !== false && (
                  <ProgressBar
                    totalStep={Math.max(
                      ...essentialStep?.map((i: any) => i?.display_index ?? 0)
                    )}
                    progress={Number(currentStep?.display_index)}
                  />
                )}
              </div>

              {progress === 1 && (
                <div className="col continue">
                  <button
                    className="col-12 custom-btn btn-ice-gradient button-small"
                    onClick={handleContinue}
                  >
                    <p className="btn-text blue">
                      {currentStep?.primary_button_text ??
                        translated("cta.next")}
                    </p>
                  </button>
                </div>
              )}
              {currentStep?.step_count_display === true && (
                <h4 className="text-18 bold blue">
                  {currentStep?.display_index}/
                  {
                    essentialStep?.filter(
                      (attribute: any) => attribute?.step_count_display === true
                    ).length
                  }
                </h4>
              )}
            </div>

            {currentStep?.attributes
              ?.filter(
                (attribute: AttributeType) =>
                  attribute?.type === "section_title" ||
                  attribute?.type === "text_label"
              )
              ?.map((attribute: AttributeType, index: number) => (
                <div
                  className="left-content width-500 no-margin-left custom-mt-20"
                  key={index?.toString()}
                >
                  <h1
                    className={
                      mainLoader || remainingLoaderTime
                        ? "common-shimmer text-32 "
                        : "text-32 "
                    }
                    dangerouslySetInnerHTML={{
                      __html: attribute?.display_text
                        ?.replace("<b>", "<strong>")
                        .replace("</b>", "</strong>"),
                    }}
                  />

                </div>
              ))}
          </div>
          <div className="half-col right-col align-block-center flow-column ">
            {currentStep?.template === "input" &&
              currentStep?.attributes?.map(
                (attribute: AttributeType, index: number) =>
                  attribute?.type === "section_image" && (
                    <ImageSectionInput
                      attribute={attribute}
                      submitData={(iContinue, key, value) => {
                        setIsContinue(iContinue);
                        submitData(iContinue, key, value);
                      }}
                    />
                  )
              )}
            {essentialStep?.length > 0 && (
              <div className="">
                {currentStep?.template === "input" &&
                  currentStep?.attributes?.map(
                    (attribute: AttributeType, index: number) => (
                      <div
                        key={index?.toString()}
                        className="text-area-feedback custom-pl-16 custom-pr-16"
                      >
                        {attribute?.type === "multiline_string" && (
                          <TextareaInput
                            attribute={attribute}
                            submitData={(iContinue, key, value) => {
                              setIsContinue(iContinue);
                              submitData(iContinue, key, value);
                            }}
                            oldSelection={
                              previousData[attribute?.key]
                                ? previousData[attribute?.key]
                                : ""
                            }
                            autoFocus={!index}
                          />
                        )}
                      </div>
                    )
                  )}

                {!isNext &&
                  currentStep?.template === "input_feedback" &&
                  currentStep?.attributes?.map(
                    (attribute: AttributeType, index: number) => (
                      <>
                        {attribute?.type === "feedback_slider" && (
                          <div
                            className="input-feedback"
                            key={index?.toString()}
                          >
                            <RatingSlider
                              attribute={attribute}
                              submitData={(iContinue, key, value) => {
                                setIsContinue(iContinue);
                                submitData(iContinue, key, value);
                              }}
                              question={currentStep?.display_index}
                              oldSelection={
                                previousData[attribute?.key]
                                  ? previousData[attribute?.key]
                                  : ""
                              }
                            />
                          </div>
                        )}
                      </>
                    )
                  )}
              </div>
            )}

            {progress !== 1 && (
              <div className=" text-center">
                <button
                  className={
                    mainLoader || remainingLoaderTime
                      ? "common-shimmer standard-btn custom-mb-15"
                      : "standard-btn custom-mb-15"
                  }
                  disabled={!isContinue || mainLoader || remainingLoaderTime}
                  onClick={handleContinue}
                >
                  <p className="text-18 bold color-blue">
                    {currentStep?.primary_button_text ?? translated("cta.next")}
                  </p>
                </button>

                {currentStep?.skip_to !== undefined && (
                  <div className="text-center skip-btn">
                    <button
                      className="col-12 custom-btn btn-empty-background "
                      onClick={() => {
                        handleSkip();
                      }}
                    >
                      <p className="text-18 bold color-blue text-decoration-underline">
                        {currentStep?.skip_button_text ??
                          translated("cta.skip")}
                      </p>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Modal
          size={"sm"}
          modalTitle={""}
          show={ModalShow}
          modalClosingButton={false}
          handleClose={() => {
            setModalShow(false);
            navigate(routes?.root);
          }}
        >
          <div className="confirm-modal text-center">
            <img className="confirm-icon" src={confirmCheck} alt="confirm" />
            <h2 className="text-18 bold black custom-mb-15 custom-mt-15">
              Thank you, {userInfo?.user_data?.first_name}
            </h2>
            <div className="sub-separator"></div>
            <p className="text-16 custom-mb-30">
              Thank you for taking the time to review your experience.
            </p>
            <div className=" text-center">
              <button
                className="standard-btn"
                onClick={() => {
                  setModalShow(false);
                  navigate(routes?.root);
                }}
              >
                <p className="text-18 bold color-blue">
                  {translated("cta.ok")}
                </p>
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </Pages>
  );
};

export default FeedbackSteps;
