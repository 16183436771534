/* eslint-disable react-hooks/exhaustive-deps */
import { AppContext } from "@/context/createContext";
import { base64Encode, regexValidation } from "@/helper/common";
import { ShippingAddress } from "@/interface/index";
import {
  addressList,
  insertAddress,
  updateAddress,
  validateZipCode,
} from "@/services/api/fireFunction";
import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { SearchLocationShimmer } from "../shimmer";


let autoComplete: any;

const SearchLocationInput: React.FC<{
  isSave: () => void;
  product: any;
}> = ({ isSave, product }) => {
  const {
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    state,
    showToastMessage,
    translated,
    settingsState
  } = useContext(AppContext);
  const configState = state?.config_state
  const [allAddresss, setAllAddress] = useState<any>(
    userInfo?.address
  );
  const [query, setQuery] = useState("");
  const [fullName, setFullName] = useState(
    userInfo?.user_data?.first_name
      ? userInfo?.user_data?.first_name +
      (userInfo?.user_data?.last_name ? ` ${userInfo.userInfo.user_data.last_name}` : '')
      : userInfo?.user_data?.name ?? ""
  );
  const [placeId, setPlaceId] = useState("");
  const [lat, setLat] = useState("");
  const [lang, setLang] = useState("");
  const [location, setLocation] = useState<any>([]);

  const [address, setAddress] = useState<ShippingAddress>({
    street: [],
    city: "",
    state: "",
    postal_code: "",
    country: "",
    placeName: "",
  });
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const textInput = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (userInfo?.authUId) {
      if (
        userInfo?.address &&
        userInfo?.address.length > 0
      ) {
        setAllAddress(userInfo?.address);
      }
      setFullName(userInfo?.user_data?.first_name
        ? userInfo?.user_data?.first_name +
        (userInfo?.user_data?.last_name ? ` ${userInfo.userInfo.user_data.last_name}` : '')
        : userInfo?.user_data?.name ?? "")
    }
  }, [state, userInfo]);
  const loadScript = (url: any, callback: any) => {
    let script: any = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery: any, autoCompleteRef: any) {
    autoComplete = new (window as any).google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "name",
      "place_id",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery: any) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setPlaceId(addressObject.place_id);
    setLat(addressObject.geometry.location.lat());
    setLang(addressObject.geometry.location.lng());
    setAddress({ ...address, placeName: addressObject.name });
    setLocation(addressObject.address_components);
  }

  useEffect(() => {
    googleLocationScript()
    setRootLoader();

    addressList().then((data: any) => {
      if (data?.data?.length > 0) {
        setAddress(data?.data[0]);
      } else {
        const zipCodeData = localStorage.getItem(base64Encode(String(userInfo?.authUId) + "zip_code"))
          ? atob(String(localStorage.getItem(base64Encode(String(userInfo?.authUId) + "zip_code"))))
          : null
        if (zipCodeData) {

          setAddress({
            street: [],
            city: "",
            state: "",
            postal_code: zipCodeData,
            country: "",
            placeName: "",
          });
        } else {

          setAddress({
            street: [],
            city: "",
            state: "",
            postal_code: "",
            country: "",
            placeName: "",
          });
        }
      }
      unSetRootLoader();
    }).catch((err: any) => {
      if (err?.code === 'ERR_NETWORK')
        showToastMessage(translated("error.network_connection_error"), true)
      unSetRootLoader();
    });
  }, []);

  useEffect(() => {
    if (location.length) {
      let allAddress = {
        street: [] as string[],
        city: "",
        state: "",
        postal_code: "",
        country: "",
      };
      location.forEach((item: any) => {
        switch (item.types[0]) {
          case "postal_code":
            allAddress.postal_code = item.long_name;
            break;
          case "country":
            allAddress.country = item.long_name;
            break;
          case "administrative_area_level_1":
            allAddress.state = item.long_name;
            break;
          case "administrative_area_level_2":
            allAddress.city = item.long_name;
            break;
          case "street_number":
            allAddress.street = [
              ...allAddress.street,
              item.long_name as string,
            ];
            break;
          case "street_address":
            allAddress.street = [
              ...allAddress.street,
              item.long_name as string,
            ];
            break;
          case "route":
            allAddress.street = [
              ...allAddress.street,
              item.long_name as string,
            ];
            break;
          case "locality":
            allAddress.street = [
              ...allAddress.street,
              item.long_name as string,
            ];
            break;
          default:
            allAddress = { ...allAddress };
        }
      });
      setAddress({ ...address, ...allAddress });
    }
  }, [location]);

  const addNewAddress = () => {
    if (address.country === "") {
      showToastMessage("Country is required", true);
    } else if (address.state === "") {
      showToastMessage("State is required", true);
    } else if (address.postal_code === "") {
      showToastMessage("Postal code is required", true);
    } else if (address.city === "") {
      showToastMessage("City is required", true);
    } else if (product === null) {
      if (allAddresss.length > 0) {
        updateAddress({ ...address, street: address.street.toString() });
        isSave();
      } else if (allAddresss.length === 0) {
        const newAddress = {
          address_components: location,
          place_id: placeId,
          lat: lat,
          long: lang,
          city: address.city,
          country: address.country,
          name: address.placeName,
          postal_code: address.postal_code,
          state: address.state,
          street: address.street.toString(),
        };
        insertAddress(newAddress);
        isSave();
      }
    } else {
      setRootLoader();
      const payload = {
        zip_code: address.postal_code,
        product_id: product.id,
      }
      validateZipCode(payload).then((response: any) => {
        if (response?.data?.valid) {
          if (allAddresss.length > 0) {
            updateAddress({ ...address, street: address.street.toString() });
            isSave();
          } else if (allAddresss.length === 0) {
            const newAddress = {
              address_components: location,
              place_id: placeId,
              lat: lat,
              long: lang,
              city: address.city,
              country: address.country,
              name: address.placeName,
              postal_code: address.postal_code,
              state: address.state,
              street: address.street.toString(),
            };
            insertAddress(newAddress);
            isSave();
          }
        } else {
          googleLocationScript()
          toast.error('This service is not available in your location')
        }
        unSetRootLoader()
      }).catch((err) => {
        if (err?.code === 'ERR_NETWORK')
          showToastMessage(translated("error.network_connection_error"), true)
        unSetRootLoader()
      })
    }
  };

  const googleLocationScript = () => {
    setTimeout(() => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${configState?.places_key?.web}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef)
      );
    }, 3000);
  }
  return (
    <div className="search-location-input ">

      {mainLoader || remainingLoaderTime ? (
        <SearchLocationShimmer />
      ) : (
        <>
          <div className="mb-3 row">
            {product &&
              <div className="col-sm-12  " >
                <input
                  type="text"
                  ref={textInput}
                  readOnly
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  placeholder={translated('full_name')}
                  className="form-control"
                />
              </div>
            }
          </div>
          {product &&
            <div className="my-3 row">
              <div className="col-sm-12">
                <input
                  ref={autoCompleteRef}
                  onChange={(event) => {
                    setQuery(event.target.value)
                  }}
                  onFocus={() => {
                    if (query === "" && address.postal_code !== "") {
                      textInput?.current?.focus();

                      setQuery(address.postal_code)
                      setTimeout(() => {

                        autoCompleteRef?.current?.focus()
                      }, 500);
                    }
                  }}
                  placeholder="Search place"
                  className="form-control"
                  value={query}
                />
              </div>
            </div>
          }
          <div className="mb-3 row">
            <div className="col-sm-12  ">
              <input
                type="text"
                autoComplete="off"
                readOnly={product === null ? true : false}
                value={address.street.toString()}
                onChange={(e) =>
                  setAddress({ ...address, street: [e.target.value] })
                }
                placeholder={translated("app_copy.street_address")}
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-sm-6  ">
              <input
                type="text"
                autoComplete="off"
                value={address.city}
                readOnly={product === null ? true : false}
                onChange={(e) =>
                  setAddress({ ...address, city: e.target.value })
                }
                placeholder={translated('app_copy.city')}
                className="form-control"
              />
            </div>
            <div className="col-sm-6  ">
              <input
                type="text"
                autoComplete="off"
                value={address.state}
                readOnly={product === null ? true : false}
                onChange={(e) =>
                  setAddress({ ...address, state: e.target.value })
                }
                placeholder={translated('app_copy.state')}
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row  ">
            <div className="col-sm-6 ">
              <input
                type="text"
                autoComplete="off"
                value={address.country}
                readOnly={product === null ? true : false}
                onChange={(e) =>
                  setAddress({ ...address, country: e.target.value })
                }
                placeholder={translated('app_copy.country')}
                className="form-control"
              />
            </div>
            <div className="col-sm-6">
              <input
                type="text"
                autoComplete="off"
                value={address.postal_code}
                readOnly={product === null ? true : false}
                onChange={(e) =>
                  setAddress({ ...address, postal_code: e.target.value })
                }
                placeholder={translated('app_copy.postal_code')}
                className="form-control"
                maxLength={10}
              />
            </div>
          </div>
          <div className="mb-3  width-236 confirm-address">
            <button
              disabled={product ? !(
                address.country !== "" &&
                address.state !== "" &&
                address.postal_code !== "" &&
                address.city !== ""
              ) || !regexValidation(address.postal_code, settingsState.app_config.zip_code_regex) : false}
              className="custom-btn btn-standard-gradient"
              onClick={() => product ? addNewAddress() : isSave()}
            >
              <p className="btn-text bold small-text rainbow_purple-blue">
                {translated("cta.confirm")}
              </p>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchLocationInput;
