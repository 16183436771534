/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { getFireStoreData } from "@/services/api/fireFunction";

const SelectedOption: React.FC<{
  attribute: AttributeType;
  config: any;
  fetch_options: boolean;
  oldSelection: any;
  multiSelection: boolean;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[]
  ) => void;
}> = ({ attribute, oldSelection, fetch_options, multiSelection, config, submitData }) => {
  const [updateValue, setUpdateValue] = useState<Array<string>>([]);
  const [options, setOptions] = useState<any>(fetch_options ? [] : config?.options);
  const optionClicked = (newupdateValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        var newArray = multiSelection
          ? [...updateValue, dataProcess(element, newupdateValue).trimStart()]
          : [dataProcess(element, newupdateValue).trimStart()];
        setUpdateValue(newArray);
      });
    } else {
      var newArray = multiSelection
        ? [...updateValue, newupdateValue]
        : [newupdateValue];
      setUpdateValue(newArray);
    }
  };

  const optionUnselect = (value: string) => {
    var newArray = updateValue.filter(function (item: string) {
      return item !== value;
    });
    setUpdateValue(newArray);
  };

  // useEffect(() => {
  //   if (oldSelection !== "") {
  //     setUpdateValue(oldSelection.toString().split(","));
  //   }
  // }, [oldSelection]);

  useEffect(() => {
    if (fetch_options === true) {
      getFireStoreData(config?.filter?.path).then((data: any) => {

        console.log('data1', data.random())
        setOptions([data[Math.floor(Math.random() * data.length)]])
      })
    }
  }, [fetch_options]);

  useEffect(() => {
    if (updateValue.length > 0) {
      submitData(
        inputValidation(attribute?.validation, updateValue[0]),
        attribute.key,
        updateValue.toString()
      );
    } else {
      submitData(false, attribute.key, updateValue.toString());
    }
  }, [updateValue]);

  return (
    <div className="list-group">
      {options.length > 0 && !fetch_options &&
        options.map((option: any, index: number) => (
          <p
            key={index.toString()}
            onClick={() =>
              updateValue.includes(option.key)
                ? optionUnselect(option.key)
                : optionClicked(option.key)
            }
            placeholder={attribute.placeholder_text}
            className={
              updateValue.includes(option.key)
                ? "cursor-pointer form-control custom-mb-20 active"
                : "cursor-pointer form-control custom-mb-20"
            }
          >
            {option.display_text}
          </p>
        ))}

      {options.length > 0 && fetch_options &&
        options.map((option: any, index: number) => (
          <p
            key={index.toString()}
            onClick={() =>
              updateValue.includes(option)
                ? optionUnselect(option)
                : optionClicked(option)
            }
            placeholder={attribute.placeholder_text}
            className={
              updateValue.includes(option)
                ? "cursor-pointer form-control custom-mb-20 active"
                : "cursor-pointer form-control custom-mb-20"
            }
          >
            {option.name}
          </p>
        ))}
    </div>
  );
};

export default SelectedOption;
