/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "@/context/createContext";
import { findItemFromArray } from "@/helper/common";
import routes from "@/config/routes";
import { RTDBStepModules, ResultStatusList, reducerType } from "@/config/configarableVariable";
import { Spiner } from "@/components/index";
const PreTest: React.FC = () => {
    const { userInfo, translated, stepData, setStepData } = useContext(AppContext);
    const [order, setOrder] = useState<any>(null);
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (userInfo?.orders && userInfo?.orders.length > 0 && searchParams.get("metadata") && order === null) {

            const orderData = findItemFromArray(
                userInfo?.orders,
                "order_id",
                atob(String(searchParams.get("metadata")))
            )
            setOrder(orderData);
            if (orderData && orderData?.status === ResultStatusList?.resultsAvailable) {
                localStorage.setItem("mode", "result")
                if (stepData?.allStep[RTDBStepModules?.resultAuthentication]) {
                    setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: stepData?.allStep[RTDBStepModules?.resultAuthentication] })
                    navigate(routes.authenticationStep);
                }
            }
        }
    }, [userInfo, searchParams]);

    useEffect(() => {
        document.body.className = "checkout-area welcome";
        return () => {
            document.body.className = "";
        };
    }, []);

    return (
        <Pages>
            <section className="pending-order-list">
                <div className="row align-items-center scrollcontainer1 custom-pb-16">
                    <div className="d-flex justify-content-start">
                        {order ?
                            <div className="container">
                                <div className="container-title">
                                    <div className="cnt-bar custom-mb-20 custom-mt-10">
                                        <p className="text-18 bold color-blue custom-mb-20">{translated("app_copy.one_no_drinking")}</p>
                                    </div>
                                    <h2 className="text-36 custom-mb-25">{translated("app_copy.avoid_drinking")}</h2>

                                    <p className="text-16 custom-mb-40">
                                        {translated("app_copy.no_drinking_description")}
                                    </p>
                                </div>
                                <div className="sub-separator"></div>
                                <div className="container-title">
                                    <div className="cnt-bar custom-mb-20 custom-mt-10">
                                        <p className="text-18 bold color-blue custom-mb-20">{translated("app_copy.two_don_t_eat")}</p>
                                    </div>
                                    <h2 className="text-36 custom-mb-25">{translated("app_copy.you_shouldn_t_eat")}</h2>

                                    <p className="text-16 custom-mb-40">
                                        {translated("app_copy.don_t_eat_description")}
                                    </p>
                                </div>
                            </div>
                            :
                            <Spiner
                                height="100%"
                                width="100px"
                                className="page-center align-items-center"
                                margin="auto"
                            />
                        }
                    </div>
                </div>
            </section>
        </Pages>
    );
};

export default PreTest;
