/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import DatePicker from "./DatePicker";
import moment from "moment";
import { AppContext } from "@/context/createContext";

const DateInput: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
  oldSelection: any;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string, validation_only: boolean) => void;
}> = ({ attribute, isShimmer, oldSelection, index, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFormat, setInputFormat] = useState<string | null>(null);
  const { userInfo } = useContext(AppContext);
  const [oldData, setOldData] = useState(oldSelection);
  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };

  useEffect(() => {
    if (inputFormat === null && attribute?.format?.input_format) {
      setInputFormat(attribute?.format?.input_format);
    }
    // if (oldSelection !== "" && !isNaN(Number(oldSelection)) && attribute?.validation?.user_validate === undefined) {
    //   setInputValue(moment.unix(Number(oldSelection)).format("L"));
    // }
  }, [oldSelection, attribute]);

  useEffect(() => {
    console.log({ inputValue, oldData })

    submitData(
      inputValidation(attribute.validation, inputValue, oldData),
      attribute.key,
      inputValue,
      attribute?.validation?.user_validate ?? false
    );
  }, [inputValue]);

  useEffect(() => {
    if (userInfo?.user_data && attribute?.validation?.user_validate === true) {
      setOldData((userInfo?.user_data !== null
        && Object.keys(userInfo?.user_data).length > 0
        && userInfo?.user_data[attribute?.key] !== undefined)
        ? userInfo?.user_data[attribute?.key] : oldSelection)
    }
  }, [userInfo]);

  return inputFormat !== null ? (
    <>
      {attribute?.instruction && (
        <p className="text-16">{attribute?.instruction}</p>
      )}
      {attribute?.enabled !== undefined && attribute?.enabled === false ? (
        <div className="input-wrapper" key={index?.toString()}>
          <input
            type="text"
            id={"date-input-" + index}
            autoComplete="off"
            value={inputValue}
            // readOnly={
            //   attribute?.enabled !== undefined && attribute?.enabled === false
            // }
            onChange={(e) => updateInputValue(e.target.value)}
            className={
              isShimmer ? "common-shimmer form-control" : "form-control"
            }
          />
          <label htmlFor={"date-input-" + index}>
            {attribute.placeholder_text}
          </label>
        </div>
      ) : (
        <DatePicker
          oldSelection={(oldSelection && attribute?.validation?.user_validate === undefined) ? oldSelection : ""}
          dateFormat={inputFormat}
          submitData={(value) => {
            updateInputValue(moment(value).format("L"));
          }}
          placeholderText={attribute.placeholder_text}
          showMonthYearPicker={false}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default DateInput;
