/* eslint-disable no-lone-blocks */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppContextProvider } from "@/context/provider/AppContextProvider";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";

// import { authentication } from "@/services/firebase/authentication";
import React from "react";
// import { isMobile } from "react-device-detect";
// import { logoLogin, appleStore, googlePlay } from "@/config/svg";

function App() {

  {/* if (
    isMobile &&
    !(window.location.href.indexOf("action?mode=verifyEmail") > -1 || window.location.href.indexOf("test-temp-user-create") > -1 || window.location.href.indexOf("action?mode=resetPassword") > -1 )
  ) {
    return (
      <BrowserRouter>
        <AppContextProvider>
          <div className="cnt-mob">
            <div className="logo">
              <div className="logo-login text-center">
                <img className="m-2 custom-mb-60" src={logoLogin} alt="logo" />
                <h1>Download our app</h1>
                <div className="d-flex">
                  <span className="cursor-pointer dwd-app">
                    <img src={appleStore} alt="logo" />
                  </span>
                  <span className="cursor-pointer dwd-app">
                    <img src={googlePlay} alt="logo" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </AppContextProvider>
      </BrowserRouter>
    );
  }*/}
  return (
    <React.Suspense fallback="loading">
      <BrowserRouter>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
