/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"; // useState
import { AttributeType } from "@/interface/index";
// import { ShimmerTitle } from "../../packages/Shimmer";

const TitleType: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
}> = ({ attribute, isShimmer }) => {
  return (
    <div className="form-group sub-title custom-mb-20">
      <h2
        className={isShimmer ? "common-shimmer text-25 bold" : "text-25 bold"}
      >
        {attribute.display_text}
      </h2>
    </div>
  );
};

export default TitleType;
