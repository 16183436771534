import { dataUrlToFile } from "@/helper/common";
import { CameraType } from "@/interface/index";
import React, { useRef, useState, useContext } from "react";
import { Camera } from "./Camera";
import { AppContext } from "@/context/createContext";

// import { Camera, CameraType } from "react-camera-pro";
export declare type FacingMode = "user" | "environment";
const DeviceCamera: React.FC<{
  setopenCamera: any;
  setPhotoURL: any;
  setOpenCrop?: any;
}> = ({ setopenCamera, setPhotoURL, setOpenCrop }) => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [facingMode, setFacingMode] = useState<FacingMode>("user");
  const camera = useRef<CameraType>(null);
  const { translated } = useContext(AppContext);

  const uploadImage = async () => {
    if (camera.current) {

      setopenCamera(false);
      const photo = camera.current.takePhoto();
      const file = await dataUrlToFile(photo, "userImage");
      //console.log(file);
      setPhotoURL(URL.createObjectURL(file));
      // fileUpload(file);
      setOpenCrop(true)
    }
  };
  return (
    <div className="h-100 position-relative">
      <div className="camera-wrapper">
        <Camera
          ref={camera}
          aspectRatio="cover"
          facingMode={facingMode}
          numberOfCamerasCallback={setNumberOfCameras}
          errorMessages={{
            noCameraAccessible:
              "No camera device accessible. Please connect your camera or try a different browser.",
            permissionDenied:
              "Permission denied. Please refresh and give camera permission.",
            switchCamera:
              "It is not possible to switch camera to different one because there is only one video device accessible.",
            canvas: "Canvas is not supported.",
          }}
        />
      </div>
      <div className="control position-absolute d-flex justify-content-evenly align-items-end h-100 custom-mt-24 w-100">
        <div className="width-180">
          <button
            className="col-12 custom-btn btn-standard-background button-small"

            onClick={uploadImage}
          >
            <p className="btn-text background-color">{translated("cta.take_photo")}</p>
          </button>
        </div>
        <div className="width-180">
          <button
            className="col-12 custom-btn btn-standard-background button-small"

            disabled={numberOfCameras <= 1}
            onClick={() => {
              setTimeout(() => {
                if (camera.current) {
                  if (facingMode === "user") {
                    setFacingMode("environment");
                  } else {
                    setFacingMode("user");
                  }

                  camera.current.switchCamera();
                  //console.log(result);
                }
              }, 1000);
            }}
          >
            <p className="btn-text background-color">{translated("cta.change_camera")}</p>
          </button>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default DeviceCamera;
