/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { password1, hidePassword } from "@/config/svg";

const PasswordInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  isShimmer?: boolean;
  autoFocus?: boolean;
  compareValidationData?: any;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({
  attribute,
  oldSelection,
  autoFocus,
  compareValidationData,
  index,
  submitData,
  isShimmer,
}) => {
  const [inputValue, setInputValue] = useState("");

  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };
  // This variable determines whether password is shown or not
  const [isShown, setIsSHown] = useState(false);

  // This function is called when the checkbox is checked or unchecked
  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue, compareValidationData),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    <>
      <input
        type={isShown ? "text" : "password"}
        id={"password-" + index}
        autoComplete="off"
        autoFocus={autoFocus}
        value={inputValue}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        onChange={(e) => updateInputValue(e.target.value)}
        className={isShimmer ? "common-shimmer" : ""}
      />
      <label htmlFor={"password-" + index}>{attribute.placeholder_text}</label>
      <span className="show-hide-psw" onClick={togglePassword}>
        <img src={isShown ? hidePassword : password1} alt="hide"></img>
      </span>
    </>
  );
};

export default PasswordInput;
