import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getApp } from "firebase/app";
const firebaseApp = getApp()
const firebaseStorage = getStorage(firebaseApp);

export const uploadImageToStorage = (file: any, file_url: string) => {
    const storageRef = ref(firebaseStorage, file_url);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask
}

export const storageDownloadURL = async (path: string) => {
    return await getDownloadURL(ref(firebaseStorage, path))
        .then((url) => {
            // console.log('storage', url);
            return url;
        })
        .catch((error) => {
            console.log(error);
        });
}


export const getStorageData = async (url: string) => {
    // const storage = ref(firebaseStorage, "gs:/" + url);
    // getDownloadURL(ref(storage, 'images/stars.jpg'))
    // .then((url) => {
    console.log('storage');
}