/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Pages from "@/layouts/Pages";
const DiagnosisReport: React.FC = () => {

  return (
    <Pages>
      <section className="h-100 h-custom diagnosis-report">
        <div className="d-flex justify-content-center py-3 h-100">
          <div className="demo-diagonosis">
            {/* <div><img src="/sample/1.jpg" alt="" />  </div> */}
            <div><img src="/sample/2.jpg" alt="" /> </div>
            <div><img src="/sample/3.jpg" alt="" /> </div>
            {/* <div><img src="/sample/4.jpg" alt="" /> </div> */}
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default DiagnosisReport;
