/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import resetPasswordImage from "../../assets/img/popcorn-bowl.png";
// import { useNavigate } from "react-router-dom";
// import routes from "@/config/routes";
import { AppContext } from "@/context/createContext";
const PasswordResetSuccess: React.FC = () => {
  // const navigate = useNavigate();
  const { translated } = useContext(AppContext);
  useEffect(() => {
    document.body.className = "checkout-area welcome";

    return () => {
      document.body.className = "";
    };
  }, []);

  // const handleContinue = () => {
  //   navigate(routes.root);
  // };
  return (
    <Pages>
      <section className="h-100 h-custom schedule-container">
        <div className="row h-100">
          <div className="row align-items-center scrollcontainer1">
            <PerfectScrollbar>
              <div className="col-6 kyc-title custom-pl-100 h-100">
                <div className="container-title">
                  <div className="cnt-bar custom-mb-20">
                    <p className="blue-small-bold-text">{translated("app_copy.way_to_go")}</p>
                  </div>
                  <h1 className="kyc-flow">{translated("app_copy.your_password_has_been_changed")}</h1>

                  {/* <div className="mb-3 text-center confirm-schedule width-236">
                    <button
                      className="custom-btn btn-standard-gradient"
                      onClick={handleContinue}
                    >
                      <p className="btn-text bold small-text rainbow_purple-blue">
                      {translated('cta.accept')}
                      </p>
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="col-6 h-100 dates-box position-relative">
                <img src={resetPasswordImage} alt={translated("app_copy.reset_password")} className="welcome-img" />
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default PasswordResetSuccess;
