/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { AppContext } from "@/context/createContext";
import { checkMark } from "@/config/svg";
import Modal from "../common/Modal";

const OTPInput: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
  otpValidate?: boolean;
  oldSelection: any;
  autoFocus?: boolean;
  resentOTP: () => void;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({
  attribute,
  otpValidate,
  oldSelection,
  isShimmer,
  autoFocus,
  index,
  submitData,
  resentOTP,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [validation, setValidation] = useState(false);
  const [ModalShow, setModalShow] = useState(false);

  const { translated } = useContext(AppContext);
  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };
  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
    setValidation(inputValidation(attribute.validation, inputValue));
  }, [inputValue]);
  return (
    <>
      <div id="otp-verrify" className="input-wrapper">
        <input
          type="text"
          id={"otp-input-" + index}
          autoComplete="off"
          autoFocus={
            attribute?.enabled !== undefined && attribute?.enabled === false
              ? false
              : autoFocus
          }
          value={inputValue}
          maxLength={6}
          onChange={(e) => updateInputValue(e.target.value)}
          className={isShimmer ? "common-shimmer" : ""}
        />
        <label htmlFor={"otp-input-" + index}>
          {attribute.placeholder_text}
        </label>

        {validation === true && otpValidate === true && (
          <span
            className={
              isShimmer ? "common-shimmer valid-field-icon" : "valid-field-icon"
            }
          >
            <img src={checkMark} width="20" height="20" alt="Check Mark" />
          </span>
        )}
      </div>

      <div className="text-center">
        <span
          onClick={() => setModalShow(true)}
          className={
            isShimmer
              ? "common-shimmer cursor-pointer text-underline text-14"
              : "cursor-pointer text-underline text-14"
          }
        >
          {translated("cta.havent_recieved_the_code")}
        </span>
      </div>

      <Modal
        size={"sm"}
        modalTitle={"Contact customer support"}
        show={ModalShow}
        handleClose={() => setModalShow(false)}
      >
        <div className="custom-mt-20">
          <button className="standard-btn" onClick={() => setModalShow(false)}>
            <p className="text-18 bold color-blue">{translated("cta.ok")}</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default OTPInput;
