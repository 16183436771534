/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import "react-dates/initialize";
// import "react-dates/lib/css/_datepicker.css";
import DatePicker from "react-datepicker";
import "./datePicker.css";
import moment from "moment";
import LeftArrow from "../svg/LeftArrow";
import RightArrow from "../svg/RightArrow";

const PrevIcon = (props: any) => <LeftArrow {...props} />;
const NextIcon = (props: any) => <RightArrow {...props} />;
const DatePciker: React.FC<{
  oldSelection: string;
  dateFormat: string;
  placeholderText?: string;
  showMonthYearPicker: boolean;
  isFutureDateOff?: boolean;
  submitData: (value: string) => void;
}> = ({
  oldSelection,
  dateFormat,
  showMonthYearPicker,
  isFutureDateOff,
  placeholderText = "YYYY-MM-DD",
  submitData,
}) => {
    const [startDate, setStartDate] = useState<any>(null);

    const updateInputValue = (newInputValue: string) => {
      setStartDate(newInputValue);
      submitData(newInputValue);
    };

    // const DateProps = {
    //   numberOfMonths: 1,
    //   navPrev: <PrevIcon />,
    //   navNext: <NextIcon />,
    //   displayFormat: placeholderText,
    //   placeholder: placeholderText,
    //   isOutsideRange: (date: any) => false,
    //   showDefaultInputIcon: false,
    //   hideKeyboardShortcutsPanel: true,
    //   appendToBody: true,
    //   block: true,
    // };

    // const renderMonthElementT: React.FC<{
    //   month?: any;
    //   onMonthSelect?: any;
    //   onYearSelect?: any;
    // }> = ({ month, onMonthSelect, onYearSelect }) => {
    //   let i;
    //   let years = [];
    //   for (i = moment().year(); i >= moment().year() - 100; i--) {
    //     years.push(
    //       <option value={i} key={`year-${i}`}>
    //         {i}
    //       </option>
    //     );
    //   }
    //   return (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <div>
    //         <select
    //           value={month.month()}
    //           onChange={(e) => onMonthSelect(month, e.target.value)}
    //         >
    //           {moment.monthsShort().map((label, value) => (
    //             <option value={value} key={`month-${value}`}>
    //               {label}
    //             </option>
    //           ))}
    //         </select>
    //       </div>
    //       <div>
    //         <select
    //           value={month.year()}
    //           onChange={(e) => onYearSelect(month, e.target.value)}
    //         >
    //           {/* <option value={moment().year() - 1}>Last year</option>
    //         <option value={moment().year()}>{moment().year()}</option>
    //         <option value={moment().year() + 1}>Next year</option> */}
    //           {years}
    //         </select>
    //       </div>
    //     </div>
    //   );
    // };

    useEffect(() => {
      if (oldSelection !== "" && !isNaN(Number(oldSelection))) {
        setStartDate(new Date(Number(oldSelection) * 1000));
      }
    }, [oldSelection]);

    return (
      <DatePicker
        selected={startDate}
        onChange={(date: any) => {
          if (date) {
            updateInputValue(date);
          }
        }}
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        // excludeDateIntervals={[
        //   { start: new Date(), end: new Date(new Date().getFullYear(), 11, 31) },
        // ]}
        maxDate={isFutureDateOff ? new Date() : null}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          let i;
          let years = [];
          for (i = moment().year(); i >= moment().year() - 100; i--) {
            years.push(
              <option value={i} key={`year-${i}`}>
                {i}
              </option>
            );
          }
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <PrevIcon />
              </button>
              <div>
                {/* <select
                value={moment(date).format("MMM")}
                onChange={(e) => changeMonth(Number(e.target.value))}>
                {moment.monthsShort().map((label: any, value: Number) => (
                  <option value={value} key={`month-${value}`}>
                    {label}
                  </option>
                ))}
              </select> */}
                <select
                  value={moment(date).format("MMM")}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  value={moment(date).format("YYYY")}
                  onChange={(e) => changeYear(Number(e.target.value))}
                >
                  {years}
                </select>
              </div>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <NextIcon />
              </button>
            </div>
          );
        }}
      />
    );
    // <SingleDatePicker
    //   {...DateProps}
    //   renderMonthElement={renderMonthElement}
    //   date={startDate} // momentPropTypes.momentObj or null
    //   onDateChange={(date: any) => {
    //     if (date) {
    //       updateInputValue(date);
    //     }
    //   }} // PropTypes.func.isRequired
    //   focused={focusedInput} // PropTypes.bool
    //   onFocusChange={(focusedInput) => setFocusedInput(focusedInput.focused)} // PropTypes.func.isRequired
    //   id="your_unique_id" // PropTypes.string.isRequired,
    // />

    // startDate !== "" ? (
    //   // <DatePicker
    //   //   selected={startDate}
    //   //   onChange={(date: any) => {
    //   //     if (date) {
    //   //       updateInputValue(date);
    //   //     }
    //   //   }}
    //   //   dateFormat={dateFormat}
    //   //   placeholderText={placeholderText}
    //   //   showMonthYearPicker={showMonthYearPicker}
    //   //   showYearDropdown
    //   // />
    // ) : (
    //   // <DatePicker
    //   //   onChange={(date: any) => {
    //   //     if (date) {
    //   //       updateInputValue(date);
    //   //     }
    //   //   }}
    //   //   dateFormat={dateFormat}
    //   //   placeholderText={placeholderText}
    //   //   showMonthYearPicker={showMonthYearPicker}
    //   //   showYearDropdown
    //   // />
    //   <SingleDatePicker
    //     {...DateProps}
    //     date={startDate} // momentPropTypes.momentObj or null
    //     onDateChange={(date: any) => {
    //       if (date) {
    //         updateInputValue(date);
    //       }
    //     }} // PropTypes.func.isRequired
    //     focused={focusedInput} // PropTypes.bool
    //     onFocusChange={(focusedInput) => setFocusedInput(focusedInput.focused)} // PropTypes.func.isRequired
    //     id="your_unique_id" // PropTypes.string.isRequired,
    //   />
  };

export default DatePciker;
