/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { textNotification } from "@/config/svg";
import { setUserData, updateUserData } from "@/services/firebase/fireStore";
import { PreferencesShimmer } from "@/components/shimmer";
const Preferences: React.FC = () => {
  const { userInfo, translated } = useContext(AppContext);
  const [isSmS, setIsSmS] = useState(false);
  const [isApp, setIsApp] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (userInfo?.notification_preferences) {
      setIsSmS(userInfo?.notification_preferences?.sms ?? false);
      setIsApp(userInfo?.notification_preferences?.app ?? false);
    }
  }, [userInfo]);
  useEffect(() => {
    document.body.className = "user-area";
    setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <div className="col-7 h-100">

      <div className=" information-panel">
        <h2 className="section-title bold-sub">{translated("app_copy.notifications")}</h2>

        {loader ?
          <PreferencesShimmer />
          : <>
            <p className="section-content preferences">
              {translated("app_copy.select_all_the_ways_you_would_like_hear_from_us")}
            </p>
            <div className="content-details">
              <ul className="list-group">
                <li className="list-group-item clearfix position-relative">
                  <img
                    className="float-start"
                    src={textNotification}
                    width="20"
                    height="20"
                    alt="text-notification"
                  />{" "}
                  <p>
                    {translated("app_copy.label_contact_sms")}
                    <label className="switch">
                      <input
                        type="checkbox"
                        autoComplete="off"
                        checked={isSmS}
                        onChange={() => {
                          setLoader(true);
                          setIsSmS(!isSmS);
                          if (userInfo?.authUId) {
                            if (userInfo?.user_data) {
                              updateUserData(userInfo?.authUId, {
                                notification_preferences: {
                                  ...userInfo?.notification_preferences,
                                  sms: !isSmS,
                                },
                              }).then(() => {
                                setLoader(false);
                              });
                            } else {
                              setUserData(userInfo?.authUId, {
                                notification_preferences: {
                                  sms: !isSmS,
                                },
                              }).then(() => {
                                setLoader(false);
                              });
                            }
                          }
                        }}
                        className=""
                      />
                      <span className="slider round"></span>
                    </label>
                  </p>
                </li>
                <li className="list-group-item clearfix position-relative">
                  <img
                    className="float-start"
                    src={textNotification}
                    width="20"
                    height="20"
                    alt="text-notification"
                  />{" "}
                  <p>
                    {translated("app_copy.label_contact_app")}
                    <label className="switch">
                      <input
                        type="checkbox"
                        autoComplete="off"
                        checked={isApp}
                        onChange={() => {
                          setLoader(true);
                          setIsApp(!isApp);
                          if (userInfo?.authUId) {
                            if (userInfo?.user_data) {
                              updateUserData(userInfo?.authUId, {
                                notification_preferences: {
                                  ...userInfo?.notification_preferences,
                                  app: !isApp,
                                },
                              }).then(() => {
                                setLoader(false);
                              });
                            } else {
                              setUserData(userInfo?.authUId, {
                                notification_preferences: {
                                  app: !isApp,
                                },
                              }).then(() => {
                                setLoader(false);
                              });
                            }
                          }
                        }}
                        className=""
                      />
                      <span className="slider round"></span>
                    </label>
                  </p>
                </li>
              </ul>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Preferences;
