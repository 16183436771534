/* eslint-disable react-hooks/exhaustive-deps */
import { backArrow } from "@/config/svg";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";
const OrderPDF: React.FC = () => {
  const params = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    document.body.className = "user-area";

    // if (isMobile && isAndroid) {
    //   var link = document.createElement('a');
    //   link.href = "data:application/pdf; base64," + atob(String(params?.data));
    //   link.download = 'file.pdf';
    //   link.dispatchEvent(new MouseEvent('click'));
    // }
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <section className="order-pdf-section">
      <img
        className="cursor-pointer float-start pr-5 cart"
        src={backArrow}
        width="70"
        height="70"
        alt="back"
        onClick={() => navigate(-1)}
      />
      <object
        className="pdf-viewer-object"
        data={
          atob(String(params?.data)) === "URL"
            ? "https://files.labcorp.com/testmenu-d8/sample_reports/144980.pdf"
            : "data:application/pdf; base64," + atob(String(params?.data))
        }
        type="application/pdf"
      >
        <div>No online PDF viewer installed</div>
      </object>
    </section>
  );
};

export default OrderPDF;
