/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";

const SelectionSwitch: React.FC<{
  attribute: AttributeType;
  config: any;
  oldSelection: any;
  multiSelection: boolean;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[]
  ) => void;
}> = ({ attribute, oldSelection, multiSelection, config, submitData }) => {
  const [updateValue, setUpdateValue] = useState<Array<string>>([]);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    let options = config?.options.reduce((a: any, b: any) => {
      const title = b.title;
      let obj = a.find((x: any) => x.title === title);
      if (!obj)
        a.push({
          title: title,
          links: [
            {
              key: b.key,
              url: b.url,
              display_text: b.display_text,
            },
          ],
        });
      else
        obj.links.push({
          key: b.key,
          url: b.url,
          display_text: b.display_text,
        });
      return a;
    }, []);
    setOptions(options);
  }, []);

  // console.log("options", options);
  const optionClicked = (newupdateValue: string) => {
    const noneValue = options.filter((e: any) => e.is_reset_option);
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        var newArray = multiSelection
          ? [...updateValue, dataProcess(element, newupdateValue).trimStart()]
          : [dataProcess(element, newupdateValue).trimStart()];
        setUpdateValue(newArray.filter((e: any) => e !== noneValue[0].key));
      });
    } else {
      var newArray = multiSelection
        ? [...updateValue, newupdateValue]
        : [newupdateValue];
      setUpdateValue(newArray.filter((e: any) => e !== noneValue[0].key));
    }
  };

  const optionUnselect = (value: string) => {
    var newArray = updateValue.filter(function (item: string) {
      return item !== value;
    });
    setUpdateValue(newArray);
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setUpdateValue(oldSelection.toString().split(","));
    }
  }, [oldSelection]);

  useEffect(() => {
    if (updateValue.length > 0) {
      submitData(
        inputValidation(attribute?.validation, updateValue[0]),
        attribute.key,
        updateValue
      );
    } else {
      submitData(false, attribute.key, updateValue);
    }
  }, [updateValue]);

  const resetSelections = (value: string) => {
    if (value !== "") {
      setUpdateValue([value]);
    } else {
      setUpdateValue([]);
    }
  };
  return (
    <div className="form-group">
      {options.length > 0 &&
        options.map((links: any, indexx: number) => {
          return (
            links?.links?.length > 0 && (
              <div key={indexx.toString()}>
                <h2 className="start-page concent_title">{links.title}</h2>
                {links?.links?.map((option: any, index: number) => {
                  return (
                    <div className="concent_form form-check" key={index.toString()}>
                      {!option.is_reset_option ? (
                        <input
                          type="checkbox"
                          autoComplete="off"
                          checked={updateValue.includes(option.key)}
                          onChange={() =>
                            updateValue.includes(option.key)
                              ? optionUnselect(option.key)
                              : optionClicked(option.key)
                          }
                          className="form-check-input switch_box"
                        />
                      ) : (
                        <input
                          type="checkbox"
                          autoComplete="off"
                          checked={updateValue.includes(option.key)}
                          onChange={() =>
                            updateValue.includes(option.key)
                              ? resetSelections("")
                              : resetSelections(option.key)
                          }
                          className="form-check-input switch_box"
                        />
                      )}
                      <a className="text-decoration-underline form-check-label" target="_blank" rel="noopener noreferrer" href={option.url}>
                        {option.display_text}
                      </a>
                    </div>
                  );
                })}
              </div>
            )
          );
        })}
    </div>
  );
};

export default SelectionSwitch;
