import Pages from "@/layouts/Pages";
import { useContext } from "react";
import { LoginForm, LoginOtpVerify } from "@/components/index";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "@/context/createContext";

const Login: React.FC = () => {
  const { modules, translated, mainLoader, remainingLoaderTime } =
    useContext(AppContext);
  const isOtpVerify = false;
  const [searchParams] = useSearchParams();
  const user_email = searchParams.get("email");

  return (
    <Pages>
      {modules?.is_login_flow === true ?

        <section className="login-section">
          {isOtpVerify &&
            String(searchParams.get("metadata")) === "verify_otp" ? (
            <LoginOtpVerify />
          ) : (
            <div className="two-columns-layout">
              <div className="half-col left-col background-lilla">
                <p
                  className={
                    mainLoader || remainingLoaderTime
                      ? "common-shimmer text-18 bold color-blue custom-mb-20"
                      : "text-18 bold color-blue custom-mb-20"
                  }
                >
                  {user_email
                    ? translated("app_copy.sign_up_header")
                    : translated("app_copy.welcome_back")}
                </p>
                <h2
                  className={
                    mainLoader || remainingLoaderTime
                      ? "common-shimmer text-36 custom-mb-25"
                      : "text-36 custom-mb-25"
                  }
                >
                  {user_email
                    ? translated("app_copy.sign_up_title")
                    : translated("app_copy.login_title")}
                </h2>
                <p
                  className={
                    mainLoader || remainingLoaderTime
                      ? "common-shimmer text-16"
                      : "text-16"
                  }
                >
                  {user_email
                    ? translated("app_copy.sign_up_desc")
                    : translated(
                      "app_copy.enter_the_credentials_you_used_during_sign_up"
                    )}
                </p>
              </div>
              <div className="half-col right-col align-block-center">
                <LoginForm />
              </div>
            </div>
          )}
        </section>
        :
        <section className="login-section">
          <div className="text-center">
            <div className="cnt-bar custom-mb-20">
            </div>
            <h1 className="text-36 custom-mb-25">
              {translated("app_copy.session_time_out")}</h1>
            <p className="text-16 custom-mb-40 ">
              {translated("app_copy.please_try_again")}
            </p>
            <br />
          </div>
        </section>
      }
    </Pages>
  );
};

export default Login;
