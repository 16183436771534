/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  unlikelyBad,
  probeblyNotBad,
  maybeNotBad,
  maybeNormal,
  ratherYesNormal,
  yesGood,
  feedbackDisable,
  likelyGood,
  veryLikelyGood,
  ratherNoNormal,
  veryUnlikelyBad,
} from "@/config/svg";
// import { ShimmerCircularImage, ShimmerTitle, ShimmerText } from "../../packages/Shimmer";
const RatingSliderInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  question: any;
  autoFocus?: boolean;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, oldSelection, question, autoFocus, submitData }) => {
  const [feedbackImage, setFeedbackImage] = useState(feedbackDisable);
  const [value, setValue] = useState(0);
  // const [shimmer, setShimmer] = useState(true)
  // setTimeout(() => {
  //   setShimmer(false)
  // }, 200)
  useEffect(() => {
    if (oldSelection !== "") {
      setValue(oldSelection as number);
    }
  }, []);
  const [color, setColor] = useState("gray");

  useEffect(() => {
    if (Number(value) > 0) {
      submitData(
        inputValidation(attribute.validation, String(value)),
        attribute.key ?? "",
        String(value) ?? ""
      );
    }

    if (Number(value) === 0) {
      submitData(inputValidation(attribute.validation, ""), attribute.key, "");
      setFeedbackImage(feedbackDisable);
      setColor("drag-text gray");
    } else if (Number(value) === 1) {
      setFeedbackImage(veryUnlikelyBad);
      setColor(" drag-text very-unlikely");
    } else if (Number(value) === 2) {
      setFeedbackImage(unlikelyBad);
      setColor(" drag-text unlikely ");
    } else if (Number(value) === 3) {
      setFeedbackImage(probeblyNotBad);
      setColor(" drag-text probably-not");
    } else if (Number(value) === 4) {
      setFeedbackImage(maybeNotBad);
      setColor(" drag-text maybe-not");
    } else if (Number(value) === 5) {
      setFeedbackImage(ratherNoNormal);
      setColor(" drag-text rather-no");
    } else if (Number(value) === 6) {
      setFeedbackImage(maybeNormal);
      setColor(" drag-text maybe");
    } else if (Number(value) === 7) {
      setFeedbackImage(ratherYesNormal);
      setColor(" drag-text rather-yes");
    } else if (Number(value) === 8) {
      setFeedbackImage(yesGood);
      setColor(" drag-text yes");
    } else if (Number(value) === 9) {
      setFeedbackImage(likelyGood);
      setColor(" drag-text likely");
    } else if (Number(value) === 10) {
      setFeedbackImage(veryLikelyGood);
      setColor("drag-text very-likely");
    } else {
      setFeedbackImage(feedbackDisable);
      setColor("drag-text gray");
    }
  }, [value]);

  const updateCurrentActive = (currentIndex: number) => {
    const elements = document.querySelectorAll(".rc-slider .rc-slider-mark");

    const rcSlider: HTMLButtonElement | any =
      elements.length > 0 ? elements[0] : null;

    if (rcSlider) {
      rcSlider.childNodes.forEach((element: any, index: number) => {
        if (index === currentIndex - 1) {
          element.setAttribute("data-active", "1");
        } else {
          element.setAttribute("data-active", "0");
        }
      });
    }
  };

  return (
    <>
      <div className="cnt-feed-img text-center">
        {(value <= attribute?.options?.length && value > 0) &&
          <img
            className={"feedback-image " + attribute?.options[value - 1]?.image}
            src={feedbackImage}
            height="110px"
            width="110px"
            alt="feedback"
          />
        }
        {(value === 0) &&
          <img
            className="feedback-image"
            src={feedbackImage}
            height="110px"
            width="110px"
            alt="feedback"
          />
        }
      </div>

      <div className="text-center">
        {(value <= attribute?.options?.length && value > 0) &&
          <p className={"text-16 bold " + color} style={{ color: attribute?.options[value - 1]?.color }}> {attribute?.options[value - 1]?.display_text}</p>
        }
        {(value === 0) &&
          <p className={"text-16 bold " + color}> drag the slider</p>
        }
      </div>
      {(value <= attribute?.options?.length && value > 0) &&
        <Slider
          className={color + " " + attribute?.options[value - 1]?.image}
          step={1}
          onChange={(nextValues: any) => {
            setValue(nextValues as any);
            updateCurrentActive(nextValues);
          }}
          value={value}
          min={1}
          max={10}
          marks={{
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
          }}
        />
      }

      {(value === 0) &&
        <Slider
          className={color}
          step={1}
          onChange={(nextValues: any) => {
            setValue(nextValues as any);
            updateCurrentActive(nextValues);
          }}
          value={value}
          min={1}
          max={10}
          marks={{
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
          }}
        />
      }
      {/* <div className="rating-values">
        {[...Array(10).keys()].map((index) => (
          <span className={Number(value) === index + 1 ? "active-value" : ""}>
            {index + 1}
          </span>
        ))}
      </div> */}
    </>
  );
};

export default RatingSliderInput;
