/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { backArrow, uploadRange } from "@/config/svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import routes from "@/config/routes";
import { AttributeType } from "@/interface/index";

import {
  DateInput,
  EmailInput,
  NumberInput,
  PhoneInput,
  ProgressBar,
  SelectedOption,
  Spiner,
  StringInput,
  ConsentLink,
  CheckBoxInput,
  SelectionCheckbox,
  SelectedDropdown,
  CapturedImage,
  ZipcodeInput,
  SelectionSwitch,
  SwitchSelection,
  TitleType,
  BioMarkerInput,
  PasswordInput,
  OTPInput,
} from "@/components/index";
import {
  getFireStoreDoc,
  setUserData,
  updateUserData,
} from "@/services/firebase/fireStore";
import { isLogin } from "@/helper/common";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
} from "firebase/auth";
// import jsonStep from "../../onboarding.json";
// import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { toast } from "react-toastify";
import {
  commonSentOTP,
  commonVerifyOTP,
  createStripeCustomer,
} from "@/services/api/fireFunction";

import PerfectScrollbar from "react-perfect-scrollbar";
import { stepModuleText } from "@/config/configarableVariable";

const Onboarding: React.FC = () => {
  const auth = getAuth();
  const {
    showToastMessage,
    userInfo,
    stepModules,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    translated,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isContinue, setIsContinue] = useState(false);
  const [onOnboarding, setOnOnboarding] = useState<any>([]);
  const [previousData, setPreviousData] = useState<any>({});
  const [isLoader, setIsLoader] = useState(false);
  const [onboardingStep, setOnboardingSteps] = useState<any>([]);
  const [modulePath, setModulePath] = useState<string>("");
  const [progress, setProgress] = useState(
    Number(searchParams.get("step") ? searchParams.get("step") : 1)
  );
  const [currentStep, setCurrentStep] = useState<any>();
  const [currentStepValue, setCurrentStepValue] = useState<any>({});
  const { search } = useLocation();

  // console.log(onboardingStep);
  // useEffect(() => {
  //   if (userInfo?.authUId && !isLogin(userInfo?.authUId)) {
  //     if (auth?.currentUser !== null && auth?.currentUser?.email !== null) {
  //       newAnonymousUser();
  //     }
  //   }
  // }, [userInfo]);
  useEffect(() => {
    const currentStep = Number(
      searchParams.get("step") ? searchParams.get("step") : 1
    );
    setProgress(currentStep);
    if (currentStep !== 1) {
      if (
        stepModules[stepModuleText?.essentialStepDataText]?.steps &&
        stepModules[stepModuleText?.essentialStepDataText].steps[
          currentStep - 1
        ]?.conditions !== undefined
      ) {
        var conditions: any =
          stepModules[stepModuleText?.essentialStepDataText].steps[
            currentStep - 1
          ]?.conditions;
        if (conditions.length > 0) {
          setRootLoader();
          conditions.forEach((item: any) => {
            getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
              if (
                keys !== undefined &&
                keys[item.scope] !== undefined &&
                keys[item.scope]
              ) {
                if (keys[item.scope][item.attribute] !== item.value) {
                  handleContinue();
                  unSetRootLoader();
                  return false;
                } else {
                  unSetRootLoader();
                }
              } else {
                unSetRootLoader();
              }
            });
          });
        }
      }
      if (
        stepModules[stepModuleText?.essentialStepDataText]?.steps &&
        stepModules[stepModuleText?.essentialStepDataText].steps[
          currentStep - 1
        ]?.is_active !== undefined &&
        stepModules[stepModuleText?.essentialStepDataText].steps[
          currentStep - 1
        ]?.is_active === false
      ) {
        nextStep();
      }
    }
  }, [search, stepModules]);
  useEffect(() => {
    if (stepModules[stepModuleText?.essentialStepDataText] === null) {
      setIsLoader(true);
    }
    if (stepModules[stepModuleText?.essentialStepDataText]?.steps?.length > 0) {
      setIsLoader(false);
      setOnboardingSteps(
        stepModules[stepModuleText?.essentialStepDataText]?.steps
      );
      setCurrentStep(
        stepModules[stepModuleText?.essentialStepDataText]?.steps[progress - 1]
      );
      setModulePath(
        stepModules[stepModuleText?.essentialStepDataText]?.store_path
      );
    }

    if (
      stepModules[stepModuleText?.essentialStepDataText]?.steps?.length <
      progress
    ) {
      setIsLoader(true);
      // showToastMessage("!", true);
      navigate(routes.root);
      setIsLoader(false);
    }

    if (modulePath !== "") {
      setIsLoader(true);
      getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
        if (
          keys !== undefined &&
          keys[modulePath] !== undefined &&
          keys[modulePath]
        ) {
          setIsLoader(false);
          setPreviousData(keys[modulePath]);
        } else {
          setIsLoader(false);
        }
      });
    }
  }, [stepModules, progress, searchParams, modulePath]);

  useEffect(() => { }, [onOnboarding]);
  useEffect(() => {
    setIsContinue(false);
  }, [progress]);

  useEffect(() => {
    document.body.className = "essential-data-page";

    return () => {
      document.body.className = "";
    };
  }, []);
  const sentOTP = () => {
    setRootLoader();
    var OTPPayload = {
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    };

    commonSentOTP(OTPPayload)
      .then((response: any) => {
        if (response?.data?.success) {
          showToastMessage("OTP sent successfully!");
          nextStep();
          unSetRootLoader();
        }
        if (response?.data?.error) {
          unSetRootLoader();
          showToastMessage(response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage("Something went wrong", true);
      });
  };

  const verifyOTP = () => {
    setRootLoader();
    commonVerifyOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
      otp: currentStepValue?.otp ?? previousData.otp,
    })
      .then((res: any) => {
        if (res?.data?.success) {
          showToastMessage(translated("error.OTP_verified_successfully!"));
          // console.log('yes', atob(String(localStorage.getItem("creadValue"))))
          const credential = EmailAuthProvider.credential(
            currentStepValue?.email ?? previousData.email,
            atob(String(localStorage.getItem("creadValue")))
          );
          if (auth?.currentUser) {
            linkWithCredential(auth?.currentUser, credential)
              .then((userCredential: any) => {
                if (userInfo.authUId) {
                  setTimeout(() => {
                    createStripeCustomer({
                      user_id: userInfo?.authUId,
                    });
                  }, 1000);
                  nextStep();
                  unSetRootLoader();
                }
                localStorage.removeItem("creadValue");
              })
              .catch((error) => {
                unSetRootLoader();
                //console.log("error", error);
                if (error.code === "auth/weak-password") {
                  showToastMessage(
                    translated(
                      "error.Password_should_be_at_least_6_characters"
                    ),
                    true
                  );
                }
                if (error.code === "auth/wrong-password") {
                  showToastMessage("Please check the Password.", true);
                }
                if (error.code === "auth/user-not-found") {
                  showToastMessage(
                    translated("error.user_not_exist_signup"),
                    true
                  );
                }
                if (error.code === "auth/email-already-in-use") {
                  showToastMessage(
                    translated("error.user_with_same_email_already_exists"),
                    true
                  );
                }
                if (error.code === "auth/provider-already-linked") {
                  showToastMessage(
                    translated("error.same_provider_used"),
                    true
                  );
                }
                // navigate(routes.signup);
              });
          }
        }
        if (res?.data?.error) {
          unSetRootLoader();
          showToastMessage(res?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  const resentOTP = () => {
    setRootLoader();

    commonSentOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    })
      .then((res: any) => {
        unSetRootLoader();
        //console.log(res?.response.data.error.message);
        if (res?.data?.success) {
          showToastMessage(translated("error.OTP_Resent_successfully"));
        }
        if (res?.response?.data?.error) {
          showToastMessage(res?.response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  const checkEmailExist = () => {
    setRootLoader();
    fetchSignInMethodsForEmail(
      auth,
      currentStepValue?.email ?? previousData.email
    )
      .then((signInMethods) => {
        unSetRootLoader();
        if (
          signInMethods.indexOf(
            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          ) !== -1 ||
          signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !==
          -1
        ) {
          toast.error(translated("error.Email_already_exist"));

          // User can sign in with email/password.
        } else {
          if (userInfo?.authUId) {
            if (
              userInfo?.user_data !== null ||
              Object.keys(previousData).length > 0
            ) {
              updateUserData(userInfo?.authUId, {
                [modulePath]: { ...previousData, ...currentStepValue },
              });
            } else {
              setUserData(userInfo?.authUId, {
                [modulePath]: { ...previousData, ...currentStepValue },
              });
            }
          }
          nextStep();
        }
      })
      .catch((error) => {
        unSetRootLoader();
        toast.error(translated("error.something_went_wrong"));
        // Some error occurred, you can inspect the code: error.code
      });
  };
  // const emailExistCheck = () => {
  //   emailExist(currentStepValue?.email ?? previousData.email).then(
  //     (data: false | QuerySnapshot<DocumentData>) => {
  //       if (data) {
  //         data.forEach(() => {
  //           console.log("email exist");
  //           toast.error("Email already exist!");
  //           setIsContinue(false);
  //         });
  //       }
  //     }
  //   );
  // };

  const nextStep = () => {
    if (
      progress ===
      stepModules[stepModuleText?.essentialStepDataText]?.steps?.length
    ) {
      if (userInfo?.authUId && isLogin(userInfo?.authUId)) {
        navigate(routes.userProfile);
      } else if (userInfo?.authUId && !isLogin(userInfo?.authUId)) {
        navigate(routes.consent); //removed product
      }
    } else {
      const nextStep = progress + 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(routes.onBoarding + "?step=" + nextStep);
    }
  };
  const handleContinue = () => {
    if (currentStep?.id === "id_phone") {
      sentOTP();
    } else if (
      currentStepValue["otp"] !== undefined &&
      currentStep?.id === "id_otp"
    ) {
      verifyOTP();
    } else if (
      currentStepValue["email"] !== undefined &&
      currentStep?.id === "id_email"
    ) {
      checkEmailExist();
    } else {
      if (userInfo?.authUId) {
        if (
          userInfo?.user_data !== null ||
          Object.keys(previousData).length > 0
        ) {
          updateUserData(userInfo?.authUId, {
            [modulePath]: { ...previousData, ...currentStepValue },
          });
        } else {
          setUserData(userInfo?.authUId, {
            [modulePath]: { ...previousData, ...currentStepValue },
          });
        }
      }
      nextStep();
    }
  };

  const handleBack = () => {
    if (userInfo?.authUId && progress === 1 && isLogin(userInfo?.authUId)) {
      navigate(routes.userProfile);
    } else if (
      userInfo?.authUId &&
      progress === 1 &&
      !isLogin(userInfo?.authUId)
    ) {
      navigate(routes.login);
    } else {
      const nextStep = progress - 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(routes.onBoarding + "?step=" + nextStep);
    }
  };

  const handleSkip = () => {
    if (
      currentStep.skip_to_finish !== undefined &&
      currentStep.skip_to_finish === true
    ) {
      navigate(routes.root);
    } else if (currentStep?.skip_to && currentStep?.skip_to !== "") {
      stepModules[stepModuleText?.essentialStepDataText]?.steps?.map(
        (step: any, index: number) => {
          if (step.id === currentStep?.skip_to) {
            const nextStep = index + 1;
            setProgress(nextStep);
            setIsContinue(false);
            navigate(routes.onBoarding + "?step=" + nextStep);
          }
        }
      );
    } else {
      if (userInfo?.authUId && isLogin(userInfo?.authUId)) {
        navigate(routes.userProfile);
      } else if (userInfo?.authUId && !isLogin(userInfo?.authUId)) {
        navigate(routes.root);
      }
    }
  };

  const submitData = (isContinue: boolean, key: string, value: any) => {
    if (
      key !== "biomarker" &&
      key !== "password" &&
      key !== "confirm_password"
    ) {
      setCurrentStepValue({
        ...currentStepValue,
        [key]: value,
      });
    }

    if (key === "password") {
      localStorage.setItem("creadValue", btoa(value));
    }
    const newData = [
      ...onOnboarding.filter((data: any) => {
        return data.key !== key;
      }),
      {
        isContinue,
        key,
        inputId: currentStep?.id,
        value,
      },
    ];
    setOnOnboarding(newData);
    let continueActive: boolean = isContinue;
    newData.map((item: any) => {
      if (item.isContinue === false && item.inputId === currentStep?.id) {
        continueActive = false;
      }
    });
    setIsContinue(continueActive);
  };

  return (
    <Pages>
      <section className="h-100 h-custom category">
        <img
          className="cursor-pointer float-start pr-5 back-btn"
          src={backArrow}
          width="70"
          height="70"
          alt="back"
          onClick={handleBack}
        />
        <div className="row h-100">
          <div className="col-5 kyc-title custom-ml-100">
            <div className="container-title">
              <div className="cnt-bar custom-mb-20">
                <ProgressBar
                  totalStep={onboardingStep.length}
                  progress={progress}
                />
                <div>
                  <p className="blue-small-bold-text">
                    {translated("app_copy.let_s_get_started")}
                  </p>
                </div>
              </div>
              <h1 className="kyc-flow"> {currentStep?.title}</h1>
              <h2 className="kyc-flow custom-mt-40">
                {" "}
                {currentStep?.description}
              </h2>
            </div>

            {currentStep?.attributes
              ?.filter(
                (attribute: AttributeType) =>
                  attribute?.key === "attestation_of_no_emergency" ||
                  attribute?.key === "biomarker" ||
                  attribute?.display_text?.includes("Clinical Condition")
              )
              ?.map((attribute: AttributeType, index: number) => (
                <div className="right-inputs" key={index?.toString()}>
                  {attribute?.display_text === "Clinical Condition" && (
                    <TitleType attribute={attribute} />
                  )}
                  {attribute?.key === "attestation_of_no_emergency" && (
                    <SwitchSelection
                      attribute={attribute}
                      submitData={(iContinue, key, value) => {
                        setIsContinue(iContinue);
                        submitData(iContinue, key, value);
                      }}
                      oldSelection={
                        previousData[attribute?.key]
                          ? previousData[attribute?.key]
                          : ""
                      }
                    />
                  )}
                  {attribute?.key === "biomarker" && (
                    <div className="bio-item clearfix d-flex justify-content-between">
                      <div className="ps-2" id={attribute?.key}>
                        <h2 className="start-page ">Set your values</h2>
                        <p className="small-text-supergray">
                          Play with our ranges
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          className="forward-icon"
                          src={uploadRange}
                          width="45"
                          height="45"
                          alt="Next"
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="col-5">
            {isLoader || mainLoader || remainingLoaderTime ? (
              <Spiner height="120px" width="120px" margin="auto" />
            ) : (
              <div className="row h-100">
                <div className="row align-items-center h-75 custom-pb-16">
                  {onboardingStep?.length > 0 && (
                    <PerfectScrollbar>
                      <div className="col custom-mt-10 width-445 centered-y custom-pb-16">
                        {currentStep?.template === "input" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                key={index?.toString()}
                                className={
                                  attribute?.type !== "date"
                                    ? "col-sm-12 custom-mb-30 custom-pl-16 custom-pr-16"
                                    : "col-sm-12 custom-pl-16 custom-pr-16"
                                }
                              >
                                {attribute?.type === "string" && (
                                  <StringInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "email" && (
                                  <EmailInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "zip_code" && (
                                  <ZipcodeInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "date" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <DateInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                  />
                                )}
                                {attribute?.type === "number" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <NumberInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "phone" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <PhoneInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "consent" && (
                                  <ConsentLink
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      // setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key] ?? false
                                    }
                                  />
                                )}
                                {attribute?.type === "boolean" && (
                                  <CheckBoxInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key] ?? false
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "dropdown" && (
                                  <SelectedDropdown
                                    attribute={attribute}
                                    config={currentStep?.config}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                  />
                                )}
                                {attribute?.type === "section_title" &&
                                  attribute?.display_text !==
                                  "Clinical Condition" && (
                                    <TitleType attribute={attribute} />
                                  )}
                                {attribute?.type === "switch_selection" &&
                                  attribute?.key !==
                                  "attestation_of_no_emergency" && (
                                    <SwitchSelection
                                      attribute={attribute}
                                      submitData={(iContinue, key, value) => {
                                        setIsContinue(iContinue);
                                        submitData(iContinue, key, value);
                                      }}
                                      oldSelection={
                                        previousData[attribute?.key]
                                          ? previousData[attribute?.key]
                                          : ""
                                      }
                                    />
                                  )}
                              </div>
                            )
                          )}
                        {currentStep?.template === "input_checkbox" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12 custom-mb-30"
                                key={index?.toString()}
                              >
                                {attribute?.type === "string" && (
                                  <StringInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "email" && (
                                  <EmailInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "zip_code" && (
                                  <ZipcodeInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "date" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <DateInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                  />
                                )}
                                {attribute?.type === "number" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <NumberInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "phone" && (
                                  // eslint-disable-next-line react/jsx-no-undef
                                  <PhoneInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    autoFocus={!index}
                                  />
                                )}

                                {attribute?.type === "boolean" && (
                                  <CheckBoxInput
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key] ?? false
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                                {attribute?.type === "dropdown" && (
                                  <SelectedDropdown
                                    attribute={attribute}
                                    config={currentStep?.config}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                  />
                                )}
                                {attribute?.type === "section_title" && (
                                  <TitleType attribute={attribute} />
                                )}
                                {attribute?.type === "switch_selection" && (
                                  <SwitchSelection
                                    attribute={attribute}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                        {currentStep?.template === "selection_checkbox" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12  custom-mb-30"
                                key={index?.toString()}
                              >
                                <SelectionCheckbox
                                  multiSelection={currentStep.multi_selection}
                                  attribute={attribute}
                                  config={currentStep?.config}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                />
                              </div>
                            )
                          )}
                        {currentStep?.template === "selection_switch" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12"
                                key={index?.toString()}
                              >
                                <SelectionSwitch
                                  multiSelection={currentStep.multi_selection}
                                  attribute={attribute}
                                  config={currentStep?.config}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                />
                              </div>
                            )
                          )}
                        {currentStep?.template === "selection" &&
                          currentStep?.config?.options !== undefined &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12 custom-mb-30"
                                key={index?.toString()}
                              >
                                <SelectedOption
                                  multiSelection={currentStep.multi_selection}
                                  attribute={attribute}
                                  config={currentStep?.config}
                                  fetch_options={currentStep?.config?.fetch_options ?? false}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                />
                              </div>
                            )
                          )}

                        {currentStep?.template === "capture_image" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12 custom-mb-30 "
                                key={index?.toString()}
                              >
                                <CapturedImage
                                  attribute={attribute}
                                  submitData={(iContinue, key, value) => {
                                    setIsContinue(iContinue);
                                    submitData(iContinue, key, value);
                                  }}
                                  oldSelection={
                                    previousData[attribute?.key]
                                      ? previousData[attribute?.key]
                                      : ""
                                  }
                                />
                              </div>
                            )
                          )}

                        {
                          currentStep?.template === "input_biomarker" && (
                            // currentStep?.attributes?.map(
                            //   (attribute: AttributeType, index: number) => (
                            <div
                              className="col-sm-12 custom-mb-30"
                            //key={index?.toString()}
                            >
                              <BioMarkerInput
                                attribute={currentStep?.attributes[0]}
                                oldSelection={""}
                                submitData={(iContinue, key, value) => {
                                  setIsContinue(iContinue);
                                  submitData(iContinue, key, value);
                                }}
                              />
                            </div>
                          )
                          // )
                          //)
                        }
                        {currentStep?.template === "input_password" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12 custom-mb-30"
                                key={index?.toString()}
                              >
                                {attribute?.type === "password" && (
                                  <PasswordInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    compareValidationData={
                                      attribute?.validation
                                        ?.compare_attribute &&
                                        localStorage.getItem("creadValue")
                                        ? atob(
                                          String(
                                            localStorage.getItem("creadValue")
                                          )
                                        )
                                        : null
                                    }
                                    autoFocus={!index}
                                  />
                                )}
                              </div>
                            )
                          )}
                        {currentStep?.template === "static_component" &&
                          currentStep?.attributes?.map(
                            (attribute: AttributeType, index: number) => (
                              <div
                                className="col-sm-12 custom-mb-30"
                                key={index?.toString()}
                              >
                                {attribute?.type === "otp" && (
                                  <OTPInput
                                    attribute={attribute}
                                    index={index}
                                    submitData={(iContinue, key, value) => {
                                      setIsContinue(iContinue);
                                      submitData(iContinue, key, value);
                                    }}
                                    oldSelection={
                                      previousData[attribute?.key]
                                        ? previousData[attribute?.key]
                                        : ""
                                    }
                                    resentOTP={resentOTP}
                                    autoFocus={!index}
                                  />
                                )}
                              </div>
                            )
                          )}
                      </div>
                    </PerfectScrollbar>
                  )}
                </div>
                <div className="row custom-mb-54 justify-content-center align-items-end">
                  <div className="w-100 text-center">
                    <div className="col">
                      <button
                        className="col-12 custom-btn btn-standard-background button-small"
                        disabled={!isContinue}
                        onClick={handleContinue}
                      >
                        <p className="btn-text background-color">
                          {currentStep?.primary_button_text ??
                            translated("cta.next")}
                        </p>
                      </button>
                    </div>
                    <div className="w-100"></div>
                    {currentStep?.skip_to !== undefined && (
                      <div className="col">
                        <button
                          className="col-12 custom-btn btn-empty-background custom-mt-20"
                          onClick={() => {
                            handleSkip();
                          }}
                        >
                          <p className="btn-text grey-color tiny-txt">
                            {currentStep?.skip_button_text ??
                              translated("cta.skip")}
                          </p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default Onboarding;
