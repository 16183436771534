/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { casualLife } from "@/config/svg";
import React from "react";

const EmailNotVerified: React.FC = () => {
  return (
    <section className="email-not-verified-section">
      <div className="two-columns-layout">
        <div className="half-col left-col">
          <p className="text-18 bold color-blue custom-mb-20">We are sorry</p>
          <h2 className="text-36 custom-mb-25">
            An issue occurred, we could not verify your email.
          </h2>
          <p className="text-18 custom-mb-25">Please, try again.</p>
        </div>
        <div className="half-col right-col align-block-center">
          <img src={casualLife} alt="Welcome member" />
        </div>
      </div>
    </section>
  );
};

export default EmailNotVerified;
