/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { DateMonthInput } from "@/components/index";
import {
  stripeSaveCardDelete,
  stripeSaveCardList,
  stripeSaveCardUpdate,
} from "@/services/api/fireFunction";
import { back, edit, trash } from "@/config/svg";
import visaCardImage from "../../assets/img/visa.png";
import masterCardImage from "../../assets/img/master.png";
import jcbImage from "../../assets/img/jcb.png";
import unionPayImage from "../../assets/img/unionpay.png";
import americanPayImage from "../../assets/img/american.png";
import discoverCardImage from "../../assets/img/discover.png";
import { PaymentCardItemShimmer } from "@/components/shimmer";

const PaymentCardList: React.FC = () => {
  const {
    userInfo,
    showToastMessage,
    setRootLoader,
    unSetRootLoader,
    translated,
  } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [imageLoader, setImageLoader] = useState(true);
  const [cardList, setCardList] = useState<any>([]);
  const [editCard, setEditCard] = useState<any>(null);
  const [saveCheck, setSaveCheck] = useState(false);

  const [isCardUpdate, setIsCardUpdate] = useState<boolean>(false);
  const [updateCardData, setUpdateCardData] = useState<any>(null);
  useEffect(() => {
    if (userInfo?.user_data) {
      fetchCardList().then(() => {
        setTimeout(() => {
          setLoader(false);
          setImageLoader(false);
        }, 1000);
        //  console.log('card list found!');
      });
    }
    document.body.className = "user-area";

    return () => {
      document.body.className = "";
    };
  }, []);

  const fetchCardList = async () => {
    setLoader(true);
    stripeSaveCardList(userInfo?.authUId as string)
      .then((data: any) => {
        if (data?.data?.total_cards > 0) {
          setCardList(data?.data?.data);
        } else {
          setCardList([]);
          setImageLoader(false);
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        setImageLoader(false);
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        console.log({ err });
      });
  };
  const handleUpdateCard = () => {
    setRootLoader();
    stripeSaveCardUpdate(updateCardData, editCard?.id as string)
      .then((data: any) => {
        fetchCardList();
        setUpdateCardData(null);
        unSetRootLoader();
        setEditCard(null);
        setIsCardUpdate(false);
        showToastMessage("card updated successfully!");
      })
      .catch((err: any) => {
        unSetRootLoader();
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        // showToastMessage(err, true);
      });
  };

  const handleDelete = (card: any) => {
    setLoader(true);
    setRootLoader();
    stripeSaveCardDelete(card?.id as string)
      .then((data: any) => {
        fetchCardList();
        unSetRootLoader();
        showToastMessage("Successfully deleted card!");
      })
      .catch((err: any) => {
        unSetRootLoader();
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        // showToastMessage(err, true);
      });
  };

  const CardImage = ({
    src,
    height,
    brand,
  }: {
    src: string;
    height: string;
    brand: string;
  }) => {
    return (
      <img
        src={src}
        height={height}
        alt={brand}
        onLoad={() => {
          setImageLoader(false);
        }}
      />
    );
  };

  return loader || imageLoader ? (
    <PaymentCardItemShimmer />
  ) : (
    <div className="payment-info-wrapper">
      {editCard !== null && (
        <>
          <button
            className="go-back-button"
            onClick={() => {
              setEditCard(null);
            }}
          >
            <img
              className="forward-icon cursor-pointer"
              src={back}
              alt="Next"
            />
          </button>
        </>
      )}
      <h3 className="text-18 bold">{translated("app_copy.your_cards")}</h3>
      {cardList.length > 0 && (
        <div className="card-main-container">
          {editCard === null ? (
            cardList.map((card_list: any, index: number) => (
              <div key={index.toString()} className="card-wrapper active">
                <div className="card-icon-number-wrapper">
                  <>
                    {card_list?.card?.brand === "visa" && (
                      <CardImage
                        src={visaCardImage}
                        height="20"
                        brand={card_list?.card?.brand}
                      />
                    )}
                    {card_list?.card?.brand === "jcb" && (
                      <CardImage
                        src={jcbImage}
                        height="35"
                        brand={card_list?.card?.brand}
                      />
                    )}
                    {card_list?.card?.brand === "master" && (
                      <CardImage
                        src={masterCardImage}
                        height="30"
                        brand={card_list?.card?.brand}
                      />
                    )}
                    {card_list?.card?.brand === "american" && (
                      <CardImage
                        src={americanPayImage}
                        height="30"
                        brand={card_list?.card?.brand}
                      />
                    )}
                    {card_list?.card?.brand === "unionpay" && (
                      <CardImage
                        src={unionPayImage}
                        height="30"
                        brand={card_list?.card?.brand}
                      />
                    )}
                    {card_list?.card?.brand === "discover" && (
                      <CardImage
                        src={discoverCardImage}
                        height="20"
                        brand={card_list?.card?.brand}
                      />
                    )}
                  </>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      readOnly
                      autoComplete="off"
                      id="firstName"
                      name="firstName"
                      value={"**** **** ****" + card_list?.card?.last4}
                    />
                    <label htmlFor="firstName">Card number </label>
                  </div>
                </div>

                <div className="card-managing-wrapper">
                  <div className="d-flex align-items-center">
                    <span className="text-16 bold custom-mr-40">
                      {translated("app_copy.default_method")}
                    </span>
                    <div className="input-wrapper toggle-switch">
                      <input
                        type="checkbox"
                        id={"toggle-switch-" + card_list?.card?.last4}
                        checked={saveCheck}
                        onChange={() => setSaveCheck(!saveCheck)}
                      />
                      <label
                        htmlFor={"toggle-switch-" + card_list?.card?.last4}
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      className="extra-small-btn custom-mr-15"
                      onClick={() => setEditCard(card_list)}
                    >
                      <img src={edit} width="18" height="18" alt="Next" />
                    </button>
                    <button
                      className="extra-small-btn"
                      onClick={() => handleDelete(card_list)}
                    >
                      <img src={trash} width="18" height="18" alt="Next" />
                    </button>
                  </div>
                </div>
                <div className="sub-separator"></div>
              </div>
            ))
          ) : (
            <div className="custom-mt-30">
              <div className="input-wrapper">
                <input
                  type="text"
                  readOnly
                  autoComplete="off"
                  id="firstName"
                  name="firstName"
                  value={"**** **** ****" + editCard?.card?.last4}
                />
                <label htmlFor="firstName">Card number </label>
              </div>

              <DateMonthInput
                oldSelection={
                  editCard?.card?.exp_year + "-" + editCard?.card?.exp_month
                }
                placeholderText="YYYY-MM"
                label="Exp. date"
                submitData={(value, isValid) => {
                  var date = value.split("-");
                  var newDate = {
                    exp_month: date[1],
                    exp_year: date[0],
                  };
                  setIsCardUpdate(isValid);
                  setUpdateCardData(newDate);
                }}
              />

              <button
                className="standard-btn"
                disabled={!isCardUpdate}
                onClick={() => handleUpdateCard()}
              >
                <p className="text-16 bold color-blue">
                  {translated("cta.update")}
                </p>
              </button>
            </div>
          )}
        </div>
      )}
      {
        cardList.length === 0 &&
        <p className="text-18">No card available</p>
      }
    </div>
  );
};

export default PaymentCardList;
