/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Spiner } from "@/components/index";
// import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import {
  commonSentOTP,
  commonVerifyOTP,
  createStripeCustomer,
} from "@/services/api/fireFunction";
import routes from "@/config/routes";
import {
  getFireStoreDoc,
  setUserData,
  updateUserData,
} from "@/services/firebase/fireStore";
import { AppContext } from "@/context/createContext";
import {
  linkWithCredential,
  EmailAuthProvider,
  //sendEmailVerification,
  getAuth,
} from "firebase/auth";
const VerifyOTP: React.FC<{ signupData: any }> = ({ signupData }) => {
  const {
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated
  } = useContext(AppContext);
  const auth = getAuth();
  let navigate = useNavigate();
  const [canSubmit, setCanSubmit] = useState(true);
  const [otp, setOtp] = useState("");
  const [previousData, setPreviousData] = useState<any>({});

  const handleAction = () => {
    if (otp.length === 0) {
      setCanSubmit(false);
      showToastMessage("OTP not empty!", true);
    } else if (canSubmit) {
      setRootLoader();
      commonVerifyOTP({
        email: signupData.email,
        phone: signupData.isSMSSent ? signupData.phone : "",
        otp: otp,
      })
        .then((res: any) => {
          if (res?.data?.success) {
            showToastMessage("OTP verified successfully!");
            const credential = EmailAuthProvider.credential(
              signupData.email,
              signupData.password
            );
            if (auth?.currentUser) {
              linkWithCredential(auth?.currentUser, credential)
                .then((userCredential: any) => {
                  if (userInfo.authUId) {
                    const userInfoUpdate = {
                      email: signupData.email,
                      first_name: signupData.firstName,
                      last_name: signupData.lastName,
                      gender: signupData.gender,
                      dob: signupData.dob,
                      phone: signupData.phone,
                    };
                    if (Object.keys(previousData).length > 0) {
                      updateUserData(userInfo.authUId, {
                        user_data: { ...userInfoUpdate },
                      });
                      showToastMessage("Signup completed successfully!");
                      unSetRootLoader();
                      navigate(routes.login);
                      setTimeout(() => {
                        createStripeCustomer({
                          user_id: userInfo?.authUId,
                        });
                      }, 1000);
                    } else {
                      setUserData(userInfo.authUId, {
                        user_data: { ...userInfoUpdate },
                      });

                      showToastMessage("Signup completed successfully!");
                      unSetRootLoader();
                      navigate(routes.login);
                      setTimeout(() => {
                        createStripeCustomer({
                          user_id: userInfo?.authUId,
                        });
                      }, 1000);
                    }
                  }
                })
                .catch((error) => {
                  unSetRootLoader();
                  //console.log("error", error);
                  if (error.code === "auth/weak-password") {
                    showToastMessage(
                      "Password should be at least 6 characters",
                      true
                    );
                  }
                  if (error.code === "auth/wrong-password") {
                    showToastMessage("Please check the Password.", true);
                  }
                  if (error.code === "auth/user-not-found") {
                    showToastMessage("User not exist! Please signup", true);
                  }
                  if (error.code === "auth/email-already-in-use") {
                    showToastMessage("Email already in use", true);
                  }
                  if (error.code === "auth/provider-already-linked") {
                    showToastMessage(
                      "Same email provider already in use",
                      true
                    );
                  }
                  navigate(routes.signup);
                });
            }
          }
          if (res?.data?.error) {
            unSetRootLoader();
            showToastMessage(res?.data?.error?.message, true);
          }
        })
        .catch((error) => {
          unSetRootLoader();
          showToastMessage(error, true);
        });
    }
  };
  const resentOTP = () => {
    setRootLoader();

    commonSentOTP({
      email: signupData.email,
      phone: signupData.isSMSSent ? signupData.phone : "",
    })
      .then((res: any) => {
        unSetRootLoader();
        //console.log(res?.response.data.error.message);
        if (res?.data?.success) {
          showToastMessage("OTP Resent successfully!");
        }
        if (res?.response?.data?.error) {
          showToastMessage(res?.response?.data?.error?.message, true);
          navigate(routes.login);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  useEffect(() => {
    if (userInfo?.authUId) {
      getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
        //console.log(keys);

        if (keys && Object.keys(keys).length > 0) {
          setPreviousData(keys);
        }
      });
    }
  }, [userInfo]);
  return (
    <>
      {mainLoader || remainingLoaderTime ? (
        <Spiner
          className="signup-loder"
          height="100px"
          width="100px"
          margin="auto"
        />
      ) : (
        <div id="login-box" className="col-md-12">
          <h3 className="text-info text-center">Verify your OTP</h3>
          <div className="form-group">
            <p className="text-center">
              Check your email/phone and enter the verification code we sent
              you.
            </p>
            <div id="otp-verrify">
              <input
                type="text"
                autoComplete="off"
                autoFocus={true}
                value={otp}
                maxLength={6}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Verification Code"
                className="form-control"
              />
            </div>
            {/* <div id="otp-verrify">
              <OtpInput
                value={otp}
                shouldAutoFocus={true}
                isInputNum={true}
                onChange={(otp: any) => setOtp(otp)}
                numInputs={6}
                containerStyle={{
                  justifyContent: "center",
                }}
                inputStyle={{
                  textAlign: "center",
                  width: "28px",
                  padding: "5px",
                  margin: "5px",
                }}
                separator={<span>-</span>}
              />
            </div> */}
          </div>

          <div className="form-group">
            <label className="d-flex justify-content-center small-text-lightgray">
              <span onClick={resentOTP} className="cursor-pointer text-underline small-text-lightgray">{translated('cta.havent_recieved_the_code')}</span>
            </label>
            <div className="row custom-mb-54 custom-mt-85 justify-content-center align-items-end">
              <button
                id="login-button"
                disabled={!(otp.length === 6)}
                className=" custom-btn btn-standard-gradient"
                onClick={handleAction}
              >
                <p className="btn-text bold small-text rainbow_purple-blue">Submit</p>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyOTP;
