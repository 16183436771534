/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { RangAttribute, RangesProp } from "@/interface/index";
import "./range.scss";
import { biomarkerBG } from "@/config/svg";

const Rangemeter: React.FC<{
  rangValues: RangAttribute;
  unit: string;
  title: string;
}> = ({ rangValues, unit = "", title = "" }) => {
  const [inputValue, setInputValue] = useState<Number>(0);
  const [rangesValue, setRangesValue] = useState([]);
  const [rangesAnimation, setRangesAnimation] = useState(false);
  const [rangesChangeValue, setRangesChangeValue] = useState(0);

  const animationStarted = useRef(false);
  const rangeValueMax = useRef(180);
  const rangeValueMin = useRef(0);
  useEffect(() => {
    // console.log({rangValues});
    setInputValue(Number(rangValues.value));
    if (rangValues.ranges.length > 0) {
      if (rangValues.max !== undefined) rangeValueMax.current = rangValues.max;
      if (rangValues.min !== undefined) rangeValueMin.current = rangValues.min;
    }
    let percentStart = 0;
    let percentEnd = 0;
    let startValue = 0;
    let endValue = 0;

    const ranges: any = rangValues.ranges.map(
      (value: RangesProp, index: number): RangesProp => {
        const percent = (value.value / rangeValueMax.current) * 100;
        percentStart = percentEnd;
        percentEnd += percent;
        startValue = endValue;
        endValue = endValue + value.value;
        return {
          ...value,
          percent,
          percentStart,
          percentEnd,
          startValue,
          endValue,
        };
      }
    );
    setRangesValue(ranges);
    setRangesAnimation(true);
    // console.log({ ranges });
  }, [rangValues]);

  useEffect(() => {
    // console.log(animationStarted.current);

    if (
      rangesAnimation &&
      animationStarted.current === false &&
      inputValue > 0 &&
      rangValues.pointer !== undefined &&
      rangValues.pointer
    ) {
      animationStarted.current = true;
      animation();
    }

    // console.log({ ranges });
  }, [rangesAnimation]);

  const animation = () => {
    const speed = rangValues?.speed ?? 1;
    // console.log({rangesChangeValue,inputValue});

    if (
      rangesChangeValue < inputValue &&
      rangesChangeValue < rangeValueMax.current &&
      rangValues.pointer !== undefined &&
      rangValues.pointer &&
      animationStarted.current
    ) {
      setTimeout(() => {
        animation();
      }, 5 * speed);
      setRangesChangeValue((pre) => {
        if (pre < inputValue && pre < rangeValueMax.current) return pre + 1;
        animationStarted.current = false;
        return pre;
      });
    }
  };

  const CenterColor = () => {
    // console.log({ rangesValue });

    //if(inputValue > 0)

    const percent = (rangesChangeValue / rangeValueMax.current) * 100;
    let backgroundColor = rangValues.ranges[0]?.color;
    const celectedRange: RangesProp[] = rangesValue.filter(
      (value: any) =>
        value?.percentStart <= percent && value?.percentEnd >= percent
    );
    const rangeDeg = -92.5 + (percent / 100) * 180;
    if (celectedRange.length > 0) {
      backgroundColor = celectedRange[0]?.color;
    } else {
      backgroundColor = rangValues.ranges[rangValues.ranges.length - 1]?.color;
    }

    // console.log("rangeDeg", celectedRange, backgroundColor);
    return (
      <div
        className={
          "color-main-container" + (rangValues.dotRange ? " dotted" : "")
        }
        style={{ transform: `rotate(${rangeDeg}deg)` }}
      >
        {/* <div className="color-center-container" style={{ left: `${percent}%` }}> */}
        <div className="color-center-container" style={{ left: `50%` }}>
          <div className="thumb-center-color" style={{ backgroundColor }}></div>
        </div>
      </div>
    );
  };

  const RangeValue = ({
    degrees,
    radius,
    offset = { x: 10, y: 10 },
    value,
  }: {
    degrees: number;
    radius: number;
    offset?: { x: number; y: number };
    value: any;
  }) => {
    const rangeDeg = -180 + degrees;

    // const {x,y} = getCircle(radians,radius);
    const { x, y } = getCircle(rangeDeg, radius);
    // console.log({degrees:rangeDeg, radius},{x,y});

    const css = {
      left:
        x + radius + offset.x < radius
          ? `${x + radius - offset.x}px`
          : `${x + radius + offset.x}px`,
      top: `${y + radius - offset.y}px`,
    };

    return (
      <div className="ranges" style={css}>
        {value}
      </div>
    );
  };
  const RangeValues = () => {
    return (
      <>
        {rangeValueMax.current > 0 &&
          rangesValue.map((value: RangesProp, index: number) => {
            if (rangesValue.length <= index + 1) return;
            const percent =
              value.percentEnd !== undefined ? value.percentEnd : 0;
            const rangeDeg = (percent / 100) * 180;

            if (rangesValue.length > index + 1)
              return (
                <RangeValue
                  key={`range-value-${index}`}
                  degrees={rangeDeg}
                  radius={150}
                  offset={{ x: 30, y: 0 }}
                  value={value.endLabel ?? value.endValue}
                />
              );
          })}
      </>
    );
  };

  const getCircle = (degrees: number, radius: number) => {
    const radians = degrees * (Math.PI / 180);
    return { x: Math.cos(radians) * radius, y: Math.sin(radians) * radius };
  };

  return (
    <>
      <div className="range-input-container custom-pt-20 custom-pb-20">
        <div className="main-meter-container">
          <div className="range-meter-container">
            <div className="center-text-container">
              <span className="center-text text-36">
                {rangValues.valueLabel ??
                  (rangesChangeValue === rangeValueMax.current &&
                  rangValues.value > rangeValueMax.current
                    ? rangValues.value
                    : rangesChangeValue)}
                {unit && !rangValues.valueLabel && <span>{unit}</span>}
              </span>
            </div>

            <div className="meter-container">
              {rangeValueMax.current > 0 &&
                !rangValues.dotRange &&
                rangesValue.map((value: RangesProp, index: number) => {
                  const percent =
                    value.percentStart !== undefined ? value.percentStart : 0;
                  const rangeDeg = -135 + (percent / 100) * 180;
                  const css = {
                    borderColor: value.color,
                    transform: `rotate(${rangeDeg}deg)`,
                  };

                  return (
                    <div
                      key={index}
                      className="meter-container-color"
                      data-percent={value.percentStart}
                      style={css}
                    ></div>
                  );
                })}
              {rangValues.dotRange && (
                <div>
                  <img
                    src={biomarkerBG}
                    width="270"
                    height="135"
                    alt="up arrow"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="range-values">
            <RangeValues />
          </div>
          <div className="range-meter-texts">
            <span className="start-value">{rangValues?.minLabel ?? ""}</span>
            <span className="max-value">{rangValues?.maxLabel ?? ""}</span>
          </div>
          {rangValues.valueLabel2 !== undefined && (
            <h2 className="label-2">{rangValues.valueLabel2}</h2>
          )}
          {rangValues.pointer !== undefined &&
            rangValues.pointer &&
            rangValues?.maxLabel !== "" && (
              <div className="color-center-container-h">
                <CenterColor />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Rangemeter;
