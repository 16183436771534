/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import Modal from "../common/Modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppContext } from "@/context/createContext";

const SwitchSelection: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, oldSelection, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const [ModalShow, setModalShow] = useState(false);
  const { translated } =
    useContext(AppContext);

  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }

    setModalShow(false);
  };
  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);

  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);

  return (
    <div className="form-group">
      <div className=" d-flex justify-content-between consent-cnt ">
        <p
          role="button"
          className="pe-auto small-text-lightgray custom-mb-0 text-decoration-underline"
          onClick={() => {
            setModalShow(true);
            // inputValue === attribute.key
            //   ? updateInputValue(attribute.key)
            //   : updateInputValue(attribute.key);
          }}
        >
          {attribute.display_text}
        </p>
        <label className="switch">
          {/* <input
            type="checkbox"
            autoComplete="off"
            id={attribute.key}
            checked={inputValue === attribute.key}
            onChange={
              inputValue === attribute.key
                ? () => setInputValue("")
                : () => updateInputValue(attribute.key)
            }
            className=""
          />
          <span className="slider round">
          </span> */}
        </label>
      </div>
      <Modal
        size={"lg"}
        modalTitle={attribute.display_text}
        show={ModalShow}
        handleClose={() => setModalShow(false)}
      >
        <div className="consent-modal">
          <PerfectScrollbar>
            <div className="consent-data w-75 mx-auto">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem
                nemi .
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
            </div>
          </PerfectScrollbar>
        </div>
        <div className="width-180 custom-mt-20">
          <button
            className="custom-btn btn-standard-background button-small"
            onClick={() => updateInputValue(attribute.key)}
          >
            <p className="btn-text background-color">{translated('cta.accept')}</p>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SwitchSelection;
