import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./input.scss";

const ShimmerInput = ({ size }) => (
  <div
    className={classNames({
      "shimmer shimmer-input": true,
      [`shimmer-input--${size}`]: true,
    })}
  />
);

ShimmerInput.propTypes = {
  size: PropTypes.oneOf(["lg", "md", "sm"]),
};

ShimmerInput.defaultProps = {
  size: "md",
};

export default ShimmerInput;
