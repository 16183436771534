/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaceBad,
  FaceGood,
  plusSvg,
  minusSvg,
  ArrowRight,
} from "@/config/svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ResultItemShimmer } from "../shimmer";
const TestResult: React.FC<{ resultData: any }> = ({ resultData }) => {
  const [showingItem, setShowingItem] = useState<any>([]);
  let navigate = useNavigate();


  useEffect(() => {
    const selectedItem = JSON.parse(localStorage.getItem('testItemSelection' + resultData?.data?.id) ?? "[]")
    if (selectedItem.length) {
      setShowingItem(selectedItem)
    }
  }, [resultData])

  return resultData === null ? (
    <ResultItemShimmer />
  ) : (
    resultData &&
    resultData?.data &&
    resultData?.data?.fulfillment_orders.length > 0 && (
      <ul>
        <PerfectScrollbar>
          {resultData?.data?.fulfillment_orders[0].lab_test_results.length >
            0 &&
            resultData?.data?.fulfillment_orders[0]?.lab_test_results.map(
              (resultItem: any, index: number) => (
                <li
                  className={`${showingItem.includes(resultItem?.id)
                    ? "bio-list-item-wrapper item-openend"
                    : "bio-list-item-wrapper"
                    }`}
                  key={index.toString()}
                >
                  <div className="bio-list-item flex-center-between">
                    <p className="text-14 text-uppercase bold color-black">
                      {resultItem?.name}
                    </p>
                    <button
                      className="small-btn"
                      onClick={() => {
                        if (showingItem.includes(resultItem?.id)) {
                          setShowingItem([]);
                          localStorage.removeItem('testItemSelection' + resultData?.data?.id)
                        } else {
                          localStorage.setItem('testItemSelection' + resultData?.data?.id, JSON.stringify([resultItem?.id]))
                          setShowingItem([resultItem?.id]);
                        }
                      }}
                    >
                      <img
                        className="cursor-pointer"
                        src={
                          showingItem.includes(resultItem?.id)
                            ? minusSvg
                            : plusSvg
                        }
                        alt="Expand Arrow"
                      />
                    </button>
                  </div>

                  {showingItem.includes(resultItem?.id) &&
                    resultItem?.lab_test_result_items?.length && (
                      <ul className="report-list-wrapper">
                        {resultItem?.lab_test_result_items.map(
                          (labItem: any, index: number) => (
                            <div
                              key={index.toString()}>
                              <li
                                className="report-item"
                                onClick={() =>
                                  navigate(
                                    "/result-biomarker-details/" +
                                    btoa(resultData?.data?.id) +
                                    "/" +
                                    btoa(labItem?.id) +
                                    "/" +
                                    btoa(resultItem?.id)
                                  )
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    className="custom-mr-15"
                                    width="28"
                                    height="28"
                                    src={
                                      labItem?.abnormal_flag
                                        ? FaceBad
                                        : FaceGood
                                    }
                                    alt="Mood"
                                  />
                                  <p
                                    // onClick={() =>
                                    //   navigate(
                                    //     "/result-biomarker-details/" +
                                    //     String(params?.order_id) +
                                    //     "/" +
                                    //     btoa(labItem?.id) +
                                    //     "/" +
                                    //     btoa(resultItem?.id)
                                    //   )
                                    // }
                                    className="text-12 cursor-pointer"
                                  >
                                    {labItem?.name}
                                  </p>
                                </div>
                                <img
                                  // onClick={() =>
                                  //   navigate(
                                  //     "/result-biomarker-details/" +
                                  //     btoa(resultData?.data?.id) +
                                  //     "/" +
                                  //     btoa(labItem?.id) +
                                  //     "/" +
                                  //     btoa(resultItem?.id)
                                  //   )
                                  // }
                                  className="forward-icon cursor-pointer "
                                  src={ArrowRight}
                                  width="20"
                                  height="20"
                                  alt="Right Arrow"
                                />
                              </li>
                              <div className="sub-separator"></div>
                            </div>
                          )
                        )}
                      </ul>
                    )}
                </li>
              )
            )}
        </PerfectScrollbar>
      </ul>
    )
  );
};

export default TestResult;
