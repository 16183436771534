import React from "react";

const WildHealth: React.FC = () => {
  return (
    <iframe
      src="https://results.wildhealth.com/"
      style={{ width: "100%", height: "100vh" }}
      loading="lazy"
      title="Custom title"
    ></iframe>
  );
};

export default WildHealth;
