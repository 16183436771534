/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  base64Encode,
  findItemFromArray,
  // findItemFromArray,
  // getOrderAllStatus,
  // parseFloat2digit,
  // totalOfArrayObject,
} from "@/helper/common";
// import { BioMarker } from "@/components/index";
// import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import routes from "@/config/routes";
import { back, backArrow, star } from "@/config/svg";
import { AppContext } from "@/context/createContext";
import {
  getLabCorpEndPoint,
  updateOrderData,
} from "@/services/api/fireFunction";
import PerfectScrollbar from "react-perfect-scrollbar";
import Pages from "@/layouts/Pages";
import { OrderItem, TestResult, ChatBot, DialogflowCX } from "@/components/index";
import { isAndroid, isMobile } from "react-device-detect";
const OrderDetails: React.FC = () => {
  let navigate = useNavigate();
  const {
    modules,
    getResultData,
    userInfo,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const [order, setOrder] = useState<any | null>(null);
  const [isLab, setIsLab] = useState<boolean>(false);
  const [pdf, setPdf] = useState<string | null>(null);
  const [resultData, setResultData] = useState<any>(null);
  let isContinueData = new Map()

  const params = useParams();
  const handleSchedule = () => {
    const orderData = {
      order_id: String(order?.order_id),
    };
    navigate(
      routes.schedulingDeeplink +
      "?metadata=" +
      base64Encode(JSON.stringify(orderData))
    );
  };

  useEffect(() => {
    if (
      userInfo?.orders &&
      userInfo?.orders.length > 0 &&
      params?.order_id
    ) {
      setOrder(
        findItemFromArray(
          userInfo?.orders,
          "order_id",
          atob(String(params?.order_id))
        )
      );
    }
  }, [userInfo, params]);

  useEffect(() => {
    if (
      order &&
      order?.status === "results_available" &&
      order?.is_order_seen !== true
    ) {
      updateOrderData(order?.order_id, {
        is_order_seen: true,
      });
    }
    const isCallingApi = isContinueData.get(order?.order_id)

    if (order && !isCallingApi) {
      isContinueData.set(order?.order_id, 'calling...')

      getResultData(order?.order_id)
        .then(async (responseItem: any) => {
          // console.log('data', data)
          isContinueData.set(order?.order_id, null)
          // const data = responseItem?.result
          setResultData(responseItem?.result);
        });
    }
  }, [order]);

  const handleNoPleaseAction = () => {
    setRootLoader();
    getLabCorpEndPoint()
      .then((labCorpEndPoint: any) => {
        unSetRootLoader();
        if (labCorpEndPoint?.data?.url) {
          window.open(labCorpEndPoint?.data?.url, "_blank", "noreferrer");
        } else {
          showToastMessage("URL not found!", true);
        }
      })
      .catch(function (err) {
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        else showToastMessage("Something went wrong!", true);

        unSetRootLoader();
      });
  };

  return (
    <>
      {
        pdf ?
          <section className="order-pdf-section">
            <img
              className="cursor-pointer float-start pr-5 cart"
              src={backArrow}
              width="70"
              height="70"
              alt="back"
              onClick={() => setPdf(null)}
            />
            <object
              className="pdf-viewer-object"
              data={"data:application/pdf; base64," + pdf}
              type="application/pdf"
            >
              <div>No online PDF viewer installed</div>
            </object>
          </section>
          :
          <Pages>
            <section className="orders-area-section background-light">
              {order && (
                <PerfectScrollbar className="full-page-scrollbar">
                  <div className="two-columns-layout">
                    {modules?.is_login_flow === true &&
                      <div className="half-col left-col">
                        {userInfo?.orders.length > 1 && (
                          <button
                            className="go-back-button"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            <img
                              className="forward-icon cursor-pointer"
                              src={back}
                              alt="Next"
                            />
                          </button>
                        )}

                        {isLab && (
                          <button
                            className="go-back-button"
                            onClick={() => {
                              setIsLab(false);
                            }}
                          >
                            <img
                              className="forward-icon cursor-pointer"
                              src={back}
                              alt="Next"
                            />
                          </button>
                        )}

                        <div className="orders-wrapper">
                          {isLab && (
                            <>
                              <div className="custom-mt-40 custom-mb-50">
                                <p className="text-18 bold color-blue">
                                  {translated("app_copy.label_at_lab_option_selection")}
                                </p>
                                <h2 className="text-25 custom-mt-15">
                                  {translated("app_copy.confirm_your_order")}
                                </h2>
                              </div>
                            </>
                          )}
                          <OrderItem
                            order={order}
                            showDetails={() => {
                              navigate(
                                "/order-details/" + base64Encode(order?.order_id)
                              );
                            }}
                          />
                          {order?.status === "pending" && isLab === false && (
                            <>
                              <div className="order-instructions-wrapper custom-mt-50">
                                <p className="text-18 bold color-blue">Your test</p>
                                <h2 className="text-25 custom-mt-15">
                                  How to get tested.
                                </h2>
                                <p className="text-16 custom-mt-15">
                                  Choose how you would like to get tested.
                                </p>
                              </div>
                              <div className="test-mode-choice-wrapper custom-mt-50">
                                <button
                                  className="standard-btn custom-mr-15"
                                  onClick={() => handleSchedule()}
                                >
                                  <p className="text-18 bold color-blue">
                                    {translated(
                                      "app_copy.label_at_home_option_selection"
                                    )}
                                  </p>
                                </button>

                                <button
                                  className="standard-btn"
                                  // disabled
                                  onClick={() => setIsLab(true)}
                                >
                                  <p className="text-18 bold color-blue">
                                    {translated(
                                      "app_copy.label_at_lab_option_selection"
                                    )}
                                  </p>
                                </button>
                              </div>
                            </>
                          )}

                          {(order?.status === "ph_visit_success" ||
                            order?.status === "ph_visit_started" ||
                            order?.status === "in_progress" ||
                            order?.status === "lab_order_create_success" ||
                            order?.status === "ph_visit_scheduled_success") && (
                              <>
                                <div className="order-instructions-wrapper custom-mt-80 custom-pb-50">
                                  <p className="text-18 bold color-blue">
                                    {translated(
                                      "app_copy.order_details_instruction_header"
                                    )}
                                  </p>
                                  <h2 className="text-25 custom-mt-15">
                                    {translated(
                                      "app_copy.order_details_instruction_title"
                                    )}
                                  </h2>
                                  <p className="text-16 custom-mt-15">
                                    {translated("app_copy.get_prepared_for_your_test")}
                                  </p>
                                  <div className="test-mode-choice-wrapper custom-mt-50">
                                    <button
                                      className="standard-btn custom-mr-15"
                                      onClick={() => {
                                        if (
                                          order?.fulfillment_orders &&
                                          order?.fulfillment_orders[0]?.order_request_pdf
                                        ) {

                                          if (isMobile && isAndroid) {
                                            var link = document.createElement('a');
                                            link.href = "data:application/pdf; base64," + order?.fulfillment_orders[0]
                                              ?.order_request_pdf;
                                            link.download = 'OrderPDF.pdf';
                                            link.dispatchEvent(new MouseEvent('click'));
                                          } else {

                                            navigate(
                                              "/order-PDF/" +
                                              btoa(
                                                order?.fulfillment_orders[0]
                                                  ?.order_request_pdf
                                              )
                                            );
                                          }
                                        } else {
                                          showToastMessage("Order PDF not found!", true);
                                        }
                                      }}
                                    >
                                      <p className="text-18 bold color-blue">
                                        {translated("cta.order_detail_instruction_cta")}
                                      </p>
                                    </button>
                                    <button
                                      onClick={() => {
                                        navigate(
                                          "/how-to-get-prepared/" +
                                          base64Encode(order?.order_id)
                                        );
                                      }}
                                      className="standard-btn"
                                    >
                                      <span className="text-18 color-blue bold">
                                        How to get prepared
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                          {(order?.status === "lab_results_available" ||
                            order?.status === "results_available") &&
                            order?.is_order_seen === true &&
                            (order?.csat === null || order?.csat === undefined) && (
                              <div className="custom-mt-50">
                                <p className="text-18 bold color-blue custom-mb-20">
                                  {translated("app_copy.order_details_feedback_header")}
                                </p>
                                <p className="text-16 custom-mt-20">
                                  {translated("app_copy.order_details_feedback_title")}
                                </p>
                                <div
                                  className="d-flex custom-mt-20"
                                  onClick={() => {
                                    localStorage.removeItem(
                                      String(userInfo?.authUId) +
                                      base64Encode(order?.order_id)
                                    );
                                    navigate(
                                      "/feedback-step/" + base64Encode(order?.order_id)
                                    );
                                  }}
                                >
                                  {[...Array(5)].map(() => (
                                    <button className="small-btn sunken custom-mr-15">
                                      <img
                                        className="forward-icon star"
                                        src={star}
                                        width="28"
                                        height="28"
                                        alt="star"
                                      />
                                    </button>
                                  ))}
                                </div>
                                <p
                                  onClick={() => {
                                    localStorage.removeItem(
                                      String(userInfo?.authUId) +
                                      base64Encode(order?.order_id)
                                    );

                                    navigate(
                                      "/feedback-step/" + base64Encode(order?.order_id)
                                    );
                                  }}
                                  className="text-18 bold color-blue text-underline custom-mt-20"
                                >
                                  Share feedback
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    }
                    {/* <div className="sub-separator-vertical"></div> */}
                    <div className={modules?.is_login_flow === true ? "half-col right-col" : "half-col"}>
                      {isLab ? (
                        <div className="lab-order">
                          <h2 className="text-25 custom-mb-30">
                            {translated("app_copy.order_with_id").replace(
                              "{#ORDERID#}",
                              order?.order_number
                            )}
                          </h2>

                          <p className="text-16 bold">
                            {translated("app_copy.you_come_to_us")}
                          </p>

                          <p className="text-16">
                            {translated(
                              "app_copy.you_will_redirected_to_labcorp_site"
                            )}
                          </p>

                          <div className="text-center width-236 custom-mt-50">
                            <button
                              className="standard-btn"
                              onClick={() => handleNoPleaseAction()}
                            >
                              <p className="text-18 bold color-blue">
                                {translated("cta.confirm")}
                              </p>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          {(order?.status === "lab_results_available" ||
                            order?.status === "results_available") &&
                            <div className="bio-list-wrapper">
                              <TestResult resultData={resultData} />
                              {resultData?.data?.fulfillment_orders[0]?.result_pdf &&
                                <div className="custom-mt-50 text-center floating-btn floating-btn-sticky">
                                  <button
                                    className="standard-btn"
                                    onClick={() => {
                                      if (resultData?.data?.fulfillment_orders[0]?.result_pdf) {
                                        if (isMobile && isAndroid) {
                                          var link = document.createElement('a');
                                          link.href = "data:application/pdf; base64," + resultData?.data?.fulfillment_orders[0]?.result_pdf;
                                          link.download = 'resultPDF.pdf';
                                          link.dispatchEvent(new MouseEvent('click'));
                                        } else {
                                          // navigate(
                                          //   "/order-PDF/" +
                                          //   btoa()
                                          // );
                                          setPdf(resultData?.data?.fulfillment_orders[0]?.result_pdf)
                                        }
                                      } else {
                                        showToastMessage("Result PDF not found!", true);
                                      }
                                    }}
                                  >
                                    <p className="text-18 bold">{translated('cta.view_results')}</p>
                                  </button>
                                </div>
                              }
                            </div>

                          }

                          {order?.status !== "lab_results_available" &&
                            order?.status !== "results_available" && (
                              <ul className="bio-list-wrapper">
                                <h3 className="text-18 bold text-center color-blue custom-mb-30">
                                  {translated("app_copy.title_pre_order_test_items")}
                                </h3>
                                {order?.test_items.map((item: any, index: number) => (
                                  <li key={index} className="bio-list-item">
                                    <p className="text-14 text-uppercase bold color-black">
                                      {item?.name}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </PerfectScrollbar>
              )}
              {modules?.is_login_flow === true &&
                (order?.status === "lab_results_available" ||
                  order?.status === "results_available") &&
                <div className="chat-wrapper">
                  <DialogflowCX />
                  <ChatBot />
                </div>
              }
            </section>
          </Pages>
      }
    </>

  );
};

export default OrderDetails;
