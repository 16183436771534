/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import moment from "moment";
import {
  viewPdf,
  OrderCompleted,
  orderInit,
  OrderPh,
  orderReadyTest,
  orderResultsAvailable,
} from "@/config/svg";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "@/context/createContext";
import { isAndroid, isMobile } from "react-device-detect";

const OrderItem: React.FC<{
  order: any;
  showDetails: () => void;
}> = ({ order, showDetails }) => {
  const {
    translated
  } = useContext(AppContext);
  let navigate = useNavigate();
  const params = useParams();

  return (
    <div
      className={"order-item status-" + order?.status}
      onClick={params?.order_id ? () => { } : showDetails}
    >
      <div className="order-item-header">
        <p className="text-16 bold color-blue text-uppercase">
          {translated("app_copy." + order?.status)}
        </p>
        <div className="order-item-icon">
          <img
            width="22px"
            src={
              order?.status === "lab_order_create_success" ||
                order?.status === "ph_visit_scheduled_success"
                ? orderReadyTest
                : order?.status === "ph_visit_started" ||
                  order?.status === "in_progress"
                  ? OrderPh
                  : order?.status === "ph_visit_success" ||
                    order?.status === "completed"
                    ? OrderCompleted
                    : order?.status === "lab_results_available" ||
                      order?.status === "results_available"
                      ? orderResultsAvailable
                      : orderInit
            }
            alt="Order status"
          />
        </div>
      </div>
      <div className="sub-separator"></div>
      <div className="order-item-body">
        <div className="order-item-number">
          <h2 className="text-25">
            {/* Order No. {order?.order_number} */}
            {translated("app_copy.order_with_id").replace("{#ORDERID#}", order?.order_number)}
          </h2>

          {(order?.status === "completed") && (
            <img
              onClick={() => {
                if (isMobile && isAndroid) {
                  var link = document.createElement('a');
                  link.href = "data:application/pdf; base64," + order?.fulfillment_orders[0]?.order_request_pdf;
                  link.download = 'OrderPDF.pdf';
                  link.dispatchEvent(new MouseEvent('click'));
                } else {
                  navigate(
                    "/order-PDF/" +
                    btoa(order?.fulfillment_orders[0]?.order_request_pdf)
                  );
                }
              }}
              className="custom-mr-15 cursor-pointer"
              src={viewPdf}
              alt="View PDF"
            />
          )}
        </div>

        <div className="order-item-details">
          {order?.status === "pending" && (
            <p className="text-16">
              <span className="bold">Tap to view</span> the order from Dr.{" "}
              {order?.provider?.last_name ?? order?.provider?.first_name}{" "}
              received on {moment(order?.created_at).format("LL")}.
            </p>
          )}

          {/*  {(order?.status === "ph_visit_started" ||
            order?.status === "ph_visit_success" ||
            order?.status === "ph_visit_scheduled_success") &&
            order?.booking && (
              <p className="text-16">
                <span className="bold"> You have scheduled </span> at-home
                appointment on {moment(order?.booking?.start_at).format("L")}.
              </p>
            )} */}

          {order?.status === "in_progress" && (
            <p className="text-16">
              <span className="bold"> Phlebotomist is in transit </span> for
              your <span className="bold">at-home appointment.</span>
            </p>
          )}
          {order?.status === "completed" && (
            <p className="text-16">
              {/* <span className="bold"> The lab received </span> your sample and
              started working on it. */}
              {translated("app_copy.order_waiting_for_result")}
            </p>
          )}

          {(order?.status === "lab_results_available" ||
            order?.status === "results_available") &&
            order?.is_order_seen !== true && (
              <p className="text-16">
                {/* <span className="bold"> Tap to view the results </span>for this
                test and discover our suggestions. */}
                {translated("app_copy.order_test_result_ready_desc")}
              </p>
            )}

          {(order?.status === "lab_results_available" ||
            order?.status === "results_available") &&
            order?.is_order_seen === true &&
            (order?.csat === null || order?.csat === undefined) && (
              <p className="text-16">
                {/* <span className="bold"> Leave us a feedback </span> on the
                experience. */}
                {translated("app_copy.order_feedback_desc")}
              </p>
            )}
        </div>

        {
          (order?.status === "ph_visit_success" ||
            order?.status === "ph_visit_started" ||
            order?.status === "in_progress" ||
            order?.status === "lab_order_create_success" ||
            order?.status === "ph_visit_scheduled_success") && (
            <>
              {order?.booking && (
                <div className="order-item-schedule custom-mt-15">
                  <div className="schedule-date">
                    <span className="text-12 text-uppercase color-white">
                      {moment(order?.booking?.start_at).format("MMM")}
                    </span>
                    <span className="text-25 text-uppercase color-white">
                      {moment(order?.booking?.start_at).format("DD")}
                    </span>
                    <span className="text-12 text-uppercase color-white">
                      {moment(order?.booking?.start_at).format("YYYY")}
                    </span>
                  </div>
                  <div className="schedule-info">
                    <p className="text-13 bold">
                      {" "}
                      {moment(order?.booking?.start_at).format("LT")}
                    </p>
                    <p className="text-13 black">
                      <span>{order?.address?.street} </span>
                      <span>{order?.address?.city}, </span>
                      <span>{order?.address?.state} </span>
                      <span>{order?.address?.postal_code} </span>
                    </p>
                  </div>
                </div>
              )}
            </>
          )
        }
      </div >
    </div >
  );
};

export default OrderItem;
