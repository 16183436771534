import { AppContext } from "@/context/createContext";
import moment from "moment";
import React, { useContext, useState } from "react";
export interface OptionProps {
  timeSlot: any;
  selectSlot: (slot: any) => void;
}

const DaySlot: React.FC<OptionProps> = ({ timeSlot, selectSlot }) => {
  const [selectedSlot, setSelectedSlot] = useState<any>(null);
  const { translated } = useContext(AppContext);

  return (
    <>
      <p className="text-16 text-center custom-mt-40 custom-mb-40">
        {timeSlot?.slots.length === 0
          // ? translated("app_copy.available_times")
          && translated("app_copy.no_slot_available")}
      </p>
      <ul className="scheduling-slots-available">
        {timeSlot?.slots.length > 0 &&
          timeSlot.slots.map((time: any, index: number) => {
            return (
              <li
                onClick={() => {
                  selectSlot(time);
                  setSelectedSlot(time);
                }}
                className={
                  selectedSlot === time
                    ? "active standard-btn slot-time-btn text-16"
                    : "standard-btn slot-time-btn text-16"
                }
                key={index.toString()}
              >
                <span>
                  {moment(time.start).format("LT")} -
                  {moment(time.end).format("LT")}
                </span>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default DaySlot;
