/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// import Pages from "@/layouts/Pages";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth as firbaseAuth } from "@/services/firebase";
// import Modal from "@/components/common/Modal";

import {
  Spiner,
} from "@/components/index";
import { AppContext } from "@/context/createContext";
import routes from "@/config/routes";
import { applyActionCode } from "firebase/auth";
import { getInitUserData, verifyUserLocation } from "@/services/api/fireFunction";
import { base64Encode, findItemFromArray } from "@/helper/common";
import { getFireStoreDoc } from "@/services/firebase/fireStore";
// import { cliniclyLogo } from "@/config/svg";
import { RTDBStepModules, ResultStatusList, reducerType } from "@/config/configarableVariable";
const DeeplinkLanding: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [ModalShow, setModalShow] = useState(false);
  const [isCountryValid, setIsCountryValid] = useState(true);
  const auth = getAuth()
  let navigate = useNavigate();
  const {
    stepData,
    userInfo,
    consentDataLoad,
    settingsState,
    setRootLoader,
    unSetRootLoader,
    setStepData,
    addOrgCode
  } = useContext(AppContext);
  let count = 1;
  useEffect(() => {
    const user_data: any = JSON.parse(
      atob(String(searchParams.get("metadata")))
    );
    if (settingsState?.location_api?.path && count === 1) {
      localStorage.clear()
      count++;
      setRootLoader();
      verifyUserLocation(settingsState?.location_api?.path)
        .then(async (data) => {
          localStorage.setItem("i18nextLng", "en");
          localStorage.setItem("firebase:previous_websocket_failure", "true");
          if (data?.status === 200) {
            if (
              settingsState?.location_api?.allowed_country?.includes(
                data?.data?.data?.countryCode
              )
            ) {
              getInitUserData(user_data?.uid).then(async (userDataInfo: any) => {
                let userData = {
                  ...userDataInfo, mode: user_data?.mode
                }
                if (userDataInfo?.order_status === ResultStatusList?.resultsAvailable) {
                  localStorage.setItem("mode", 'result');
                  userData = {
                    ...userDataInfo, mode: 'result'
                  }
                }
                addOrgCode(userData?.org_code)
                if (userData?.email_verified === true) {
                  localStorage.setItem("login_email", userData?.email);
                  handleLogin(userData)
                } else {
                  await verifyUser(userData)
                }
              })
              setIsCountryValid(true);
            } else {
              setIsCountryValid(false);
            }
          }
        })
        .catch(() => {
          unSetRootLoader();
          setIsCountryValid(false);
        });
    }
  }, []);


  const verifyUser = async (userData: any) => {
    await applyActionCode(firbaseAuth, userData?.oob_code)
      .then(async (info) => {
        localStorage.setItem("login_email", userData?.email);
        handleLogin(userData)

      })
      .catch((error) => {
        if (userData?.org_code) {
          addOrgCode(userData?.org_code)
        }

        if (!ModalShow && auth?.currentUser?.emailVerified) {
          localStorage.setItem("login_email", userData?.email);
          handleLogin(userData)
        } else if (
          error.code === "auth/invalid-action-code" &&
          userData?.email
        ) {
          localStorage.setItem("login_email", userData?.email);
          handleLogin(userData)
        } else {
          localStorage.setItem("login_email", userData?.email);
          handleLogin(userData)
        }
      });
  }


  const handleLogin = async (userData: any) => {
    localStorage.setItem("order_id", userData?.order_id)
    try {
      const password = atob(userData?.temp_password)
      signInWithEmailAndPassword(firbaseAuth, userData?.email, password)
        .then(async (userCredential: any) => {
          localStorage.removeItem("wrongAttemp");
          localStorage.setItem("login_email", userData?.email);

          localStorage.setItem("token", userCredential.user.accessToken);
          localStorage.setItem("uid", userCredential.user.uid);
          localStorage.setItem(
            "authToken",
            userCredential._tokenResponse.refreshToken
          );
          await getFireStoreDoc("users/" + userCredential.user.uid).then(
            async (data: any) => {
              if (userData?.order_status === ResultStatusList?.pending) {
                localStorage.setItem("isLogin", "true");
                localStorage.removeItem("isOtpSubmit");
                localStorage.setItem("isOtpVerify", "true");
                if (stepData?.allStep[RTDBStepModules?.resultAuthentication]) {
                  setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: stepData?.allStep[RTDBStepModules?.authentication] })
                }
                navigate(routes.authenticationStep);
              } else if (userData?.order_status === ResultStatusList?.resultsAvailable) {
                localStorage.setItem("isLogin", "true");
                localStorage.removeItem("isOtpSubmit");
                localStorage.setItem("isOtpVerify", "true");
                if (stepData?.allStep[RTDBStepModules?.resultAuthentication]) {
                  localStorage.setItem("mode", "result")
                  setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: stepData?.allStep[RTDBStepModules?.resultAuthentication] })
                }
                navigate(routes.authenticationStep);
              } else if (userData?.order_status === ResultStatusList?.phVisitScheduledSuccess) {
                localStorage.setItem("isLogin", "true");
                localStorage.removeItem("isOtpSubmit");
                localStorage.setItem("isOtpVerify", "true");
                navigate('/order-complete?metadata=' + base64Encode(userData?.order_id));
              } else if (userData?.order_status === ResultStatusList?.inProgress) {
                localStorage.setItem("isLogin", "true");
                localStorage.removeItem("isOtpSubmit");
                localStorage.setItem("isOtpVerify", "true");
                navigate('/pre-test?metadata=' + base64Encode(userData?.order_id));
              }

              unSetRootLoader()
            }
          );
        })
        .catch((error) => {
          unSetRootLoader();
          localStorage.setItem("isOtpSubmit", "false");
          localStorage.setItem(
            "wrongAttemp",
            String(
              localStorage.getItem("wrongAttemp")
                ? Number(localStorage.getItem("wrongAttemp")) + 1
                : 1
            )
          );
        });
    } catch (error) {
      console.log(error)
    }
  };
  return <section className="h-custom category">
    <div className="container py-3 vh-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="d-flex align-items-center">
          <Spiner height="100px" width="100px" margin="auto" />
        </div>
      </div>
    </div>
  </section>
};

export default DeeplinkLanding;
