//Step modules text
export const feedbackStepDataText = "feedbacks"
export const stepModuleText = {
    essentialStepDataText: "essential_data",
    nonEssentialStepDataText: "non_essential",
    consentStepDataText: "consent",
    feedbackStepDataText: "feedbacks",
    changePasswordStepDataText: "change_password_data",
}

//Firebase firestore doc collection name
export const firestore = {
    projectConfigKeys: "project_configurations/keys",
    projectConfigSettings: "project_configurations/settings",
    boimarkerContent: "contents/test_result_item",
}

//Firebase RealtimeData collection name
export const RTDB = {
    configaurableText: "configurable_text",
    essentialStep: "module_steps/onboarding_questionnaire",
    nonEssentialStep: "module_steps/non_essential",
    consentStep: "module_steps/consent",
    feedbackStep: "module_steps/feedback",
    changePwdStep: "module_steps/change_pwd",
    updatedUserStep: "module_steps/onboarding_questionnaire_v4",
}

//Firebase RealtimeData collection name
export const RTDBStepModules = {
    essentialStep: "onboarding_questionnaire",
    nonEssentialStep: "non_essential",
    consentStep: "consent",
    feedbackStep: "feedback",
    changePwdStep: "change_pwd",
    updatedUserStep: "onboarding_questionnaire_v4",
    authentication: "authentication",
    resultAuthentication: "result_authentication",

}
//Reducer Type
export const reducerType = {
    IS_UI_CHANGE: "IS_UI_CHANGE",
    ADD_TO_CART: "ADD_TO_CART",
    INIT_CART: "INIT_CART",
    UPDATE_TO_CART: "UPDATE_TO_CART",
    ADD_TO_ORDER: "ADD_TO_ORDER",
    UPDATE_TO_ORDER: "UPDATE_TO_ORDER",
    UPDATE_PRICE: "UPDATE_PRICE",
    ADD_CATEGORY: "ADD_CATEGORY",
    ADD_POSTS: "ADD_POSTS",
    ADD_PRODUCTS: "ADD_PRODUCTS",
    ADD_PACKAGE: "ADD_PACKAGE",
    REMOVE_FROM_CART: "REMOVE_FROM_CART",
    ADD_BIOMARKER: "ADD_BIOMARKER",
    UPDATE_MODULES: "UPDATE_MODULES",
    ONBOARDING_STEP: "ONBOARDING_STEP",
    AUTHENTICATION_STEP: "AUTHENTICATION_STEP",
    CONSENT_STEP: "CONSENT_STEP",
    FEEDBACK_STEP: "FEEDBACK_STEP",
    ESSENTIAL_STEP: "ESSENTIAL_STEP",
    ALL_STEP: "ALL_STEP",
    USER_UPDATED: "USER_UPDATED",
    APP_CONFIG: "APP_CONFIG",
    UPDATE_WHITELABELING: "UPDATE_WHITELABELING",
    UPDATE_STATE: "UPDATE_STATE"
}


//API config
export const APIConfig = {
    getInitUserData: "auth/init",
    getBiomarkerFaq: "biomarkers",
    commonSentOTP: "otp/create",
    commonResendOTP: "otp/resend",
    commonVerifyOTP: "otp/verify",
    stripePaymentIntent: "payments/paymentIntent",
    createStripeCustomer: "payments/createCustomer",
    stripeSaveCard: "payments/paymentMethod",
    getScheduleList: "schedule/appointmentList",
    createBookingSlot: "schedule/book",
    fcmToken: "notification-message/token",
    tokenize: "crypt/tokenize",
    unTokenize: "crypt/un-tokenize",
    createDynamicLink: "dl/create-dynamic-link",
    sentVarificationEmail: "auth/email-verification",
    order: "order",
    address: "address",
    loginUser: "auth/login-user",
    resultScore: "score",
    getLabCorpEndPoint: "labcorp/get-endpoint"
}

//App default variable
export const DefaultConfig = {
    orgCode: "2000-0000-0000-1111",
    lightBackground: "linear-gradient(90deg, #f1f8ff 2%, #f5faff 100%)",
    blueGradient: "linear-gradient(94.94deg, #a347ff 0%, #432cd2 100%)"
}

//App default variable
export const ResultStatusList = {
    pending: "pending",
    paymentSuccess: "payment_success",
    labOrderCreateSuccess: "lab_order_create_success",
    phVisitScheduledSuccess: "ph_visit_scheduled_success",
    labResultsAvailable: "lab_results_available",
    resultsAvailable: "results_available",
    phVisitSuccess: "ph_visit_success",
    inProgress: "in_progress",
    completed: "in_progress",
}