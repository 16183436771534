import { signInAnonymously, updateEmail } from "firebase/auth";
import { auth } from "./index";

export const authentication = {
    // firebase helper methods go here... 
    signInAnonymously: async () => {
        await signInAnonymously(auth)
    },
    signin: () => {

    },
    signout: () => {

    },
}

export const updateUserEmail = async (email: string) => {
    if (auth?.currentUser) {
        await updateEmail(auth?.currentUser, email).then(() => {
            console.log(`User email updated`)
            // Email updated!
            // ...
        }).catch((error) => {
            // An error occurred
            // ...
        });
        return true
    }
    return false
}