/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { AppContext } from "@/context/createContext";

const StringInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  isShimmer?: boolean;
  autoFocus?: boolean;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string, validation_only: boolean) => void;
}> = ({ attribute, oldSelection, isShimmer, autoFocus, index, submitData }) => {
  const { userInfo } = useContext(AppContext);
  const [inputValue, setInputValue] = useState("");
  const [oldData, setOldData] = useState(oldSelection);

  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue.trimStart());
    }
  };
  useEffect(() => {
    if (oldSelection !== "" && attribute?.validation?.user_validate === undefined) {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);

  useEffect(() => {
    if (userInfo?.user_data && attribute?.validation?.user_validate === true) {
      setOldData((userInfo?.user_data !== null
        && Object.keys(userInfo?.user_data).length > 0
        && userInfo?.user_data[attribute?.key] !== undefined)
        ? userInfo?.user_data[attribute?.key] : oldSelection)
    }
  }, [userInfo]);
  useEffect(() => {
    // console.log({ inputValue, oldData })
    submitData(
      inputValidation(attribute.validation, inputValue, oldData),
      attribute.key,
      inputValue,
      attribute?.validation?.user_validate ?? false
    );
  }, [inputValue]);
  return (
    <>
      <input
        type="text"
        id={"input-" + index}
        autoComplete="off"
        autoFocus={
          attribute?.enabled !== undefined && attribute?.enabled === false
            ? false
            : autoFocus
        }
        readOnly={
          attribute?.enabled !== undefined && attribute?.enabled === false
        }
        value={inputValue}
        onChange={(e) => updateInputValue(e.target.value)}
        className={isShimmer ? "common-shimmer" : ""}
      />
      <label htmlFor={"input-" + index}>{attribute.placeholder_text}</label>
    </>
  );
};

export default StringInput;
