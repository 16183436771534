import { AppContext } from "@/context/createContext";
import React, { useContext, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";

const CropEasy: React.FC<{
  photoURL: any;
  setOpenCrop: any;
  setPhotoURL: any;
  fileUpload: (file: any) => void;
}> = ({ photoURL, setOpenCrop, setPhotoURL, fileUpload }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { translated } = useContext(AppContext);
  const cropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImg(
        photoURL,
        croppedAreaPixels,
        rotation
      );
      setPhotoURL(url);
      fileUpload(file);
      setOpenCrop(false);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadCancel = () => {
    setOpenCrop(false);
    setPhotoURL("");
  };

  return (
    <div className="profile-userpic">
      <div className="position-relative" style={{ height: "240px" }}>
        <Cropper
          image={photoURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          cropShape="round"
        />
      </div>
      <div className="d-flex justify-content-center custom-mt-24 w-100">
        <div className="width-180">
          <button
            className="col-12 custom-btn btn-standard-background button-small" onClick={uploadCancel}
          >
            <p className="btn-text background-color">{translated('cta.cancel')}</p>
          </button></div>
        <div className="width-180">
          <button
            className="col-12 custom-btn btn-standard-background button-small" onClick={cropImage}
          >
            <p className="btn-text background-color">{translated('cta.crop')}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CropEasy;
