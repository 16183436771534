/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { emailValidations } from "@/helper/common";
import { AppContext } from "@/context/createContext";
import { Spiner } from "@/components/index";
import { auth } from "@/services/firebase";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";
const defaultPayloadData = `{
  "patient": {
      "first_name": "Jon",
      "last_name": "David",
      "middle_name": "",
      "email": "test@claire.health",
      "age": 33,
      "gender": "F",
      "race": "X",
      "date_of_birth": "19900101",
      "patient_class": "O",
      "ethnic_group": "U",
      "phone_number": "+19999999999",
      "city": "Pheonix",
      "state": "AZ",
      "address_line_1": "8907 Legacy Park Dr",
      "address_line_2": "",
      "postal_code": "85003",
      "is_abn": true,
      "country": "US"
  },
  "provider": {
      "first_name": "Jams",
      "last_name": "D",
      "npi_id": "456"
  },
  "insurance": {
    "payer_code": "t6y_7823_u3i2",
    "insurance_name": "Medicare",
    "insurance_address": "202 167th Ave, Bellevue, WA 98004",
    "insurance_relation": "self",
    "policy_number": "2345678549",
    "group_number": "2341467",
    "employer_name": "Claire",
    "workers_comp": "no"
 },
    "org_code":{ "code":"2000-0000-0000-1111", "default_code": true}
}`;
const TempUserCreate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isMockOrder, setMockOrder] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [email, setEmail] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [newUserId, setNewUserId] = useState("");
  const [oldUserId, setOldUserId] = useState("");
  const [patientPayLoad, setPatientPayLoad] = useState(defaultPayloadData);
  // const [patientPayLoadJSON, setPatientPayLoadJSON] = useState(
  //   JSON.parse(defaultPayloadData)
  // );
  const [orderId, setOrderId] = useState("");
  const [oldOrderId, setOldOrderId] = useState("");
  // const [doctorId, setDoctorId] = useState("");
  const [shortLink, setShortLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // const [isEmailValidate, setIsEmailValidate] = useState(false);
  const [isPayLoadValidate, setIsPayLoadValidate] = useState(false);

  const [isUserWithPhone, setIsUserWithPhone] = useState(false);
  // const { t } = useTranslation();

  const { state, showToastMessage } = useContext(AppContext);
  const configState = state?.config_state
  const lastRequestData = useRef<any>(null);
  const logs = useRef<any>([]);

  useEffect(() => {
    document.body.className = "Temp-User-Create";

    return () => {
      document.body.className = "";
    };
  }, []);
  useEffect(() => {
    if (patientPayLoad.length > 0) {
      try {
        const patientPayLoadJson = JSON.parse(patientPayLoad);
        if (
          patientPayLoadJson?.patient &&
          patientPayLoadJson?.patient?.email &&
          emailValidations(
            patientPayLoadJson?.patient?.email,
            configState?.email_rules?.regex_validation
          )
        ) {
          setIsPayLoadValidate(true);
          setErrorMessage("");
        } else {
          setIsPayLoadValidate(false);
          setErrorMessage("email not found or invalid email");
        }

        console.log({ patientPayLoadJson });
      } catch (error: any) {
        console.log({ error });
        setIsPayLoadValidate(false);
        setErrorMessage(error?.message);
      }
    }
  }, [patientPayLoad]);
  useEffect(() => {
    if (newUserId && !email) {
      getUserInfo();
    }
  }, [newUserId]);
  useEffect(() => {
    if (orderId) {
      getOrderInfo();
    }
  }, [orderId]);

  const addLog = async (data: any) => {
    // await logs.current.push(<div className="log-time">{new Date().toUTCString()}</div>)
    await logs.current.unshift(<pre className="log-data">{data}</pre>);
    await logs.current.unshift(
      <div className="log-time">{new Date().toUTCString()}</div>
    );
    // await logs.current.push(<pre className="log-data">{data}</pre>)
  };

  const createUser = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setLoading(true);
    setErrorMessage("");
    setShortLink("");

    try {
      // const postData = { email, order_id: orderId, doctor_id: doctorId };
      const postData = JSON.parse(patientPayLoad);
      // const postData = patientPayLoadJSON;
      postData.order_id = `mock-${uuidv4()}`;
      lastRequestData.current = postData;
      const token = await auth.currentUser?.getIdToken();
      // window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL = 'http://localhost:5001/test-rnd-73136/us-central1/';
      // const base_url = "http://localhost:5001/test-rnd-73136/us-central1";
      const base_url = window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL;
      let url = `${base_url}/api/v1/ehr/init`;
      if (isUserWithPhone) url = `${base_url}/api/v1/ehr/pre-order/init`;

      const headers = {
        "x-data-payload-type": "static",
        authorization: "Bearer " + token,
        "x-token": token ?? "",
      };
      await addLog(
        JSON.stringify(
          {
            url,
            method: "post",
            headers,
            postData,
          },
          null,
          2
        )
      );

      const response = await axios.post(url, postData, { headers });
      setLoading(false);
      console.log({ data: response?.data });
      if (response?.data?.short_link) setShortLink(response?.data?.short_link);
      if (response?.data?.uid) setNewUserId(response?.data?.uid);
      if (response?.data?.email) setEmail(response?.data?.email);
      if (response?.data?.order_id) setOrderId(response?.data?.order_id);
      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      let errorMsg = "";
      const errorData = await error?.response?.data;
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      console.log({ errorData });

      // logs.current.initApi.push(JSON.stringify({ error: errorData}))
      setLoading(false);
      if (error?.code === "ERR_NETWORK") {
        throw error;
      }
      console.error("Failed to load API!", error);
      if (error?.response?.data?.error?.error?.message)
        errorMsg = error?.response?.data?.error?.error?.message;
      else if (error?.response?.data?.error?.message)
        errorMsg = error?.response?.data?.error?.message;

      setErrorMessage(errorMsg);
      return error;
    }
  };
  const updateStatus = async (status: string) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setStatusLoading(true);
    setErrorMessage("");
    if (!logs.current?.updateStatus) logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/v1/ehr/order/${orderId}/fulfillment-orders/${orderId}`;
      const headers = {
        authorization: "Bearer " + token,
        "x-user-id": uuid ?? "",
        "x-token": token ?? "",
      };
      addLog(
        JSON.stringify(
          {
            url,
            method: "post",
            headers,
            data: { status },
          },
          null,
          2
        )
      );

      const response = await axios.post(url, { status }, { headers });
      setStatusLoading(false);

      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      setStatusLoading(false);
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      if (error?.code === "ERR_NETWORK") throw error;
      console.error("Failed to load API!", error);
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error;
    }
  };
  const getOrderInfo = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setStatusLoading(true);
    setErrorMessage("");
    if (!logs.current?.updateStatus) logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/order/${newUserId}/${orderId}/get`;
      const headers = {
        authorization: "Bearer " + token,
        "x-user-id": uuid ?? "",
        "x-token": token ?? "",
      };
      addLog(
        JSON.stringify(
          {
            url,
            method: "get",
            headers,
          },
          null,
          2
        )
      );

      const response = await axios.get(url, { headers });
      // console.log({ response });
      // console.log({ response_email:response?.data?.data?.email });

      if (response?.data) {
        setMockOrder(response?.data?.is_mock);
      }
      setStatusLoading(false);

      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      setStatusLoading(false);
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      if (error?.code === "ERR_NETWORK") throw error;
      console.error("Failed to load API!", error);
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error;
    }
  };
  const getUserInfo = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setStatusLoading(true);
    setErrorMessage("");
    if (!logs.current?.updateStatus) logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/external/get-user-info/${newUserId}/`;
      const headers = {
        authorization: "Bearer " + token,
        "x-user-id": uuid ?? "",
        "x-token": token ?? "",
      };
      addLog(
        JSON.stringify(
          {
            url,
            method: "get",
            headers,
          },
          null,
          2
        )
      );

      const response = await axios.get(url, { headers });
      // console.log({ response });
      // console.log({ response_email:response?.data?.data?.email });

      if (response?.data?.data?.email) setEmail(response?.data?.data?.email);
      setStatusLoading(false);

      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      setStatusLoading(false);
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      if (error?.code === "ERR_NETWORK") throw error;
      console.error("Failed to load API!", error);
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error;
    }
  };
  const sendCSATMail = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setStatusLoading(true);
    setErrorMessage("");
    if (!logs.current?.updateStatus) logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/order/${newUserId}/${orderId}/csat`;
      const headers = {
        authorization: "Bearer " + token,
        "x-user-id": uuid ?? "",
        "x-token": token ?? "",
      };
      addLog(
        JSON.stringify(
          {
            url,
            method: "get",
            headers,
          },
          null,
          2
        )
      );

      const response = await axios.get(url, { headers });
      setStatusLoading(false);

      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      setStatusLoading(false);
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      if (error?.code === "ERR_NETWORK") throw error;
      console.error("Failed to load API!", error);
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error;
    }
  };
  const createMockOrder = async (status: boolean) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error("No base url specified for firebase functions");
    }
    setStatusLoading(true);
    setErrorMessage("");
    if (!logs.current?.updateStatus) logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/internal/order-mock/${newUserId}/${orderId}`;
      // const url = `http://localhost:5001/test-rnd-73136/us-central1/api/internal/order-mock/${newUserId}/${orderId}`;
      const headers = {
        authorization: "Bearer " + token,
        "x-user-id": uuid ?? "",
        "x-token": token ?? "",
      };
      addLog(
        JSON.stringify(
          {
            url,
            method: "put",
            headers,
            data: { is_mock: status },
          },
          null,
          2
        )
      );

      const response = await axios.put(url, { is_mock: status }, { headers });
      if (response?.data?.status) setMockOrder(response?.data?.is_mock);
      setStatusLoading(false);

      addLog(
        JSON.stringify(
          { response: response.data, status: response.status },
          null,
          2
        )
      );
      return response;
    } catch (error: any) {
      setStatusLoading(false);
      addLog(
        JSON.stringify(
          { error: error?.response?.data, status: error?.response?.status },
          null,
          2
        )
      );
      if (error?.code === "ERR_NETWORK") throw error;
      console.error("Failed to load API!", error);
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error;
    }
  };

  const copyText = () => {
    window.navigator.clipboard.writeText(shortLink);
    showToastMessage("Short link copied to clipboard!");
  };

  return (
    <Pages>
      <section className="h-100 container-temp-page">
        {/* <PerfectScrollbar> */}
        <div className="py-3 h-100">
          <div className="row">
            <div id="login-box" className="col-md-6 inner-content">
              {/* <div className="logo-login text-center">
                  <img
                    className="m-2 custom-mb-60 cursor-pointer"
                    src={logoLogin}
                    alt="logo"
                  />
                  <br />

                </div> */}

              {loading ? (
                <Spiner height="100px" width="100px" margin="auto" />
              ) : (
                <>
                  {!newUserId ? (
                    <div className="row">
                      <div className="col-md-12">
                        <h2>Create User and Order</h2>
                        <div className="input-wrapper form-group text-center patient_payload">
                          <textarea
                            name="patient_payload"
                            className="form-control"
                            disabled={loading}
                            cols={30}
                            rows={20}
                            value={patientPayLoad}
                            onChange={(e) => setPatientPayLoad(e.target.value)}
                          ></textarea>
                        </div>
                        {/* <div className="form-group">
                        <label htmlFor="login-email">Email</label>
                        <input
                          type="text"
                          id="login-email"
                          autoFocus
                          autoComplete="off"
                          value={patientPayLoadJSON.patient.email}
                          onChange={(e) =>   
                            setPatientPayLoadJSON( (pre:any) => {
                              return {...pre, patient: {...patientPayLoadJSON.patient, email: e.target.value}}
                            })                  
                            //setEmail(e.target.value)
                          }
                          placeholder={t("app_copy.email")}
                          className="form-control custom-mb-30 width-320"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="login-first_name">First Name</label>
                        <input
                          type="text"
                          id="login-first_name"
                          autoFocus
                          autoComplete="off"
                          value={patientPayLoadJSON.patient.first_name}
                          onChange={(e) =>   
                            setPatientPayLoadJSON( (pre:any) => {
                              return {...pre, patient: {...patientPayLoadJSON.patient, first_name: e.target.value}}
                            })                  
                            //setEmail(e.target.value)
                          }
                          placeholder="First Name"
                          className="form-control custom-mb-30 width-320"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="login-last_name">Last Name</label>
                        <input
                          type="text"
                          id="login-last_name"
                          autoFocus
                          autoComplete="off"
                          value={patientPayLoadJSON.patient.last_name}
                          onChange={(e) =>   
                            setPatientPayLoadJSON( (pre:any) => {
                              return {...pre, patient: {...patientPayLoadJSON.patient, last_name: e.target.value}}
                            })                  
                            //setEmail(e.target.value)
                          }
                          placeholder="Last Name"
                          className="form-control custom-mb-30 width-320"
                        />
                      </div> */}
                        <div className="form-group text-center">
                          <div className="row mx-2 custom-mb-24 custom-mt-50">
                            <div className="custom-mb-15">
                              <label htmlFor="phone-number-user">
                                <input
                                  type="checkbox"
                                  style={{ appearance: "auto", margin: "10px" }}
                                  value="1"
                                  name="user-with-phone"
                                  id="phone-number-user"
                                  onChange={(e) => {
                                    setIsUserWithPhone(e.target.checked);
                                    console.log(e.target.checked);
                                  }}
                                />
                                Create user by phone number
                              </label>
                            </div>
                          </div>
                          <div className="row mx-2 custom-mb-24 custom-mt-50">
                            <div className="custom-mb-15">
                              <button
                                disabled={!isPayLoadValidate}
                                style={{ padding: "10px 20px" }}
                                className="btn btn-primary"
                                onClick={createUser}
                              >
                                Create User
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 
                    <div className="form-group text-center">
                      <input
                        type="text"
                        id=""
                        autoFocus
                        autoComplete="off"
                        value={orderId}
                        onChange={(e) =>
                          setOrderId(e.target.value)
                        }
                        placeholder="Order ID"
                        className="form-control custom-mb-30 width-320"
                      />


                    </div>
                    <div className="form-group text-center">
                      <input
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={doctorId}
                        onChange={(e) =>
                          setDoctorId(e.target.value)
                        }
                        placeholder="Doctor ID"
                        className="form-control custom-mb-30 width-320"
                      />


                    </div> */}

                      {/*     <div className="col-md-12 existing-order">
                        <h2>Existing Order</h2>
                        <div className="d-flex">
                          <label htmlFor="">User ID:</label>
                          <input
                            type="text"
                            id=""
                            autoFocus
                            autoComplete="off"
                            value={oldUserId}
                            onChange={(e) => setOldUserId(e.target.value)}
                            placeholder="User ID"
                            className="form-control custom-mb-30 width-320"
                          />
                        </div>
                        <div className="d-flex">
                          <label htmlFor="">Order ID:</label>
                          <input
                            type="text"
                            id=""
                            autoFocus
                            autoComplete="off"
                            value={oldOrderId}
                            onChange={(e) => setOldOrderId(e.target.value)}
                            placeholder="Order ID"
                            className="form-control custom-mb-30 width-320"
                          />
                        </div>

                        <div className="form-group text-center">
                          <div className="row mx-2 d-flex justify-content-center custom-mb-24 custom-mt-50">
                            <div className="custom-mb-15">
                              <button
                                disabled={!oldUserId || !oldOrderId}
                                className="btn btn-primary"
                                onClick={() => {
                                  setNewUserId(oldUserId);
                                  setOrderId(oldOrderId);
                                }}
                              >
                                Update Order
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="text-center">
                      {shortLink && <span className="cursor-pointer small-text-supergray">
                        <p className="btn-text bold small-text rainbow_purple-blue" onClick={copyText}>
                          {shortLink}
                        </p>
                      </span>}
                      {errorMessage && <p className="small-text" style={{ color: 'red' }}>{errorMessage}</p>}

                    </div> */}
                    </div>
                  ) : (
                    <div className="row d-flex">
                      <div className="col-md-12 my-5">
                        <div className="d-flex">
                          <label htmlFor="">Email: </label>
                          <label
                            htmlFor=""
                            style={{ margin: "auto 10px", fontWeight: "bold" }}
                          >
                            {email}
                          </label>
                        </div>
                        <div className="d-flex">
                          <label htmlFor="">User ID: </label>
                          <label
                            htmlFor=""
                            style={{ margin: "auto 10px", fontWeight: "bold" }}
                          >
                            {newUserId}
                          </label>
                        </div>
                        <div className="d-flex">
                          <label htmlFor="">Order ID:</label>
                          <label
                            htmlFor=""
                            style={{ margin: "auto 10px", fontWeight: "bold" }}
                          >
                            {orderId}
                          </label>
                        </div>
                        <div className="form-group text-center">
                          {shortLink && (
                            <span className="cursor-pointer small-text-supergray">
                              <p
                                className="btn-text bold small-text rainbow_purple-blue"
                                onClick={copyText}
                              >
                                {shortLink}
                              </p>
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        {statusLoading ? (
                          <Spiner height="100px" width="100px" margin="auto" />
                        ) : (
                          <>
                            <div>
                              <div
                                className="button-group d-flex justify-content-center"
                                style={{ marginBottom: "10px;" }}
                              >
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "auto 10px" }}
                                  onClick={() => {
                                    updateStatus("in_progress");
                                  }}
                                >
                                  In progress
                                </div>
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "auto 10px" }}
                                  onClick={() => {
                                    updateStatus("completed");
                                  }}
                                >
                                  Completed
                                </div>
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "auto 10px" }}
                                  onClick={() => {
                                    updateStatus("results_available");
                                  }}
                                >
                                  Result Available
                                </div>
                              </div>
                            </div>
                            <div className="my-3">
                              <div
                                className="button-group d-flex justify-content-center"
                                style={{ marginBottom: "10px;" }}
                              >
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "auto 10px" }}
                                  onClick={() => {
                                    sendCSATMail();
                                  }}
                                >
                                  Send CSAT Email
                                </div>
                                <div
                                  className="btn btn-primary"
                                  style={{ margin: "auto 10px" }}
                                  onClick={() => {
                                    createMockOrder(!isMockOrder);
                                  }}
                                >
                                  {isMockOrder
                                    ? "Remove Mock Order"
                                    : "Make Mock Order"}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="text-center">
                    {errorMessage && (
                      <p className="small-text" style={{ color: "red" }}>
                        {errorMessage}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
            {logs.current.length > 0 && (
              <div className="col-md-6">
                {" "}
                <div className="log-history">
                  {logs.current.length > 0 &&
                    logs.current.map((item: string) => item)}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* </PerfectScrollbar> */}
      </section>
    </Pages>
  );
};

export default TempUserCreate;
