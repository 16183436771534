/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ShimmerTitle, ShimmerText, ShimmerButton } from "../../packages/Shimmer/index";

const PreferencesShimmer: React.FC = () => {
  return (
    <div className="order-item p-1">
      <br />
      <div className="order-item-body">
        <ShimmerTitle line={1} gap={15} variant="primary" />

        <ShimmerText line={1} gap={10} className="orders-wrapper " />
        <div className="d-flex justify-content-between position-relative">
          <div className="mb-3  width-180">
            <ShimmerText line={2} gap={10} className="orders-wrapper " />
          </div>
          <div className="mb-3  width-180">
            <ShimmerButton size="sm" />
          </div>
          <div className="mb-3  width-180"></div>
        </div>
        <div className="d-flex justify-content-between position-relative">
          <div className="mb-3  width-180">
            <ShimmerText line={2} gap={10} className="orders-wrapper " />
          </div>
          <div className="mb-3  width-180">
            <ShimmerButton size="sm" />
          </div>
          <div className="mb-3  width-180"></div>
        </div>
      </div>
    </div>
  );
};

export default PreferencesShimmer;
