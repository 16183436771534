/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { getFireStoreData } from "@/services/api/fireFunction";
import { selfCollection, weComeToYou, youComeToUs } from "@/config/svg";

const ContentSelectionOption: React.FC<{
  attribute: AttributeType;
  config: any;
  fetch_options: boolean;
  oldSelection: any;
  multiSelection: boolean;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[],
    validate: boolean
  ) => void;
}> = ({ attribute, oldSelection, fetch_options, multiSelection, config, submitData }) => {
  const [updateValue, setUpdateValue] = useState<Array<string>>([]);
  const [options, setOptions] = useState<any>(fetch_options ? [] : config?.options);
  const optionClicked = (newupdateValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        var newArray = multiSelection
          ? [...updateValue, dataProcess(element, newupdateValue).trimStart()]
          : [dataProcess(element, newupdateValue).trimStart()];
        setUpdateValue(newArray);
      });
    } else {
      var newArray = multiSelection
        ? [...updateValue, newupdateValue]
        : [newupdateValue];
      setUpdateValue(newArray);
    }
  };

  const optionUnselect = (value: string) => {
    var newArray = updateValue.filter(function (item: string) {
      return item !== value;
    });
    setUpdateValue(newArray);
  };

  // useEffect(() => {
  //   if (oldSelection !== "") {
  //     setUpdateValue(oldSelection.toString().split(","));
  //   }
  // }, [oldSelection]);

  useEffect(() => {
    if (fetch_options === true) {
      getFireStoreData(config?.filter?.path).then((data: any) => {
        setOptions(data)
      })
    }
  }, [fetch_options]);

  useEffect(() => {
    if (options.length) {

      setUpdateValue(options[0]?.key)
    }
  }, [options])

  useEffect(() => {
    if (updateValue.length > 0) {
      submitData(
        inputValidation(attribute?.validation, updateValue[0]),
        attribute.key,
        updateValue.toString(),
        true
      );
    } else {
      submitData(false, attribute.key, updateValue.toString(), true);
    }
  }, [updateValue]);

  return (
    <div className="content-list-group">
      {options.length > 0 && !fetch_options &&
        options.map((option: any, index: number) => (
          <div className={updateValue.length === 0 ? "content-item" : updateValue.includes(option.key) === false
            ? "content-item" : "card content-item"} key={index.toString()}
            onClick={() => {
              updateValue.includes(option.key)
                ? optionUnselect(option.key)
                : optionClicked(option.key)
            }
            }>
            <div className={"row d-flex"}>
              <div className="col-md-4 content-img">
                <img src={index === 0 ? weComeToYou : index === 1 ? youComeToUs : selfCollection} className="img-fluid rounded-start" alt="..." />
              </div>
              <div className="col-md-8">
                <div className="card-body ml-2">
                  <p className="text-12 card-text bold color-blue">{option.header_text}</p>
                  <h5 className="text-20">
                    {option.display_text}</h5>
                  {option.sub_text &&
                    <p className="text-14 card-text">{option.sub_text}</p>
                  }
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContentSelectionOption;
