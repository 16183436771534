/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppContext } from "@/context/createContext";
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect, useState } from "react";
// import { Spiner } from "@/components/index";
import PerfectScrollbar from "react-perfect-scrollbar";
import { sentVarificationEmail, getUser } from "@/services/api/fireFunction";
import Modal from "../../components/common/Modal";
import { dataProcess } from "@/helper/common";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { auth } from "@/services/firebase";
import { updateEmail } from "@firebase/auth";
import { getFireStoreDoc, updateUserData } from "@/services/firebase/fireStore";
import { casualLife } from "@/config/svg";
import { EmailVerifyShimmer } from "@/components/shimmer";

const EmailVerify: React.FC = () => {
  const {
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [checkVerify, setCheckVerify] = useState<boolean>(true);
  const [email, setEmail] = useState(userInfo?.user_data?.email);
  let navigate = useNavigate();

  useEffect(() => {
    document.body.className = "checkout-area";

    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {

    if (checkVerify) {
      setRootLoader();
      getUser().then((result: any) => {
        unSetRootLoader();
        if (result?.data?.uid && result?.data?.emailVerified) {
          const emailVerified = localStorage.getItem('emailVerify')
          if (emailVerified !== undefined && emailVerified !== null) {
            navigate(emailVerified);
          } else {
            navigate(routes.root);
          }
          // console.log({result:result?.data});          
        }
      }).then(() => {
        unSetRootLoader();
        setCheckVerify(false);
        setTimeout(() => {
          setCheckVerify(true)
        }, 30 * 1000);
      }).catch((err) => {
        unSetRootLoader();
        if (err?.code === 'ERR_NETWORK')
          showToastMessage(translated("error.network_connection_error"), true)
      });

    }

  }, [checkVerify]);

  const verifyNow = () => {
    setRootLoader();
    const payload = {
      email: userInfo?.user_data?.email,
    };
    sentVarificationEmail(payload).then(() => {
      showToastMessage("Verification email sent successfully!");
      unSetRootLoader();
    }).catch((err) => {
      if (err?.code === 'ERR_NETWORK')
        showToastMessage(translated("error.network_connection_error"), true)
      unSetRootLoader();
    });
  };

  const resentEmail = async () => {
    setRootLoader();
    const payload = {
      email,
    };
    if (auth?.currentUser) {
      // const credential = promptForCredentials();

      // reauthenticateWithCredential(auth?.currentUser, credential).then(() => {
      //   // User re-authenticated.
      // }).catch((error) => {
      //   // An error ocurred
      //   // ...
      // });
      await updateEmail(auth?.currentUser, email).then(async () => {

        await getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
          if (
            keys !== undefined &&
            keys?.user_data !== undefined &&
            userInfo?.authUId
          ) {
            updateUserData(userInfo?.authUId, {
              user_data: {
                ...keys?.user_data,
                email: email
              },
            });
            showToastMessage("Email updated successfully!");
          }
        });

        await sentVarificationEmail(payload).then(() => {
          setIsModal(false);
          showToastMessage("Verification email re-sent successfully!");
          unSetRootLoader();
        }).catch((err) => {
          if (err?.code === 'ERR_NETWORK')
            showToastMessage(translated("error.network_connection_error"), true)
          unSetRootLoader();
        });

      }).catch((error) => {
        setIsModal(false);
        unSetRootLoader();

        if (error.code === "auth/requires-recent-login") {
          showToastMessage(translated("error.requires_authentication_login_again"), true);
        } else if (error.code === "auth/user-not-found") {
          showToastMessage(translated("error.user_not_exist_signup"), true);
        } else if (error.code === "auth/email-already-in-use") {
          showToastMessage(translated("error.user_with_same_email_already_exists"), true);
        } else if (error.code === "auth/provider-already-linked") {
          showToastMessage(translated("error.same_provider_used"), true);
        } else {
          showToastMessage(translated("error.something_went_wrong"), true);
        }
        // An error occurred
        // ...
      });
    }
  };
  return (
    <Pages>
      <section className="h-100 h-custom schedule-container">
        <div className="row h-100">
          <div className="row align-items-center scrollcontainer">
            <PerfectScrollbar>
              <div className="col-6 kyc-title custom-pl-100 h-100">
                {mainLoader || remainingLoaderTime ? (
                  <EmailVerifyShimmer />
                ) : (
                  <div className="container-title">
                    <div className="cnt-bar custom-mb-20">
                      <p className="blue-small-bold-text">{translated("app_copy.you_almost_there")}</p>
                    </div>
                    <h1 className="kyc-flow">
                      {translated("app_copy.need_you_verify_email")}
                    </h1>
                    <h2 className="kyc-flow custom-mt-40">
                      {translated("app_copy.before_proceed_the_purchase_verify_email")}
                    </h2>


                    <div className="mb-3 text-center confirm-schedule width-236 verify-btn">
                      <button
                        className="custom-btn btn-standard-gradient"
                        onClick={verifyNow}
                      >
                        <p className="btn-text bold small-text rainbow_purple-blue">
                          {translated("cta.verify_now")}
                        </p>
                      </button>
                      <label className="d-flex justify-content-center small-text-lightgray">
                        <span
                          onClick={() => setIsModal(true)}
                          className="cursor-pointer text-underline small-text-lightgray"
                        >
                          {translated("app_copy.did_not_receive_email")}
                        </span>
                      </label>
                    </div>
                  </div>

                )}
              </div>
              <div className="col-6 h-100 dates-box position-relative">
                <img
                  src={casualLife}
                  alt="verify email address"
                  className="verify-img"
                />
              </div>
              <Modal
                size={"sm"}
                modalTitle={translated("app_copy.confirm_your_email")}
                show={isModal}
                handleClose={() => setIsModal(false)}
              >
                <div className="mb-3 row justify-content-center custom-mb-30">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    autoComplete="off"
                    name="email"
                    value={email}
                    onChange={(e) =>
                      setEmail(dataProcess("lowercased", e.target.value))
                    }
                    placeholder={translated("app_copy.email_address")}
                    className="form-control placeholder"
                  />
                </div>
                <div className="width-180 custom-mt-20 custom-mb-30">
                  <button
                    className="custom-btn btn-standard-background button-small"
                    onClick={() => resentEmail()}
                  >
                    <p className="btn-text background-color">{translated("cta.resend")}</p>
                  </button>
                </div>
              </Modal>
            </PerfectScrollbar>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default EmailVerify;
