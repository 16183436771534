/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";

const SelectedDropdown: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  config: any;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[]
  ) => void;
}> = ({ attribute, oldSelection,config, submitData }) => {
  const [updateValue, setUpdateValue] = useState<string>("");
  const options = config?.options;
  const optionClicked = (newupdateValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        var newArray = dataProcess(element, newupdateValue).trimStart();
        setUpdateValue(newArray);
      });
    } else {
      setUpdateValue(newupdateValue);
    }
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setUpdateValue(oldSelection);
    }
  }, [oldSelection]);

  useEffect(() => {
    if (updateValue.length > 0) {
      submitData(
        inputValidation(attribute?.validation, updateValue[0]),
        attribute.key,
        updateValue
      );
    } else {
      submitData(false, attribute.key, updateValue);
    }
  }, [updateValue]);
  return (
    <div className="form-group">
      <label className="">{attribute.display_text} </label>
      <br />
      <select
        className="form-select"
        onChange={(e) => optionClicked(e.target.value)}
        value={updateValue}
      >
        {updateValue.length === 0 && (
          <option id="dropDown" value=""> {attribute.placeholder_text} </option>
        )}
        {options.length > 0 &&
          options.map((option: any, index: number) => (
            <option
              key={index.toString()}
              value={option.key}
            >
              {option.display_text}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectedDropdown;
