/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ShimmerTitle,
  ShimmerText,
  ShimmerButton,
} from "../../packages/Shimmer/index";

const SchedulingShimmer: React.FC = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="blue-small-bold-text text-center custom-mb-10">
        <br />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={10} variant="primary" />
        <ShimmerTitle line={1} gap={10} variant="primary" />
      </div>
      <div className="mb-3 text-center width-236">
        <ShimmerButton size="sm" />
      </div>
    </div>
  );
};

export default SchedulingShimmer;
