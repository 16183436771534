// import Spiner from "@/components/common/Spiner";
import { ChatBot, DialogflowCX, DialogflowMS } from "@/components/index";
import routes from "@/config/routes";
import Pages from "@/layouts/Pages";
import React from "react";
import { useNavigate } from "react-router-dom";

// import { useSearchParams } from "react-router-dom";
const Chat: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Pages>
      <div className="chat-wrapper">
        <div className="azflow-chatbot">
          <div className={`chatbot-btn`}>
            <button
              className="small-btn"
              onClick={() => {
                navigate(routes?.azureChat);
              }}
            >
              AZ
              {/* {isES ? "ES" : "CX"} */}
              {/* <img className="cursor-pointer" src={chat} alt="Expand Arrow" /> */}
            </button>
          </div>
        </div>
        <DialogflowMS />
        <DialogflowCX />
        <ChatBot />
      </div>
    </Pages>);
};

export default Chat;
