/* eslint-disable react-hooks/exhaustive-deps */
// import Spiner from "@/components/common/Spiner";
import scheduleChatPNG from "../../assets/img/calendar.png";
import { AppContext } from "@/context/createContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ResultStatusList } from "@/config/configarableVariable";
import ReactWebChat, { createStore, createDirectLine } from 'botframework-webchat';

import { removeOrderBookingField } from "../../services/firebase/fireStore"
import { Dispatch } from "redux";
const AzureChatComponent: React.FC<{
  token: string;
}> = ({ token }) => {
  const [blink, setBlink] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const uuid = localStorage.getItem("uid")
  const order_id = localStorage.getItem("order_id")
  const directLine = useMemo(() => createDirectLine({ token }), [token]);
  // console.log({ uuid, order_id })
  const { userInfo, showAZChatBot, setShowAZChatBot } = useContext(AppContext);
  // let countNumber = 1;

  const closeChat = () => {
    if (userInfo?.user_data?.mode === "ai_chat" && userInfo?.orders[0]?.status !== ResultStatusList?.resultsAvailable) {
      setShowAZChatBot(false);
    } else {

      setShowAZChatBot(false);
      // setChatOpen(false)
    }
  };

  useEffect(() => {
    if (showAZChatBot && chatOpen === false) {
      setChatOpen(true)
    }
  }, [showAZChatBot]);

  useEffect(() => {
    if (userInfo?.orders && userInfo?.orders && userInfo?.orders.length > 0 && userInfo?.orders[0]?.booking_intent !== undefined && userInfo?.user_data?.mode === 'ai_chat') {
      setBlink(true)
      setTimeout(() => {
        setBlink(false)
        removeOrderBookingField(userInfo?.authUId + "/orders/" + userInfo?.orders[0]?.order_id)
      }, 20000);
    }
  }, [userInfo]);

  const store = useMemo(
    () =>
      createStore({}, ({ dispatch }: { dispatch: Dispatch }) => (next: any) => (action: any) => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            type: "DIRECT_LINE/POST_ACTIVITY",
            meta: {
              method: "keyboard",
            },
            payload: {
              activity: {
                type: "invoke",
                name: "InitConversation",
                value: {
                  // must use for authenticated conversation.
                  jsonWebToken: token,

                  // Use the following activity to proactively invoke a bot scenario
                  triggeredScenario: {
                    trigger: /stage/.test(window.location.href) ? "conv_info_load_stg" : "conv_info_load",
                    args: {
                      fb_uid: uuid,
                      aws_order_id: order_id
                    },
                  },
                },
              },
            },
          });
        } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
          if (
            action.payload &&
            action.payload.activity &&
            action.payload.activity.type === "message"
          ) {
            const event = new Event('webchatincomingactivity');
            // @ts-ignore    
            event.data = action.payload.activity;
            window.dispatchEvent(event);
          }
        }
        return next(action);
      }),
    []
  );


  return (
    userInfo &&
    <section className="chat-wrapper">
      <div
        className={
          "azure-bot bot-component" + (showAZChatBot ? " bot-show" : "")
        }
      >
        <div
          className="chatbot-chat-container"
          style={{
            display: showAZChatBot === false ? "none" : "block",
          }}
        >
          <div className="chat-header">
            <p className="text-14 bold color-white"></p>
            <div className="d-flex justify-content-between">
              <span
                className="text-14 bold color-white cursor-pointer"
                onClick={() => closeChat()}
              >
                {userInfo?.user_data?.mode === "ai_chat" && userInfo?.orders && userInfo?.orders[0]?.status !== ResultStatusList?.resultsAvailable ? (
                  <div className={blink ? "zoom-in-out-box" : ""}>
                    <img src={scheduleChatPNG} className={blink ? "common-shimmer" : ""} alt="logo" />
                  </div>
                ) : (
                  "X"
                )}
              </span>
            </div>
          </div>
          <div className="chat-body">
            {directLine !== null ? (
              <ReactWebChat className={`web-chat`}
                directLine={directLine} store={store}
                styleOptions={{
                  botAvatarImage: "https://firebasestorage.googleapis.com/v0/b/claire-dev-87971.appspot.com/o/white-label%2F2000-0000-0000-1111%2FClaireBot.png?alt=media&token=7e330d26-40b0-4c0c-9437-4d026bc336a4",
                  hideSendBox: false,
                  botAvatarInitials: "Claire",
                  userAvatarInitials: userInfo?.user_data?.first_name ? userInfo?.user_data?.first_name.length <= 4 ? userInfo?.user_data?.first_name : userInfo?.user_data?.first_name.slice(0, 1) + userInfo?.user_data?.last_name.slice(0, 1) : "You",
                  backgroundColor: "#F8F8F8",
                }}
              />
            ) : (
              <div className={`connect-spinner`}>
                <div className="content">
                  <div className="icon">
                    <span className="ms-Icon ms-Icon--Robot" />
                  </div>
                  <p>Please wait while we are connecting.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AzureChatComponent;
