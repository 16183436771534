/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import {
  CameraProps,
  FacingMode,
  Stream,
  SetStream,
  SetNumberOfCameras,
  SetNotSupported,
  SetPermissionDenied,
} from "@/interface/index";
// import { Container, Wrapper, Canvas, Cam, ErrorMsg } from './styles';
// eslint-disable-next-line new-parens
export const Camera = React.forwardRef<unknown, CameraProps>(
  (
    {
      facingMode = "user",
      aspectRatio = "cover",
      numberOfCamerasCallback = (v: any) => null,
      videoSourceDeviceId = undefined,
      errorMessages = {
        noCameraAccessible:
          "No camera device accessible. Please connect your camera or try a different browser.",
        permissionDenied:
          "Permission denied. Please refresh and give camera permission.",
        switchCamera:
          "It is not possible to switch camera to different one because there is only one video device accessible.",
        canvas: "Canvas is not supported.",
      },
    }: any,
    ref: any
  ) => {
    const player = useRef<HTMLVideoElement>(null);
    const canvas = useRef<HTMLCanvasElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const [numberOfCameras, setNumberOfCameras] = useState<number>(0);
    const [stream, setStream] = useState<Stream>(null);
    const [currentFacingMode, setFacingMode] = useState<FacingMode>(facingMode);
    const [notSupported, setNotSupported] = useState<boolean>(false);
    const [permissionDenied, setPermissionDenied] = useState<boolean>(false);

    useEffect(() => {
      numberOfCamerasCallback(numberOfCameras);
    }, [numberOfCameras]);

    useImperativeHandle(ref, () => ({
      takePhoto: () => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        }

        if (canvas?.current) {
          const playerWidth = player?.current?.videoWidth || 1280;
          const playerHeight = player?.current?.videoHeight || 720;
          const playerAR = playerWidth / playerHeight;

          const canvasWidth = container?.current?.offsetWidth || 1280;
          const canvasHeight = container?.current?.offsetHeight || 1280;
          const canvasAR = canvasWidth / canvasHeight;

          let sX, sY, sW, sH;

          if (playerAR > canvasAR) {
            sH = playerHeight;
            sW = playerHeight * canvasAR;
            sX = (playerWidth - sW) / 2;
            sY = 0;
          } else {
            sW = playerWidth;
            sH = playerWidth / canvasAR;
            sX = 0;
            sY = (playerHeight - sH) / 2;
          }

          canvas.current.width = sW;
          canvas.current.height = sH;

          const context = canvas.current.getContext("2d");
          if (context && player?.current) {
            context.drawImage(player.current, sX, sY, sW, sH, 0, 0, sW, sH);
          }

          const imgData = canvas.current.toDataURL("image/jpeg");
          return imgData;
        } else {
          throw new Error(errorMessages.canvas);
        }
      },
      switchCamera: () => {
        if (numberOfCameras < 1) {
          throw new Error(errorMessages.noCameraAccessible);
        } else if (numberOfCameras < 2) {
          console.error(
            "Error: Unable to switch camera. Only one device is accessible."
          ); // console only
        }
        const newFacingMode =
          currentFacingMode === "user" ? "environment" : "user";
        setFacingMode(newFacingMode);
        return newFacingMode;
      },
      getNumberOfCameras: () => {
        return numberOfCameras;
      },
    }));

    useEffect(() => {
      initCameraStream(
        stream,
        setStream,
        currentFacingMode,
        videoSourceDeviceId,
        setNumberOfCameras,
        setNotSupported,
        setPermissionDenied
      );
    }, [currentFacingMode, videoSourceDeviceId]);

    useEffect(() => {
      if (stream && player && player.current) {
        player.current.srcObject = stream;
      }
      return () => {
        if (stream) {
          stream.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      };
    }, [stream]);

    const style = {
      containerCover: {
        bottom: "0",
        top: "0",
        left: "0",
        right: "0",
      },
      container: {
        paddingBottom: `${100 / Number(aspectRatio)}%`,
      },
    };

    return (
      <div
        ref={container}
        style={
          aspectRatio === "cover"
            ? { ...style.containerCover, position: "absolute" }
            : { ...style.container, position: "relative" }
        }
      >
        <div className="cam-wrapper">
          {notSupported ? (
            <p className="cam-error">{errorMessages.noCameraAccessible}</p>
          ) : null}
          {permissionDenied ? (
            <p className="cam-error">{errorMessages.permissionDenied}</p>
          ) : null}
          <video
            className={currentFacingMode === "user" ? "cam180" : "cam"}
            ref={player}
            id="video"
            muted={true}
            autoPlay={true}
            playsInline={true}
            style={
              currentFacingMode === "user"
                ? { transform: "rotateY(180deg)" }
                : { transform: "rotateY(0deg)" }
            }
          ></video>
          <canvas className="canvas" ref={canvas} />
        </div>
      </div>
    );
  }
);

Camera.displayName = "Camera";

const initCameraStream = async (
  stream: Stream,
  setStream: SetStream,
  currentFacingMode: FacingMode,
  videoSourceDeviceId: string | undefined,
  setNumberOfCameras: SetNumberOfCameras,
  setNotSupported: SetNotSupported,
  setPermissionDenied: SetPermissionDenied
) => {
  // stop any active streams in the window

  let newNavigator: any = window.navigator;
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  const constraints = {
    audio: false,
    video: {
      deviceId: videoSourceDeviceId
        ? { exact: videoSourceDeviceId }
        : undefined,
      facingMode: currentFacingMode,
      width: { ideal: 1920 },
      height: { ideal: 1920 },
    },
  };

  if (newNavigator?.mediaDevices?.getUserMedia) {
    newNavigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: any) => {
        setStream(handleSuccess(stream, setNumberOfCameras));
      })
      .catch((err: any) => {
        handleError(err, setNotSupported, setPermissionDenied);
      });
  } else {
    const getWebcam =
      newNavigator.getUserMedia ||
      newNavigator.webkitGetUserMedia ||
      newNavigator.mozGetUserMedia ||
      newNavigator.mozGetUserMedia ||
      newNavigator.msGetUserMedia;
    if (getWebcam) {
      getWebcam(
        constraints,
        (stream: any) => {
          setStream(handleSuccess(stream, setNumberOfCameras));
        },
        (err: any) => {
          handleError(err as Error, setNotSupported, setPermissionDenied);
        }
      );
    } else {
      setNotSupported(true);
    }
  }
};

const handleSuccess = (
  stream: MediaStream,
  setNumberOfCameras: SetNumberOfCameras
) => {
  let newNavigator: any = window.navigator;

  newNavigator.mediaDevices
    .enumerateDevices()
    .then((r: any) =>
      setNumberOfCameras(r.filter((i: any) => i.kind === "videoinput").length)
    );

  return stream;
};

const handleError = (
  error: Error,
  setNotSupported: SetNotSupported,
  setPermissionDenied: SetPermissionDenied
) => {
  console.error(error);

  //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
  if (error.name === "PermissionDeniedError") {
    setPermissionDenied(true);
  } else {
    setNotSupported(true);
  }
};
