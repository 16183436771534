/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { 
  // useContext,
  useEffect } from "react";
import Pages from "@/layouts/Pages";
import {
  // Spiner,
  SearchLocationInput,
} from "@/components/index";
// import { AppContext } from "@/context/createContext";
import { useNavigate } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { backArrow } from "@/config/svg";
import routes from "@/config/routes";

const AddressValidation: React.FC = () => {
  // const {
  //   // mainLoader,
  //   // remainingLoaderTime,
  //   // translated,
  // } = useContext(AppContext);
  let navigate = useNavigate();

  useEffect(() => {
    document.body.className = "essential-area ";

    return () => {
      document.body.className = "";
    };
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  // const nextStep = () => {
  //   if (stepNumber === 1) {
  //     setStepNumber(2);
  //   } else if (stepNumber === 2) {
  //     setStepNumber(3);
  //   } else if (stepNumber === 3) {
  //     setStepNumber(4);
  //   }
  // };

  // const confirmDisable = () => {
  //   return !allAddress.length;
  // };

  const continueNext = () => {
    navigate(routes.root)
  }
  return (
    <Pages>
      <section className="h-100 h-custom">
        <div className="row h-100">
          <div className="col-6 kyc-title custom-pl-100 h-100 ">
            <div className="width-445">
              <img
                className="cursor-pointer float-start pr-5 back-btn cart"
                src={backArrow}
                width="70"
                height="70"
                alt="back"
                onClick={handleBack}
              />{" "}
            </div>

            <div className="payment width-445">
              <p className="blue-small-bold-text flow-subtitle custom-mb-10">
                Let’s check!
              </p>
              <h1 className="flow-title  address-txt">
                Is your address correct?
              </h1>

            </div>
          </div>
          <div className="col-lg-6 cart-sum">
            <div className="payment-container">
              <SearchLocationInput
                isSave={continueNext}
                product={null}
              />
            </div>
          </div>
        </div>
      </section >
    </Pages >
  );
};

export default AddressValidation;
