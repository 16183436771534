/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { StripePaymentForm, AppleGooglePay } from "@/components/index";
const Payment: React.FC<{
  selectedPayment: string;
  product: any;
  order?: any;
  handleBack: () => void;
  submitedOrder: (paymentInfo: any) => void;
}> = ({ submitedOrder, order, selectedPayment, product, handleBack }) => {
  return (
    <>
      {selectedPayment === "Apple pay" && (
        <AppleGooglePay
          orderSubmit={(payment) => submitedOrder(payment)}
          orderData={order}
          selectedPayment={selectedPayment}
        />
      )}
      {selectedPayment === "Google pay" && (
        <AppleGooglePay
          orderSubmit={(payment) => submitedOrder(payment)}
          orderData={order}
          selectedPayment={selectedPayment}
        />
      )}
      {selectedPayment === "Stripe" && (
        <StripePaymentForm
          orderSubmit={(payment) => submitedOrder(payment)}
          handleBack={handleBack}
          orderData={order}
        />
      )}
    </>
  );
};

export default Payment;
