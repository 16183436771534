/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ShimmerTitle, ShimmerText, ShimmerButton } from "../../packages/Shimmer/index";

const OrderItemShimmer: React.FC = () => {

  const itemArray = [1, 2, 3]
  return (
    <>
      {
        itemArray?.map((item: any, index: number) => (
          <div className="order-item p-1" key={index.toString()}>
            <div className="order-item-body">
              <ShimmerTitle line={1} gap={15} variant="primary" />

              <div className="d-flex justify-content-between position-relative">
                <div className="orders-wrapper " style={{ width: '120px' }}>
                  <ShimmerText line={2} gap={10} className="orders-wrapper " />
                </div>
                <div className="orders-wrapper " style={{ width: '120px' }}>
                  <ShimmerText line={2} gap={10} className="orders-wrapper " />
                </div>
                <div className="orders-wrapper " style={{ width: '120px' }}>
                  <ShimmerText line={2} gap={10} className="orders-wrapper " />
                </div>
              </div>
              <div className="d-flex justify-content-between position-relative">
                <div className="mb-3  width-180">
                  <ShimmerButton size="sm" />
                </div>
                <div className="mb-3  width-180">
                  <ShimmerButton size="sm" />
                </div>
                <div className="mb-3  width-180">
                  <ShimmerButton size="sm" />
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </>
  );
};

export default OrderItemShimmer;
