/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth as firbaseAuth } from "@/services/firebase";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "@/context/createContext";
import { emailValidations } from "@/helper/common";
import { ForgotPasswordShimmer } from "@/components/shimmer";
const ForgotPassword: React.FC = () => {
  const [canSubmit, setCanSubmit] = useState(true);
  const [email, setEmail] = useState("");
  const { translated, state } = useContext(AppContext);
  const configState = state?.config_state
  const [isValidate, setIsValidate] = useState(false);
  const [isShimmer, setIsShimmer] = useState(true);

  const handleAction = () => {
    setIsShimmer(true);
    if (email.length === 0) {
      setCanSubmit(false);
      setIsShimmer(false);
      toast.error("Email is not empty!");
    } else if (canSubmit) {
      sendPasswordResetEmail(firbaseAuth, email)
        .then(() => {
          // setEmailSent(true);
          setIsShimmer(false);
          toast.success(
            translated(
              "app_copy.password_reset_link_has_been_sent_to_your_email"
            )
          );
        })
        .catch((error) => {
          setIsShimmer(false);
          if (error.code === "auth/user-not-found") {
            toast.error(translated("error.user_not_exist_signup"));
          }
          if (error.code === "auth/invalid-email") {
            toast.error(translated("error.invalid_email"));
          }
        });
    }
  };
  useEffect(() => {
    if (email.length > 0) {
      setIsValidate(
        emailValidations(email, configState?.email_rules?.regex_validation)
      );
    } else {
      setIsValidate(false);
    }
  }, [email]);

  useEffect(() => {
    document.body.className = "checkout-area welcome";
    setTimeout(() => {
      setIsShimmer(false);
    }, 1000);
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <Pages>
      <section className="forgot-password-section">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            <p className="text-18 bold color-blue custom-mb-20">
              {translated("app_copy.recover_your_account")}
            </p>
            <h2 className="text-36 custom-mb-25">
              What is your email address?
            </h2>
            <p className="text-16">
              {translated("app_copy.we_help_you_recover_your_acc")}
            </p>
          </div>
          <div className="half-col right-col">
            <div className="custom-ml-80">
              {isShimmer ? (
                <ForgotPasswordShimmer />
              ) : (
                <>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      autoFocus
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="emailAddress"
                      name="emailAddress"
                    />
                    <label htmlFor="emailAddress">Email address</label>
                  </div>
                  <button
                    disabled={!isValidate}
                    className="standard-btn"
                    onClick={handleAction}
                  >
                    <p className="text-16 bold color-blue">
                      {translated("cta.next")}
                    </p>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default ForgotPassword;
