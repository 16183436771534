/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";

const GenderInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  isShimmer?: boolean;
  autoFocus?: boolean;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, isShimmer, oldSelection, autoFocus, index, submitData }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    <>
      <input
        type="text"
        id={"gender-input-" + index}
        autoComplete="off"
        readOnly={
          attribute?.enabled !== undefined && attribute?.enabled === false
        }
        autoFocus={autoFocus}
        value={inputValue === "0" ? "Male" : "Female"}
        className={isShimmer ? "common-shimmer" : ""}
      />
      <label htmlFor={"gender-input-" + index}>
        {attribute.placeholder_text}
      </label>
    </>
  );
};

export default GenderInput;
