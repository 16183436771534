const DialogflowMS = () => {
  return (
    <div className="dflow-ms">
      <df-messenger
        df-cx="true"
        location="us-central1"
        chat-title="Claire"
        agent-id="bb84a4ef-b57e-42d4-9768-6603161545a9"
        language-code="en"
      ></df-messenger>
    </div>
  );
};

export default DialogflowMS;
