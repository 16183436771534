/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { AppContext } from "@/context/createContext";
import moment from "moment";
// import AzureChatComponent from "@/components/chat/AzureChat";

export default function Footer() {
  const { translated } = useContext(AppContext);

  return (
    <>
      <div className="footer-container">
        <div>

          {/* <AzureChatComponent /> */}
        </div>
        <div className="separator"></div>
        <footer>
          <div className="footer-item color-grey">
            <span>
              © {moment().format("YYYY")} &nbsp;
              {translated("app_copy.claire_rights_reserved")}
            </span>
          </div>
          <div className="footer-item">
            <a className="bold">
              {translated("app_copy.privacy")}
            </a>
          </div>
          {/* <div className="footer-item">
            <a href="#" className="bold">
              {translated("app_copy.legal")}
            </a>
          </div> */}
          {/* <div
          className="claire-chat"
          id="claire_chat"
          onClick={() => triggerEvent()}
        >
          <img src={chat} alt="chat" />
        </div> */}
        </footer>
      </div>
    </>
  );
}
