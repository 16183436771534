/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
const DateMonthInput: React.FC<{
  oldSelection: any;
  autoFocus?: boolean;
  placeholderText?: string;
  label?: string;
  submitData: (value: string, isContinue: boolean) => void;
}> = ({
  oldSelection,
  autoFocus,
  submitData,
  placeholderText = "YYYY-MM",
  label,
}) => {
  const [inputValue, setInputValue] = useState<any>(null);
  useEffect(() => {
    if (oldSelection !== "") {
      const date = oldSelection.split("-");
      if (date[1] !== undefined && date[1].length === 1)
        setInputValue(date[0] + "-0" + date[1]);
      else setInputValue(oldSelection);
    }
  }, [oldSelection]);

  const handleOnChange = (value: any) => {
    var date = value.split("-");
    if (date.length === 0 || date.length < 2) {
      setInputValue(null);
      toast.error("Date format not valid!");
      submitData(value, false);
    } else {
      setInputValue(date[0] + "-" + date[1]);
      submitData(value, moment(value + "-01", "YYYY-MM-DD", true).isValid());
    }
  };
  return (
    <div className="input-wrapper">
      <input
        autoComplete="off"
        autoFocus={autoFocus}
        value={inputValue}
        onChange={(e) => handleOnChange(e.target.value)}
        placeholder={placeholderText}
        className="form-control"
        min={moment().format("YYYY-MM")}
        type="month"
      />
      <label htmlFor="firstName">{label} </label>
    </div>
  );
};

export default DateMonthInput;
