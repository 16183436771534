import React from "react";
import Pages from "@/layouts/Pages";

const PdfPage: React.FC = () => {
  return (
    <Pages>
      <section className="h-100 h-custom login">
        <div className="py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12">
              <div

                className="row justify-content-center align-items-center"
              >
                <div className="col-12">

                  <object style={{ height: '627px', width: '100%' }} data="http://localhost:3000/144980.pdf" type="application/pdf">
                    <div>No online PDF viewer installed</div>
                  </object>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default PdfPage;
