import React from "react";
const ProgressBar: React.FC<{ totalStep: number; progress: number }> = ({
  totalStep,
  progress,
}) => {
  return (
    <div className="progress-bar-wrapper" style={{ height: "6px" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: 100 / (totalStep / progress) + "%",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
