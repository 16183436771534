/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ShimmerTitle,
  ShimmerText,
  ShimmerButton,
} from "../../packages/Shimmer/index";

const SearchLocationShimmer: React.FC = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="blue-small-bold-text text-center custom-mb-10">
        <ShimmerTitle line={1} gap={10} variant="primary" />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <br />
      </div>
      <div className="mb-3  text-center width-180">
        <ShimmerButton size="md" />
      </div>
    </div>
  );
};

export default SearchLocationShimmer;
