/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { chat } from "@/config/svg";

async function loginUser(credentials: any) {
  //this api url is fixed and hard coded for now
  try {
    return fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBeiyPfdlFGk_w-qMxKAd2EKBdunOorg1A",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      }
    ).then((data) => data.json());
  } catch (error) {
    return error
  }
}

async function conversations() {
  const credentials = {
    model_id: "V4pCQJvMTXkrvUYmdvKgtX", // mode id is fixed and hard coded for now in dev and stage
  };

  return await fetch("https://aidojo-a.liveperson.net/api/v1/conversations", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(credentials),
  }).then((response) => response.json());
}

const ChatBot: React.FC<{}> = () => {
  const [messages, setMessages]: any = useState([]);
  const [usermessage, setUsermessage] = useState("");
  const [isChatActive, setIsChatActive] = useState<boolean>(false);

  const [isBotTyping, setIsBotTyping] = useState<boolean>(false);

  const chatConversation = useRef<HTMLDivElement>(null);

  const initChatBot = async () => {
    //for API auth, email and password are fixed for demo
    const response = await loginUser({
      email: "bshapiro+aidojo@liveperson.com",
      password: "8C&a&H1Z9655",
      returnSecureToken: true,
    });

    if ("idToken" in response) {
      localStorage.setItem("accessToken", response["idToken"]);
      localStorage.setItem("user", JSON.stringify(response["displayName"]));
      localStorage.setItem(
        "tokenTimeOut",
        String(Math.floor(Date.now() / 1000) + Number(response.expiresIn))
      );

      const conversation = await conversations();

      setMessages((current: any) => [
        ...current,
        ["bot", conversation.messages[0].text],
      ]);

      localStorage.setItem("conv_id", conversation.id);
      localStorage.setItem("model_id", conversation.model_id);
    } else {
      console.log("initChatBot Failed", response.message);
    }
  };

  const refreshToken = async () => {
    const response = await loginUser({
      email: "bshapiro+aidojo@liveperson.com",
      password: "8C&a&H1Z9655",
      returnSecureToken: true,
    });

    if ("idToken" in response) {
      localStorage.setItem("accessToken", response["idToken"]);
      localStorage.setItem("user", JSON.stringify(response["displayName"]));
      localStorage.setItem(
        "tokenTimeOut",
        String(Math.floor(Date.now() / 1000) + Number(response.expiresIn))
      );
    }
  };

  const handleBotSubmit = async () => {
    setMessages((current: any) => [...current, ["user", usermessage]]);
    const inputMessage = usermessage;
    setUsermessage("");

    const credentials = {
      text: inputMessage,
      conv_id: localStorage.getItem("conv_id"),
      prompt_messages_count: 10,
    };

    setIsBotTyping(true);

    const tokenTime = Number(localStorage.getItem("tokenTimeOut"));

    if (tokenTime < Math.floor(Date.now() / 1000)) {
      await refreshToken();
      const botresponse = await fetch(
        "https://aidojo-a.liveperson.net/api/v1/models/generic_simple/" +
        localStorage.getItem("model_id"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify(credentials),
        }
      )
        .then((response) => response.json())
        .catch((err: any) => {
          console.log("err", err);
        });

      setIsBotTyping(false);

      setMessages((current: any) => [...current, ["bot", botresponse.text]]);
    } else {
      const botresponse = await fetch(
        "https://aidojo-a.liveperson.net/api/v1/models/generic_simple/" +
        localStorage.getItem("model_id"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify(credentials),
        }
      )
        .then((response) => response.json())
        .catch((err: any) => {
          console.log("err", err);
        });
      setIsBotTyping(false);

      setMessages((current: any) => [...current, ["bot", botresponse.text]]);
    }
  };

  const activateChat = () => {
    setIsChatActive(true);
    if (messages.length === 0) {
      initChatBot();
    }
  };

  const closeChat = () => {
    setIsChatActive(false);
    setMessages([]);
  };

  const minimizeChat = () => {
    setIsChatActive(false);
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleBotSubmit();
    }
  };

  useEffect(() => {
    if (chatConversation.current !== null) {
      const callback = function (mutationsList: any) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            (chatConversation.current as HTMLDivElement).scrollTop = (
              chatConversation.current as HTMLDivElement
            ).scrollHeight;
          }
        }
      };

      const observer = new MutationObserver(callback);

      const config = { childList: true };
      observer.observe(chatConversation.current, config);
    }
  }, [chatConversation.current]);

  return (
    <>
      <div className="chatbot">
        <div className={`chatbot-btn ${isChatActive ? "hidden" : ""}`}>
          <button
            className="small-btn"
            onClick={() => {
              activateChat();
            }}
          >
            <img className="cursor-pointer" src={chat} alt="Expand Arrow" />
          </button>
        </div>
        {isChatActive && (
          <div className="chatbot-chat-container">
            <div className="chat-header">
              <p className="text-14 bold color-white">Kai+</p>
              <div className="d-flex justify-content-between">
                <span
                  className="text-14 bold color-white cursor-pointer"
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginRight: "16px",
                  }}
                  onClick={() => minimizeChat()}
                >
                  -
                </span>
                <span
                  className="text-14 bold color-white cursor-pointer"
                  onClick={() => closeChat()}
                >
                  X
                </span>
              </div>
            </div>
            <div className="chat-body">
              <div className="chat-conversation" ref={chatConversation}>
                {messages.map((item: any, index: any) => {
                  return (
                    <div key={index} className={"chat-message-item " + item[0]}>
                      <div className="chat-text">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item[1],
                          }}
                        ></p>
                      </div>
                    </div>
                  );
                })}
                {isBotTyping && (
                  <div className="chat-message-item bot-typing">
                    <div className="chat-text">
                      <span className="typing-dots"></span>
                      <span className="typing-dots"></span>
                      <span className="typing-dots"></span>
                    </div>
                  </div>
                )}
              </div>
              <div className="chat-input">
                <input
                  type="text"
                  placeholder="Type your message here"
                  name="chat-input"
                  className="text-14 color-black"
                  value={usermessage}
                  onChange={(e) => setUsermessage(e.target.value)}
                  onKeyDown={handleOnKeyDown}
                />
                <button
                  className="text-14 color-black"
                  onClick={() => handleBotSubmit()}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBot;
