import { Outlet } from "react-router-dom";
import AzureChatComponent from "./chat/AzureChat";
import { Components } from 'botframework-webchat-component';
import { useCallback, useEffect, useMemo, useState } from "react";
import { getLabCorpEndPoint } from "@/services/api/fireFunction";
import { createDirectLine } from "botframework-webchat";
const MasterContent: React.FC = () => {
  const [token, setToken] = useState('');

  const handleFetchToken = useCallback(async () => {
    if (!token) {
      const res = await fetch('https://healthcare-bot-l2kst5sf2gc3w.azurewebsites.net/chatBot', {
        method: 'POST'
      });
      const jsonWebToken = await res.text();
      const tokenPayload = JSON.parse(atob(jsonWebToken.split(".")[1]));
      const user = {
        id: tokenPayload.userId,
        name: tokenPayload.userName,
        locale: tokenPayload.locale,
      };
      let domain = undefined;
      if (tokenPayload.directLineURI) {
        domain = "https://" + tokenPayload.directLineURI + "/v3/directline";
      }
      setToken(tokenPayload.connectorToken);
      localStorage.setItem('azToken', tokenPayload.connectorToken)
      // console.log('token', tokenPayload.connectorToken)
      // updateAzToken(tokenPayload.connectorToken)
    }
  }, [setToken, token]);

  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  const handleMessage = (event: any) => {
    console.log(`Received an activity of type "${event.type}":`);
    console.log(event);

    // TODO - replace with api to log webchat messages
    getLabCorpEndPoint()
      .then((labCorpEndPoint) => {
        if (labCorpEndPoint?.data?.url) {
          console.log('labCorpEndPoint=' + labCorpEndPoint.data.url);
        } else {
          console.log('labCorpEndPoint not found ');
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }
  useEffect(() => {
    handleFetchToken();
    //@ts-ignore
    window.addEventListener('webchatincomingactivity', handleMessage);
    return () => window.removeEventListener('webchatincomingactivity', handleMessage);
  }, [handleFetchToken]);
  return (
    <div className="master-content">
      {!!directLine ? (
        <Components.Composer directLine={directLine}>
          <Outlet />
        </Components.Composer>
      ) : <Outlet />}
      <AzureChatComponent token={token} />
    </div>
  );
};

export default MasterContent;
