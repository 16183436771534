import axios from 'axios'
import { auth } from '../firebase/index'
import { APIConfig, DefaultConfig } from '@/config/configarableVariable';
// import { storageDownloadURL } from '../firebase/fireStorage';


const APIVersion = "/api/v1"
const baseURL = window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL + APIVersion
// const token = await auth.currentUser?.getIdToken()
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.code === 'ERR_NETWORK')
        Promise.reject({ message: 'Network connection error!' });

    return Promise.reject(error);
});

export const commonSentOTP = async (payload: any) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.commonSentOTP}`, payload, {
                headers: {
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    }
}

export const commonResendOTP = async (payload: any) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.commonResendOTP}`, payload, {
                headers: {
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    }
}

export const commonVerifyOTP = async (payload: any) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.commonVerifyOTP}`, payload, {
                headers: {
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error) {
        console.error('Failed to load API!', error)
        return error
    }
}

export const stripePaymentIntent = async (payload: any, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken()
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .post(`${baseURL}/${APIConfig?.stripePaymentIntent}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        /* if(error?.code === 'ERR_NETWORK')
        throw "Network connection error!"; */
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to load API!', error)
        return error
    }
}


export const stripePaymentIntentUpdate = async (payload: any, payment_intent_id: string, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken()
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .patch(`${baseURL}/${APIConfig?.stripePaymentIntent}/${payment_intent_id}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        /* if(error?.code === 'ERR_NETWORK')
        throw "Network connection error!"; */
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to load API!', error)
        return error
    }
}

export const createStripeCustomer = async (payload: any, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .post(`${baseURL}/${APIConfig?.createStripeCustomer}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to load API!', error)
        return error
    }
}

export const stripeSaveCardList = async (user_id: string, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .get(`${baseURL}/${APIConfig?.stripeSaveCard}/list?userId=${user_id}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        // console.error('Failed to load API!', error);
        if (error?.code === 'ERR_NETWORK')
            throw error;
        // throw "Network connection error!";
        return error
    }
}

export const stripeSaveCardDelete = async (card_id: string, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .delete(`${baseURL}/${APIConfig?.stripeSaveCard}/${card_id}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to load API!', error)
        return error
    }
}

export const stripeSaveCardUpdate = async (payload: any, card_id: string, access_token: string = '') => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
        console.error('No base url specified for firebase functions')
    }

    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const uuid = auth.currentUser?.uid
        const response = await axios
            .patch(`${baseURL}/${APIConfig?.stripeSaveCard}/${card_id}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to load API!', error)
        return error
    }
}

export const getScheduleList = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.getScheduleList}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        console.error('timekit API failed!', error)
        if (error?.code === 'ERR_NETWORK')
            throw error;
        return error
    }
}

export const createBookingSlot = async (payload: any, access_token: string = '') => {
    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const uuid = auth.currentUser?.uid;
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.createBookingSlot}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('API request failed! ', error)
        return error?.response
    }
}

export const addFCMToken = async (payload: any, access_token: string = '') => {
    try {

        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        // console.log({token});
        const orgCode = localStorage.getItem("orgCode");
        const url = `${baseURL}/${APIConfig?.fcmToken}`;
        // console.log({url});
        // console.log({payload});

        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('API request failed! ', error)
        return error
    }
}
export const deleteFCMToken = async (payload: any, access_token: string = '') => {
    try {

        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .delete(`${baseURL}/${APIConfig?.fcmToken}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
                , data: payload
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('API request failed! ', error)
        return error
    }
}
export const tokenize = async (payload: any, access_token: string = '') => {
    try {

        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const url = `${baseURL}/${APIConfig?.tokenize}`;

        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        return error?.response;
    }
}
export const unTokenize = async (payload: any, access_token: string = '') => {
    try {

        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const url = `${baseURL}/${APIConfig?.unTokenize}`;

        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        return error?.response;
    }
}

export const createDynamicLink = async (payload: any, access_token: string = '') => {
    try {
        const token = Boolean(access_token) ? access_token : await auth.currentUser?.getIdToken();
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.createDynamicLink}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('API request failed! ', error)
        return error?.response
    }
}

export const addressList = async () => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .get(`${baseURL}/${APIConfig?.address}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const insertAddress = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .put(`${baseURL}/${APIConfig?.address}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const updateAddress = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .patch(`${baseURL}/${APIConfig?.address}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const validateZipCode = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .post(`${baseURL}/${APIConfig?.address}/validate`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const sentVarificationEmail = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        // const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.sentVarificationEmail}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    // 'x-user-id': uuid ?? "",
                    // 'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const getUser = async () => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .get(`${baseURL}/${APIConfig?.loginUser}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const resultScore = async () => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .get(`${baseURL}/${APIConfig?.resultScore}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const insertOrder = async (payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .put(`${baseURL}/${APIConfig?.order}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to save order data!', error)
        return error
    }
}

export const getOrder = async (order_id: string) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .get(`${baseURL}/${APIConfig?.order}/${order_id}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to save order data!', error)
        return error
    }
}

export const getServerTimestamp = async () => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .get(`${baseURL}/${APIConfig?.order}/timestamp`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const getResultJson = async (order_id: string) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .get(`${baseURL}/${APIConfig?.order}/${order_id}/result`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const submitOrderFeedBack = async (orderId: string, payload: any) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.order}/${orderId}/csat`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const getLabCorpEndPoint = async () => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .get(`${baseURL}/${APIConfig?.getLabCorpEndPoint}`, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const verifyDoctorUser = async (email: string, password: string) => {
    try {
        const response = { "status": 200, isVerified: true, email, password }
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const verifyUserLocation = async (url: string) => {
    try {
        const response = await axios
            .get(`${url}`)
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const updateOrderData = async (orderId: string, payload: any) => {
   
    //Check if order has fulfilment
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");

        const response = await axios
            .put(`${baseURL}/${APIConfig?.order}/${orderId}`, payload, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const preFetchData = async (org_code: string | null) => {
    try {
        const orgCode = org_code ?? localStorage.getItem("orgCode") !== undefined ? localStorage.getItem("orgCode") : DefaultConfig?.orgCode;
        // const orgCode = "1000-0000-0000-0000";
        const response = await axios
            .get(`${baseURL}/prefetch`, {
                headers: {
                    'x-org-code': orgCode ?? "",
                }
            })
        return response?.data
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}


export const dialogFlowAUthToken = async () => {
    try {
        const response = await axios
            .get(`${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/v1/auth/oauth-token`)
        return response?.data
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to connect API!', error)
        return error
    }
}

export const getFireStoreData = async (path: string) => {
    try {
        const response = await axios
            .get(`${path}`)
        return response?.data
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to fetch Data!', error)
        return error
    }
}

export const getInitUserData = async (user_id: string) => {
    try {
        const response = await axios
            .post(`${baseURL}/${APIConfig?.getInitUserData}`, { user_id: user_id })
        return response?.data
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to call init API!', error)
        return error
    }
}

export const getBiomarkerFaq = async (biomarkerId: string, biomarkerName: string, biomarkerStatus: string) => {
    try {
        const token = await auth.currentUser?.getIdToken()
        const uuid = auth.currentUser?.uid
        const orgCode = localStorage.getItem("orgCode");
        const response = await axios
            .post(`${baseURL}/${APIConfig?.getBiomarkerFaq}`, {
                biomarkerId: biomarkerId,
                biomarkerName: biomarkerName,
                biomarkerStatus: biomarkerStatus
            }, {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'x-user-id': uuid ?? "",
                    'x-token': token ?? "",
                    'x-org-code': orgCode ?? "",
                }
            })
        return response?.data
    } catch (error: any) {
        if (error?.code === 'ERR_NETWORK')
            throw error;
        console.error('Failed to call init API!', error)
        return error
    }
}