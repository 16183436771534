/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
import { getScheduleList } from "@/services/api/fireFunction";
import { AppContext } from "@/context/createContext";
import moment from "moment";
import DaySlot from "../schedule_session/DaySlot";
import LeftArrow from "../svg/LeftArrow";
import RightArrow from "../svg/RightArrow";
import { SchedulingShimmer } from "../shimmer";
const PrevIcon = (props: any) => <LeftArrow {...props} />;
const NextIcon = (props: any) => <RightArrow {...props} />;

const AppointmentSelection: React.FC<{
  attribute: AttributeType;
  config: any;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[]
  ) => void;
}> = ({ attribute, submitData }) => {
  const {
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const [updateValue, setUpdateValue] = useState<any>(null);
  const [timeSlotList, setTimeSlotList] = useState<any>(null);
  const [currentDateIndex, setCurrentDateIndex] = useState<any>(null);

  useEffect(() => {
    if (updateValue !== null) {
      submitData(
        inputValidation(attribute?.validation, updateValue),
        attribute.key,
        updateValue
      );
    } else {
      submitData(false, attribute.key, "");
    }
  }, [updateValue]);


  useEffect(() => {
    if (userInfo?.orders && userInfo?.orders.length > 0) {
      setRootLoader();
      let address = {
        state: userInfo?.orders[0].address?.state,
        street: userInfo?.orders[0].address?.street,
        city: userInfo?.orders[0].address?.city,
        zip_code: userInfo?.orders[0].address?.postal_code,
      };

      if (userInfo?.is_mock === true) {
        address = {
          state: "AZ", //orderItem?.address?.state,
          street: "8907 Legacy Park Dr", //orderItem?.address?.street,
          city: "Pheonix", //orderItem?.address?.city,
          zip_code: "85003", //orderItem?.address?.postal_code,
        };
      }
      const payload = {
        type: "GETLABS",
        output_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        from: moment().format("YYYY-MM-DD"),
        days: "5",
        address: address,
      };
      getScheduleList(payload)
        .then((scheduleList: any) => {
          unSetRootLoader();
          if (scheduleList?.data?.data.length > 0) {
            setTimeSlotList(scheduleList?.data);
            setCurrentDateIndex(0);
          } else {
            setTimeSlotList(null);
            setCurrentDateIndex(0);
          }
        })
        .catch((err) => {
          if (err?.code === "ERR_NETWORK")
            showToastMessage(
              translated("error.network_connection_error"),
              true
            );
          unSetRootLoader();
        });
    }
  }, [userInfo]);

  return (
    mainLoader || remainingLoaderTime ? (
      <SchedulingShimmer />
    ) :
      timeSlotList &&
        timeSlotList?.data?.length > 0 ? (
        <div className="scheduling-slots-wrapper">
          {/* <p className="text-center">
            {translated("app_copy.your_date")}
          </p> */}
          <div className="scheduling-slots-header">
            <button
              disabled={currentDateIndex === 0}
              onClick={() =>
                setCurrentDateIndex(currentDateIndex - 1)
              }
              className="small-btn"
            >
              <PrevIcon />
            </button>
            <h5 className="slot-date text-25 bold">
              {moment(
                timeSlotList?.data[currentDateIndex]?.date
              ).format("LL")}
            </h5>
            <button
              disabled={
                currentDateIndex + 1 === timeSlotList?.data?.length
              }
              onClick={() =>
                setCurrentDateIndex(currentDateIndex + 1)
              }
              className="small-btn"
            >
              <NextIcon />
            </button>
          </div>
          <DaySlot
            timeSlot={timeSlotList?.data[currentDateIndex]}
            selectSlot={(selectSlot: any) => {
              setUpdateValue(selectSlot);
            }}
          />
        </div>
      ) : userInfo?.orders && (
        <h2 className="h-100">
          {translated("app_copy.no_slot_available")}
        </h2>
      )
  )
};

export default AppointmentSelection;
