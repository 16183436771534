/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { useNavigate, useSearchParams } from "react-router-dom";
import { commonSentOTP } from "@/services/api/fireFunction";
import { Spiner, VerifyOTP, ProgressBar } from "@/components/index";
import { useContext, useEffect, useState } from "react";
import {
  validateEmail,
  validatePhone,
  dataProcess,
  validateDate,
  validatePassword,
} from "@/helper/common";
import { AppContext } from "@/context/createContext";
import DatePicker from "@/components/input_type/DatePicker";
import moment from "moment";
import routes from "@/config/routes";
const Signup: React.FC = () => {
  const navigate = useNavigate();
  const {
    mainLoader,
    remainingLoaderTime,
    showToastMessage,
    setRootLoader,
    unSetRootLoader,
    translated,
  } = useContext(AppContext);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [isSMSSent, setIsSMSSent] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [gender, setGender] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.body.className = "signup-page";

    return () => {
      document.body.className = "";
    };
  }, []);

  const handleAction = async () => {
    //setCanSubmit(true);
    //console.log('signup call', auth?.currentUser)
    if (phone.length === 0) {
      showToastMessage("Phone number is required!", true);
    } else if (phone.length > 0 && !validatePhone(phone)) {
      showToastMessage("Invalid phone format!", true);
    } else if (email.length === 0) {
      showToastMessage("Email number is required!", true);
    } else if (!validateEmail(email)) {
      showToastMessage("Invalid Email!", true);
    } else if (password.length === 0) {
      showToastMessage("Password number is required!", true);
    } else if (!validatePassword(password)) {
      showToastMessage("Password should be at least 6 characters", true);
    } else if (password !== cPassword) {
      showToastMessage("Confirm password not match!", true);
    } else if (dob.length === 0) {
      showToastMessage("DOB is required!", true);
    } else if (!validateDate(dob)) {
      showToastMessage("Invalid DOB!", true);
    } else {
      setRootLoader();

      var OTPPayload = {
        email,
        phone: isSMSSent ? phone : "",
      };

      commonSentOTP(OTPPayload)
        .then((response: any) => {
          if (response?.data?.success) {
            showToastMessage("OTP sent successfully!");
            setVerifyOtp(true);
            unSetRootLoader();
            navigate(routes.signup + "?metadata=verify_otp");
          }
          if (response?.data?.error) {
            setVerifyOtp(false);
            unSetRootLoader();
            showToastMessage(response?.data?.error?.message, true);
          }
        })
        .catch((error) => {
          //console.log("error", error);
          unSetRootLoader();

          showToastMessage("Something went wrong", true);
        });
    }
  };

  return (
    <Pages>
      <section className="h-100">
        <div className="row h-100">
          <div className="col-5 kyc-title custom-ml-100">
            <div className="cnt-bar custom-mb-20">
              <ProgressBar totalStep={4} progress={1} />
              <div>
                <p className="blue-small-bold-text">
                  {translated("app_copy.let_s_get_started")}
                </p>
              </div>
            </div>
            <h1 className="kyc-flow">Let’s start from the basics.</h1>
            <div className="subtitle-sign">
              <p className="black-basic-text">
                Give us this information to make it more personal.
              </p>
            </div>
          </div>
          <div className="col-5 d-flex justify-content-center align-items-end h-100">
            <div className="row">
              {mainLoader || remainingLoaderTime ? (
                <Spiner
                  className="signup-loder"
                  height="100px"
                  width="100px"
                  margin="auto"
                />
              ) : verifyOtp &&
                String(searchParams.get("metadata")) === "verify_otp" ? (
                <VerifyOTP
                  signupData={{
                    email,
                    phone,
                    password,
                    gender,
                    firstName,
                    lastName,
                    dob,
                    isSMSSent,
                  }}
                />
              ) : (
                <>
                  <div className="row scrollcontainer">
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <input
                        type="text"
                        autoFocus
                        autoComplete="off"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Your name"
                        className="form-control placeholder"
                      />
                    </div>
                    <div className="mb-3 row  justify-content-center custom-mb-30">
                      <input
                        type="text"
                        autoComplete="off"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Enter your last name"
                        className="form-control placeholder"
                      />
                    </div>

                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <input
                        type="email"
                        autoComplete="off"
                        name="email"
                        value={email}
                        onChange={(e) =>
                          setEmail(dataProcess("lowercased", e.target.value))
                        }
                        placeholder={translated("email_address")}
                        className="form-control placeholder"
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <input
                        type="phone"
                        autoComplete="off"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Your phone number"
                        className="form-control placeholder"
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <input
                        type="password"
                        autoComplete="off"
                        value={password}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter password"
                        className="form-control placeholder"
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <input
                        type="password"
                        autoComplete="off"
                        value={cPassword}
                        name="confirm_password"
                        onChange={(e) => setCPassword(e.target.value)}
                        placeholder="Enter Confirm password"
                        className="form-control placeholder"
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <DatePicker
                        oldSelection={dob}
                        dateFormat="YYYY-MM-DD"
                        submitData={(value) => {
                          setDob(moment(value).format("YYYY-MM-DD"));
                        }}
                        showMonthYearPicker={false}
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <textarea
                        autoComplete="off"
                        className="form-control placeholder"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <div className="col-sm-2 flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={gender === 0}
                          onChange={() => setGender(0)}
                        />
                        <label className="form-check-label">
                          {" "}
                          {translated("male")}
                        </label>
                      </div>
                      <div className="col-sm-2 flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={gender === 2}
                          onChange={() => setGender(2)}
                        />
                        <label className="form-check-label">
                          {" "}
                          {translated("female")}
                        </label>
                      </div>
                      <div className="col-sm-2 flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={gender === 1}
                          onChange={() => setGender(1)}
                        />
                        <label className="form-check-label"> Others</label>
                      </div>
                    </div>
                    <div className="mb-3 row justify-content-center custom-mb-30">
                      <div className="col-sm-3"></div>
                      <div className="col-sm-9">
                        <label className="form-check-label mx-3">
                          <input
                            type="checkbox"
                            checked={isSMSSent}
                            onChange={() => setIsSMSSent(!isSMSSent)}
                            className="form-check-input"
                          />{" "}
                          Verify OTP using phone number?
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row custom-mb-54 custom-mt-54 justify-content-center align-items-end">
                    <div className="col-12 text-center">
                      <label className="custom-mb-30 black-basic-text">
                        Already have an account?{" "}
                        <span
                          onClick={() => navigate(routes.login)}
                          className="cursor-pointer blue-small-bold-text"
                        >
                          Login here.
                        </span>
                      </label>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="custom-btn btn-standard-background button-small"
                        onClick={handleAction}
                        id="button-next"
                      >
                        <p className="btn-text background-color">
                          {translated("cta.next")}
                        </p>
                      </button>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        className="custom-btn btn-empty-background custom-mt-20"
                        disabled={true}
                      >
                        <p className="btn-text grey-color tiny-txt">
                          {translated("cta.skip")}
                        </p>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default Signup;
