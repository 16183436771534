/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import welcomeMemberImage from "../../assets/img/welcome-member-image.png";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { AppContext } from "@/context/createContext";
const WelcomeMember: React.FC = () => {
  const { userInfo, translated } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.className = "checkout-area welcome";

    return () => {
      document.body.className = "";
    };
  }, []);

  const handleContinue = () => {
    navigate(routes.addressValidation);
  };
  return (
    <Pages>
      <section className="h-100 h-custom schedule-container">
        <div className="row h-100">
          <div className="row align-items-center scrollcontainer1">
            <PerfectScrollbar>
              <div className="col-6 kyc-title custom-pl-100 h-100">
                <div className="container-title">
                  <div className="cnt-bar custom-mb-20">
                    <p className="blue-small-bold-text">Welcome {userInfo?.user_data?.first_name}!</p>
                  </div>
                  <h1 className="kyc-flow">{translated("app_copy.you_r_member_of_claire")}</h1>
                  <h2 className="kyc-flow custom-mt-40">
                    {translated("app_copy.let_s_move_forward_and_collect_additional_info")}
                  </h2>

                  <div className="mb-3 text-center confirm-schedule width-236">
                    <button
                      className="custom-btn btn-standard-gradient"
                      onClick={handleContinue}
                    >
                      <p className="btn-text bold small-text rainbow_purple-blue">
                        {translated('cta.lets_go_for_it')}
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6 h-100 dates-box position-relative">
                <img src={welcomeMemberImage} alt="Welcome member" className="welcome-img" />
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default WelcomeMember;
