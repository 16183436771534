/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import { CartItem, BroserInfoProps } from "../interface";

//Check the new inserted cart item already exist in the cart
export function checkCartItem(arr: any, newItem: CartItem): number {
  const objIndex = arr.findIndex(
    (el: { product_id: number; variant_id: number }) =>
      el.product_id === newItem.product_id &&
      el.variant_id === newItem.variant_id
  );
  if (objIndex !== -1) {
    return arr[objIndex].id;
  } else {
    return 0;
  }
}

export function base64Encode(value: string) {
  return btoa(value);
}

//Return product src url depend on the selection variant
export function prodcutImageURL(imagesArray: any, variant_pid: number): string {
  var src = imagesArray[0].src;
  imagesArray.map((item: any) => {
    if (item.product_id === variant_pid) {
      src = item.src;
    }
  });
  return src;
}

//Return product variant item depend on the selection variant
export function checkVariantItem(variants: any, selectVariant: number): any {
  if (variants) {
    let objIndex: number = variants.findIndex(
      (el: any) => el.product_id === selectVariant
    );
    if (objIndex !== -1) {
      return variants[objIndex];
    } else {
      return false;
    }
  } else {
    return false;
  }
}

//Convert any number upto 2 digit floating point
export function parseFloat2digit(number: number): number {
  return Number(parseFloat(String(number)).toFixed(2));
}

//Calculate total summation of an object of Array
export function totalOfArrayObject(
  array: Array<any>,
  objectName: string
): number {
  return array.reduce((accumulator, object) => {
    return accumulator + object[objectName];
  }, 0);
}

//Find an item from an array, matching the object
export function findItemFromArray(
  arr: any,
  objectName: string,
  objectValue: number | string
): any {
  return arr.find((item: any) => item[objectName] === objectValue);
}

//Find an item from an array, matching the object
export function itemAvailableInArray(
  arr: any,
  objectName: string,
  objectValue: number | string
): boolean {
  return arr.find((item: any) => item[objectName] === objectValue)
    ? true
    : false;
}

export function arrayToObject(array: any) {
  return array.reduce(function (map: any, obj: any) {
    if (obj.value && obj.key) {
      map[obj.key] = obj.value.toString();
      return map;
    }
  }, {});
}

export function findDataFromObjectArray(array: any, variable: string) {
  let value: any = null;
  array.map((data: any) => {
    if (data.key === variable) {
      value = data.value;
    }
    return value;
  });
}

//Find an item from an array, matching the object
export function isLogin(auth_userId: string): boolean {
  const authToken = localStorage.getItem("authToken");
  const isOtpVerify = localStorage.getItem("isOtpVerify");
  const uid = localStorage.getItem("uid");
  if (authToken && isOtpVerify === "true" && auth_userId === uid) {
    return true;
  } else {
    return false;
  }
}

//custom date format
export function DateToIsoString(date: Date) {
  var tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    ".000" +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    pad(Math.abs(tzo) % 60)
  );
}

export function appointmentTimeCalculation(
  bookingTime: any,
  currentTimeStamp: any
) {
  let currentDate: any = new Date(currentTimeStamp),
    bookingDate: any = new Date(bookingTime);
  const timeDiff = bookingDate - currentDate;
  // console.log('currentDate', currentDate)
  // console.log('currentTimeStamp', currentTimeStamp)

  return Math.floor(timeDiff / 60e3) / 1440; // 24 or 1 day calculation
}

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export function validateEmail(mail: string) {
  return /^\w+([\.-]?[\w+]?)+([\.+]?[\w+]?)*@\w+([\.-]?\w+)*(\.\w{2,64})+$/.test(
    mail
  );
  //return /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9]+.[A-Za-z]{2,64}$/.test(mail)
}
export function validatePhone(phone: string) {
  return /^\++[0-9+]{5,15}/.test(phone);
}
export function validateDate(date: string) {
  return /^\d{4}(-|\/)(0?[1-9]|1[0-2])(-|\/)([0-2]?[1-9]|3[0-1])$/.test(date);
}
export function validatePassword(password: string) {
  return /^.{6,10}$/.test(password);
}

export function inputValidation(
  validationType: any,
  inputValue: any,
  compareValue: any = null
): boolean {
  let status = true;
  //const inputDateTime = Date.parse(inputValue as string) / 1000
  const currentDateTime = Date.now() / 1000;
  //console.log('currentDateTime', currentDateTime, inputDateTime, Number(inputValue))
  for (var key in validationType) {
    switch (key) {
      case "is_required":
        if (inputValue.length === 0 || inputValue === false) status = false;
        break;
      case "length":
        if (inputValue.length !== parseInt(validationType[key])) status = false;
        break;
      case "max_length":
        if (inputValue.length > parseInt(validationType[key])) status = false;
        break;
      case "min_length":
        if (inputValue.length < parseInt(validationType[key])) status = false;
        break;
      case "minimum_date":
        if (Number(inputValue) < validationType[key]) status = false;
        break;
      case "maximum_date":
        if (Number(inputValue) > validationType[key]) status = false;
        break;
      case "maximum_date_since_today":
        if (currentDateTime + validationType[key] < Number(inputValue))
          status = false;
        break;
      case "regex":
        const regex = new RegExp(validationType[key]);
        if (typeof inputValue === "string" && !regex.test(inputValue))
          status = false;
        break;
      case "compare_attribute":
        if (compareValue && inputValue !== compareValue) status = false;
        break;
      // case "user_validate":
      //   if (compareValue.length === 0 || inputValue.length === 0) {
      //     status = false;
      //   }
      //   if ((compareValue && inputValue) && inputValue.toLowerCase() !== compareValue.toLowerCase()) {
      //     status = false;
      //   }
      //   break;
      default:
        break;
    }
  }
  return status;
}

export function stringCapitalize(str: string): string {
  const arr = str.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

export function emailValidations(
  email: string,
  regex: RegExp | string
): boolean {
  const newregex = new RegExp(regex);
  return newregex.test(email);
}

export function passwordValidations(
  password: string,
  regex: RegExp | string
): boolean {
  const newregex = new RegExp(regex);
  return newregex.test(password);
}

export function regexValidation(
  value: string,
  regex: RegExp | string
): boolean {
  const newRegex = new RegExp(regex);
  return newRegex.test(value);
}

export function dataProcess(formatType: string, inputValue: string): string {
  let returnValue = "";
  switch (formatType) {
    case "capitalized":
      returnValue = stringCapitalize(inputValue);
      break;
    case "lowercased":
      returnValue = inputValue.toLowerCase();
      break;
    case "uppercased":
      returnValue = inputValue.toUpperCase();
      break;
    default:
  }
  return returnValue;
}

export const browserInfo = (): BroserInfoProps => {
  let nVer = navigator.userAgent ?? "";
  let nAgt = navigator.userAgent ?? "";
  let browserName = navigator.appName;
  let fullVersion = "" + parseFloat(navigator.appVersion ?? "");
  let majorVersion = parseInt(navigator.appVersion ?? "", 10);
  let nameOffset, verOffset, ix;

  // In Opera 15+, the true version is after "OPR/"
  if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 4);
  }
  // In older Opera, the true version is after "Opera" or after "Version"
  else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(nAgt);
    majorVersion = parseInt(nAgt, 10);
  }

  let OSName = "Unknown OS";
  let OSNameVersion = "";
  let osMatch = nAgt.match(/\((.*?)\)/g);

  const OsnameArray = osMatch?.map((text) => {
    const OS = { os: "", version: "" };
    let res = text.replace(/\(|\)/g, "");
    if (res.indexOf("Win") !== -1) OS.os = "Windows";
    if (res.indexOf("Mac") !== -1) OS.os = "MacOS";
    if (res.indexOf("X11") !== -1) OS.os = "UNIX";
    if (res.indexOf("Linux") !== -1) OS.os = "Linux";
    if (OS.os) {
      OS.version = res.substring(res.indexOf(" "), res.length).trim();
    }
    return OS;
  });
  const OsContent = OsnameArray?.filter((os) => os.os);
  // console.log({OsContent});

  if (OsContent && OsContent?.length > 0 && OsContent[0].os) {
    OSName = OsContent[0].os;
    OSNameVersion = OsContent[0].version;
  }

  const browserInfo = {
    browserName,
    fullVersion,
    majorVersion: "" + majorVersion,
    appVersion: nVer,
    userAgent: nAgt,
    OSName,
    OSNameVersion,
  };

  // get broser name

  return browserInfo;
};

export function getOrderAllStatus(orderStatus: any): any {
  if (orderStatus === null) return null;
  const orderAllStatus: any = {
    pending: "Order Available",
    payment_success: "Payment Success",
    // payment_fail: "Payment Failed",
    lab_order_create_success: "Ready to Test",
    ph_visit_scheduled_success: "Ready to Test",
    lab_results_available: "Results Ready",
    results_available: "Results Ready",
    ph_visit_success: "Ready to Test",
    // ph_visit_started: "Phlebotomist on the way",
    in_progress: "Phlebotomist on the way",
    completed: "Waiting for results",
  };

  return orderAllStatus[orderStatus] !== undefined
    ? orderAllStatus[orderStatus]
    : orderStatus;
}

export function fixScrollBarVisibility(): void {
  setTimeout(() => {
    const scrollBars = document.querySelectorAll(".scrollbar-container");

    const scrollBar: HTMLButtonElement | any =
      scrollBars.length > 0 ? scrollBars[0] : null;

    if (scrollBar) {
      scrollBar.scrollTop = 1;
      scrollBar.scrollTop = 0;
    }
  }, 400);
}
