/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
// import Pages from "@/layouts/Pages";
import { Spiner } from "@/components/index";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { auth as firbaseAuth } from "@/services/firebase";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { AppContext } from "@/context/createContext";
import { password1, hidePassword } from "@/config/svg";
import { passwordValidations } from "@/helper/common";

const ResetPassword: React.FC<{ actionCode: string }> = ({ actionCode }) => {
  const [password, setPassword] = useState("");
  const {
    mainLoader,
    state,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const configState = state?.config_state
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);

  const handleReset = () => {
    if (password.length === 0) {
      showToastMessage("Password not empty!", true);
    } else if (confirmPassword.length === 0) {
      showToastMessage("Confirm password not empty!", true);
    } else if (confirmPassword !== password) {
      showToastMessage("Confirm password & password mismatch!", true);
    } else {
      setRootLoader();
      verifyPasswordResetCode(firbaseAuth, actionCode)
        .then((email) => {
          //console.log("email", email);
          confirmPasswordReset(firbaseAuth, actionCode, password)
            .then(() => {
              unSetRootLoader();
              showToastMessage("Password reset sucessfully!");
              setTimeout(() => {
                navigate(routes.passwordResetSuccess);
              }, 1000);
            })
            .catch((error) => {
              unSetRootLoader();
              //console.log("error", error);
              if (error.code === "auth/expired-action-code") {
                showToastMessage("Password reset code expired!", true);
              }
              if (error.code === "auth/invalid-action-code") {
                showToastMessage("Invalid password reset URL!", true);
              }
              if (error.code === "auth/user-not-found") {
                showToastMessage("User not exist! Please signup", true);
              }
              if (error.code === "auth/weak-password") {
                showToastMessage(
                  "Password should be at least 6 characters!",
                  true
                );
              }
            });
        })
        .catch((error) => {
          unSetRootLoader();
          console.log("error", error);
          if (error.code === "auth/expired-action-code") {
            showToastMessage("Password reset code expired!", true);
          }
          if (error.code === "auth/invalid-action-code") {
            showToastMessage("Invalid password reset URL!", true);
          }
          if (error.code === "auth/user-not-found") {
            showToastMessage("User not exist! Please signup", true);
          }
          if (error.code === "auth/weak-password") {
            showToastMessage("Password should be at least 6 characters!", true);
          }
        });
    }
  };
  // This variable determines whether password is shown or not
  const [isShown, setIsSHown] = useState(false);
  const [isShownRe, setIsSHownRe] = useState(false);

  // This function is called when the checkbox is checked or unchecked
  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };
  const togglePasswordre = () => {
    setIsSHownRe((isShownRe) => !isShownRe);
  };

  useEffect(() => {
    if (password.length > 0) {
      setIsPasswordValidate(
        passwordValidations(
          password,
          configState?.password_rules?.regex_validation
        )
      );
    } else {
      setIsPasswordValidate(false);
    }
  }, [password]);
  return (
    <>
      <section className="reset-password-section">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            <p className="text-18 bold color-blue custom-mb-20">
              {translated("app_copy.recover_your_account")}
            </p>
            <h2 className="text-36 custom-mb-25">
              {translated("app_copy.let_set_a_new_password")}
            </h2>
            <p className="text-16">
              {translated("app_copy.give_us_this_info_to_make_more_personal")}
            </p>
          </div>
          <div className="half-col right-col">
            <div className="custom-ml-80">
              {mainLoader || remainingLoaderTime ? (
                <Spiner height="100px" width="100px" margin="auto" />
              ) : (
                <>
                  <div
                    className={
                      mainLoader || remainingLoaderTime
                        ? "common-shimmer input-wrapper"
                        : "input-wrapper"
                    }
                  >
                    <input
                      type={isShown ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label
                      htmlFor="newPassword"
                      className={
                        password !== ""
                          ? "input-placeholder"
                          : "input-placeholder hide"
                      }
                    >
                      {translated("app_copy.enter_new_password")}
                    </label>
                    <span className="show-hide-psw" onClick={togglePassword}>
                      <img
                        src={isShown ? hidePassword : password1}
                        alt="hide"
                      ></img>
                    </span>
                  </div>

                  <div
                    className={
                      mainLoader || remainingLoaderTime
                        ? "common-shimmer input-wrapper"
                        : "input-wrapper"
                    }
                  >
                    <input
                      type={isShownRe ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      autoComplete="off"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <label
                      htmlFor="newPassword"
                      className={
                        confirmPassword !== ""
                          ? "input-placeholder"
                          : "input-placeholder hide"
                      }
                    >
                      Enter confirm password
                    </label>
                    <span className="show-hide-psw" onClick={togglePasswordre}>
                      <img
                        src={isShownRe ? hidePassword : password1}
                        alt="hide"
                      ></img>
                    </span>
                  </div>
                  <div className="text-center">
                    <button
                      disabled={
                        !isPasswordValidate || password !== confirmPassword
                      }
                      className="standard-btn"
                      onClick={handleReset}
                    >
                      <p className="text-16 bold color-blue">
                        {translated("cta.reset")}
                      </p>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
