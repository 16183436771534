/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import Footer from "./Footer";
import { ProviderProps } from "../interface/type";
import { AppContext } from "@/context/createContext";
import { useContext, useEffect, useState } from "react";
import { Spiner } from "../components";
import { isLogin } from "@/helper/common";

const Pages = ({ children }: ProviderProps) => {
  const { userInfo, state, logoutUser } = useContext(AppContext);
  const [loader, setLoader] = useState<boolean>(false);
  let timer = setInterval(() => {
    let uid = localStorage.getItem("uid") as string;

    // console.log('2', uid)
    if ((uid === null || uid === undefined || uid === "") && loader && isLogin(userInfo?.authUId ?? "") === true) {
      localStorage.clear();
      logoutUser();
    }
  }, 15000);

  useEffect(() => {
    if (userInfo?.authUId && (isLogin(userInfo?.authUId ?? "") === true)) {

      setLoader(true);
      let uid = localStorage.getItem("uid") as string;
      // console.log('1', userInfo?.authUId)
      if (userInfo?.authUId && uid) {
        setLoader(false);
        clearTimeout(timer);
      }
    }
  }, [userInfo]);

  return (
    <>
      {(loader && state?.ui_loaded === false) ? (
        <section className="h-custom category">
          <div className="container py-3 vh-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="d-flex align-items-center">
                <Spiner height="100px" width="100px" margin="auto" />
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          {/* DO NOT ADD OTHER COMPONENTS HERE */}
          <Header />
          <div className="page-content">{children}</div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Pages;
