import React from "react";
const Spiner: React.FC<{
  height: string;
  width: string;
  margin: string;
  className?: string;
}> = ({ height, width, margin, className = "" }) => {
  return (
    <div
      className="spinner-container"
      style={{
        margin: margin,
      }}
    >
      <div className="spinner"></div>
    </div>
  );
};

export default Spiner;
