/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { OrderDetails, OrderItem, Spiner } from "@/components/index";
import { OrderItemShimmer } from "@/components/shimmer/index";

import PerfectScrollbar from "react-perfect-scrollbar";
import { isMobile } from "react-device-detect";

import routes from "@/config/routes";
import { useNavigate, useParams } from "react-router-dom";
import OrderPDF from "@/components/order/OrderPDF";
import { backArrow } from "@/config/svg";
import Pages from "@/layouts/Pages";
import { base64Encode, isLogin } from "@/helper/common";
import { FALSE } from "sass";
const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { userInfo, modules } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [showPDF, setShowPDF] = useState<any>(null);

  const [allOrder, setAllOrder] = useState<any>([]);
  useEffect(() => {
    if (userInfo?.orders && (userInfo?.orders.length > 1 || modules?.is_login_flow === false)) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      if (params?.order) {
        const pdfOrder = userInfo?.orders.filter(
          (order: any) => order.order_id === params?.order
        );
        if (pdfOrder.length > 0) setShowPDF(pdfOrder[0]);
        else setShowPDF(null);
      } else {
        setShowPDF(null);
      }
      const sorter = (a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      };
      setAllOrder(userInfo?.orders?.sort(sorter));
    } else if (
      userInfo?.orders &&
      userInfo?.orders.length === 1 && modules?.is_login_flow === true
    ) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
      navigate(
        "/order-details/" +
        base64Encode(userInfo?.orders[0]?.order_id)
      );
    }
  }, [userInfo, userInfo?.orders, params]);
  useEffect(() => {
    document.body.className = "user-area";
    setTimeout(() => {
      if (userInfo?.authUId && isLogin(userInfo?.authUId) === false) {
        navigate(routes.login);
      }
      if (userInfo?.authUId === null) {
        navigate(routes.login);
      }
    }, 2000);
    return () => {
      document.body.className = "";
    };
  }, []);

  const orderItems = () => {
    return (
      <div className="multiple-order-items">
        {loader ? (
          userInfo?.authUId && isLogin(userInfo?.authUId) ? (
            <OrderItemShimmer />
          ) : (
            <Spiner height="100px" width="100px" margin="auto" />
          )
        ) : (
          userInfo?.authUId &&
          isLogin(userInfo?.authUId) &&
          orderDetails === null &&
          showPDF === null &&
          allOrder.length > 0 &&
          allOrder.map((order: any, index: number) => (
            <OrderItem
              order={order}
              key={index.toString()}
              showDetails={() => {
                navigate("/order-details/" + base64Encode(order?.order_id));
              }}
            />
          ))
        )}

        {orderDetails && (
          <OrderDetails
            order={orderDetails}
            showDetails={() => {
              setOrderDetails(null);
            }}
          />
        )}
      </div>
    );
  };

  return (
    <Pages>
      <section className="pending-order-list">
        <div className="row align-items-center scrollcontainer1 custom-pb-16">
          {showPDF ? (
            <OrderPDF
              order={showPDF}
              showPDF={() => {
                navigate(routes.root);
                setShowPDF(null);
              }}
            />
          ) : (
            <>
              <div className="d-flex justify-content-start">
                {showPDF && (
                  <img
                    onClick={() => {
                      navigate(routes.orderList);
                      // setShowPDF(null);
                    }}
                    className="forward-icon"
                    src={backArrow}
                    width="45"
                    height="45"
                    alt="Next"
                  />
                )}
              </div>
              <br />
              {((!loader &&
                orderDetails === null &&
                showPDF === null &&
                userInfo?.orders &&
                userInfo?.orders.length > 1) || (userInfo?.orders && modules?.is_login_flow === false)) && (
                  <>
                    <p className="text-16 bold color-blue custom-mb-15">
                      Hi {userInfo?.user_data?.first_name},{" "}
                    </p>
                    <h1 className="text-36">Your Orders </h1>
                  </>
                )}
              {!isMobile ? (
                <PerfectScrollbar>{orderItems()}</PerfectScrollbar>
              ) : (
                orderItems()
              )}
            </>
          )}
        </div>
      </section>
    </Pages>
  );
};

export default OrderList;
