/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useReducer, useState } from "react";
import { AppContext } from "@/context/createContext";
import { AppState } from "@/interface/index";
import {
  appReducer,
  modulesReducer,
  stepDataReducer,
  userReducer,
  whitLabelReducer,
} from "@/context/reducer/appReducer";
import { ProviderProps } from "@/interface/type";
import { auth, messaging } from "@/services/firebase";
import { onValue } from "firebase/database";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { fireStore } from "@/services/firebase/index";
import { onSnapshot, doc, collection } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "../../translations/config";
import * as RTdb from "@/services/firebase/realtimeDB";
import { Spiner } from "@/components/index";
import parse from "html-react-parser";
import { RTDB, reducerType, RTDBStepModules, DefaultConfig, firestore } from "@/config/configarableVariable";
import { getResultJson, preFetchData } from "@/services/api/fireFunction";
import routes from "@/config/routes";
import { useNavigate } from "react-router-dom";
import { authentication } from "@/services/firebase/authentication";
import { getFireStoreDoc } from "@/services/firebase/fireStore";
// import stepJsonData from "../../step.json";
const INITIAL_STATE: AppState = {
  credit_card_mock: false,
  ui_loaded: false,
  stepModules: [],
  modules: {
    onboarding: false,
    consent: false,
    payment: false,
    scheduling: false,
    is_login_flow: false,
    is_bot_flow: false,
  },
  config_state: {
    live_engage: null,
    places_key: null,
    stripe: null,
    timekit: null,
  },
};
const INITIAL_USER_STATE = {
  authUId: null,
  authToken: null,
  user_data: null,
  is_mock: false,
  address: null,
  device_location: null,
  cart: null,
  orders: null,
  shcedule_list: null,
  non_essential: null,
  notification_preferences: null,
};

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  // const user = getAuth().currentUser
  const [user, setUser] = useState(() => {
    const user = getAuth().currentUser;
    return user;
  });

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
    });
  }, [])

  const styleDoc = document.documentElement;
  const [org_code, setOrgCode] = useState<string | null>(DefaultConfig?.orgCode);

  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);

  const [userInfo, setUserInfo] = useReducer(userReducer, INITIAL_USER_STATE);
  const [whiteLabeling, setWhiteLabeling] = useReducer(whitLabelReducer, {
    logo: null,
    mobile_logo: null
  });

  const [modules, setModules] = useReducer(modulesReducer, {
    onboarding: false,
    consent: false,
    payment: false,
    scheduling: false,
    is_login_flow: false,
    is_bot_flow: false,
  });

  const [stepData, setStepData] = useReducer(stepDataReducer, {
    allStep: null,
    authentication: null,
    onboarding: null,
    essential: null,
    nonEssentialStepModules: null,
    consent: null,
    feedback: null,
  });
  const [stepModules, setStepModules] = useState<any>({
    onboarding_questionnaire: null,
  });
  const [showAZChatBot, setShowAZChatBot] = useState<boolean>(false);
  const [essentialStepModules, setEStepModules] = useState<any>(null);
  const [nonEssentialStepModules, setNonEStepModules] = useState<any>(null);
  const [consentStepModules, setCStepModules] = useState<any>(null);
  const [fedbackStepModules, setFeedbackStepModules] = useState<any>(null);
  const [changePwdStepModules, setCPWDStepModules] = useState<any>(null);
  const [userInfoUpdateStepModules, setUserInfoUpdateStepModules] =
    useState<any>(null);
  const uid = localStorage.getItem("uid") as string;
  const [settingsState, setSettingsState] = useState<any>({
    app_config: null,
  });
  const [boimarkerContent, setBoimarkerContent] = useState<any>(null);
  let messagetimeout: any = false;
  // firebase message part
  const [isTokenFound, setTokenFound] = useState(false);
  const [isMessageTokenStable, setMessageTokenStable] = useState(false);
  const [messageToken, setMessageToken] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [remainingLoaderTime, setRemainingLoaderTime] = useState(false);
  const [isLanguageLoaded, setisLanguageLoaded] = useState(false);
  const [resultData, setResultData] = useState<any>(null);
  let isContinueData = new Map()
  useEffect(() => {
    styleDoc.style.setProperty("--light-background", DefaultConfig?.lightBackground);
    styleDoc.style.setProperty("--blue-gradient", DefaultConfig?.blueGradient);
  }, []);
  useEffect(() => {
    // console.log('auth', user)
    if (user === null) {
      setUserInfo({
        type: reducerType?.USER_UPDATED,
        payload: {
          authUId: null,
          authToken: null,
        },
      });
    }
    if (user?.uid) {
      if (uid !== undefined && user?.uid !== uid) {
        localStorage.removeItem("authToken");
      }

      user?.getIdToken().then((token) => {
        setUserInfo({
          type: reducerType?.USER_UPDATED,
          payload: {
            authUId: user?.uid,
            authToken: token,
          },
        });
        localStorage.setItem("token", token);
        localStorage.setItem("uid", user?.uid as string);
      });

      getFireStoreDoc(firestore?.boimarkerContent).then((keys: any) => {
        setBoimarkerContent(keys);
      });

      onSnapshot(
        doc(fireStore, "users/" + user?.uid),
        async (doc: any) => {
          const updateData = {
            user_data: null,
            non_essential: null,
            consent: null,
            is_mock: null,
            device_location: null,
            notification_preferences: null,
            org_code: null,
          };
          if (typeof doc.data === "function") {
            const data = doc.data();
            if (data === undefined) {
              setUserInfo({
                type: reducerType?.USER_UPDATED,
                payload: {
                  user_data: null,
                  address: null,
                  device_location: null,
                  orders: null,
                },
              });
            } else {
              if (data?.user_data) {
                updateData.user_data = data?.user_data;
              }
              if (nonEssentialStepModules &&
                data?.[nonEssentialStepModules?.store_path]
              ) {
                updateData.non_essential =
                  data?.[nonEssentialStepModules?.store_path]
              }

              if (stepData?.consent && data?.[stepData?.consent?.store_path]) {
                updateData.consent =
                  data?.[stepData?.consent.store_path] ?? data?.consent;
              }

              if (data?.is_mock !== undefined) {
                updateData.is_mock = data.is_mock;
              }
              if (data?.device_location) {
                updateData.device_location = data.device_location;
              }
              if (data?.notification_preferences) {
                updateData.notification_preferences =
                  data.notification_preferences;
              }
              if (data?.org_code && data?.org_code?.length > 0) {
                updateData.org_code = data.org_code;
                const orgData = data.org_code?.filter(
                  (data: any) => data?.default_code === true
                );
                if (orgData.length > 0) {
                  getFireStoreDoc(
                    "project_configurations/" + orgData[0]?.code
                  ).then((keys: any) => {
                    dispatch({
                      type: reducerType?.APP_CONFIG,
                      payload: keys?.keys,
                    });
                    if (keys?.settings?.package) {
                      styleDoc.style.setProperty(
                        "--light-background",
                        keys?.settings?.package?.color_system?.background_color
                      );
                      setTimeout(() => {
                        dispatch({
                          type: reducerType?.IS_UI_CHANGE,
                          payload: true,
                        });
                      }, 500);
                    }
                  });
                  addOrgCode(orgData[0]?.code);
                } else {
                  localStorage.removeItem("orgCode");
                  setOrgCode(null);
                }
              }
              setUserInfo({
                type: reducerType?.USER_UPDATED,
                payload: { ...updateData },
              });
            }
          }
        }
      );
      onSnapshot(
        collection(fireStore, "users/" + user?.uid + "/orders"),
        (querySnapshot: any) => {
          let records: any[] = [];
          querySnapshot.forEach((doc: any) => {
            records.push(doc.data());
          });
          setUserInfo({
            type: reducerType?.USER_UPDATED,
            payload: { orders: records },
          });
        }
      );

      onSnapshot(
        collection(fireStore, "users/" + user?.uid + "/address"),
        (querySnapshot: any) => {
          let records: any[] = [];
          querySnapshot.forEach((doc: any) => {
            records.push(doc.data());
          });
          setUserInfo({
            type: reducerType?.USER_UPDATED,
            payload: { address: records },
          });
        }
      );

      if (user?.uid !== sessionStorage.getItem("authUId")) {
        sessionStorage.setItem("authUId", user?.uid);
        localStorage.setItem("uid", user?.uid);
      }
    }

    if (!isTokenFound) {
      getToken(messaging, {
        vapidKey: window._env_.REACT_APP_WEB_PUSH_CERTIFICATE,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            setTokenFound(true);

            setMessageToken(currentToken);
            if (messagetimeout !== false) {
              clearTimeout(messagetimeout);
            }
            localStorage.setItem("messageToken", currentToken);
            messagetimeout = setTimeout(async () => {
              const oldmessageToken = localStorage.getItem("messageToken");

              if (oldmessageToken === currentToken) {
                setMessageTokenStable(true);
              }
            }, 5000);
          } else {
            // console.log(
            //   "No registration token available. Request permission to generate one."
            // );
            setTokenFound(false);
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          // console.log("An error occurred while retrieving token. ", err);
          setTokenFound(false);

          // catch error while creating client token
        });
    }
    if (
      user?.uid &&
      isMessageTokenStable &&
      userInfo?.user_data !== null
    ) {
      saveMessageRegistrsationToken();
    }
  }, [user]);

  useEffect(() => {
    // console.log({user?.uid});

    // if (
    //   !isLanguageLoaded &&
    //   user?.uid !== null &&
    //   user?.uid !== undefined &&
    //   RTDB &&
    //   RTDB?.configaurableText !== undefined
    // ) {
    //   let timer = setInterval(() => {
    //     const languageData = RTdb.getRTDBData(RTDB?.configaurableText);

    //     languageData.then(async (value) => {
    //       if (await value) {
    //         for (const [lang, trans] of Object.entries(value)) {
    //           // console.log({ typeoftrans: typeof trans });
    //           const transValue = await trans;
    //           const newTrans = traceAndReplace(transValue, "\\n", "<br/>");
    //           // console.log({ transValue });
    //           // console.log({ newTrans });

    //           i18next.addResourceBundle(
    //             lang,
    //             "translation",
    //             newTrans,
    //             true,
    //             true
    //           );
    //         }
    //         setisLanguageLoaded(true);
    //         clearTimeout(timer);
    //       }
    //       //i18next.addResourceBundle('en','translation',value.en ,true,true)
    //     });
    //   }, 5000);
    // }

    const localOrgCode = localStorage.getItem("orgCode");
    const isCallingApi = isContinueData.get(localOrgCode)
    if (
      (user?.uid === null ||
        user?.uid === undefined) &&
      // localOrgCode &&
      !isLanguageLoaded && !isCallingApi
    ) {
      updatePrefetchData(localOrgCode);
    }
  }, [user?.uid]);

  const updatePrefetchData = async (orgCode: string | null) => {
    console.log('Prefetch log...')
    isContinueData.set(orgCode, 'calling...')
    return preFetchData(orgCode).then(async (value) => {
      // console.log(value);
      if (value?.data?.configurable_text) {
        for (const [lang, trans] of Object.entries(
          value?.data?.configurable_text
        )) {
          // console.log({ typeoftrans: typeof trans });
          const transValue = await trans;
          const newTrans = traceAndReplace(transValue, "\\n", "<br/>");
          // console.log({ transValue });
          // console.log({ newTrans });

          i18next.addResourceBundle(lang, "translation", newTrans, true, true);
        }
        setisLanguageLoaded(true);
      }

      if (value?.data?.credit_card_mock) {
        dispatch({ type: reducerType?.UPDATE_STATE, payload: { credit_card_mock: value?.data?.credit_card_mock } });
      }

      if (value?.data?.location_api) {
        const newData = {
          location_api: value?.data?.location_api,
          password_rules: value?.data?.password_rules,
        };
        setSettingsState((prevState: any) => {
          return { ...prevState, ...newData };
        });
      }

      if (value?.data?.module_steps) {
        // console.log('data', user?.uid)
        setStepData({
          type: reducerType?.ALL_STEP,
          payload: value?.data?.module_steps,
        });
        setCStepModules(
          value?.data?.module_steps[RTDBStepModules?.consentStep]
        );
        setStepData({
          type: reducerType?.CONSENT_STEP,
          payload: value?.data?.module_steps[RTDBStepModules?.consentStep],
        });

        setStepModules(value?.data?.module_steps);
        setUserInfoUpdateStepModules(value?.data?.module_steps[RTDBStepModules?.updatedUserStep]);
        setStepData({ type: reducerType?.ONBOARDING_STEP, payload: value?.data?.module_steps[RTDBStepModules?.updatedUserStep] })
        setEStepModules(value?.data?.module_steps[RTDBStepModules?.updatedUserStep]);
        setFeedbackStepModules(value?.data?.module_steps[RTDBStepModules?.feedbackStep]);
        setStepData({ type: reducerType?.FEEDBACK_STEP, payload: value?.data?.module_steps[RTDBStepModules?.feedbackStep] })
        if (localStorage.getItem("mode") === 'result') {
          setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: value?.data?.module_steps[RTDBStepModules?.resultAuthentication] })
        } else {
          setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: value?.data?.module_steps[RTDBStepModules?.authentication] })
        }
      }
      if (value?.data?.package && orgCode !== null) {
        styleDoc.style.setProperty(
          "--light-background",
          value?.data?.package?.color_system?.background_color
        );
        setTimeout(() => {
          dispatch({ type: reducerType?.IS_UI_CHANGE, payload: true });
        }, 500);
      }

      if (orgCode && value?.data?.logo) {
        // console.log('orgCode', orgCode)
        setWhiteLabeling({ type: reducerType?.UPDATE_WHITELABELING, payload: { logo: null, mobile_logo: null } });
        setWhiteLabeling({ type: reducerType?.UPDATE_WHITELABELING, payload: { logo: value?.data?.logo?.url, mobile_logo: value?.data?.logo?.url } });
      } else {
        setWhiteLabeling({ type: reducerType?.UPDATE_WHITELABELING, payload: { logo: null, mobile_logo: null } });
      }

      if (value?.data?.modules) {
        dispatch({
          type: reducerType?.UPDATE_MODULES,
          payload: value?.data?.modules,
        });
        setModules({
          type: reducerType?.UPDATE_MODULES,
          payload: value?.data?.modules,
        });
      }
      isContinueData.set(orgCode, null)

      // console.log('data', value?.data)
      return value?.data;
    });
  };

  // let timer = setTimeout(() => {

  // }, 5000);

  /*  onMessage(messaging, (payload) => {
    const propsValue: MessageProp = {
      title: payload?.notification?.title ?? "",
      messageBody: payload?.notification?.body ?? "",
      image: payload.notification?.image ?? "",
      extraData: payload.data ?? {},
    };
  
    if (propsValue.title || propsValue.messageBody)
      toast(<PopupMessage {...propsValue} />, { autoClose: false });
  
  }); */


  const addOrgCode = async (orgCode: string | null) => {
    const localOrgCode = localStorage.getItem("orgCode");
    const isCallingApi = isContinueData.get(orgCode)

    // console.log(orgCode, localOrgCode)
    if (orgCode && orgCode !== localOrgCode && !isCallingApi) {
      localStorage.setItem("orgCode", orgCode);
      // console.log('org_code', orgCode)
      // updatePrefetchData(orgCode);
    }
    if (orgCode === null) {
      localStorage.removeItem("orgCode");
    }
    setOrgCode(orgCode);

  };

  const setRootLoader = () => {
    setMainLoader(true);
    setRemainingLoaderTime(true);

    setTimeout(() => {
      setRemainingLoaderTime(false);
    }, 3000);
  };

  const consentDataLoad = async () => {
    return consentStepModules;
  };

  const traceAndReplace = (
    element: any,
    search: string[] | string | null = null,
    replace: string[] | string | null = null
  ): any => {
    if (typeof element === "string")
      return searchAndReplace(element, search, replace);
    if (typeof element === "object") {
      const newElement: any = {};
      const keys = Object.keys(element);
      for (let index = 0; index < keys.length; index++) {
        newElement[keys[index]] = traceAndReplace(
          element[keys[index]],
          search,
          replace
        );
      }
      return newElement;
    }
  };

  /** Translate with dynamic place holder value */

  /**
   * searchAndReplace
   * This function takes a text with search and replace data. The function modify input text with given search and replace value.
   * @param {string} key text to modify.
   * @param {string[]|string|null} search search string or array of string.
   * @param {string[]|string|null} replace replace string or array of string.
   * @returns {string} Return the modified string
   */

  const searchAndReplace = (
    text: string,
    search: string[] | string | null = null,
    replace: string[] | string | null = null
  ): string => {
    if (search === null || replace === null) return text;
    if (typeof search !== typeof replace) return text;
    if (typeof search === "string")
      return text.replaceAll(search as string, replace as string);
    if (search[0] === undefined || replace[0] === undefined) return text;

    let newText = text;
    for (let index = 0; index < search.length; index++) {
      if (replace[index] !== undefined)
        newText = newText.replaceAll(
          search[index] as string,
          replace[index] as string
        );
    }

    return newText;
  };
  /**
   * translateDynamic
   * This function takes a translation key with search and replace data. The function first translate the key with and then replace the dynamic values according to search.
   * @param {string} key Translation key.
   * @param {string[]|string|null} search search string or array of string.
   * @param {string[]|string|null} replace replace string or array of string.
   * @returns {string} Return the modified string
   */
  const translateDynamic = (
    key: string,
    search: string[] | string | null,
    replace: string[] | string | null
  ) => {
    return searchAndReplace(t(key), search, replace);
  };

  /** Showing popup success or error messages */
  const showToastMessage = (
    message: string,
    isError: boolean = false,
    options: any
  ) => {
    // const date = new Date();
    // console.log(`TOAST -- message: ${message}, time:${date.toUTCString()}`);
    // toast.clearWaitingQueue();
    if (isError) {
      toast.error(message, { limit: 1, toastId: "error-toast", ...options });
    } else {
      toast.success(message, {
        limit: 1,
        toastId: "success-toast",
        ...options,
      });
    }
  };

  const setMaxRootLoader = () => {
    setMainLoader(true);
    setTimeout(() => {
      setMainLoader(false);
      setRemainingLoaderTime(false);
    }, 5000);
  };

  const setMinRootLoader = () => {
    setMainLoader(true);
    setTimeout(() => {
      setMainLoader(false);
      setRemainingLoaderTime(false);
    }, 3000);
  };

  const unSetRootLoader = () => {
    setMainLoader(false);
  };

  const updateTranslationLanguage = (lang: string) => {
    i18next.changeLanguage(lang);
  };

  const newAnonymousUser = async () => {
    await authentication.signInAnonymously();
    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        localStorage.setItem("uid", user?.uid as string);
      }
    });
  };

  const logoutUser = () => {
    signOut(auth)
      .then(async () => {
        localStorage.clear();
        sessionStorage.clear();
        setMainLoader(false);
        setRemainingLoaderTime(false);
        navigate(routes.login);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };

  const getResultData = async (orderId: string) => {

    if (boimarkerContent) {
      if (resultData) {
        return { result: resultData[orderId], boimarkerData: boimarkerContent };
      } else {
        return getResultJson(orderId).then(async (data: any) => {
          setResultData({ [orderId]: data?.data });
          return { result: data?.data, boimarkerData: boimarkerContent };
        });
      }
    } else {

      return await getFireStoreDoc(
        "project_configurations/" + org_code + "/" + firestore?.boimarkerContent
      ).then(async (keys) => {
        // console.log('keys', firestore?.boimarkerContent, keys, org_code)
        setBoimarkerContent(keys);
        if (resultData) {
          return { result: resultData[orderId], boimarkerData: keys };
        } else {
          return getResultJson(orderId).then(async (data: any) => {
            setResultData({ [orderId]: data?.data });
            return { result: data?.data, boimarkerData: keys };
          });
        }
      });
    }
    // const localResult = null
    // const localResult = localStorage.getItem(orderId + "result")
    // ? JSON.parse(atob(String(localStorage.getItem(orderId + "result"))))
    // : null;
  };

  const saveMessageRegistrsationToken = async (): Promise<any> => {
    return;
    /* const browserInformation = browserInfo();
  
    const device_info = {
      browser: browserInformation.browserName,
      version: browserInformation.fullVersion,
    };
    if (!messageToken || !user?.uid || userInfo?.user_data === null) return;
  
    try {
      const payload = {
        user_id: user?.uid,
        token: messageToken,
        device: "web",
        overwrite: true,
        device_info,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const response = (await addFCMToken(payload)) as any;
      // console.log(response?.data?.message);
    } catch (error) {
      console.error("Failed to load API!", error);
    } */
  };

  const translateAndParse = (
    key: string,
    search?: string[] | string | null,
    replace?: string[] | string | null
  ): any => {
    if (search !== undefined && replace !== undefined)
      return parse(translateDynamic(key, search, replace));
    return parse(t(key));
  };

  const providerValue = {
    whiteLabeling,
    stepData,
    modules,
    state,
    org_code,
    userInfo,
    settingsState,
    boimarkerContent,
    stepModules,
    essentialStepModules,
    changePwdStepModules,
    userInfoUpdateStepModules,
    nonEssentialStepModules,
    consentStepModules,
    fedbackStepModules,
    remainingLoaderTime,
    showToastMessage,
    setRootLoader,
    setMinRootLoader,
    setMaxRootLoader,
    unSetRootLoader,
    translated: translateAndParse,
    searchAndReplace,
    translateDynamic,
    updateTranslationLanguage,
    newAnonymousUser,
    messageToken,
    saveMessageRegistrsationToken,
    mainLoader,
    getResultData,
    logoutUser,
    addOrgCode,
    updatePrefetchData,
    consentDataLoad,
    setStepData,
    setShowAZChatBot,
    showAZChatBot,
  };
  return (
    <>
      <AppContext.Provider value={providerValue}>
        {isLanguageLoaded === false ? (
          <Spiner
            height="100%"
            width="100px"
            className="page-center align-items-center"
            margin="auto"
          />
        ) : (
          children
        )}
      </AppContext.Provider>
    </>
  );
};
