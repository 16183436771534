/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";

const CheckBoxInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  autoFocus?: boolean;
  submitData: (sContinue: boolean, key: string, value: any) => void;
}> = ({ attribute, oldSelection, autoFocus, submitData }) => {
  const [inputValue, setInputValue] = useState(false);
  useEffect(() => {
    if (oldSelection) {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    <div className="mb-3 form-check ">
      <input
        type="checkbox"
       
        autoComplete="off"
        autoFocus={autoFocus}
        checked={inputValue}
        onChange={() => setInputValue(!inputValue)}
        className="form-check-input"
         
      />
      <label className="form-check-label" htmlFor = {attribute.key} >{attribute.display_text}</label>
    </div>
  );
};

export default CheckBoxInput;
