import { Modal } from "react-bootstrap";
import React from "react";
import { ModalSize } from "@/interface/index";

const RootModal = (props: {
  show: boolean;
  modalClosingButton?: boolean;
  handleClose: () => void;
  modalTitle: string;
  children: any;
  size: ModalSize;
}) => {
  return (
    <Modal
      size={props.size}
      backdrop={"static"}
      show={props.show}
      onHide={props.handleClose}
      centered={true}
      dialogClassName="text-center"
      contentClassName="text-center"
      className="modal-wrapper"
    >
      {props.modalClosingButton && props.modalClosingButton === true ? (
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{props.modalTitle}</Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header>
          <Modal.Title className="text-center">{props.modalTitle}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <>{props.children}</>
      </Modal.Body>
    </Modal>
  );
};

export default RootModal;
