/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ShimmerTitle, ShimmerButton } from "../../packages/Shimmer/index";

const ForgotPasswordShimmer: React.FC = () => {
  return (
    <>
      <br />
      <br />
      <div className="forgot-password-shimmer text-center">
        <ShimmerTitle line={1} gap={15} className="text-center " />
        <br />
        <br />
        <ShimmerButton size="sm" />
      </div>
    </>
  );
};

export default ForgotPasswordShimmer;
