/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ShimmerTitle,
  ShimmerText,
  ShimmerButton,
} from "../../packages/Shimmer/index";

const ResultBoimarkerShimmer: React.FC = () => {
  return (
    <div className="two-columns-layout">

      <div className="mb-3 text-center">
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={4} gap={10} />
        <ShimmerTitle line={1} gap={10} variant="primary" />
        <ShimmerText line={4} gap={10} />
        <div className="mb-3  text-center width-236">
          <ShimmerButton size="sm" />
        </div>
      </div>
    </div>
  );
};

export default ResultBoimarkerShimmer;
