/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { AppContext } from "@/context/createContext";
import { stripePaymentIntent } from "@/services/api/fireFunction";
import { loadStripe } from "@stripe/stripe-js";
import Spiner from "../common/Spiner";
const AGPay: React.FC<{
  orderSubmit: (paymentDetails: any) => void;
  orderData: any;
  clientSecret: any;
  selectedPayment: string;
}> = ({ orderSubmit, orderData, clientSecret, selectedPayment }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const { mainLoader, remainingLoaderTime, setRootLoader, unSetRootLoader, showToastMessage } =
    useContext(AppContext);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    setRootLoader();

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Total",
        amount: orderData?.total_price * 100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment()
      .then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
        unSetRootLoader();
      })
      .catch((error: any) => {
        unSetRootLoader();

        console.log("error", error);
      });

    pr.on("paymentmethod", async (e) => {
      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        showToastMessage(stripeError.message as string, true);
        return;
      }
      if (paymentIntent.status === "requires_action") {
        orderSubmit(paymentIntent);
        showToastMessage(`Payment success!`);
      } else {
        orderSubmit({
          payment_status: 'failed'
        })
      }
    });
  }, [stripe, elements]);

  return (
    <>
      {paymentRequest ? (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      ) : mainLoader || remainingLoaderTime ? (
        <Spiner height="100px" width="100px" margin="auto" />
      ) : (
        <h4 className="text-center text-danger">
          {selectedPayment} is not supported in this browser!
        </h4>
      )}
    </>
  );
};

const AppleGooglePay: React.FC<{
  orderSubmit: (paymentDetails: any) => void;
  orderData: any;
  selectedPayment: string;
}> = ({ orderSubmit, orderData, selectedPayment }) => {
  const {
    userInfo,
    state,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage
  } = useContext(AppContext);
  const configState = state?.config_state
  const stripePk = loadStripe(configState?.stripe?.publishable_key);
  const [clientSecret, setClientSecret] = useState("");
  let count = 0;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    var paymentIntentData = {
      amount: orderData?.total_price * 100, //required // smallest currency unit (e.g., 100 cents to charge $1.00, a zero-decimal currency)
      user_id: userInfo?.authUId, //Claire user id  //required
      //is_auto_payment_method: true, //Set to true while using stripe client SDK //required
      metadata: {
        order_id: orderData?.order_id, // random number for now
      },
      setup_future_usage: "off_session",
    };
    if (count === 0) {
      setRootLoader();
      count++;
      stripePaymentIntent(paymentIntentData)
        .then((data: any) => {
          if (data?.data?.client_secret) {
            setClientSecret(data?.data?.client_secret);
            unSetRootLoader();
          } else {
            showToastMessage("Something went wrong, try again", true);
          }
        })
        .catch((err: any) => {
          unSetRootLoader()
          showToastMessage(err, true);
        });
    }
  }, [count]);
  return (
    <>
      {clientSecret === "" && (mainLoader || remainingLoaderTime) && (
        <Spiner height="100px" width="100px" margin="auto" />
      )}
      {clientSecret !== "" && (
        <Elements stripe={stripePk}>
          <AGPay
            orderSubmit={(paymentDetails) => orderSubmit(paymentDetails)}
            clientSecret={clientSecret}
            orderData={orderData}
            selectedPayment={selectedPayment}
          />
        </Elements>
      )}
    </>
  );
};

export default AppleGooglePay;
