import { ResultDataType } from "../interface"

/* eslint-disable array-callback-return */
export function getBiomarkerResult(bioMarkerData: any): any {
    let returnObject: any = {
        normal: false,
        low_risk: false,
        high_risk: false,
    }

    // console.log('bioMarkerData?.biomarker', bioMarkerData?.biomarker)

    if (bioMarkerData?.biomarker?.levels && bioMarkerData?.biomarker?.levels?.length > 0) {
        bioMarkerData?.biomarker?.levels?.map((level: any) => {
            if (level?.ruling === 'Normal') {
                if (level?.range_from && level?.range_from !== "" && level?.range_to && level?.range_to !== "") {
                    returnObject.normal = (Number(level?.range_from) < Number(bioMarkerData?.result_value) && Number(bioMarkerData?.result_value) < Number(level?.range_to))
                } else if (level?.range_from && level?.range_from !== "" && level?.range_to === "") {
                    returnObject.normal = (Number(level?.range_from) < Number(bioMarkerData?.result_value))
                } else if (level?.range_from === "" && level?.range_to && level?.range_to !== "") {
                    returnObject.normal = (Number(level?.range_to) > Number(bioMarkerData?.result_value))
                } else {
                    returnObject.normal = false
                }

            } else if (level?.ruling === 'Low Risk') {
                if (level?.range_from && level?.range_from !== "" && level?.range_to && level?.range_to !== "") {
                    returnObject.low_risk = (Number(level?.range_from) < Number(bioMarkerData?.result_value) && Number(bioMarkerData?.result_value) < Number(level?.range_to))
                } else if (level?.range_from && level?.range_from !== "" && level?.range_to === "") {
                    returnObject.low_risk = (Number(level?.range_from) < Number(bioMarkerData?.result_value))
                } else if (level?.range_from === "" && level?.range_to && level?.range_to !== "") {
                    returnObject.low_risk = (Number(level?.range_to) > Number(bioMarkerData?.result_value))
                } else {
                    returnObject.low_risk = false
                }

            } else if (level?.ruling === 'High Risk') {
                if (level?.range_from && level?.range_from !== "" && level?.range_to && level?.range_to !== "") {
                    returnObject.high_risk = (Number(level?.range_from) < Number(bioMarkerData?.result_value) && Number(bioMarkerData?.result_value) < Number(level?.range_to))
                } else if (level?.range_from && level?.range_from !== "" && level?.range_to === "") {
                    returnObject.high_risk = (Number(level?.range_from) < Number(bioMarkerData?.result_value))
                } else if (level?.range_from === "" && level?.range_to && level?.range_to !== "") {
                    returnObject.high_risk = (Number(level?.range_to) > Number(bioMarkerData?.result_value))
                } else {
                    returnObject.high_risk = false
                }
            }
        })
    }

    return returnObject
}

export function getResultMeterMinMax(bioMarkerData: any): any {
    let returnObject: any = {
        min: 0,
        max: 0
    }

    if (bioMarkerData?.biomarker?.levels && bioMarkerData?.biomarker?.levels?.length > 0) {
        bioMarkerData?.biomarker?.levels?.map((level: any) => {
            if (level?.range_from && level?.range_from !== "" && level?.range_to && level?.range_to !== "") {
                if (returnObject.min > Number(level?.range_from)) {
                    returnObject.min = Number(level?.range_from);
                }
                if (returnObject.max < Number(level?.range_to)) {
                    returnObject.max = Number(level?.range_to);
                }
            } else if (level?.range_from && level?.range_from !== "" && level?.range_to === "") {
                if (returnObject.min > Number(level?.range_from)) {
                    returnObject.min = Number(level?.range_from);
                }
            } else if (level?.range_from === "" && level?.range_to && level?.range_to !== "") {
                if (returnObject.max < Number(level?.range_to)) {
                    returnObject.max = Number(level?.range_to);
                }
            }
        })
    }

    return returnObject
}

export function getResultData(bioMarkerData: any): ResultDataType {
    const redColor = "#FF0077"
    const yellowColor = "#F4E72D"
    const greenColor = "#2EC057"

    const resultValue = bioMarkerData?.result_value

    let returnObject: ResultDataType = {
        dotRange: false,
        arcSegments: [],
        dotPositioning: 0,
        maxLabel: "180",
        optimalRange: "",
        minLabel: "0",
    }

    if (bioMarkerData?.biomarker?.levels && bioMarkerData?.biomarker?.levels?.length > 1 && bioMarkerData?.biomarker?.levels?.length === 3) {
        let levelType = {
            normal: false,
            lowRisk: false,
            highRisk: false
        }
        bioMarkerData?.biomarker?.levels?.map((level: any) => {
            if (level?.ruling === 'Normal') {
                levelType.normal = true;
                returnObject.arcSegments.push({ value: 60, color: greenColor, endLabel: level?.range_to })
                if (Number(resultValue) > 0 && Number(resultValue) <= Number(level?.range_to)) {
                    returnObject.dotPositioning = (60 * (Number(resultValue) / Number(level?.range_to)))
                    returnObject.optimalRange = "<" + level?.range_to
                }
            } else if (level?.ruling === 'Low Risk') {
                levelType.lowRisk = true;

                if (Number(resultValue) >= Number(level?.range_from) && Number(resultValue) <= Number(level?.range_to)) {
                    returnObject.dotPositioning = (60 * ((Number(resultValue) - Number(level?.range_from)) / (Number(level?.range_to) - Number(level?.range_from)))) + 60
                    returnObject.optimalRange = "<" + level?.range_to
                }
                returnObject.maxLabel = String(Math.round(Number(level?.range_to) + Number(level?.range_from)))

                returnObject.arcSegments.push({ value: 60, color: yellowColor, endLabel: level?.range_to })
            } else if (level?.ruling === 'High Risk') {
                levelType.highRisk = true;

                if (Number(resultValue) > Number(level?.range_from)) {
                    returnObject.optimalRange = "<" + level?.range_to
                    returnObject.dotPositioning = (60 * ((Number(resultValue) - Number(level?.range_from)) / (Number(level?.range_to) - Number(level?.range_from)))) + 60
                }
                if (level?.range_to !== "" && (Number(level?.range_to) > 0)) {
                    returnObject.maxLabel = level?.range_to
                } else {
                    returnObject.maxLabel = "> " + level?.range_from
                }
                returnObject.arcSegments.push({ value: 60, color: redColor })
            }
        })

        returnObject.dotRange = levelType?.normal && levelType?.lowRisk && levelType?.highRisk
    } else if (bioMarkerData?.biomarker?.levels && bioMarkerData?.biomarker?.levels?.length === 1) {
        const range_to = bioMarkerData?.biomarker?.levels[0]?.range_to
        const range_from = bioMarkerData?.biomarker?.levels[0]?.range_from
        const ruling = bioMarkerData?.biomarker?.levels[0]?.ruling
        //const json_filters = bioMarkerData?.biomarker?.levels[0]?.json_filters


        if (ruling === 'Normal' && Number(range_from) > 0 && range_to !== "") {
            returnObject.arcSegments = [
                { value: 45, color: redColor, endLabel: range_from },
                { value: 90, color: greenColor, endLabel: range_to },
                { value: 45, color: redColor }
            ]

            if (Number(resultValue) >= 0 && Number(resultValue) < Number(range_from)) {
                returnObject.maxLabel = String(Math.round(Number(range_to) + Number(range_from)))
                returnObject.dotPositioning = 45 * Number(resultValue) / Number(range_from)

                returnObject.optimalRange = "" + range_from
            } else if (Number(resultValue) >= Number(range_from) && Number(resultValue) < Number(range_to)) {
                returnObject.maxLabel = String(Math.round(Number(range_to) + Number(range_from)))
                returnObject.dotPositioning = (90 * (Number(resultValue) - Number(range_from))) / (Number(range_to) - Number(range_from)) + 45
                returnObject.optimalRange = "" + range_from + " <= x <=" + range_to
            } else if (Number(resultValue) >= Number(range_to)) {
                returnObject.maxLabel = ' > ' + String(Math.round(135 * (Number(range_to) - Number(range_from)) / (90 + Number(range_from))))
                returnObject.dotPositioning = (90 * Number(resultValue) - Number(range_from)) / (Number(range_to) - Number(range_from)) + 45
                returnObject.optimalRange = "" + range_to
            }
        }

        if (ruling === 'Normal' && (Number(range_from) === 0 || range_from === "") && Number(range_to) > 0) {
            returnObject.arcSegments = [
                { value: 120, color: greenColor, endLabel: range_to },
                { value: 60, color: redColor },
            ]

            if (Number(resultValue) >= Number(range_from) && Number(resultValue) < Number(range_to)) {
                returnObject.maxLabel = String(Math.round(Number(range_to) + (Number(range_to) / 2)))

                returnObject.dotPositioning = (120 * Number(resultValue)) / Number(range_to)

                returnObject.optimalRange = " < " + range_to
            } else if (Number(resultValue) >= Number(range_to)) {
                returnObject.maxLabel = ' > ' + String(Math.round(180 * (Number(range_to) / Number(resultValue))))

                returnObject.dotPositioning = (120 * Number(resultValue)) / Number(range_to)
                returnObject.optimalRange = " > " + range_to
            }
        }

        if (ruling === 'Normal' && Number(range_from) > 0 && range_to === "") {
            returnObject.arcSegments = [
                { value: 60, color: redColor, endLabel: range_from },
                { value: 120, color: greenColor },
            ]

            if (Number(resultValue) >= 0 && Number(resultValue) < Number(range_from)) {
                returnObject.maxLabel = String(Math.round(Number(range_from) * 3))

                returnObject.dotPositioning = 60 * Number(resultValue) / Number(range_from)

                returnObject.optimalRange = " < " + range_from
            } else if (Number(resultValue) >= Number(range_from)) {
                returnObject.maxLabel = ' > ' + String(Math.round(180 * (Number(range_from) / 60)))

                returnObject.dotPositioning = (60 * Number(resultValue)) / Number(range_from)

                returnObject.optimalRange = " > " + range_from
            }
        }

        if (Number(range_from) === Number(range_to) && Number(resultValue) === Number(range_from)) {
            returnObject.arcSegments = [{ value: 180, color: greenColor }]
            returnObject.optimalRange = ""
            returnObject.minLabel = ""
            returnObject.maxLabel = ""
        }

        if (Number(range_from) === Number(range_to) && Number(resultValue) !== Number(range_from)) {
            returnObject.arcSegments = [{ value: 180, color: redColor }]
            returnObject.optimalRange = ""
            returnObject.minLabel = ""
            returnObject.maxLabel = ""
        }

    } else if (bioMarkerData?.biomarker?.levels === null || bioMarkerData?.biomarker?.levels?.length === 0) {
        returnObject.arcSegments = [{ value: 180, color: greenColor }]
        returnObject.minLabel = ""
        returnObject.maxLabel = ""
    }

    return returnObject
}

export function getNewResultData(bioMarkerData: any): ResultDataType {
    const redColor = "#FF0077"
    // const yellowColor = "#F4E72D"
    const greenColor = "#2EC057"

    const resultValue = bioMarkerData?.result_value

    let returnObject: ResultDataType = {
        dotRange: false,
        arcSegments: [],
        dotPositioning: 0,
        maxLabel: "180",
        optimalRange: "",
        minLabel: "0",
    }

    const range_to = bioMarkerData?.range_to
    const range_from = bioMarkerData?.range_from
    // const ruling = bioMarkerData?.biomarker?.levels[0]?.ruling
    //const json_filters = bioMarkerData?.biomarker?.levels[0]?.json_filters


    if (Number(range_from) > 0 && range_to !== "") {
        returnObject.arcSegments = [
            { value: 45, color: redColor, endLabel: range_from },
            { value: 90, color: greenColor, endLabel: range_to },
            { value: 45, color: redColor }
        ]

        if (Number(resultValue) >= 0 && Number(resultValue) < Number(range_from)) {
            returnObject.maxLabel = String(Math.round(Number(range_to) + Number(range_from)))
            returnObject.dotPositioning = 45 * Number(resultValue) / Number(range_from)

            returnObject.optimalRange = ""
        } else if (Number(resultValue) >= Number(range_from) && Number(resultValue) < Number(range_to)) {
            returnObject.maxLabel = String(Math.round(Number(range_to) + Number(range_from)))
            returnObject.dotPositioning = (90 * (Number(resultValue) - Number(range_from))) / (Number(range_to) - Number(range_from)) + 45
            returnObject.optimalRange = ""
            if (Number(resultValue) === Number(range_from)) {
                returnObject.dotPositioning = (45 * (Number(resultValue) - Number(range_from))) / (Number(range_to) - Number(range_from)) + 45 + 1

            } else {
                returnObject.dotPositioning = (90 * (Number(resultValue) - Number(range_from))) / (Number(range_to) - Number(range_from)) + 45

            }
        } else if (Number(resultValue) >= Number(range_to)) {
            //returnObject.maxLabel = ' > ' + String(Math.round(Number(range_to) + Number(range_from)))
            returnObject.maxLabel = ' > ' + String(Math.round((135 * (Number(range_to) - Number(range_from))) / (90 + Number(range_from))))
            returnObject.optimalRange = ""
            if (Number(resultValue) === Number(range_to)) {
                returnObject.dotPositioning = (45 * Number(resultValue) - Number(range_from)) / (Number(range_to) - Number(range_from)) + 45 + 1

            } else {
                returnObject.dotPositioning = (90 * Number(resultValue) - Number(range_from)) / (Number(range_to) - Number(range_from)) + 45
            }
        }
    }

    if ((Number(range_from) === 0 || range_from === "") && Number(range_to) > 0) {
        returnObject.arcSegments = [
            { value: 120, color: greenColor, endLabel: range_to },
            { value: 60, color: redColor },
        ]

        if (Number(resultValue) >= Number(range_from) && Number(resultValue) < Number(range_to)) {
            returnObject.maxLabel = String(Math.round(Number(range_to) + (Number(range_to) / 2)))

            returnObject.dotPositioning = (120 * Number(resultValue)) / Number(range_to)

            returnObject.optimalRange = " < " + range_to
        } else if (Number(resultValue) >= Number(range_to)) {
            returnObject.maxLabel = ' > ' + String(Math.round(180 * (Number(range_to) / 120)))

            returnObject.dotPositioning = (120 * Number(resultValue)) / Number(range_to)
            returnObject.optimalRange = " > " + range_to
        }
    }

    if (Number(range_from) > 0 && range_to === "") {
        returnObject.arcSegments = [
            { value: 60, color: redColor, endLabel: range_from },
            { value: 120, color: greenColor },
        ]

        if (Number(resultValue) >= 0 && Number(resultValue) < Number(range_from)) {
            returnObject.maxLabel = String(Math.round(Number(range_from) * 3))

            returnObject.dotPositioning = 60 * Number(resultValue) / Number(range_from)

            returnObject.optimalRange = " < " + range_from
        } else if (Number(resultValue) >= Number(range_from)) {
            returnObject.maxLabel = ' > ' + String(Math.round(180 * (Number(range_from) / 60)))

            returnObject.dotPositioning = (60 * Number(resultValue)) / Number(range_from)

            returnObject.optimalRange = " > " + range_from
        }
    }

    // if (Number(range_from) === Number(range_to) && Number(resultValue) === Number(range_from)) {
    //     returnObject.arcSegments = [{ value: 180, color: greenColor }]
    //     returnObject.optimalRange = "Optimal Range = " + resultValue
    //     returnObject.minLabel = ""
    //     returnObject.maxLabel = ""
    // }

    // if (Number(range_from) === Number(range_to) && Number(resultValue) !== Number(range_from)) {
    //     returnObject.arcSegments = [{ value: 180, color: redColor }]
    //     returnObject.optimalRange = "Optimal Range = " + resultValue
    //     returnObject.minLabel = ""
    //     returnObject.maxLabel = ""
    // }

    return returnObject
}