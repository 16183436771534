/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "@/context/createContext";
import { findItemFromArray } from "@/helper/common";
import { backArrow, payments } from "@/config/svg";
import { RTDBStepModules, ResultStatusList, reducerType } from "@/config/configarableVariable";
import routes from "@/config/routes";
import { Spiner } from "@/components/index";
import { isAndroid, isMobile } from "react-device-detect";
// import { getOrder } from "@/services/api/fireFunction";
const OrderComplete: React.FC = () => {
  const { userInfo, stepData, setStepData, translated } = useContext(AppContext);
  const [order, setOrder] = useState<any>(null);
  const [pdf, setPdf] = useState<string | null>(null);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [showPDF, setShowPdf] = useState<boolean>(false);

  let navigate = useNavigate();

  //const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (userInfo?.orders && userInfo?.orders.length > 0 && searchParams.get("metadata") && order === null) {
      const orderData = findItemFromArray(
        userInfo?.orders,
        "order_id",
        atob(String(searchParams.get("metadata")))
      )
      setOrder(orderData);
      if (orderData?.status === ResultStatusList?.inProgress) {
        navigate('/pre-test?metadata=' + searchParams.get("metadata"));
      }
      if (orderData?.status === ResultStatusList?.resultsAvailable) {
        localStorage.setItem("mode", "result")
        if (stepData?.allStep[RTDBStepModules?.resultAuthentication]) {
          setStepData({ type: reducerType?.AUTHENTICATION_STEP, payload: stepData?.allStep[RTDBStepModules?.resultAuthentication] })
          navigate(routes.authenticationStep);
        }
      }
    }

    if (userInfo?.orders && userInfo?.orders.length > 0 && searchParams.get("metadata")) {
      const orderData = findItemFromArray(
        userInfo?.orders,
        "order_id",
        atob(String(searchParams.get("metadata")))
      )

      if (
        orderData?.fulfillment_orders &&
        orderData?.fulfillment_orders[0]?.order_request_pdf
      ) {
        setIsPdf(true)
        setPdf(orderData?.fulfillment_orders[0]
          ?.order_request_pdf)
      }
    }
  }, [userInfo?.orders, searchParams]);

  useEffect(() => {
    document.body.className = "checkout-area welcome";
    return () => {
      document.body.className = "";
    };
  }, []);

  const openPDF = () => {
    if (isMobile && isAndroid) {
      var link = document.createElement('a');
      link.href = "data:application/pdf; base64," + pdf;
      link.download = 'OrderPDF.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      setShowPdf(true)
    }
  }
  return (
    pdf && showPDF ?
      <section className="order-pdf-section">
        <img
          className="cursor-pointer float-start pr-5 cart"
          src={backArrow}
          width="70"
          height="70"
          alt="back"
          onClick={() => setShowPdf(false)}
        />
        <object
          className="pdf-viewer-object"
          data={"data:application/pdf; base64," + pdf}
          type="application/pdf"
        >
          <div>No online PDF viewer installed</div>
        </object>
      </section>
      :
      <Pages>
        <section className="pending-order-list">
          <div className="row align-items-center scrollcontainer1 custom-pb-16">
            <div className="d-flex justify-content-start">
              {order && isPdf ?
                <div className="text-center">
                  <img
                    className="cursor-pointer pr-5 "
                    src={payments}
                    width="100"
                    height="100"
                    alt="back"
                  />
                  <br />
                  <h1 className="text-36 custom-mb-25">{translated("app_copy.order_confirmed")}</h1>
                  <p className="text-16 custom-mb-40 ">{translated("app_copy.order_confirmed_description")}</p>
                  <br />
                  <button
                    className={"standard-btn custom-mr-15"}
                    // disabled={mainLoader || remainingLoaderTime}
                    onClick={openPDF}
                  >
                    <p className="text-18 bold">
                      {translated("cta.order_detail_instruction_cta")}
                    </p>
                  </button>
                </div>
                :
                <Spiner
                  height="100%"
                  width="100px"
                  className="page-center align-items-center"
                  margin="auto"
                />
              }
            </div>
          </div>
        </section>
      </Pages>
  );
};

export default OrderComplete;
