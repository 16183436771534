/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppContext } from "@/context/createContext";
import {
  Elements,
} from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import {
  base64Encode,
  findItemFromArray,
  fixScrollBarVisibility,
} from "@/helper/common";
import Pages from "@/layouts/Pages";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { back, calenderSvg, clockSvg, confirmCheck } from "@/config/svg";
import {
  createBookingSlot,
  stripePaymentIntent,
  stripeSaveCardList,
  updateOrderData,
} from "@/services/api/fireFunction";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { SchedulingShimmer } from "@/components/shimmer";
import { Modal, Spiner } from "@/components/index";

import visaCardImage from "../../assets/img/visa.png";
import masterCardImage from "../../assets/img/master.png";
import jcbImage from "../../assets/img/jcb.png";
import unionPayImage from "../../assets/img/unionpay.png";
import americanPayImage from "../../assets/img/american.png";
import discoverCardImage from "../../assets/img/discover.png";
// import { getFireStoreDoc, updateUserData } from "@/services/firebase/fireStore";
import { CardForm, PaymentForm } from "@/components/payment/StripePaymentForm";

const Payment: React.FC = () => {
  let isContinueData = new Map()
  const {
    state,
    // modules,
    showAZChatBot,
    setShowAZChatBot,
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);

  let navigate = useNavigate();
  const [order, setOrder] = useState<any>(null);
  const [bookingData, setBookingData] = useState<any>(null);
  const [testUrl, setTestUrl] = useState("");
  const [paymentButtonState, setPaymentButtonState] = useState(false);
  const [searchParams] = useSearchParams();
  const [stepNumber, setStepNumber] = useState(1);
  const [ModalShow, setModalShow] = useState(false);
  const [stripePk, setStripePk] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState("");
  const [saveCheck, setSaveCheck] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [cardList, setCardList] = useState<any>([]);
  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [cardElement, setCardElement] = useState<any>(null);

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    if (paymentIntent !== null && order && stripePk) {
      paymentIntentData(order);
    }
  }, [saveCheck]);

  useEffect(() => {
    // console.log('state?.config_state', state?.config_state?.stripe)

    if (stripePk === null && state?.config_state?.stripe?.publishable_key) {
      setStripePk(loadStripe(state?.config_state?.stripe?.publishable_key))
    }
    if (cardElement === null && state?.config_state?.stripe_ui?.card_element) {
      setCardElement(state?.config_state?.stripe_ui?.card_element)
    }
  }, [state?.config_state?.stripe])


  useEffect(() => {
    // console.log('state?.config_state', state?.config_state?.stripe)
    if (userInfo?.authUId && userInfo?.orders && userInfo?.orders.length > 0 && state?.config_state?.stripe) {
      try {
        const order_data: any = atob(String(searchParams.get("metadata")));
        const orderItem = findItemFromArray(
          userInfo?.orders,
          "order_id",
          JSON.parse(order_data)?.order_id
        );
        const isCallingApi = isContinueData.get(JSON.parse(order_data)?.order_id)
        if (!isCallingApi && orderItem !== undefined) {
          isContinueData.set(orderItem?.order_id, 'calling...')
          setOrder(orderItem);
          if (paymentIntent === null && orderItem?.fulfillment_orders === undefined) {
            var payload = localStorage.getItem(userInfo?.orders[0]?.order_id + "booking")
              ? JSON.parse(atob(String(localStorage.getItem(userInfo?.orders[0]?.order_id + "booking"))))
              : null;
            setBookingData(payload)

            paymentIntentData(orderItem);
          }
          if ((orderItem?.fulfillment_orders !== undefined) && orderItem?.fulfillment_orders && orderItem?.fulfillment_orders.length > 0) {
            navigate(
              "/order-complete?metadata=" + base64Encode(orderItem.order_id)
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [userInfo, state?.config_state?.stripe]);

  const paymentIntentData = (orderItem: any) => {
    var paymentIntentPayload = {
      amount: orderItem?.total_price * 100, //required // smallest currency unit (e.g., 100 cents to charge $1.00, a zero-decimal currency)
      user_id: userInfo?.authUId, //Claire user id  //required
      //is_auto_payment_method: true, //Set to true while using stripe client SDK //required
      metadata: {
        order_id: orderItem?.order_id, // random number for now
      },
      // setup_future_usage: "off_session",
    };
    setRootLoader();
    stripePaymentIntent(paymentIntentPayload)
      .then((data: any) => {
        // console.log('data2', data)
        if (data?.data?.client_secret) {
          setClientSecret(data?.data?.client_secret);
          setPaymentIntent(data?.data);
          unSetRootLoader();
        } else if (data?.response?.data?.status === "failed") {
          showToastMessage("Something went wrong, try again", true);
        } else {
          showToastMessage("Something went wrong, try again", true);
        }
      })
      .catch((err: any) => {
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
        }
        unSetRootLoader();
      });
  };

  const handleBack = () => {
    if (stepNumber === 1) {
      navigate(-1);
    } else if (stepNumber === 4) {
      setStepNumber(1);
    }
    fixScrollBarVisibility();
  };

  const orderSubmit = (payment: any) => {
    // console.log('payment', payment)
    if (
      payment?.payment_status === "failed" ||
      payment?.status !== "succeeded"
    ) {
      showToastMessage("Payment failed!", true);
      unSetRootLoader();
    } else {
      //Check if Order has fulfillment, to prevent a second call  
      updateOrderData(order?.order_id, {
        payment_info: { ...payment },
        // status: "ph_visit_scheduled_success",
      }).then(() => {
        handleBooking().then(async (orderData) => {
          navigate(
            "/order-complete?metadata=" + base64Encode(order.order_id)
          );
          unSetRootLoader()
        })
      });
    }
  };

  const submitAppionment = () => {
    // setSelectedPayment("Stripe");
    fetchCardList();
    setStepNumber(4);
    fixScrollBarVisibility();
  }

  const handleBooking = async () => {
    setRootLoader();
    var payload = localStorage.getItem(userInfo?.orders[0]?.order_id + "booking")
      ? JSON.parse(atob(String(localStorage.getItem(userInfo?.orders[0]?.order_id + "booking"))))
      : null;
    if (payload) {
      return await createBookingSlot(payload)
        .then((response: any) => {
          // localStorage.setItem("isLogin", "true");
          // localStorage.removeItem("isOtpSubmit");
          // localStorage.setItem("isOtpVerify", "true");
          // nextStep()
          return response
        })
        .catch((err) => {
          if (err?.code === "ERR_NETWORK")
            showToastMessage(translated("error.network_connection_error"), true);
          // unSetRootLoader();
        });
    } else {
      return null
    }
  };

  const fetchCardList = () => {
    stripeSaveCardList(userInfo?.authUId as string)
      .then((data: any) => {
        if (data?.data?.total_cards > 0) {
          setCardList(data?.data?.data);
        } else {
          setCardList([]);
        }
      })
      .catch((err: any) => {
        setCardList([]);
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
          handleBack();
        }
      });
  };

  const CardImage = ({
    src,
    height,
    brand,
  }: {
    src: string;
    height: string;
    brand: string;
  }) => {
    return (
      <img
        src={src}
        height={height}
        alt={brand}
      />
    );
  };

  const handleRePayment = (payment_method_id: string) => {
    // console.log('hel', payment_method_id)
    setRootLoader();
    const paymentIntentData = {
      amount: userInfo?.orders[0]?.total_price * 100, //required // smallest currency unit (e.g., 100 cents to charge $1.00, a zero-decimal currency)
      user_id: userInfo?.authUId, //Claire user id  //required
      //is_auto_payment_method: true, //Set to true while using stripe client SDK //required
      metadata: {
        order_id: userInfo?.orders[0]?.order_id, // random number for now
      },
      setup_future_usage: "off_session",
      payment_method_id: payment_method_id,
    };

    stripePaymentIntent(paymentIntentData)
      .then((data: any) => {
        // unSetRootLoader();
        if (data?.data?.status === "succeeded") {
          orderSubmit(data?.data);
        } else {
          orderSubmit({
            payment_status: "failed",
          });
        }
      })
      .catch((err: any) => {
        // console.log('error', err)
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
          handleBack();
        } else showToastMessage(err, true);
        // unSetRootLoader();
        orderSubmit({
          payment_status: "failed",
        });
      });
  };

  return (
    <Pages>
      <section className="schedule-appointment-section">
        <div className="two-columns-layout">
          <div className="half-col left-col">
            {order &&
              <div className="orders-wrapper custom-mb-50">
                <h2 className="text-30 custom-mb-20 bold">
                  {translated("app_copy.your_lab_tests")}
                </h2>
                <h6 className="text-16 custom-mb-30">
                  {translated("app_copy.lab_order_with_id").replace(
                    "{#ORDERID#}",
                    order?.order_number
                  )}
                </h6>
                <ul className="lab-items-list custom-pl-50 custom-mb-20">
                  {order?.test_items.map((item: any, index: number) => (
                    <li key={index}
                      // onClick={() => urlSetting(item)}
                      className="l-item-li color-blue text-18">
                      {item?.name}
                    </li>
                  ))}
                </ul>
                <p className="text-12">
                  {translated("app_copy.lab_test_fee_instruction")}
                </p>
                <p className="text-12">
                  <label
                    onClick={() => {
                      setShowAZChatBot(!showAZChatBot);
                    }}
                    className="text-underline color-blue">
                    {translated("app_copy.help_me_understand_labs_fee")}
                  </label>
                </p>
              </div>
            }
          </div>
          <div className="half-col right-col">
            {mainLoader || remainingLoaderTime ? (
              <SchedulingShimmer />
            ) : order && (
              <PerfectScrollbar>
                <div className="order-checkout-wrapper">
                  <h2 className="text-30 custom-mb-5 bold">
                    {translated("app_copy.where_sample_collected")}
                  </h2>
                  {bookingData &&
                    <div className="flex-center-between custom-mb-15">
                      <p className="text-14 bold color-blue flex-center-between">
                        <img
                          src={clockSvg}
                          alt="Clock"
                          className="clock custom-mr-15"
                        />
                        {moment(bookingData?.start).format("LT")} -{" "}
                        {moment(bookingData?.end).format("LT")}
                      </p>
                      <p className="text-14 bold color-blue flex-center-between">
                        <img
                          src={calenderSvg}
                          alt="Calendar"
                          className="calendar custom-mr-15"
                        />
                        {moment(bookingData?.start).format("LL")}
                      </p>
                    </div>
                  }

                  <div className="custom-mb-30">
                    <p className="text-16 bold">{translated("app_copy.we_come_to_you")}</p>
                    <p className="address-details text-16">
                      <span>{order?.address?.street} </span>
                      <br />
                      <span>{order?.address?.city}, </span>

                      <span>{order?.address?.state} </span>
                      <span>{order?.address?.postal_code}</span>
                      <br />
                      <span>
                        {userInfo?.address[0]?.country}
                      </span>
                    </p>
                  </div>

                  <div className="sub-separator"></div>

                  <div className="custom-mt-30">
                    <div className="d-flex justify-content-between custom-mb-20">
                      <p className="text-16 bold">
                        {translated("app_copy.total")}
                      </p>
                      <p className="text-16 bold">
                        ${order?.total_price.toFixed(2)}
                      </p>
                    </div>
                    <p className="text-12 text-center ">
                      {translated(
                        "app_copy.claire_convenience_fee_instruction"
                      )}
                    </p>
                  </div>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="standard-btn"
                    onClick={submitAppionment}
                  >
                    <p className="text-18 bold">
                      {translated("cta.at_home_checkout_cta")}
                    </p>
                  </button>
                </div>
              </PerfectScrollbar>
            )}
          </div>
        </div>
        {/* )} */}
        {order && stepNumber === 4 && ModalShow === false && (
          <div className="offcanvas payment offcanvas-bottom show">
            <div className="offcanvas-header">
              <button type="button" onClick={() => { setStepNumber(1); }} className="btn-close text-reset">X</button>
            </div>
            <div className="offcanvas-body small">
              <PerfectScrollbar>
                <div className="stripe-payment-wrapper">
                  <div className="stripe-payment-inner">

                    {state?.credit_card_mock && cardList.length > 0 &&
                      <div className="scheduling-slots-wrapper ">
                        <ul className="scheduling-slots-available">
                          {
                            cardList.map((card_list: any, index: number) => (
                              <li
                                key={index.toString()}
                                //Set Button to disable:
                                onClick={() => {
                                  // setTimeout(() => {

                                  if (!paymentButtonState) {
                                    handleRePayment(card_list.id)
                                    setPaymentButtonState(true)
                                  }

                                  // }, 2000);
                                  setSelectedCard(card_list.id)
                                }
                                }
                                className={
                                  selectedCard === card_list.id
                                    ? "active save-card standard-btn slot-time-btn text-16"
                                    : "save-card standard-btn slot-time-btn text-16"
                                }
                              >
                                <>
                                  {card_list?.card?.brand === "visa" && (
                                    <CardImage
                                      src={visaCardImage}
                                      height="20"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                  {card_list?.card?.brand === "jcb" && (
                                    <CardImage
                                      src={jcbImage}
                                      height="35"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                  {card_list?.card?.brand === "master" && (
                                    <CardImage
                                      src={masterCardImage}
                                      height="30"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                  {card_list?.card?.brand === "american" && (
                                    <CardImage
                                      src={americanPayImage}
                                      height="30"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                  {card_list?.card?.brand === "unionpay" && (
                                    <CardImage
                                      src={unionPayImage}
                                      height="30"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                  {card_list?.card?.brand === "discover" && (
                                    <CardImage
                                      src={discoverCardImage}
                                      height="20"
                                      brand={card_list?.card?.brand}
                                    />
                                  )}
                                </>
                                <p className="card-text">{"  ****" + card_list?.card?.last4}
                                </p>
                                <p className="card-text">
                                  {"   Exp. " +
                                    card_list?.card?.exp_month +
                                    "/" +
                                    card_list?.card?.exp_year}
                                </p>
                              </li>
                            ))}
                        </ul>
                      </div>
                    }
                    {clientSecret === "" && stripePk && (mainLoader || remainingLoaderTime) ? (
                      <Spiner height="100px" width="100px" margin="auto" />
                    ) : (
                      <Elements options={options} stripe={stripePk}>
                        {cardElement ? (
                          <CardForm
                            orderSubmit={(paymentDetails) =>
                              orderSubmit(paymentDetails)
                            }
                            clientSecret={clientSecret}
                            saveCheck={saveCheck}
                            orderData={order}
                            handleSaveCheck={() => setSaveCheck(!saveCheck)}
                          />
                        ) : (
                          <PaymentForm
                            orderSubmit={(paymentDetails) =>
                              orderSubmit(paymentDetails)
                            }
                            clientSecret={clientSecret}
                            saveCheck={saveCheck}
                            orderData={order}
                            handleSaveCheck={() => setSaveCheck(!saveCheck)}
                          />
                        )}
                      </Elements>
                    )}
                  </div>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        )}
        {testUrl !== "" && (
          <div className="offcanvas payment offcanvas-bottom show">
            <div className="offcanvas-header">
              <button type="button" onClick={() => { setTestUrl(""); }} className="btn-close text-reset">X</button>
            </div>
            <div className="offcanvas-body small">
              <PerfectScrollbar>
                <iframe
                  style={{ width: "100%", height: "100%" }}
                  src={testUrl}
                  loading="lazy"
                  title="Custom title"
                ></iframe>
              </PerfectScrollbar>
            </div>
          </div>
        )}
        <Modal
          size={"sm"}
          modalTitle={""}
          show={ModalShow}
          modalClosingButton={false}
          handleClose={() => {
            setModalShow(false);
            navigate(routes?.root);
          }}
        >
          <div className="confirm-modal text-center">
            <img className="confirm-icon" src={confirmCheck} alt="confirm" />
            <h2 className="text-18 bold black custom-mt-30 custom-mb-30">
              {translated("app_copy.order_confirmed")}
            </h2>
            <div className="sub-separator"></div>
            <p className="text-16 custom-mb-30">
              {translated("app_copy.order_confirmed_description")}
            </p>
            <div className="width-236 custom-mt-20">
              <button
                className="standard-btn"
                onClick={() => {
                  setModalShow(false);
                  setStepNumber(1)
                  // navigate(routes?.orderPaymentSuccess);
                  navigate(
                    "/order-complete?metadata=" + base64Encode(order.order_id)
                  );
                }}
              >
                <p className="text-18">{translated("cta.ok")}</p>
              </button>
            </div>
            {/* <a className="text-18 bold color-blue custom-mt-30">
              Add to Calendar
            </a> */}
          </div>
        </Modal>
      </section>
    </Pages>
  );
};

export default Payment;
