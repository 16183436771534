/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { useState, useEffect, useContext, useRef } from "react";
// import { logoLogin } from "@/config/svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { emailValidations } from "@/helper/common";
import { AppContext } from "@/context/createContext";
import { Spiner } from "@/components/index";
// import PerfectScrollbar from "react-perfect-scrollbar";
import { auth } from '@/services/firebase'
// import { promisify } from "util";
import { v4 as uuidv4 } from 'uuid';
import "./style.scss";
// import { backArrow } from "@/config/svg";
// import routes from "@/config/routes";
const defaultPayloadData = `{
  "order_id":"ba47b445-2ed8-4caa-aed1-b7c67431c75d-1",
  "patient": {
      "first_name": "Jon",
      "last_name": "David",
      "middle_name": "",
      "email": "test@claire.health",
      "age": 33,
      "gender": "F",
      "race": "X",
      "date_of_birth": "19900101",
      "patient_class": "O",
      "ethnic_group": "U",
      "phone_number": "+19999999999",
      "city": "Pheonix",
      "state": "AZ",
      "address_line_1": "8907 Legacy Park Dr",
      "address_line_2": "",
      "postal_code": "85003",
      "is_abn": true,
      "country": "US"
  },
  "provider": {
      "first_name": "Jams",
      "last_name": "D",
      "npi_id": "456"
  },
  "insurance": {
    "payer_code": "t6y_7823_u3i2",
    "insurance_name": "Medicare",
    "insurance_address": "202 167th Ave, Bellevue, WA 98004",
    "insurance_relation": "self",
    "policy_number": "2345678549",
    "group_number": "2341467",
    "employer_name": "Claire",
    "workers_comp": "no"
 }
}`;
// const defaultPayloadDatas = `{
//   "order_id":"ba47b445-2ed8-4caa-aed1-b7c67431c75d-51-006",
//   "patient": {
//     "first_name": "Md",
//     "last_name": "Saifuddin",
//     "middle_name": "",
//     "email": "saifuddin+tempuser051@govegans.it",
//     "age": 33,
//     "gender": "M",
//     "race": "X",
//     "date_of_birth": "19900101",
//     "patient_class": "O",
//     "ethnic_group": "U",
//     "phone_number": "+19999999999",
//     "city": "Pheonix",
//     "state": "AZ",
//     "address_line_1": "8907 Legacy Park Dr",
//     "address_line_2": "",
//     "postal_code": "85003",
//     "is_abn": "true",
//     "country": "US"
//   },
//   "insurance": {
//   "payer_code": "t6y_7823_u3i2",
//   "insurance_name": "Medicare",
//   "insurance_address": "202 167th Ave, Bellevue, WA 98004",
//   "insurance_relation": "self",
//   "policy_number": "2345678549",
//   "group_number": "2341467",
//   "employer_name": "Claire",
//   "workers_comp": "no"
// },
//   "provider": {
//       "first_name": "Kabir",
//       "last_name": "Hossain",
//       "npi_id": "456"
//   }
// }`;
const TempUserCreateV2: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [email, setEmail] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [newUserId, setNewUserId] = useState('');
  // const [oldUserId, setOldUserId] = useState('');
  const [patientPayLoad] = useState(defaultPayloadData);
  const [patientPayLoadJSON, setPatientPayLoadJSON] = useState(JSON.parse(defaultPayloadData));
  const [orderId, setOrderId] = useState('');
  // const [oldOrderId, setOldOrderId] = useState('');
  // const [doctorId, setDoctorId] = useState('');
  const [shortLink, setShortLink] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [isEmailValidate, setIsEmailValidate] = useState(false);
  const [isPayLoadValidate, setIsPayLoadValidate] = useState(false);
  const { t } = useTranslation();
  const { state, showToastMessage } = useContext(AppContext);
  const configState = state?.config_state
  const lastRequestData = useRef<any>(null);
  const logs = useRef<any>([]);


  useEffect(() => {
    document.body.className = "Temp-User-Create";

    return () => {
      document.body.className = "";
    }
  }, []);
  /*  useEffect(() => {
     if (patientPayLoad.length > 0) {
       console.log({patientPayLoad});
       try {
         const patientPayLoadJson = JSON.parse(patientPayLoad);
         if(patientPayLoadJson?.patient && patientPayLoadJson?.patient?.email && emailValidations(patientPayLoadJson?.patient?.email, configState?.email_rules?.regex_validation)){
           setIsPayLoadValidate(true);
           setErrorMessage('')
         }
         else{
           setIsPayLoadValidate(false);
           setErrorMessage('email not found or invalid email')
         }
 
       console.log({patientPayLoadJson});
       } catch (error:any) {
         console.log({error});
         setIsPayLoadValidate(false)
         setErrorMessage(error?.message)
       }
       
     } 
   }, [patientPayLoad]); */
  useEffect(() => {
    if (patientPayLoad.length > 0) {
      console.log({ patientPayLoadJSON });
      let errorMessage = '';
      if (!(patientPayLoadJSON?.patient && patientPayLoadJSON?.patient?.email && emailValidations(patientPayLoadJSON?.patient?.email, configState?.email_rules?.regex_validation))) {
        errorMessage = 'email not found or invalid email'
      }
      if (!errorMessage && !(patientPayLoadJSON?.patient && patientPayLoadJSON?.patient?.first_name)) {
        errorMessage = 'First name Missing!'
      }
      if (!errorMessage && !(patientPayLoadJSON?.patient && patientPayLoadJSON?.patient?.last_name)) {
        errorMessage = 'Last name Missing!'
      }
      if (!errorMessage) {
        setIsPayLoadValidate(true);
      } else {
        setIsPayLoadValidate(false);
      }
      setErrorMessage(errorMessage)

    }
  }, [patientPayLoadJSON]);

  const addLog = async (data: any) => {
    // await logs.current.push(<div className="log-time">{new Date().toUTCString()}</div>)
    await logs.current.unshift(<pre className="log-data">{data}</pre>)
    await logs.current.unshift(<div className="log-time">{new Date().toUTCString()}</div>)
    // await logs.current.push(<pre className="log-data">{data}</pre>)
  }

  const createUser = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error('No base url specified for firebase functions')
    }
    setLoading(true);
    setErrorMessage('');
    setShortLink('');


    try {
      // const postData = { email, order_id: orderId, doctor_id: doctorId };
      // const postData = JSON.parse(patientPayLoad);
      const postData = patientPayLoadJSON;
      postData.order_id = `mock-${uuidv4()}`
      lastRequestData.current = postData;
      const token = await auth.currentUser?.getIdToken();
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/v1/ehr/init`
      const headers = {
        'x-data-payload-type': "static",
        'authorization': 'Bearer ' + token,
        'x-token': token ?? "",
      };
      await addLog(JSON.stringify({
        url,
        method: 'post',
        headers,
        postData
      }, null, 2))

      const response = await axios
        .post(
          `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/v1/ehr/init`,
          postData,
          { headers })
      setLoading(false);
      console.log({ data: response?.data })
      if (response?.data?.short_link)
        setShortLink(response?.data?.short_link);
      if (response?.data?.uid)
        setNewUserId(response?.data?.uid);
      if (response?.data?.email)
        setEmail(response?.data?.email);
      if (response?.data?.order_id)
        setOrderId(response?.data?.order_id);
      addLog(JSON.stringify({ response: response.data, status: response.status }, null, 2))
      return response
    } catch (error: any) {

      let errorMsg = '';
      const errorData = await error?.response?.data;
      addLog(JSON.stringify({ error: error?.response?.data, status: error?.response?.status }, null, 2))
      console.log({ errorData });

      // logs.current.initApi.push(JSON.stringify({ error: errorData}))
      setLoading(false);
      if (error?.code === 'ERR_NETWORK') {
        throw error;
      }
      console.error('Failed to load API!', error)
      if (error?.response?.data?.error?.error?.message)
        errorMsg = error?.response?.data?.error?.error?.message;
      else if (error?.response?.data?.error?.message)
        errorMsg = error?.response?.data?.error?.message;


      setErrorMessage(errorMsg);
      return error
    }
  }
  const updateStatus = async (status: string) => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error('No base url specified for firebase functions')
    }
    setStatusLoading(true);
    setErrorMessage('');
    if (!logs.current?.updateStatus)
      logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/v1/ehr/order/${orderId}/fulfillment-orders/${orderId}`
      const headers = {
        'authorization': 'Bearer ' + token,
        'x-user-id': uuid ?? "",
        'x-token': token ?? "",
      }
      addLog(JSON.stringify({
        url,
        method: 'post',
        headers,
        data: { status }
      }, null, 2))

      const response = await axios
        .post(url, { status }, { headers })
      setStatusLoading(false);

      addLog(JSON.stringify({ response: response.data, status: response.status }, null, 2))
      return response
    } catch (error: any) {
      setStatusLoading(false);
      addLog(JSON.stringify({ error: error?.response?.data, status: error?.response?.status }, null, 2))
      if (error?.code === 'ERR_NETWORK')
        throw error;
      console.error('Failed to load API!', error)
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error
    }
  }
  const sendCSATMail = async () => {
    if (!window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL) {
      console.error('No base url specified for firebase functions')
    }
    setStatusLoading(true);
    setErrorMessage('');
    if (!logs.current?.updateStatus)
      logs.current.updateStatus = [];
    //setShortLink('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const uuid = newUserId;
      const url = `${window._env_.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/order/${newUserId}/${orderId}/csat`;
      const headers = {
        'authorization': 'Bearer ' + token,
        'x-user-id': uuid ?? "",
        'x-token': token ?? "",
      }
      addLog(JSON.stringify({
        url,
        method: 'get',
        headers
      }, null, 2))

      const response = await axios
        .get(url, { headers })
      setStatusLoading(false);

      addLog(JSON.stringify({ response: response.data, status: response.status }, null, 2))
      return response
    } catch (error: any) {
      setStatusLoading(false);
      addLog(JSON.stringify({ error: error?.response?.data, status: error?.response?.status }, null, 2))
      if (error?.code === 'ERR_NETWORK')
        throw error;
      console.error('Failed to load API!', error)
      // addLog(JSON.stringify({ error: error?.response?.data},null,2))
      if (error?.response?.data?.error?.error?.message)
        setErrorMessage(error?.response?.data?.error?.error?.message);
      else if (error?.response?.data?.error?.message)
        setErrorMessage(error?.response?.data?.error?.message);

      return error
    }
  }

  const copyText = () => {
    window.navigator.clipboard.writeText(shortLink);
    showToastMessage("Short link copied to clipboard!");
  }

  return (
    <Pages>
      <section className="h-100 h-custom login">

        {/* <PerfectScrollbar> */}
        <div className="py-3 h-100">
          <div className="row">
            <div id="login-box" className="col-md-6 inner-content">
              {/* <div className="logo-login text-center">
                  <img
                    className="m-2 custom-mb-60 cursor-pointer"
                    src={logoLogin}
                    alt="logo"
                  />
                  <br />

                </div> */}

              {loading ? (
                <Spiner height="100px" width="100px" margin="auto" />
              ) : (
                <>{!newUserId ? <div className="row">
                  <div className="col-md-12">
                    <h2>Create User and Order</h2>
                    {/* <div className="form-group text-center patient_payload">
                        <textarea 
                          name="patient_payload"  
                          className="form-control"
                          disabled={loading}
                          cols={30} 
                          rows={20} 
                          value={patientPayLoad} 
                          onChange={(e) =>
                            setPatientPayLoad(e.target.value)
                          }></textarea>
                      </div> */}
                    <div className="form-group">
                      <label htmlFor="login-email">Email</label>
                      <input
                        type="text"
                        id="login-email"
                        autoFocus
                        autoComplete="off"
                        value={patientPayLoadJSON.patient.email}
                        onChange={(e) =>
                          setPatientPayLoadJSON((pre: any) => {
                            return { ...pre, patient: { ...patientPayLoadJSON.patient, email: e.target.value } }
                          })
                          //setEmail(e.target.value)
                        }
                        placeholder={t("app_copy.email")}
                        className="form-control custom-mb-30 width-320"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="login-first_name">First Name</label>
                      <input
                        type="text"
                        id="login-first_name"
                        autoFocus
                        autoComplete="off"
                        value={patientPayLoadJSON.patient.first_name}
                        onChange={(e) =>
                          setPatientPayLoadJSON((pre: any) => {
                            return { ...pre, patient: { ...patientPayLoadJSON.patient, first_name: e.target.value } }
                          })
                          //setEmail(e.target.value)
                        }
                        placeholder="First Name"
                        className="form-control custom-mb-30 width-320"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="login-last_name">Last Name</label>
                      <input
                        type="text"
                        id="login-last_name"
                        autoFocus
                        autoComplete="off"
                        value={patientPayLoadJSON.patient.last_name}
                        onChange={(e) =>
                          setPatientPayLoadJSON((pre: any) => {
                            return { ...pre, patient: { ...patientPayLoadJSON.patient, last_name: e.target.value } }
                          })
                          //setEmail(e.target.value)
                        }
                        placeholder="Last Name"
                        className="form-control custom-mb-30 width-320"
                      />
                    </div>
                    <div className="form-group text-center">
                      <div className="row mx-2 custom-mb-24 custom-mt-50">
                        <div className="custom-mb-15">
                          <button
                            disabled={!isPayLoadValidate}
                            style={{ padding: '10px 20px' }}
                            className="custom-btn btn-standard-gradient"
                            onClick={createUser}
                          >
                            <p className="btn-text bold small-text rainbow_purple-blue">
                              Create User
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 
                    <div className="form-group text-center">
                      <input
                        type="text"
                        id=""
                        autoFocus
                        autoComplete="off"
                        value={orderId}
                        onChange={(e) =>
                          setOrderId(e.target.value)
                        }
                        placeholder="Order ID"
                        className="form-control custom-mb-30 width-320"
                      />


                    </div>
                    <div className="form-group text-center">
                      <input
                        type="text"
                        autoFocus
                        autoComplete="off"
                        value={doctorId}
                        onChange={(e) =>
                          setDoctorId(e.target.value)
                        }
                        placeholder="Doctor ID"
                        className="form-control custom-mb-30 width-320"
                      />


                    </div> */}


                  {/* <div className="col-md-5">
                      <h2>Existing Order</h2>
                      <div className="d-flex">
                        <label htmlFor="">User ID:</label>
                        <input
                          type="text"
                          id=""
                          autoFocus
                          autoComplete="off"
                          value={oldUserId}
                          onChange={(e) =>                           
                            setOldUserId(e.target.value)
                          }
                          placeholder="User ID"
                          className="form-control custom-mb-30 width-320"
                        />
                      </div>
                      <div className="d-flex">
                        <label htmlFor="">Order ID:</label>
                        <input
                          type="text"
                          id=""
                          autoFocus
                          autoComplete="off"
                          value={oldOrderId}
                          onChange={(e) =>
                            setOldOrderId(e.target.value)
                          }
                          placeholder="Order ID"
                          className="form-control custom-mb-30 width-320"
                        />
                      </div>
                    
                      <div className="form-group text-center">
                        <div className="row mx-2 d-flex justify-content-center custom-mb-24 custom-mt-50">
                          <div className="custom-mb-15">
                            <button
                              disabled={(!oldUserId || !oldOrderId)}
                              className="custom-btn btn-standard-gradient"
                              onClick={()=>{
                                setNewUserId(oldUserId);
                                setOrderId(oldOrderId);
                              }}
                            >
                              <p className="btn-text bold small-text rainbow_purple-blue">
                                Update Order
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="text-center">
                      {shortLink && <span className="cursor-pointer small-text-supergray">
                        <p className="btn-text bold small-text rainbow_purple-blue" onClick={copyText}>
                          {shortLink}
                        </p>
                      </span>}
                      {errorMessage && <p className="small-text" style={{ color: 'red' }}>{errorMessage}</p>}

                    </div> */}
                </div> :
                  <div className="row d-flex">

                    <div className="col-md-8">
                      <div className="d-flex">
                        <label htmlFor="">Email: </label>
                        <label htmlFor="" style={{ margin: 'auto 10px', fontWeight: 'bold' }}>{email}</label>
                      </div>
                      <div className="d-flex">
                        <label htmlFor="">User ID: </label>
                        <label htmlFor="" style={{ margin: 'auto 10px', fontWeight: 'bold' }}>{newUserId}</label>
                      </div>
                      <div className="d-flex">
                        <label htmlFor="">Order ID:</label>
                        <label htmlFor="" style={{ margin: 'auto 10px', fontWeight: 'bold' }}>{orderId}</label>
                      </div>
                      <div className="form-group text-center">
                        {shortLink && <span className="cursor-pointer small-text-supergray">
                          <p className="btn-text bold small-text rainbow_purple-blue" onClick={copyText}>
                            {shortLink}
                          </p>
                        </span>}
                      </div>
                    </div>

                    <div className="col-md-12">
                      {statusLoading
                        ?
                        <Spiner height="100px" width="100px" margin="auto" />
                        :
                        <div className="button-group d-flex justify-content-center" style={{ marginBottom: '10px;' }}>
                          <div className="btn btn-primary" style={{ margin: 'auto 10px' }} onClick={() => { updateStatus('in_progress') }}>In progress</div>
                          {/* <div className="btn btn-primary" style={{margin:'auto 10px'}} onClick={()=>{updateStatus('ph_visit_started')}}>Phlebotomist Visit Started</div> */}
                          {/* <div className="btn btn-primary" style={{margin:'auto 10px'}} onClick={()=>{updateStatus('ph_visit_success')}}>ph_visit_success</div> */}
                          <div className="btn btn-primary" style={{ margin: 'auto 10px' }} onClick={() => { updateStatus('results_available') }}>Result Available</div>
                          <div className="btn btn-primary" style={{ margin: 'auto 10px' }} onClick={() => { sendCSATMail() }}>Send CSAT Email</div>
                        </div>
                      }
                    </div>
                  </div>
                }
                  <div className="text-center">
                    {errorMessage && <p className="small-text" style={{ color: 'red' }}>{errorMessage}</p>}

                  </div>
                </>

              )}

            </div>
            {logs.current.length > 0 && <div className="col-md-6"> <div className="log-history">
              {logs.current.length > 0 && logs.current.map((item: string) => item)}
            </div></div>}
          </div>
        </div>

        {/* </PerfectScrollbar> */}
      </section>
    </Pages>
  );
};

export default TempUserCreateV2;
