import routes from "./config/routes";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import MasterContent from "@/components/MasterContent";
import {
  Login,
  Response404,
  Signup,
  OrderList,
  DiagnosisReport,
  Action,
  ForgotPassword,
  Profile,
  OnBoarding,
  Fcm,
  Scheduling,
  CompoentsCom,
  ConsentStep,
  EmailVerify,
  WildHealth,
  WelcomeMember,
  Preferences,
  PaymentCardList,
  ResultView,
  Instruction,
  UserIndex,
  PasswordResetSuccess,
  OrderComplete,
  ResultBiomarker,
  PdfPage,
  FeedbackSteps,
  FeedbackSuccess,
  DeeplinkLanding,
  UpdateUserSteps,
  TempUserCreate,
  AddressValidation,
  OrderDetails,
  Settings,
  TempUserCreateV2,
  AppClip,
  OrderPDF,
  Chat,
  AzureChat,
  Payment,
  PreTest,
  AuthenticationSteps,
} from "@/pages/index";
import { isLogin } from "./helper/common";
import { AppContext } from "./context/createContext";
import { useContext } from "react";
import { Spiner } from "./components";
import { auth } from "./services/firebase";
import { useEffect } from 'react';

const user = auth.currentUser;
const AppRouter = () => (
  <Routes>
    <Route path={routes.duser} element={<DeeplinkLanding />} />
    <Route path="/" element={<MasterContent />}>

      <Route path={routes.chat} element={<Chat />} />
      <Route path={routes.azureChat} element={<AzureChat />} />
      <Route path={routes.appclip} element={<AppClip />} />
      {/** Protected Routes */}
      <Route path="/" element={<ProtectedArea />}>
        <Route path="/pdfpage" element={<PdfPage />} />
        <Route path={routes.fcm} element={<Fcm />} />
        <Route path={routes.compoentscom} element={<CompoentsCom />} />
        <Route
          path={routes.instruction}
          element={<Instruction />}
        />
        <Route path="/" element={<Authenticated />}>
          <Route path={routes.authenticationStep} element={<AuthenticationSteps />} />

          <Route path={routes.resultBoiMarker} element={<ResultBiomarker />} />
          <Route path="/" element={<OrderList />} />
          <Route path={routes.emailVerify} element={<EmailVerify />} />
          <Route path={routes.preTest} element={<PreTest />} />
          <Route path={routes.orderComplete} element={<OrderComplete />} />
          {/* <Route path={routes.passwordChange} element={<ChangePassword />} /> */}
          <Route path="/" element={<UserIndex />}>
            <Route path={routes.userProfile} element={<Profile />} />
            <Route path={routes.orderList} element={<OrderList />} />
            <Route path={routes.preferences} element={<Preferences />} />
            <Route path={routes.settings} element={<Settings />} />
            <Route path={routes.paymentEdit} element={<PaymentCardList />} />
          </Route>
          <Route path={routes.schedulingDeeplink} element={<Scheduling />} />
          <Route path={routes.confirmPayment} element={<Payment />} />
          <Route path={routes.labReport} element={<DiagnosisReport />} />
          <Route path={routes.resultView} element={<ResultView />} />
          <Route
            path={routes.addressValidation}
            element={<AddressValidation />}
          />
          <Route path={routes.orderDetails} element={<OrderDetails />} />
          <Route path={routes.orderListPDF} element={<OrderPDF />} />
          <Route path={routes.feedbackStep} element={<FeedbackSteps />} />
          <Route path={routes.feedbackSubmit} element={<FeedbackSuccess />} />
        </Route>
      </Route>

      {/** Public Routes */}
      <Route path="/" element={<PublicArea />}>
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.signup} element={<Signup />} />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      </Route>

      {/** Without Permission route */}
      <Route path="/*" element={<Response404 />}></Route>
      <Route path={routes.onBoarding} element={<OnBoarding />} />

      <Route path={routes.updateUserStep} element={<UpdateUserSteps />} />
      <Route path={routes.consent} element={<ConsentStep />} />
      <Route path={routes.action} element={<Action />} />
      <Route path={routes.welcomeMember} element={<WelcomeMember />} />
      <Route path={routes.testTempUserCreate} element={<TempUserCreate />} />
      <Route
        path={routes.testTempUserCreateV2}
        element={<TempUserCreateV2 />}
      />

      <Route
        path={routes.passwordResetSuccess}
        element={<PasswordResetSuccess />}
      />
    </Route>
    <Route path={routes.wildhealth} element={<WildHealth />} />
  </Routes>
);

function ProtectedArea() {
  return <Outlet />;
}

const Authenticated = () => {
  let location = useLocation();
  const { userInfo, state } = useContext(AppContext);

  const isLoginData = localStorage.getItem("isLogin");
  return state?.ui_loaded === false && userInfo?.authUId === null ?
    (
      <section className="h-custom category">
        <div className="container py-3 vh-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="d-flex align-items-center">
              <Spiner height="100px" width="100px" margin="auto" />
            </div>
          </div>
        </div>
      </section>
    ) :
    (userInfo?.authUId && isLogin(userInfo?.authUId)) ? (
      <Outlet />
    ) : (isLoginData === undefined || isLoginData === 'false') ? (
      <Navigate to={routes.login} state={{ from: location }} replace />
    ) : <Outlet />;
};

const PublicArea = () => {
  const { userInfo, state } = useContext(AppContext);
  const isLoginData = localStorage.getItem("isLogin");
  // console.log('userInfo?.authUId', userInfo?.authUId)
  return state?.ui_loaded === false ?
    (
      <section className="h-custom category">
        <div className="container py-3 vh-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="d-flex align-items-center">
              <Spiner height="100px" width="100px" margin="auto" />
            </div>
          </div>
        </div>
      </section>
    ) : userInfo?.authUId && isLogin(userInfo?.authUId) ? <Navigate to="/" /> : <Outlet />
};

export default AppRouter;
