/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";

import Pages from "@/layouts/Pages";
import { AppContext } from "@/context/createContext";
// import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import { heart } from "@/config/svg";
import { findItemFromArray } from "@/helper/common";
import moment from "moment";
const ResultView: React.FC = () => {
  const params = useParams();

  const { state, userInfo, translated } = useContext(AppContext);
  const [order, setOrder] = useState<any>(null);

  let navigate = useNavigate();
  // useEffect(() => {
  //   if (userInfo?.authUId) {
  //     // console.log('auth', auth?.currentUser?.emailVerified)
  //     if (isLogin(userInfo?.authUId) && !auth?.currentUser?.emailVerified) {
  //       navigate(routes.emailVerify);
  //     }
  //   }
  // }, [userInfo]);

  useEffect(() => {
    // console.log({userInfo,params});
    if (userInfo?.orders.length > 0 && params?.id) {

      setOrder(
        findItemFromArray(
          userInfo?.orders,
          "order_id",
          atob(String(params?.id))
        )
      );
    }
  }, [userInfo, params]);
  useEffect(() => {
    console.log('ResultView,tsx');

    document.body.className = "packages-page";
    return () => {
      document.body.className = "";
    };
  }, [state, userInfo]);

  return (
    <Pages>
      <section className="h-100 h-custom packages-section d-flex justify-content-center result">
        <div className="py-3 h-100">
          <div className="custom-mb-60 text-center width-558">
            <img
              className="mx-auto"
              src={heart}
              alt="test result"
              width="100"
              height="100"
            />
            <h1 className="start-page text-center result">
              {translated('app_copy.your_test_results_are_ready')}
            </h1>
            <p className="darkgrey-small-text text-center">
              {order && translated('app_copy.your_result_home_page_description', ['{#RESULT#}', '{#DATE#}'], [order?.order_id, moment(order?.booking?.start_at).format("MM/DD/YYYY")])}
            </p>
          </div>
          <div className="text-center width-180">
            <button
              className="col-12 custom-btn btn-standard-background button-small"
              onClick={() => navigate("/result-score/" + params.id)}
            >
              <p className="btn-text background-color">Your Claire Score</p>
            </button>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default ResultView;
