/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import welcomeMemberImage from "../../assets/img/welcome-member-image.png";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { AppContext } from "@/context/createContext";
const FeedbackSuccess: React.FC = () => {
  const { userInfo } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.className = "checkout-area welcome";

    return () => {
      document.body.className = "";
    };
  }, []);

  const handleContinue = () => {
    navigate(routes.root);
  };
  return (
    <Pages>
      <section className="h-100 h-custom schedule-container">
        <div className="row h-100">
          <div className="row align-items-center scrollcontainer1">
            <PerfectScrollbar>
              <div className="col-6 kyc-title custom-pl-100 h-100 purple">
                <div className="container-title">
                  <div className="cnt-bar custom-mb-20">
                    <p className="blue-small-bold-text">Thank you, {userInfo?.user_data?.first_name}!</p>
                  </div>
                  <h1 className="title-36">We appreciate your time!</h1>
                  <h2 className="text-16 custom-mt-20">
                    Thank you for taking part in sharing your experience with us. We want to do everything the best possible way for you.
                  </h2>

                  <div className="mb-3 text-center confirm-schedule width-236">
                    <button
                      className="custom-btn btn-ice-gradient purple"
                      onClick={handleContinue}
                    >
                      <p className="btn-text bold small-text blue">
                        Go back to orders
                      </p>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-6 h-100 dates-box position-relative">
                <img src={welcomeMemberImage} alt="Welcome member" className="welcome-img" />
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default FeedbackSuccess;
