/* eslint-disable react-hooks/exhaustive-deps */
import { AppContext } from "@/context/createContext";
import {
  Elements,
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { trash, edit, back } from "@/config/svg";
import {
  stripePaymentIntent,
  stripePaymentIntentUpdate,
  stripeSaveCardDelete,
  stripeSaveCardList,
  stripeSaveCardUpdate,
} from "@/services/api/fireFunction";
import { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Spiner from "../common/Spiner";
import DateMonthInput from "../input_type/DateMonthInput";
import { PaymentCardItemShimmer } from "../shimmer";
import PerfectScrollbar from "react-perfect-scrollbar";
// import visaCardImage from "../../assets/img/visa.png";
// import masterCardImage from "../../assets/img/master.png";
// import jcbImage from "../../assets/img/jcb.png";
// import unionPayImage from "../../assets/img/unionpay.png";
// import americanPayImage from "../../assets/img/american.png";
// import discoverCardImage from "../../assets/img/discover.png";

export const CardForm: React.FC<{
  orderSubmit: (paymentDetails: any) => void;
  clientSecret: string;
  orderData: any;
  saveCheck: boolean;
  handleSaveCheck: () => void;
}> = ({ orderSubmit, clientSecret, orderData, saveCheck, handleSaveCheck }) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    showToastMessage,
    // unSetRootLoader,
  } = useContext(AppContext);
  const handleSubmit = (stripe: any, elements: any) => async () => {
    setRootLoader();
    const cardElement = elements.getElement(CardElement);
    if (clientSecret) {
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            type: "card",
            card: cardElement,
          },
        })
        .then((data: any) => {
          if (data?.error) {
            showToastMessage(data?.error?.message, true);
          }
          if (data?.paymentIntent) {
            orderSubmit(data?.paymentIntent);
          }

          // unSetRootLoader();
        })
        .catch((error: any) => {
          // unSetRootLoader();
          orderSubmit({
            payment_status: "failed",
          });
        });
    }
  };

  return (
    <>
      <CardElement />

      {/* <div className="switch">
        <input
          type="checkbox"
          checked={saveCheck}
          onChange={() => handleSaveCheck()}
          className={mainLoader || remainingLoaderTime ? "common-shimmer" : ""}
        />
        <label
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer form-check-label"
              : "form-check-label"
          }
        >
          Save card to account
        </label>

        <span className="slider round"></span>
      </div> */}
      <div className="mb-3 text-center">
        <button
          className={
            mainLoader
              ? "common-shimmer standard-btn"
              : "standard-btn"
          }
          disabled={mainLoader}
          onClick={handleSubmit(stripe, elements)}
        >
          <p className="text-18 bold">
            Pay ${orderData?.total_price.toFixed(2)}
          </p>
        </button>
      </div>
    </>
  );
};

export const PaymentForm: React.FC<{
  orderSubmit: (paymentDetails: any) => void;
  clientSecret: string;
  orderData: any;
  saveCheck: boolean;
  handleSaveCheck: () => void;
}> = ({ orderSubmit, clientSecret, orderData, saveCheck, handleSaveCheck }) => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    mainLoader,
    remainingLoaderTime,
    // translated,
  } = useContext(AppContext);
  const handleSubmit = (stripe: any, elements: any) => async () => {
    // const cardElement = elements.getElement(PaymentElement);
    setRootLoader();
    if (clientSecret) {
      stripe
        .confirmPayment({
          elements,
          redirect: "if_required",
        })
        .then((data: any) => {
          //console.log("[payment]", data);
          if (data?.error) {
            showToastMessage(data?.error?.message, true);
          }
          if (data?.paymentIntent) {
            orderSubmit(data?.paymentIntent);
          }

          // unSetRootLoader();
        })
        .catch((err: any) => {
          // unSetRootLoader();
          orderSubmit({
            payment_status: "failed",
          });
        });
    }
  };

  return (
    <>
      <PaymentElement />
      {/* <div className="input-wrapper check_box custom-mt-30">
        <input
          type="checkbox"
          id="stripe-checkbox"
          autoComplete="off"
          checked={saveCheck}
          onChange={() => handleSaveCheck()}
          className={mainLoader || remainingLoaderTime ? "common-shimmer" : ""}
        />
        <label
          htmlFor="stripe-checkbox"
          className={
            mainLoader || remainingLoaderTime
              ? "common-shimmer text-14 bold color-black"
              : "text-14 bold color-black"
          }
        >
          Save card for future payments
        </label>
      </div> */}
      <div className="text-center custom-mt-30">
        <button
          className={
            mainLoader
              ? "common-shimmer standard-btn w-100"
              : "standard-btn w-100"
          }
          disabled={mainLoader}
          onClick={handleSubmit(stripe, elements)}
        >
          <p className="text-18 bold">
            Pay ${orderData?.total_price.toFixed(2)}
          </p>
        </button>
      </div>
    </>
  );
};
const StripePaymentForm: React.FC<{
  orderSubmit: (paymentDetails: any) => void;
  handleBack: () => void;
  orderData: any;
}> = ({ orderSubmit, orderData, handleBack }) => {
  const {
    userInfo,
    state,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  const stripePk = loadStripe(state?.config_state?.stripe?.publishable_key);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntent, setPaymentIntent] = useState<any>(null);
  const [cardElement, setCardElement] = useState(true);
  const [cardList, setCardList] = useState<any>([]);
  const [selectedCard, setSelectedCard] = useState("");
  const [editCard, setEditCard] = useState<any>(null);
  const [isCardUpdate, setIsCardUpdate] = useState<boolean>(true);
  const [updateCardData, setUpdateCardData] = useState<any>(null);
  const [saveCheck, setSaveCheck] = useState(true);
  const [cardFetch, setCardFetch] = useState(false);
  const location = useLocation()
  let count = 0;
  useEffect(() => {
    if (count === 0) {
      fetchCardList();
      count++;
      paymentIntentData();
      setCardElement(state?.config_state?.stripe_ui?.card_element);
    }
  }, []);

  useEffect(() => {
    if (paymentIntent !== null) {
      paymentIntentUpdate();
    }
  }, [saveCheck]);

  const paymentIntentData = () => {
    var paymentIntentPayload = {
      amount: orderData?.total_price * 100, //required // smallest currency unit (e.g., 100 cents to charge $1.00, a zero-decimal currency)
      user_id: userInfo?.authUId, //Claire user id  //required
      //is_auto_payment_method: true, //Set to true while using stripe client SDK //required
      metadata: {
        order_id: orderData?.order_id, // random number for now
      },
      setup_future_usage: "off_session",
    };
    setRootLoader();
    stripePaymentIntent(paymentIntentPayload)
      .then((data: any) => {
        if (data?.data?.client_secret) {
          setClientSecret(data?.data?.client_secret);
          setPaymentIntent(data?.data);
          unSetRootLoader();
        } else if (data?.response?.data?.status === "failed") {
          showToastMessage("Something went wrong, try again", true);
        } else {
          showToastMessage("Something went wrong, try again", true);
        }
      })
      .catch((err: any) => {
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
        }
        unSetRootLoader();
      });
  };

  const paymentIntentUpdate = () => {
    setRootLoader();

    var intentUpdatePayload = {
      off_session: saveCheck,
    };
    stripePaymentIntentUpdate(intentUpdatePayload, paymentIntent?.id)
      .then((data: any) => {
        unSetRootLoader();
        if (data?.data?.client_secret) {
          setPaymentIntent(data?.data);
          unSetRootLoader();
        } else if (data?.response?.data?.status === "failed") {
          showToastMessage("Something went wrong, try again", true);
        } else {
          showToastMessage("Something went wrong, try again", true);
        }
      })
      .catch((err: any) => {
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        unSetRootLoader();
      });
  };

  const appearance: Appearance = {
    theme: "stripe",
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  const fetchCardList = () => {
    setCardFetch(true);
    stripeSaveCardList(userInfo?.authUId as string)
      .then((data: any) => {
        if (data?.data?.total_cards > 0) {
          setCardList(data?.data?.data);
        } else {
          setCardList([]);
        }
        setCardFetch(false);
      })
      .catch((err: any) => {
        setCardFetch(false);
        // showToastMessage(err, true);
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
          handleBack();
        }
      });
  };

  const handleRePayment = () => async () => {
    setRootLoader();
    const paymentIntentData = {
      amount: orderData?.total_price * 100, //required // smallest currency unit (e.g., 100 cents to charge $1.00, a zero-decimal currency)
      user_id: userInfo?.authUId, //Claire user id  //required
      //is_auto_payment_method: true, //Set to true while using stripe client SDK //required
      metadata: {
        order_id: orderData?.order_id, // random number for now
      },
      setup_future_usage: "off_session",
      payment_method_id: selectedCard,
    };

    stripePaymentIntent(paymentIntentData)
      .then((data: any) => {
        unSetRootLoader();
        if (data?.data?.status === "succeeded") {
          orderSubmit(data?.data);
        } else {
          orderSubmit({
            payment_status: "failed",
          });
        }
      })
      .catch((err: any) => {
        if (err?.code === "ERR_NETWORK") {
          showToastMessage(translated("error.network_connection_error"), true);
          handleBack();
        } else showToastMessage(err, true);
        unSetRootLoader();
        orderSubmit({
          payment_status: "failed",
        });
      });
  };

  const handleDelete = (card: any) => {
    setCardFetch(true);
    setRootLoader();
    stripeSaveCardDelete(card?.id as string)
      .then((data: any) => {
        fetchCardList();
        setSelectedCard("");
        unSetRootLoader();
        showToastMessage("Successfully deleted card!");
      })
      .catch((err: any) => {
        unSetRootLoader();
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        // showToastMessage(err, true);
      });
  };

  const handleUpdateCard = () => {
    setCardFetch(true);
    setRootLoader();
    stripeSaveCardUpdate(updateCardData, editCard?.id as string)
      .then((data: any) => {
        fetchCardList();
        setSelectedCard("");
        setUpdateCardData(null);
        unSetRootLoader();
        setEditCard(null);
        showToastMessage("card updated successfully!");
      })
      .catch((err: any) => {
        unSetRootLoader();
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        // showToastMessage(err, true);
      });
  };

  return (
    <div className="card-payment-wrapper two-columns-layout">
      <div className="half-col left-col">
        {
          location.pathname !== '/bot' &&
          <button className="go-back-button">
            <img
              className="forward-icon"
              src={back}
              alt="back"
              onClick={handleBack}
            />
          </button>
        }

        <p className="text-18 bold color-blue custom-mb-20">Credit card</p>
        <h2 className="text-36 custom-mb-50">
          Enter your credit card information
        </h2>
        <div className="payment-container">
          {clientSecret !== "" &&
            cardList.length > 0 &&
            (cardFetch || mainLoader || remainingLoaderTime ? (
              <PaymentCardItemShimmer />
            ) : (
              <>
                {editCard === null ? (
                  cardList.map((card_list: any, index: number) => (
                    <div
                      key={index.toString()}
                      onClick={() => setSelectedCard(card_list.id)}
                      className={
                        selectedCard === card_list.id
                          ? "card-wrapper active"
                          : "card-wrapper"
                      }
                    >
                      <div className="custom-mb-20 d-flex align-items-center ">
                        <div className="custom-mr-20">
                          <p>{"**** **** ****" + card_list?.card?.last4}</p>
                          <p>
                            {"Exp. " +
                              card_list?.card?.exp_month +
                              "/" +
                              card_list?.card?.exp_year}
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <button
                            className="extra-small-btn custom-mr-15"
                            onClick={() => setEditCard(card_list)}
                          >
                            <img src={edit} width="18" height="18" alt="Next" />
                          </button>
                          <button
                            className="extra-small-btn"
                            onClick={() => handleDelete(card_list)}
                          >
                            <img
                              src={trash}
                              width="18"
                              height="18"
                              alt="Next"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="input-wrapper">
                      <input
                        type="text"
                        readOnly
                        autoComplete="off"
                        id="firstName"
                        name="firstName"
                        value={"**** **** ****" + editCard?.card?.last4}
                      />
                      <label htmlFor="firstName">Card number </label>
                    </div>
                    <DateMonthInput
                      oldSelection={
                        editCard?.card?.exp_year +
                        "-" +
                        editCard?.card?.exp_month
                      }
                      label="Exp. date"
                      submitData={(value, isValid) => {
                        var date = value.split("-");
                        var newDate = {
                          exp_month: date[1],
                          exp_year: date[0],
                        };
                        setIsCardUpdate(isValid);
                        setUpdateCardData(newDate);
                      }}
                    />
                  </>
                )}

                <br />
                {selectedCard !== "" && editCard === null && (
                  <div className="text-center">
                    <button
                      className={
                        mainLoader || remainingLoaderTime
                          ? "common-shimmer standard-btn"
                          : "standard-btn"
                      }
                      disabled={mainLoader || remainingLoaderTime}
                      onClick={handleRePayment()}
                    >
                      <p className="text-18 bold">
                        Pay ${orderData?.total_price.toFixed(2)}
                      </p>
                    </button>
                  </div>
                )}
                {editCard !== null && (
                  <>
                    <div className="d-flex">
                      <button
                        className="standard-btn custom-mr-20"
                        onClick={() => {
                          setEditCard(null);
                          setSelectedCard("");
                        }}
                      >
                        <p className="text-18 bold">
                          {translated("cta.cancel")}
                        </p>
                      </button>
                      <button
                        className="standard-btn"
                        disabled={!isCardUpdate}
                        onClick={() => handleUpdateCard()}
                      >
                        <p className="text-18 bold">
                          {translated("cta.update")}
                        </p>
                      </button>
                    </div>
                  </>
                )}
              </>
            ))}
        </div>
      </div>
      <div className="sub-separator-vertical"></div>

      <div className="half-col right-col align-block-center">
        <PerfectScrollbar>
          <div className="stripe-payment-wrapper">
            <div className="stripe-payment-inner">
              {clientSecret === "" && (mainLoader || remainingLoaderTime) ? (
                <Spiner height="100px" width="100px" margin="auto" />
              ) : (
                <Elements options={options} stripe={stripePk}>
                  {cardElement ? (
                    <CardForm
                      orderSubmit={(paymentDetails) =>
                        orderSubmit(paymentDetails)
                      }
                      clientSecret={clientSecret}
                      saveCheck={saveCheck}
                      orderData={orderData}
                      handleSaveCheck={() => setSaveCheck(!saveCheck)}
                    />
                  ) : (
                    <PaymentForm
                      orderSubmit={(paymentDetails) =>
                        orderSubmit(paymentDetails)
                      }
                      clientSecret={clientSecret}
                      saveCheck={saveCheck}
                      orderData={orderData}
                      handleSaveCheck={() => setSaveCheck(!saveCheck)}
                    />
                  )}
                </Elements>
              )}
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default StripePaymentForm;
