/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import routes from "@/config/routes";
import { aiChatIcon, userLogoImg, logoMobile, backArrow } from "@/config/svg";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "@/context/createContext";
import { isLogin } from "@/helper/common";
// import { Offline } from "react-detect-offline";
import { storageDownloadURL } from "@/services/firebase/fireStorage";
import { Spiner } from "@/components/index";
import { getAuth } from "@firebase/auth";
import { ToastContainer } from "react-toastify";
import { ResultStatusList } from "@/config/configarableVariable";
import { isMobile } from "react-device-detect";

const delayTime = 30000; //ms

export default function Header(): JSX.Element {
  let navigate = useNavigate();
  const [isLoggedIn, setIsLogin] = useState(false);
  // const [logoMobile, setLogoMobile] = useState<any>(null);
  // const [logo, setLogo] = useState<any>(null);
  const auth = getAuth();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [chatIcon, setChatIcon] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const lastOflineTime = useRef(Date.now() - delayTime);
  const [searchParams] = useSearchParams();

  const { userInfo, whiteLabeling, modules, showAZChatBot, setShowAZChatBot } =
    useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    // console.log('userInfo?.user_data', location)
    if (location.pathname === '/order-complete' || location.pathname === '/pre-test') {
      setChatIcon(false);
    } else if (location.pathname !== '/authentication' &&
      userInfo?.user_data?.mode === 'ai_chat' &&
      isLogin(userInfo?.authUId)) {
      setChatIcon(true);
    } else if (location.pathname === '/authentication' &&
      userInfo?.user_data?.mode === 'ai_chat' &&
      isLogin(userInfo?.authUId) &&
      (Number(searchParams.get("step")) === 2 ||
        Number(searchParams.get("step")) === 3 ||
        Number(searchParams.get("step")) === 4)) {
      setChatIcon(true);
    } else if (isLogin(userInfo?.authUId) && location.pathname === '/order-details') {
      setChatIcon(true);
    } else if (isLogin(userInfo?.authUId) && userInfo?.user_data?.mode !== 'ai_chat' && location.pathname !== '/authentication' && userInfo?.orders?.length > 0) {

      if (userInfo?.orders[0]?.status === ResultStatusList?.resultsAvailable) {
        setChatIcon(true);
      } else {
        setChatIcon(false);
      }
    } else if (userInfo?.user_data?.mode !== 'ai_chat' && location.pathname === '/authentication') {
      setChatIcon(false);
    } else {
      setChatIcon(false);
    }
  }, [location, userInfo]);

  useEffect(() => {
    // console.log('userInfo?.user_data', userInfo)
    if (userInfo?.authUId) {
      setIsLogin(isLogin(userInfo?.authUId));
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      userInfo?.non_essential?.profile_picture !== undefined &&
      userInfo?.non_essential?.profile_picture
    ) {
      setIsImageLoading(true);
      storageDownloadURL(
        "users/" + userInfo?.authUId + "/profile_picture"
      ).then((url: any) => {
        setPhotoURL(url);
        setIsImageLoading(false);
      });
    } else {
      setIsImageLoading(false);
    }

    // console.log('whiteLabeling', whiteLabeling)
  }, [userInfo?.authUId, userInfo?.non_essential?.profile_picture]);

  const changeToOffline = (status: boolean) => {
    if (!status && Date.now() > lastOflineTime.current + delayTime) {
      lastOflineTime.current = Date.now();
      // showToastMessage("You are offline!", true);
    }
  };

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty(
        "--app-height",
        `${document.documentElement.clientHeight}px`
      );
    };

    appHeight();
    window.addEventListener("resize", appHeight);
  });

  return (
    <div className="header-container">
      <ToastContainer />
      <header>
        {isMobile && (location.pathname).includes('result-biomarker-details') &&
          <img
            className="cursor-pointer"
            src={backArrow}
            width="70"
            height="70"
            alt="back"
            onClick={() => navigate(-1)}
          />
        }
        <div className="logo-wrapper">
          {/* <Link to="/"> */}
          {
            <img src={whiteLabeling?.logo ?? logoMobile} alt="logo" className="desktop-logo" />
          }
          {
            <img src={whiteLabeling?.logo ?? logoMobile} alt="logo" className="mobile-logo" />
          }
          {/* </Link> */}
        </div>

        {chatIcon === true && showAZChatBot === false &&
          <div
            className="az-chat show-only-mobile"
          >
            <button
              className="go-back-button"
              onClick={() => {
                setShowAZChatBot(!showAZChatBot);
              }}
            >
              {showAZChatBot ? "hide" :
                <img src={aiChatIcon} alt="logo" className="chatbot-img" />
              }
            </button>

          </div>
        }
        {isLoggedIn && (
          <>
            {modules?.is_login_flow &&
              auth?.currentUser?.email !== undefined && (
                <>
                  <div className="header-item">
                    <Link to={routes.userProfile} className=" ">
                      {isImageLoading ? (
                        <Spiner
                          height="20px"
                          width="20px"
                          className="header-profile-image-loader"
                          margin="auto"
                        />
                      ) : photoURL !== "" ? (
                        <img
                          className="m-2 user-img"
                          src={userLogoImg}
                          alt="user"
                          width={"50px"}
                        />
                      ) : (
                        <>
                          <img
                            className="m-2"
                            src={userLogoImg}
                            alt="user"
                            width={"50px"}
                          />
                        </>
                      )}
                    </Link>
                  </div>
                </>
              )}
          </>
        )}
      </header>
    </div>
  );
}
