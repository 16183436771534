/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import emailVerified3dImage from "../../../assets/img/verify-email-3d-girl.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppContext } from "@/context/createContext";
import { useNavigate } from "react-router-dom";
import { isLogin } from "@/helper/common";
import routes from "@/config/routes";
// import routes from "@/config/routes";
// import { AppContext } from "@/context/createContext";
const EmailVerified: React.FC = () => {
  const navigate = useNavigate();

  const { translated, userInfo } = useContext(AppContext);
  const handleContinue = () => {
    if (userInfo?.authUId && isLogin(userInfo?.authUId)) {
      const emailVerified = localStorage.getItem('emailVerify')
      if (emailVerified !== undefined && emailVerified !== null) {
        navigate(emailVerified);
      } else {
        navigate(routes.root);
      }
    } else
      navigate(routes.login);

    // navigate(routes.nonEssentialStep);
  };
  return (
    <section className="h-100 h-custom schedule-container">
      <div className="row h-100">
        <div className="row align-items-center scrollcontainer">
          <PerfectScrollbar>
            <div className="col-6 kyc-title custom-pl-100 h-100">
              <div className="container-title">
                <div className="cnt-bar custom-mb-20">
                  <p className="blue-small-bold-text">{translated('app_copy.you_almost_there')}</p>
                </div>
                <h1 className="kyc-flow">
                  {translated('app_copy.email_verified_successful')}
                </h1>
                <h2 className="kyc-flow custom-mt-40">
                  {translated("app_copy.you_can_proceed_purchase")}
                </h2>

                <div className="mb-3 text-center confirm-schedule width-236">
                  <button
                    className="custom-btn btn-standard-gradient"
                    onClick={handleContinue}
                  >
                    <p className="btn-text bold small-text rainbow_purple-blue">
                      Continue
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6 h-100 dates-box ">
              <img src={emailVerified3dImage} alt="Welcome member" />
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </section>
  );
};

export default EmailVerified;
