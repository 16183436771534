// import Spiner from "@/components/common/Spiner";
import Pages from "@/layouts/Pages";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
// import { useSearchParams } from "react-router-dom";
const AppClip: React.FC = () => {
  const [value, setValue] = useState<string>("");
  // const [searchParams] = useSearchParams();

  useEffect(() => {
    // const user_data: any = JSON.parse(
    //   atob(String(searchParams.get("metadata")))
    // );
    setValue(window.location.href)
  }, [])
  return (<Pages>
    {/* <Spiner height="100px" width="100px" margin="auto" /> */}
    <div className="qrcode">
      <QRCode
        value={value} />
    </div>
  </Pages>);
};

export default AppClip;
