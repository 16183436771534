/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
// import { ShimmerText } from "../../packages/Shimmer";

const SelectionCheckbox: React.FC<{
  attribute: AttributeType;
  config: any;
  oldSelection: any;
  multiSelection: boolean;
  submitData: (
    sContinue: boolean,
    key: string,
    value: string | string[]
  ) => void;
}> = ({ attribute, oldSelection, multiSelection, config, submitData }) => {
  const [updateValue, setUpdateValue] = useState<Array<string>>([]);
  // const [shimmer, setShimmer] = useState(true)
  // setTimeout(() => {
  //   setShimmer(false)
  // }, 200)
  const options = config?.options;
  const optionClicked = (newupdateValue: string) => {
    const noneValue = options.filter((e: any) => e.is_reset_option);
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        var newArray = multiSelection
          ? [...updateValue, dataProcess(element, newupdateValue).trimStart()]
          : [dataProcess(element, newupdateValue).trimStart()];
        setUpdateValue(newArray.filter((e: any) => e !== noneValue[0]));
      });
    } else {
      var newArray = multiSelection
        ? [...updateValue, newupdateValue]
        : [newupdateValue];
      setUpdateValue(newArray.filter((e: any) => e !== noneValue[0]));
    }
  };

  const optionUnselect = (value: string) => {
    var newArray = updateValue.filter(function (item: string) {
      return item !== value;
    });
    setUpdateValue(newArray);
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setUpdateValue(oldSelection.toString().split(","));
    }
  }, [oldSelection]);

  useEffect(() => {
    if (updateValue.length > 0) {
      submitData(
        inputValidation(attribute?.validation, updateValue[0]),
        attribute.key,
        updateValue.toString()
      );
    } else {
      submitData(false, attribute.key, updateValue.toString());
    }
  }, [updateValue]);

  const resetSelections = (value: string) => {
    if (value !== "") {
      setUpdateValue([value]);
    } else {
      setUpdateValue([]);
    }
  };
  return (
    // shimmer ?
    //   <ShimmerText line={options.length > 0 ? options.length : 2} gap={10} /> :
      <>
        {options.length > 0 &&
          options.map((option: any, index: number) => (
            <div className="" key={index.toString()}>
              <label className="form-check-label">
                {option.display_text}
                {!option.is_reset_option ? (
                  <input
                    id={option.key}
                    type="checkbox"
                    autoComplete="off"
                    checked={updateValue.includes(option.key)}
                    onChange={() =>
                      updateValue.includes(option.key)
                        ? optionUnselect(option.key)
                        : optionClicked(option.key)
                    }
                    className={
                      multiSelection
                        ? "form-check-input checkbox-check"
                        : "form-check-input radio-check"
                    }
                  />
                ) : (
                  <input
                    type="checkbox"
                    autoComplete="off"
                    checked={updateValue.includes(option.key)}
                    onChange={() =>
                      updateValue.includes(option.key)
                        ? resetSelections("")
                        : resetSelections(option.key)
                    }
                    className={
                      multiSelection
                        ? "form-check-input checkbox-check"
                        : "form-check-input radio-check"
                    }
                  />
                )}
                <span className="custom-pl-10 checkmark"></span>
              </label>
            </div>
          ))}
      </>
  );
};

export default SelectionCheckbox;
