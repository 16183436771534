/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Pages from "@/layouts/Pages";
import Rangemeter from "@/components/biomarker/Rangemeters/Rangemeter";
const CompoentsCom: React.FC = () => {
  useEffect(() => {
    document.body.className = "packages-page";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <Pages>
      <section className="h-100 h-custom packages-section">
        <div className="py-3 h-100">
          <div className="custom-mb-60">
            <h1 className="start-page text-center">Components</h1>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-4">

                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Rangemeter
                    rangValues={{
                      value: 1,
                      minLabel: '00',
                      maxLabel: '?180',
                      dotRange: true,
                      pointer: false,
                      enableInputBox: false,
                      valueLabel: (<>16 <span>%</span></>),
                      valueLabel2: (<>16 <span> {'>'}</span> 32 <span> {'>'}</span> 100</>),
                      ranges: [
                      ],
                    }}
                    unit=""
                    title="Practical number"
                  />
                </div>
                <div className="col-md-4">
                  <Rangemeter
                    rangValues={{
                      min: 0,
                      max: 180,
                      step: 1,
                      value: 150,
                      dotRange: true,
                      pointer: true,
                      enableInputBox: false,
                      ranges: [
                        { value: 60, color: "#2EC057", endLabel: ' < 60' },
                        { value: 60, color: "#F4E72D" },
                        { value: 60, color: "#FF0077" },
                        //{ value: 50, color: "#3AD2A4" },
                      ],
                    }}
                    unit="nm/mL"
                    title="Practical number"
                  />
                </div>
                <div className="col-md-4">
                  <Rangemeter
                    rangValues={{
                      min: 0,
                      max: 200,
                      step: 1,
                      value: 100,
                      pointer: true,
                      maxInfinite: true,
                      ranges: [
                        { value: 150, color: "#2EC057" },
                        { value: 50, color: "#FF0077" },
                        // { value: 50, color: "#F4E72D" },
                        //{ value: 50, color: "#3AD2A4" },
                      ],
                    }}
                    unit="nm/mL"
                    title="Practical number"
                  />
                </div>
                {/* <div className="col-md-12" style={{marginTop:'50px'}}>
                <RangeInput
                  rangValues={{
                    min: 0,
                    max: 140,
                    step: 1,
                    value: 100,
                    disabled: false,
                    enableInputBox: false,
                    ranges: [
                      { value: 30, color: "#FF008A" },
                      { value: 50, color: "#FFBF00" },
                      { value: 40, color: "#F4E72D" },
                      { value: 20, color: "#3AD2A4" },
                    ],
                  }}
                  unit="mg/dL"
                  title="Hba1c"
                />
              </div>
              <div className="col-md-12" style={{marginTop:'50px'}}>
                <RangeInput
                  rangValues={{
                    min: 0,
                    max: 100,
                    step: 1,
                    value: 10,
                    disabled: false,
                    enableInputBox: true,
                    ranges: [
                      { value: 50, color: "#FFBF00" },
                      { value: 30, color: "#FF008A" },                      
                      { value: 20, color: "#3AD2A4" },
                    ],
                  }}
                  unit="mg/dL"
                  title="Insulin"
                />
              </div> */}
              </div>
            </div>

          </div>
        </div>
      </section>
    </Pages>
  );
};

export default CompoentsCom;
