/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

const OrderPDF: React.FC<{
  order: any;
  showPDF: () => void;
}> = ({ order, showPDF }) => {

  useEffect(() => {
    document.body.className = "user-area";

    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <div className="d-flex justify-content-between">
        <object
          style={{ height: "627px", width: "100%", minWidth: "665px" }}
          data="https://files.labcorp.com/testmenu-d8/sample_reports/144980.pdf"
          type="application/pdf"
        >
          <div>No online PDF viewer installed</div>
        </object>
    </div>
  );
};

export default OrderPDF;