/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
import testAtHomeImage from "../../../src/assets/img/test-at-home.svg";

const ImageSectionInput: React.FC<{
  attribute: AttributeType;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, submitData }) => {
  const [inputValue, setInputValue] = useState(testAtHomeImage);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
    if (attribute?.url && attribute?.url !== "") {
      setInputValue(attribute?.url)
    }
  }, []);
  return (
    <div className='position-relative'>
      <img className="feedback-img" src={testAtHomeImage} alt="" />
    </div>
  );
};

export default ImageSectionInput;
