/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { casualLife } from "@/config/svg";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { useNavigate } from "react-router-dom";
// import routes from "@/config/routes";
const RecoverEmail: React.FC = () => {
  // const navigate = useNavigate();
  // const handleContinue = () => {
  //   navigate(routes.nonEssentialStep);
  // };
  return (
    <section className="h-100 h-custom schedule-container">
      <div className="row h-100">
        <div className="row align-items-center scrollcontainer">
          <PerfectScrollbar>
            <div className="col-6 kyc-title custom-pl-100 h-100">
              <div className="container-title">
                <div className="cnt-bar custom-mb-20">
                  <p className="blue-small-bold-text">Contact customer support</p>
                </div>
                <h1 className="kyc-flow">
                If you didn’t ask to change your email, follow this link to reset your sign-in email.
                </h1>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             

                {/* <div className="mb-3 text-center confirm-schedule width-236">
            <button
              className="custom-btn btn-standard-gradient"
              onClick={handleContinue}
            >
              <p className="btn-text bold small-text rainbow_purple-blue">
                Send new link
              </p>
            </button>
          </div> */}
              </div>
            </div>
            <div className="col-6 h-100 dates-box ">
              <img src={casualLife} alt="Welcome member" />
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </section>
  );
};

export default RecoverEmail;
