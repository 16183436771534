/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
// import { ShimmerText } from "../../packages/Shimmer";

const ConsentCheckbox: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
  oldSelection: any;
  autoFocus?: boolean;
  index: number;
  submitData: (sContinue: boolean, key: string, value: boolean) => void;
}> = ({ attribute, oldSelection, isShimmer, autoFocus, index, submitData }) => {
  const [inputValue, setInputValue] = useState(false);

  const updateInputValue = (newInputValue: boolean) => {
    setInputValue(newInputValue);
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);

  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);

  return (
    <>
      <input
        type="checkbox"
        id={"checkbox-" + index}
        autoComplete="off"
        autoFocus={autoFocus}
        checked={inputValue}
        onChange={() => updateInputValue(!inputValue)}
        className={isShimmer ? "common-shimmer" : ""}
      />
      <label htmlFor={"checkbox-" + index}>{attribute.display_text}</label>
    </>
  );
};

export default ConsentCheckbox;
