/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import Pages from "@/layouts/Pages";
import axios from "axios";
import { auth } from "@/services/firebase";
interface StateInterface {
  title: string;
  message: string;
  data?: string;
  uid?: string;
  dataOnly?: boolean;
}
interface PayloadInterface {
  title: string;
  message: string;
  data?: string;
  only_data?: boolean;
}
const Fcm: React.FC = () => {
  // const { state } = useContext(AppContext);
  const [data, setData] = useState<StateInterface>({
    title: "Claire dev" || "",
    message: "Test message from dev" || "",
    data: "",
    uid: "",
    dataOnly: false,
  });
  const refTitle = useRef<HTMLInputElement>(null);
  const refUid = useRef<HTMLInputElement>(null);
  const refMessage = useRef<HTMLTextAreaElement>(null);
  const refdata = useRef<HTMLTextAreaElement>(null);
  const refdataOnly = useRef<HTMLInputElement>(null);

  //console.log(navigator.userAgent);

  const clickHandaler = async () => {
    try {
      //const token = localStorage.getItem("token") as string;
      const token = await auth.currentUser?.getIdToken();

      const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL;
      // const baseUrl = "http://localhost:5001/test-rnd-73136/us-central1";
      const payload: PayloadInterface = {
        title: data.title,
        message: data.message,
      };
      // console.log({data});

      if (data.data) {
        try {
          const refDataJson = JSON.parse(data.data);
          payload.data = refDataJson;
          if (data.dataOnly) payload.only_data = true;
        } catch (error) {
          console.log(error);
        }
      }

      console.log({ payload_data: payload });
      // return ;

      const response = await axios.post(
        `${baseUrl}/api/notification-message/send/${data.uid ?? "all"}`,
        payload,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      console.log("success", response);
    } catch (error) {
      console.error("Failed to load API!", error);
    }
  };
  const update = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === "title") {
      setData({ ...data, title: event.target.value });
    } else if (event.target.name === "message") {
      setData({ ...data, message: event.target.value });
    } else if (event.target.name === "data") {
      setData({ ...data, data: event.target.value });
    } else if (event.target.name === "uid") {
      setData({ ...data, uid: event.target.value });
    } else if (event.target.name === "dataOnly") {
      setData({ ...data, dataOnly: !data.dataOnly });
    }
  };

  return (
    <Pages>
      <section className="h-100 h-custom category">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12">
              <div className="card card-registration card-registration-2">
                <div className="card-body p-0">
                  <div className="px-5 pb-5">
                    <div className="d-flex justify-content-between align-items-center mb-5">
                      <h3 className="fw-bold mb-0 text-black mt-5">
                        Send a Notification
                      </h3>
                    </div>
                    <hr className="my-4" />
                    <div className="row d-flex justify-content-center">
                      <div className="col-5">
                        <div style={{ display: "block" }}>
                          <input
                            type="text"
                            name="uid"
                            value={data.uid}
                            onChange={update}
                            ref={refUid}
                            className="form-control my-1"
                            placeholder="UserId"
                          />
                          <input
                            type="text"
                            name="title"
                            value={data.title}
                            onChange={update}
                            ref={refTitle}
                            className="form-control my-1"
                            placeholder="Title"
                          />
                          <textarea
                            ref={refMessage}
                            name="message"
                            onChange={update}
                            className="form-control my-1"
                            defaultValue={data.message}
                            placeholder="Message"
                          ></textarea>
                          <textarea
                            ref={refdata}
                            name="data"
                            onChange={update}
                            className="form-control my-1"
                            defaultValue={data.data}
                            placeholder="extra data format should be valid json"
                          ></textarea>
                        </div>

                        <div className="d-flex justify-content-center">
                          <label htmlFor="data-only" className="my-2">
                            Data Only
                          </label>
                          <input
                            ref={refdataOnly}
                            type="checkbox"
                            value="true"
                            name="dataOnly"
                            checked={data.dataOnly}
                            onChange={update}
                            className="mx-1"
                            id="data-only"
                          />
                          <button
                            onClick={clickHandaler}
                            className="btn btn-primary"
                          >
                            Send message
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default Fcm;
