/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AttributeType } from "@/interface/index";
// import { dataProcess, inputValidation } from "@/helper/common";
import RangeInput from "./RangeInput/RangeInput";
import { download } from "@/config/svg";

const BioMarkerInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  submitData: (sContinue: any, key: string, value: string | null) => void;
}> = ({ attribute, oldSelection, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const [isSlider, setIsSlider] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>([]);
  const fileInput = useRef<any>();
  // const updateInputValue = (newInputValue: string) => {
  //   if (attribute?.data_process?.length > 0) {
  //     attribute.data_process.forEach((element: string) => {
  //       setInputValue(dataProcess(element, newInputValue).trimStart());
  //     });
  //   } else {
  //     setInputValue(newInputValue);
  //   }
  // };

  const handleFileChange = async (event: any) => {
    const newFile = event.target.files[0];
    if (newFile) {
      setUploadedFile([...uploadedFile, newFile]);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
    setIsSlider(false)
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      true,
      attribute.key,
      null
    );
  }, [inputValue]);
  return (
    <div className="biomarker">
      <input
        type="file"
        onChange={(e) => handleFileChange(e)}
        ref={fileInput}
        hidden={true}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="bio">
            {/* <div className="bio-item clearfix d-flex justify-content-between">
              <div className="ps-2">
                <h4>Upload test</h4>
                <p>Upload your latest results</p>
              </div>
              <div className="icon" onClick={() => setIsSlider(false)}>
                <img
                  className="forward-icon"
                  src={forward}
                  width="20"
                  height="20"
                  alt="Next"
                />
              </div>
            </div> 
            <div className="bio-item clearfix d-flex justify-content-between">
              <div className="ps-2" id={attribute.key}>
                <h4>Set your values</h4>
                <p>Play with our ranges</p>
              </div>
              <div className="icon" onClick={() => setIsSlider(false)}>
                <img
                  className="forward-icon"
                  src={forward}
                  width="20"
                  height="20"
                  alt="Next"
                />
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      {!isSlider ? (
        <div className="row">
          <div className="slider-section custom-mb-16 custom-mt-16">
            <div className="col-md-12">
              <RangeInput
                rangValues={{
                  min: 0,
                  max: 200,
                  step: 1,
                  value: 100,
                  disabled: false,
                  enableInputBox: false,
                  ranges: [
                    { value: 70, color: "#FF008A" },
                    { value: 40, color: "#FFBF00" },
                    { value: 40, color: "#F4E72D" },
                    { value: 50, color: "#3AD2A4" },
                  ],
                }}
                unit=""
                title="Partical number"
              />
            </div>
            <div className="col-md-12">
              <RangeInput
                rangValues={{
                  min: 0,
                  max: 140,
                  step: 1,
                  value: 100,
                  disabled: false,
                  enableInputBox: false,
                  ranges: [
                    { value: 30, color: "#FF008A" },
                    { value: 50, color: "#FFBF00" },
                    { value: 40, color: "#F4E72D" },
                    { value: 20, color: "#3AD2A4" },
                  ],
                }}
                unit="mg/dL"
                title="Hba1c"
              />
            </div>
            <div className="col-md-12">
              <RangeInput
                rangValues={{
                  min: 0,
                  max: 100,
                  step: 1,
                  value: 10,
                  disabled: false,
                  enableInputBox: true,
                  ranges: [
                    { value: 50, color: "#FFBF00" },
                    { value: 30, color: "#FF008A" },
                    { value: 20, color: "#3AD2A4" },
                  ],
                }}
                unit="mg/dL"
                title="Insulin"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          {uploadedFile.length > 0 ? (
            <div className="uploaded-file">
              <button className="btn upload-btn" onClick={handleClick}>
                Choose a file
              </button>
              <ul className="list-group">
                {uploadedFile.length > 0 &&
                  uploadedFile.map((value: any, index: number) => (
                    <li className="list-group-item" key={index.toString()}>
                      {value.name}
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <div className="upload-section text-center">
              <button className="btn upload-btn" onClick={handleClick}>
                Choose a file
              </button>
              <div className="drag-section text-center">
                <img
                  className="download-icon"
                  src={download}
                  width="20"
                  height="20"
                  alt="Next"
                />
                <p className="drag-label">or drag it here</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BioMarkerInput;
