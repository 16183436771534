/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ShimmerTitle, ShimmerText, ShimmerButton } from "../../packages/Shimmer/index";

const ChangePasswordShimmer: React.FC = () => {
  return (
    <>
      <div className="information-panel width-558">
        <ShimmerTitle line={1} gap={15} variant="primary" />
      </div>
      <div className="information-panel text-center">

        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerButton />
      </div>
    </>
  );
};

export default ChangePasswordShimmer;
