/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppContext } from "@/context/createContext";
import { back } from "@/config/svg";
import { useNavigate } from "react-router-dom";
import { fixScrollBarVisibility } from "@/helper/common";

const Instruction: React.FC = () => {
  const { translated } = useContext(AppContext);
  let navigate = useNavigate();

  useEffect(() => {
    fixScrollBarVisibility();
  }, []);

  return (
    <Pages>
      <section className="appointment-instructions-section">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            <button
              className="go-back-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img className="" src={back} alt="Next" />
            </button>
            <p className="text-18 bold color-blue custom-mb-20">Instructions</p>
            <h2 className="text-36 custom-mb-25">
              Here are some instructions to follow
            </h2>
          </div>
          <div className="half-col right-col">
            <PerfectScrollbar>
              <div className="instructions-wrapper">
                <div className="custom-mb-30">
                  <p className="text-18 bold color-blue custom-mb-5">
                    {translated("app_copy.home_information_first_title")}
                  </p>
                  <h2 className="text-32 custom-mb-10">
                    {translated("app_copy.home_information_first_header")}
                  </h2>
                  <p className="text-16 black">
                    {translated("app_copy.home_information_first_description")}
                  </p>
                </div>

                <div className="custom-mb-30">
                  <p className="text-18 bold color-blue custom-mb-5">
                    {translated("app_copy.home_information_second_title")}
                  </p>
                  <h2 className="text-32 custom-mb-10">
                    {translated("app_copy.home_information_second_header")}
                  </h2>
                  <p className="text-16 black">
                    {translated("app_copy.home_information_second_description")}
                  </p>
                </div>

                <div className="custom-mb-30">
                  <p className="text-18 bold color-blue custom-mb-5">
                    {translated("app_copy.home_information_third_title")}
                  </p>
                  <h2 className="text-32 custom-mb-10">
                    {translated("app_copy.home_information_third_header")}
                  </h2>
                  <p className="text-16 black">
                    {translated("app_copy.home_information_third_description")}
                  </p>
                </div>

                <div className="custom-mb-30">
                  <p className="text-18 bold color-blue custom-mb-5">
                    {translated("app_copy.home_information_forth_title")}
                  </p>
                  <h2 className="text-32 custom-mb-10">
                    {translated("app_copy.home_information_forth_header")}
                  </h2>
                  <p className="text-16 black">
                    {translated("app_copy.home_information_forth_description")}
                  </p>
                </div>

                <div className="custom-mb-30">
                  <p className="text-18 bold color-blue custom-mb-5">
                    {translated("app_copy.home_information_fifth_title")}
                  </p>
                  <h2 className="text-32 custom-mb-10">
                    {translated("app_copy.home_information_fifth_header")}
                  </h2>
                  <p className="text-16 black">
                    {translated("app_copy.home_information_fifth_description")}
                  </p>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default Instruction;
