/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { back } from "@/config/svg";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import routes from "@/config/routes";
import { AttributeType } from "@/interface/index";

import {
  DateInput,
  EmailInput,
  NumberInput,
  PhoneInput,
  ProgressBar,
  SelectedOption,
  StringInput,
  ConsentLink,
  CheckBoxInput,
  SelectionCheckbox,
  SelectedDropdown,
  CapturedImage,
  ZipcodeInput,
  // SelectionSwitch,
  SwitchSelection,
  TitleType,
  // BioMarkerListInput,
  PasswordInput,
  OTPInput,
  GenderInput,
  Modal,
  ContentSelectionOption,
  AppointmentSelection,
} from "@/components/index";
import {
  getFireStoreDoc,
  setUserData,
  updateUserData,
} from "@/services/firebase/fireStore";
import { base64Encode, isLogin } from "@/helper/common";
import {
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";

// import stepData?.onboarding from "../../step.json";
// import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { toast } from "react-toastify";
import {
  commonSentOTP,
  commonVerifyOTP,
  createBookingSlot,
  createStripeCustomer,
} from "@/services/api/fireFunction";

const UpdateUserSteps: React.FC = () => {
  const auth = getAuth();
  const {
    stepData,
    modules,
    showToastMessage,
    // newAnonymousUser,
    logoutUser,
    userInfo,
    mainLoader,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    translated,
    updatePrefetchData
  } = useContext(AppContext);

  const [ModalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isContinue, setIsContinue] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [otpValidate, setOtpValidate] = useState(false);
  const [isMock, setIsMock] = useState(false);
  const [showMock, setShowMock] = useState(false);
  const [onEssentialData, setOnEssentialData] = useState<any>([]);
  const [previousData, setPreviousData] = useState<any>({});
  const [essentialStep, setEssentialSteps] = useState<any>([]);
  const [modulePath, setModulePath] = useState<string>("");
  const [progress, setProgress] = useState(
    Number(searchParams.get("step") ? searchParams.get("step") : 1)
  );
  const [currentStep, setCurrentStep] = useState<any>();
  const [currentStepValue, setCurrentStepValue] = useState<any>({});
  const [selectedSlot, setSelectedSlot] = useState<any>(null);

  const { search } = useLocation();

  useEffect(() => {
    const currentStep = Number(
      searchParams.get("step") ? searchParams.get("step") : 1
    );
    setProgress(currentStep);
    if (
      stepData?.onboarding?.steps &&
      stepData?.onboarding.steps[currentStep - 1]?.conditions !== undefined
    ) {
      var conditions: any =
        stepData?.onboarding.steps[currentStep - 1]?.conditions;
      if (conditions.length > 0) {
        setRootLoader();
        conditions.forEach((item: any) => {
          getFireStoreDoc("users/" + userInfo?.authUId).then((keys: any) => {
            if (
              keys !== undefined &&
              keys[item.scope] !== undefined &&
              keys[item.scope]
            ) {
              if (keys[item.scope][item.attribute] !== item.value) {
                handleContinue();
                unSetRootLoader();
                return false;
              } else {
                unSetRootLoader();
              }
            } else {
              unSetRootLoader();
            }
          });
        });
      }
    }
    if (
      stepData?.onboarding?.steps &&
      stepData?.onboarding.steps[currentStep - 1]?.is_active !==
      undefined &&
      stepData?.onboarding.steps[currentStep - 1]?.is_active === false
    ) {
      nextStep();
    }

    if (
      stepData?.onboarding?.steps &&
      stepData?.onboarding.steps[currentStep - 1]?.id ===
      "id_change_password" &&
      userInfo?.user_data?.sign_flow_completed === true
    ) {
      navigate(routes?.root);
    } else if (
      stepData?.onboarding?.steps &&
      stepData?.onboarding.steps[currentStep - 1]?.id ===
      "id_change_password" &&
      userInfo?.user_data?.temp_pwd_changed === true
    ) {
      nextStep();
    }
  }, [search, stepData?.onboarding]);
  useEffect(() => {
    if (stepData?.onboarding?.steps?.length > 0) {
      setEssentialSteps(stepData?.onboarding?.steps);
      setCurrentStep(stepData?.onboarding?.steps[progress - 1]);
      setModulePath(stepData?.onboarding?.store_path);
    }

    if (stepData?.onboarding?.steps?.length < progress) {
      // showToastMessage("!setPreviousData", true);
      navigate(routes.root);
    }

    if (modulePath !== "") {
      const essentialData = localStorage.getItem(String(userInfo?.authUId))
        ? JSON.parse(
          atob(String(localStorage.getItem(String(userInfo?.authUId))))
        )
        : {};
      setPreviousData(essentialData);
    }
  }, [
    stepData?.onboarding,
    progress,
    searchParams,
    modulePath,
    currentStep,
  ]);

  useEffect(() => {
    setIsContinue(false);
  }, [progress]);

  // useEffect(() => {
  //   document.body.className = "essential-data-page";

  //   return () => {
  //     document.body.className = "";
  //   };
  // }, []);

  const sentOTP = () => {
    setRootLoader();
    var OTPPayload = {
      email: currentStepValue?.email ?? previousData.email,
      phone: currentStepValue?.phone ?? previousData.phone,
    };

    commonSentOTP(OTPPayload)
      .then((response: any) => {
        if (response?.data?.success) {
          showToastMessage("OTP sent successfully!");
          // nextStep();
          unSetRootLoader();
        }
        if (response?.data?.error) {
          unSetRootLoader();
          showToastMessage(response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage("Something went wrong", true);
      });
  };

  const verifyOTP = (InputOTP: string) => {
    setRootLoader();
    commonVerifyOTP({
      email: currentStepValue?.email ?? previousData.email,
      phone:
        currentStepValue?.phone ??
        previousData.phone ??
        userInfo?.user_data?.phone,
      otp: InputOTP,
    })
      .then((res: any) => {
        if (res?.data?.success) {
          // showToastMessage("OTP verified successfully!");
          unSetRootLoader();
          setOtpValidate(true);
          setTimeout(() => {
            nextStep();
          }, 2000);
        }
        if (res?.data?.error) {
          setOtpValidate(false);
          unSetRootLoader();
          showToastMessage(res?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };
  useEffect(() => {
    if (modules?.onboarding === false) {

      navigate(routes.root);
    }
    document.body.className = "essential-area pw";

    return () => {
      document.body.className = "";
    };
  }, []);

  const resentOTP = () => {
    setRootLoader();

    commonSentOTP({
      email: "",
      phone: currentStepValue?.phone ?? previousData.phone,
    })
      .then((res: any) => {
        unSetRootLoader();
        // if (res?.data?.success) {
        //   showToastMessage("OTP Resent successfully!");
        // }
        if (res?.response?.data?.error) {
          showToastMessage(res?.response?.data?.error?.message, true);
        }
      })
      .catch((error) => {
        unSetRootLoader();
        showToastMessage(error, true);
      });
  };

  const checkEmailExist = () => {
    setRootLoader();
    fetchSignInMethodsForEmail(
      auth,
      currentStepValue?.email ?? previousData.email
    )
      .then((signInMethods) => {
        unSetRootLoader();
        if (
          signInMethods.indexOf(
            EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          ) !== -1 ||
          signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !==
          -1
        ) {
          toast.error("This email already used, please try other!");

          // User can sign in with email/password.
        } else {
          nextStep();
        }
      })
      .catch((error) => {
        unSetRootLoader();
        toast.error("Something went wrong!");
        // Some error occurred, you can inspect the code: error.code
      });
  };

  const changePassword = async () => {
    if (auth?.currentUser) {
      const credential = EmailAuthProvider.credential(
        auth?.currentUser?.email ?? "",
        atob(String(localStorage.getItem("temppass")))
      );
      reauthenticateWithCredential(auth?.currentUser, credential)
        .then(() => {
          // User re-authenticated.
          if (auth?.currentUser) {
            updatePassword(
              auth?.currentUser,
              currentStepValue?.password ??
              previousData.password ??
              atob(String(localStorage.getItem("creadValue")))
            )
              .then(() => {
                unSetRootLoader();
                localStorage.removeItem("temppass");
                // showToastMessage("Password changed successfully!");
              })
              .catch((error) => {
                if (error.code === "auth/requires-recent-login") {
                  // showToastMessage(
                  //  "Please re-login again to change the password!",
                  //  true
                  // );
                  setModalShow(true);
                  // setTimeout(() => {
                  //  unSetRootLoader();
                  //  logoutUser();
                  //  navigate(routes.login);
                  // }, 1000);
                }
                if (error.code === "auth/weak-password") {
                  unSetRootLoader();

                  showToastMessage(
                    "Password should be at least 6 characters!",
                    true
                  );
                }
              });
          }
        })
        .catch((error) => {
          showToastMessage(error?.message, true);
        });
    }
  };

  const saveChangePassword = async () => {
    getFireStoreDoc("users/" + userInfo?.authUId).then(async (keys: any) => {
      if (userInfo?.authUId && userInfo?.user_data !== null) {
        await updateUserData(userInfo?.authUId, {
          "user_data.temp_pwd_changed": true,
        });
      }
    });
  };
  const signUp = async () => {
    if (userInfo?.authUId) {
      const essentialData = localStorage.getItem(String(userInfo?.authUId))
        ? JSON.parse(
          atob(String(localStorage.getItem(String(userInfo?.authUId))))
        )
        : {};

      // const essentialData = {};
      // console.log('userInfo?.user_data', userInfo?.user_data)
      if (userInfo?.user_data !== null) {
        getFireStoreDoc("users/" + userInfo?.authUId).then(
          async (data: any) => {
            if (userInfo?.authUId) {
              await updateUserData(userInfo?.authUId, {
                [modulePath]: {
                  ...data[modulePath],
                  sign_flow_completed: true,
                  ...essentialData,
                },
                is_mock: isMock,
                // url_used: modules?.is_login_flow ? false : true
              });
            }
          }
        );
      } else {
        await setUserData(userInfo?.authUId, {
          [modulePath]: {
            sign_flow_completed: true,
            ...essentialData,
          },
          is_mock: isMock,
          // url_used: modules?.is_login_flow ? false : true
        });
      }
      // unSetRootLoader();
    }
  };

  const handleBooking = async () => {
    setRootLoader();
    var payload = {
      type: "GETLABS",
      user_id: userInfo?.authUId,
      order_id: String(userInfo?.orders[0]?.order_id),
      resource: {
        name: selectedSlot?.resource?.name,
        id: selectedSlot?.resource?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      start: selectedSlot.start,
      end: selectedSlot.end,
    };
    createBookingSlot(payload)
      .then((response: any) => {
        createStripeCustomer({
          user_id: userInfo?.authUId,
        }).then(async () => {
          localStorage.setItem("isLogin", "true");
          localStorage.removeItem("isOtpSubmit");
          localStorage.setItem("isOtpVerify", "true");
          const localOrgCode = localStorage.getItem("orgCode");
          updatePrefetchData(localOrgCode)
          unSetRootLoader();
          const orderData = {
            order_id: String(userInfo?.orders[0]?.order_id),
          };
          navigate(
            routes.confirmPayment +
            "?metadata=" +
            base64Encode(JSON.stringify(orderData))
          );
        });
      })
      .catch((err) => {
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        unSetRootLoader();
      });
  };

  const nextStep = async () => {
    if (progress === stepData?.onboarding?.steps?.length) {
      setIsContinue(false);
      setRootLoader();
      signUp()
        .then(async () => {
          await createStripeCustomer({
            user_id: userInfo?.authUId,
          }).then(async () => {
            localStorage.setItem("isLogin", "true");
            localStorage.removeItem("isOtpSubmit");
            localStorage.setItem("isOtpVerify", "true");
            const localOrgCode = localStorage.getItem("orgCode");
            updatePrefetchData(localOrgCode)
            unSetRootLoader();
            navigate(routes.root);
          });
          setIsContinue(true);
        })
        .catch(() => {
          setRootLoader();
        });
    } else if (
      userInfo?.user_data?.temp_pwd_changed !== true &&
      stepData?.onboarding.steps[progress - 1]?.id === "id_change_password"
    ) {
      setIsContinue(false);
      setRootLoader();
      await changePassword()
        .then(async () => {
          await saveChangePassword();
          unSetRootLoader();
          if (userInfo?.user_data?.sign_flow_completed === true) {
            const localOrgCode = localStorage.getItem("orgCode");
            updatePrefetchData(localOrgCode)
            localStorage.setItem("isLogin", "true");
            localStorage.removeItem("isOtpSubmit");
            localStorage.setItem("isOtpVerify", "true");
            navigate(routes.root);
          } else {
            const nextStep = progress + 1;
            setProgress(nextStep);
            setIsContinue(false);
            navigate(routes.updateUserStep + "?step=" + nextStep);
          }
        })
        .catch(() => {
          unSetRootLoader();
        });
    } else {
      const nextStep = progress + 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(routes.updateUserStep + "?step=" + nextStep);
    }
  };
  const handleContinue = () => {
    if (currentStep?.id === "id_phone") {
      sentOTP();
    } else if (currentStep?.id === "id_otp") {
      // verifyOTP();
      sentOTP();
    } else if (
      currentStepValue["email"] !== undefined &&
      currentStep?.id === "id_email"
    ) {
      checkEmailExist();
    } else if (currentStep?.id === "id_appointment") {
      handleBooking();
    } else {
      nextStep();
    }
  };

  const handleBack = () => {
    if (userInfo?.authUId && progress === 1 && isLogin(userInfo?.authUId)) {
      navigate(routes.userProfile);
    } else if (
      userInfo?.authUId &&
      progress === 1 &&
      !isLogin(userInfo?.authUId)
    ) {
      navigate(routes.consent);
    } else {
      const nextStep = progress - 1;
      setProgress(nextStep);
      setIsContinue(false);
      navigate(routes.updateUserStep + "?step=" + nextStep);
    }
  };

  const handleSkip = () => {
    if (
      currentStep.skip_to_finish !== undefined &&
      currentStep.skip_to_finish === true
    ) {
      navigate(routes.root);
    } else if (currentStep?.skip_to && currentStep?.skip_to !== "") {
      stepData?.onboarding?.steps?.map((step: any, index: number) => {
        if (step.id === currentStep?.skip_to) {
          const nextStep = index + 1;
          setProgress(nextStep);
          setIsContinue(false);
          navigate(routes.updateUserStep + "?step=" + nextStep);
        }
      });
    } else {
      if (userInfo?.authUId && isLogin(userInfo?.authUId)) {
        navigate(routes.userProfile);
      } else if (userInfo?.authUId && !isLogin(userInfo?.authUId)) {
        navigate(routes.root);
      }
    }
  };

  const submitData = async (isContinue: boolean, key: string, value: any, validation_only?: boolean) => {
    if (
      key !== "biomarker" &&
      key !== "biomarker_values" &&
      key !== "password" &&
      key !== "confirm_password" &&
      key !== "otp"
    ) {
      setCurrentStepValue({
        ...currentStepValue,
        [key]: value,
      });
      if (value !== undefined && value !== "" && key !== "otp" &&
        validation_only !== true) {
        localStorage.setItem(
          String(userInfo?.authUId),
          btoa(
            JSON.stringify({
              ...previousData,
              ...currentStepValue,
              [key]: value,
            })
          )
        );
      }
    }

    if (key === "password") {
      localStorage.setItem("creadValue", btoa(value));
    }

    if (key === "confirm_password") {
      localStorage.setItem("ccreadValue", btoa(value));
    }

    if (key === "phone") {
      setIsNext(isContinue);
    }

    const newData = [
      ...onEssentialData.filter((data: any) => {
        return data.key !== key;
      }),
      {
        isContinue,
        key,
        inputId: currentStep?.id,
        value,
      },
    ];
    setOnEssentialData(newData);
    const filterData = newData.filter(item => item.inputId === currentStep?.id && item?.isContinue === false)
    // console.log('data', filterData);
    if (filterData.length > 0) {
      setIsContinue(false);
    } else if (key === "password") {
      const cpassword = atob(String(localStorage.getItem("ccreadValue")));
      if (cpassword === value) {
        setIsContinue(true);
      } else {
        setIsContinue(false);
      }
    } else {
      setIsContinue(isContinue);
    }
    if (isContinue && key === "otp") {
      verifyOTP(value as string);
    }
  };

  const inputType = (attribute: any, index: number) => {
    const oldData = attribute?.validation?.user_validate === undefined &&
      (Object.keys(previousData).length > 0 &&
        previousData[attribute?.key]) ?
      previousData[attribute?.key]
      : (userInfo?.user_data !== null
        && Object.keys(userInfo?.user_data).length > 0
        && userInfo?.user_data[attribute?.key] !== undefined)
        ? userInfo?.user_data[attribute?.key] : ""
    return (
      <div
        key={index?.toString()}
        className={"input-wrapper " + attribute?.type}
      >
        {attribute?.type === "string" && (
          <StringInput
            attribute={attribute}
            index={index}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={oldData}
            isShimmer={mainLoader || remainingLoaderTime}
            autoFocus={!index}
          />
        )}
        {attribute?.type === "gender" && (
          <GenderInput
            attribute={attribute}
            index={index}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            isShimmer={mainLoader || remainingLoaderTime}
            autoFocus={!index}
          />
        )}
        {attribute?.type === "email" && (
          <EmailInput
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            isShimmer={mainLoader || remainingLoaderTime}
            autoFocus={!index}
          />
        )}
        {attribute?.type === "zip_code" && (
          <ZipcodeInput
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            autoFocus={!index}
          />
        )}
        {attribute?.type === "date" && (
          // eslint-disable-next-line react/jsx-no-undef
          <DateInput
            attribute={attribute}
            index={index}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={oldData}
          />
        )}
        {attribute?.type === "number" && (
          // eslint-disable-next-line react/jsx-no-undef
          <NumberInput
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            isShimmer={mainLoader || remainingLoaderTime}
            autoFocus={!index}
          />
        )}
        {attribute?.type === "phone" && (
          // eslint-disable-next-line react/jsx-no-undef
          <PhoneInput
            attribute={attribute}
            index={index}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            autoFocus={!index}
          />
        )}
        {attribute?.type === "consent" && (
          <ConsentLink
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              // setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key] ??
              userInfo?.user_data[attribute?.key] ??
              false
            }
          />
        )}
        {attribute?.type === "boolean" && (
          <CheckBoxInput
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key] ??
              userInfo?.user_data[attribute?.key] ??
              false
            }
            autoFocus={!index}
          />
        )}
        {attribute?.type === "dropdown" && (
          <SelectedDropdown
            attribute={attribute}
            config={currentStep?.config}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
          />
        )}
        {attribute?.type === "switch_selection" &&
          attribute?.key !== "attestation_of_no_emergency" && (
            <SwitchSelection
              attribute={attribute}
              submitData={(iContinue, key, value) => {
                setIsContinue(iContinue);
                submitData(iContinue, key, value);
              }}
              oldSelection={
                previousData[attribute?.key]
                  ? previousData[attribute?.key]
                  : userInfo?.user_data[attribute?.key]
              }
            />
          )}
        {attribute?.type === "section_title" &&
          (attribute?.key === "password_must" ? (
            <div className="pw-requirments custom-mt-40">
              <p className="text-18 bold">{attribute?.display_text}</p>
            </div>
          ) : (
            <TitleType attribute={attribute} />
          ))}
        {attribute?.type === "switch_selection" && (
          <SwitchSelection
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
          />
        )}
        {attribute?.type === "selection_checkbox" && (
          <SelectionCheckbox
            multiSelection={currentStep.multi_selection}
            attribute={attribute}
            config={currentStep?.config}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
          />
        )}
        {/* {attribute?.type === "selection_switch" && (
          <SelectionSwitch
            multiSelection={currentStep.multi_selection}
            attribute={attribute}
            config={currentStep?.config}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
          />
        )} */}
        {attribute?.type === "capture_image" && (
          <CapturedImage
            attribute={attribute}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key]
                ? previousData[attribute?.key]
                : userInfo?.user_data[attribute?.key]
            }
          />
        )}
        {attribute?.type === "selection" && (
          <SelectedOption
            multiSelection={currentStep.multi_selection}
            attribute={attribute}
            config={currentStep?.config}
            fetch_options={currentStep?.config?.fetch_options ?? false}
            oldSelection={[]}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
          />
        )}

        {attribute?.type === "content_selection" && (
          <ContentSelectionOption
            multiSelection={currentStep.multi_selection}
            attribute={attribute}
            config={currentStep?.config}
            fetch_options={currentStep?.config?.fetch_options ?? false}
            oldSelection={[]}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
          />
        )}

        {attribute?.type === "appointment_selection" && (
          <AppointmentSelection
            attribute={attribute}
            config={currentStep?.config}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
              setSelectedSlot(value)
            }}
          />
        )}
        {attribute?.type === "password" && (
          <>
            <PasswordInput
              attribute={attribute}
              index={index}
              submitData={(iContinue, key, value) => {
                setIsContinue(iContinue);
                submitData(iContinue, key, value);
              }}
              oldSelection={
                previousData[attribute?.key] ? previousData[attribute?.key] : ""
              }
              compareValidationData={
                attribute?.validation?.compare_attribute &&
                  localStorage.getItem("creadValue")
                  ? atob(String(localStorage.getItem("creadValue")))
                  : null
              }
              isShimmer={mainLoader || remainingLoaderTime}
              autoFocus={!index}
            />
          </>
        )}
        {attribute?.type === "otp" && (
          <OTPInput
            attribute={attribute}
            index={index}
            submitData={(iContinue, key, value) => {
              setIsContinue(iContinue);
              submitData(iContinue, key, value);
            }}
            oldSelection={
              previousData[attribute?.key] ? previousData[attribute?.key] : ""
            }
            otpValidate={otpValidate}
            isShimmer={mainLoader || remainingLoaderTime}
            resentOTP={resentOTP}
            autoFocus={!index}
          />
        )}
        {attribute?.type === "readonly_list" && (
          <div className="pw-requirments custom-mt-15">
            {attribute?.options?.map((instruction: any, index: number) => (
              <p className="instruction-item text-14" key={index.toString()}>
                {instruction?.display_text}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <Pages>
      <section className="create-password-section">
        <div className="two-columns-layout">
          <div className="half-col left-col background-lilla">
            {currentStep?.back_enabled !== false && (
              <button className="go-back-button" onClick={handleBack}>
                <img
                  className={
                    mainLoader || remainingLoaderTime ? "common-shimmer" : ""
                  }
                  src={back}
                  alt="back"
                />
              </button>
            )}

            {currentStep?.progress_enabled !== false && (
              <ProgressBar
                totalStep={Math.max(
                  ...essentialStep?.map((i: any) => i?.display_index ?? 0)
                )}
                progress={Number(currentStep?.display_index)}
              />
            )}
            <p
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-18 bold color-blue custom-mb-20"
                  : "text-18 bold color-blue custom-mb-20"
              }
            >
              {currentStep?.header}
            </p>
            <h2
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-36 custom-mb-25"
                  : "text-36 custom-mb-25"
              }
            >
              {currentStep?.title}
            </h2>
            <p
              className={
                mainLoader || remainingLoaderTime
                  ? "common-shimmer text-16 custom-mb-40"
                  : "text-16 custom-mb-40"
              }
            >
              {currentStep?.description}
            </p>
          </div>
          <div className="half-col right-col align-block-center">
            <div>
              {previousData !== null && essentialStep?.length > 0 && (
                <>
                  {currentStep?.template === "input" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "input_checkbox" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "selection_checkbox" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "selection_switch" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "selection" &&
                    // currentStep?.config?.options !== undefined &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}

                  {currentStep?.template === "capture_image" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}

                  {currentStep?.template === "input_biomarker" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "input_password" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {currentStep?.template === "static_component" &&
                    currentStep?.attributes?.map(
                      (attribute: AttributeType, index: number) =>
                        inputType(attribute, index)
                    )}
                  {progress === stepData?.onboarding?.steps?.length && (
                    <div
                      onDoubleClick={() => {
                        setShowMock(!showMock);
                      }}
                    >
                      <p
                        className="custom-mb-24"
                        style={{
                          display: showMock ? "block" : "none",
                        }}
                      >
                        <span
                          style={{
                            margin: "10px",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          Is Mock ?
                        </span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            autoComplete="off"
                            checked={isMock}
                            onChange={() => {
                              setIsMock(!isMock);
                            }}
                            className=""
                          />
                          <span className="slider round"></span>
                        </label>
                      </p>
                    </div>
                  )}
                </>
              )}

              <div className="custom-mt-50 text-center">
                {currentStep?.template === "static_component" ? (
                  <button
                    className={
                      mainLoader || remainingLoaderTime
                        ? "common-shimmer standard-btn"
                        : "standard-btn"
                    }
                    disabled={!isNext || mainLoader || remainingLoaderTime}
                    onClick={handleContinue}
                  >
                    <p className="text-18 bold color-blue">
                      {currentStep?.primary_button_text ??
                        translated("cta.next")}
                    </p>
                  </button>
                ) : (
                  <button
                    className={
                      mainLoader || remainingLoaderTime
                        ? "common-shimmer standard-btn"
                        : "standard-btn"
                    }
                    disabled={!isContinue || mainLoader || remainingLoaderTime}
                    onClick={handleContinue}
                  >
                    <p className="text-18 bold color-blue">
                      {currentStep?.primary_button_text ??
                        translated("cta.next")}
                    </p>
                  </button>
                )}

                {currentStep?.skip_to !== undefined && (
                  <button
                    className="standard-btn custom-mt-20"
                    onClick={() => {
                      handleSkip();
                    }}
                  >
                    <p className="text-18 bold color-blue">
                      {currentStep?.skip_button_text ?? translated("cta.skip")}
                    </p>
                  </button>
                )}
              </div>
            </div>
            <Modal
              size={"sm"}
              modalTitle={"Session Timeout!"}
              show={ModalShow}
              modalClosingButton={false}
              handleClose={() => {
                setModalShow(false);
                // logoutUser();
                // navigate(routes.login);
              }}
            >
              <div className="confirm-modal text-center">
                {/* <img
                    className="confirm-icon"
                    src={confirmCheck}
                    alt="confirm"
                  /> */}
                {/* <h2 className="text-18 bold black custom-mb-30">
                  Session Timeout!
                </h2>
                <div className="sub-separator"></div> */}
                <p className="text-16 custom-mb-30">
                  Requires recent login to change the password.
                </p>
                {
                  <div className="width-236 custom-mt-20">
                    <button
                      className="custom-btn btn-standard-background button-small"
                      onClick={() => {
                        setModalShow(false);
                        logoutUser();
                        navigate(routes.login);
                      }}
                    >
                      <p className="btn-text blue">{translated("cta.ok")}</p>
                    </button>
                  </div>
                }
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </Pages>
  );
};

export default UpdateUserSteps;
