/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
// import { ShimmerTitle } from "../../packages/Shimmer";

const EmailInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  isShimmer?: boolean;
  autoFocus?: boolean;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, isShimmer, oldSelection, autoFocus, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  // const [shimmer, setShimmer] = useState(true)
  // setTimeout(() => {
  //   setShimmer(false)
  // }, 200)
  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };
  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    // shimmer ?
    //   <ShimmerTitle line={1} gap={10} /> :
    <>
    <input
        type="email"
        autoFocus={autoFocus}
        autoComplete="off"
        readOnly={attribute?.enabled !== undefined && attribute?.enabled === false}
        value={inputValue}
        onChange={(e) => updateInputValue(e.target.value)}
        placeholder={attribute.placeholder_text}
        className={ isShimmer? "common-shimmer form-control": "form-control" }
      />
      {attribute?.instruction && <label className="form-label up-label">{attribute?.instruction}</label>}
      
    </>
  );
};

export default EmailInput;
