/* eslint-disable react-hooks/exhaustive-deps */
// import Spiner from "@/components/common/Spiner";
import { AppContext } from "@/context/createContext";
import { findItemFromArray } from "@/helper/common";
import Pages from "@/layouts/Pages";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Payment } from "@/components/index";
import { updateOrderData } from "@/services/api/fireFunction";
const AzureChat = () => {
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState(null);
  const [isChatActive, setIsChatActive] = useState(true);
  const { userInfo, showToastMessage } = useContext(AppContext);

  const closeChat = () => {
    setIsChatActive(false);
  };

  const minimizeChat = () => {
    setIsChatActive(false);
  };
  const activeChat = () => {
    setIsChatActive(true);
  };

  useEffect(() => {
    window.chatRequested();
  }, []);

  useEffect(() => {
    if (userInfo?.authUId && userInfo?.orders) {
      try {
        const order_data = atob(String(searchParams.get("metadata")));
        const orderItem = findItemFromArray(
          userInfo?.orders,
          "order_id",
          order_data
        );
        if (orderItem !== undefined) {
          setOrder(orderItem);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [userInfo, userInfo]);

  const orderSubmit = (payment) => {
    if (
      payment?.payment_status === "failed" ||
      payment?.status !== "succeeded"
    ) {
      showToastMessage("Payment failed!", true);
    } else {
      updateOrderData(order?.order_id, {
        payment_info: { ...payment },
        status: "ph_visit_scheduled_success",
      });
    }
  };

  const handleBack = () => {
    // navigate(-1);
  };

  return (
    <Pages>
      <section
        className="schedule-appointment-section chat-wrapper"
        style={{
          display:
            order && order?.booking && order?.payment_info === undefined
              ? "none"
              : "block",
        }}
      >
        <div className="azure-bot">
          <div className={`chatbot-btn ${isChatActive ? "hidden" : ""}`}>
            <button
              className="small-btn"
              onClick={() => {
                activeChat();
              }}
            >
              AZ
            </button>
          </div>
          <div
            className="chatbot-chat-container"
            style={{
              display:
                (order &&
                  order?.booking &&
                  order?.payment_info === undefined) ||
                isChatActive === false
                  ? "none"
                  : "block",
            }}
          >
            <div className="chat-header">
              <p className="text-14 bold color-white">Message us</p>
              <div className="d-flex justify-content-between">
                <span
                  className="text-14 bold color-white cursor-pointer"
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    marginRight: "16px",
                  }}
                  onClick={() => minimizeChat()}
                >
                  -
                </span>
                <span
                  className="text-14 bold color-white cursor-pointer"
                  onClick={() => closeChat()}
                >
                  X
                </span>
              </div>
            </div>
            <div className="chat-body">
              {/* <iframe
                src="https://healthcare-bot-l2kst5sf2gc3w.azurewebsites.net/"
                loading="lazy"
                title="Custom title"
              ></iframe> */}
              <div id="webchat" role="main" watermark="false"></div>
            </div>
          </div>
        </div>
      </section>
      {order && order?.booking && order?.payment_info === undefined && (
        <section className="schedule-appointment-section">
          <Payment
            selectedPayment={"Stripe"}
            handleBack={handleBack}
            product={null}
            order={order}
            submitedOrder={orderSubmit}
          />
        </section>
      )}
    </Pages>
  );
};

export default AzureChat;
