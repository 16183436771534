/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AppContext } from "@/context/createContext";
import Modal from "../common/Modal";
// import { ShimmerText } from "../../packages/Shimmer";

const ConsentLink: React.FC<{
  attribute: AttributeType;
  isShimmer?: boolean;
  oldSelection: any;
  submitData: (sContinue: boolean, key: string, value: any) => void;
}> = ({ attribute, isShimmer, oldSelection, submitData }) => {
  const [inputValue, setInputValue] = useState(false);
  const [ModalShow, setModalShow] = useState(false);
  const { translated } = useContext(AppContext);
  // const [shimmer, setShimmer] = useState(true)

  // setTimeout(() => {
  //   setShimmer(false)
  // }, 500)
  useEffect(() => {
    if (oldSelection) {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    <>
      <div className="mb-3 form-check">
        {attribute?.url !== undefined ? (
          <span
            onClick={() => {
              setModalShow(true);
            }}
            className={
              isShimmer
                ? "common-shimmer cursor-pointer text-16 text-underline"
                : "cursor-pointer text-16 text-underline"
            }
          >
            {attribute.display_text}
          </span>
        ) : (
          <label className={isShimmer ? "common-shimmer label" : "label"}>
            {attribute.display_text}
          </label>
        )}
      </div>
      <Modal
        size={"lg"}
        modalTitle={attribute.display_text}
        show={ModalShow}
        handleClose={() => setModalShow(false)}
      >
        <div className="consent-modal">
          <PerfectScrollbar>
            <div className="consent-data w-75 mx-auto">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem
                nemi .
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </p>
            </div>
          </PerfectScrollbar>
        </div>
        <div className="text-center">
          <button
            className="standard-btn"
            onClick={() => {
              setInputValue(true);
              setModalShow(false);
            }}
          >
            <p className="text-16 bold color-blue">{translated("next")}</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConsentLink;
