/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { edit } from "@/config/svg";

const PhoneInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  isShimmer?: boolean;
  autoFocus?: boolean;
  index: number;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, isShimmer, oldSelection, autoFocus, index, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };

  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    if (inputValue.length) {
      submitData(
        inputValidation(attribute.validation, inputValue),
        attribute.key,
        inputValue
      );
    } else {
      submitData(
        inputValidation(attribute.validation, inputValue),
        attribute.key,
        inputValue
      );
    }
  }, [inputValue]);
  return (
    <>
      <input
        type="phone"
        id={"phone-input-" + index}
        autoComplete="off"
        value={inputValue}
        readOnly={
          attribute?.enabled !== undefined &&
          attribute?.enabled === false &&
          isEdit === false
        }
        autoFocus={
          attribute?.enabled !== undefined && attribute?.enabled === false
            ? false
            : autoFocus
        }
        onChange={(e) => updateInputValue(e.target.value)}
        className={isShimmer ? "common-shimmer" : ""}
      />
      <label htmlFor={"phone-input-" + index}>
        {attribute.placeholder_text}
      </label>

      {attribute?.editable &&
        attribute?.editable === true &&
        isEdit === false && (
          <button
            onClick={() => {
              setIsEdit(true);
            }}
            className={isShimmer ? "common-shimmer edit-field" : "edit-field"}
          >
            <img src={edit} width="13" height="13" alt="Edit" />
          </button>
        )}
    </>
  );
};

export default PhoneInput;
