/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ShimmerTitle, ShimmerText, ShimmerButton,
  // ShimmerCircularImage
} from "../../packages/Shimmer/index";

const ProfileShimmer: React.FC = () => {
  return (
    <>
      {/* <div className="information-panel width-558">
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <div className="img-section text-center">
          <ShimmerCircularImage size={150} />
        </div>
      </div> */}
      <div className="information-panel text-center">
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />
        <ShimmerText line={2} gap={10} className="orders-wrapper " />
        <ShimmerTitle line={1} gap={15} variant="primary" />

        <ShimmerButton />
      </div>
    </>
  );
};

export default ProfileShimmer;
