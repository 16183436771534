/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import {
  base64Encode,
  // findItemFromArray,
  // getOrderAllStatus,
  // parseFloat2digit,
  // totalOfArrayObject,
} from "@/helper/common";
// import { BioMarker } from "@/components/index";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import { dwdPdf, backArrow } from "@/config/svg";
import { AppContext } from "@/context/createContext";
import { getLabCorpEndPoint } from "@/services/api/fireFunction";

const OrderDetails: React.FC<{
  order: any;
  showDetails: () => void;
}> = ({ order, showDetails }) => {
  let navigate = useNavigate();
  const { setRootLoader, unSetRootLoader, showToastMessage, translated } =
    useContext(AppContext);
  // const [product, setProduct] = useState<any | null>(null);

  const handleSchedule = () => {
    const orderData = {
      order_id: String(order?.order_id),
    };
    navigate(
      routes.schedulingDeeplink +
        "?metadata=" +
        base64Encode(JSON.stringify(orderData))
    );
  };
  // useEffect(() => {
  //   if (Object.values(order).length > 0) {
  //     if (state?.product_list?.length > 0) {
  //       setProduct(
  //         findItemFromArray(
  //           state?.product_list,
  //           "id",
  //           order?.products[0]?.product_id
  //         )
  //       );
  //     }
  //   }
  // }, [order]);

  useEffect(() => {
    document.body.className = "user-area ";

    return () => {
      document.body.className = "";
    };
  }, []);

  const handleNoPleaseAction = () => {
    setRootLoader();
    getLabCorpEndPoint()
      .then((labCorpEndPoint: any) => {
        unSetRootLoader();
        if (labCorpEndPoint?.data?.url) {
          window.open(labCorpEndPoint?.data?.url, "_blank", "noreferrer");
        } else {
          showToastMessage("URL not found!", true);
        }
      })
      .catch(function (err) {
        if (err?.code === "ERR_NETWORK")
          showToastMessage(translated("error.network_connection_error"), true);
        else showToastMessage("Something went wrong!", true);

        unSetRootLoader();
      });
  };

  return (
    <div className="order-item p-1">
      <div className="order-item-header d-flex justify-content-start">
        <img
          onClick={showDetails}
          className="forward-icon cursor-pointer"
          src={backArrow}
          width="45"
          height="45"
          alt="Next"
        />
      </div>
      <div className="d-flex justify-content-between row">
        {/* <BioMarker product_id={product?.id} /> */}
        <div className="col-md-6">
          <div className="orders-wrapper">
            <div className="order-item-body shadow p-3 mb-5 bg-white rounded">
              <p className="btn-text bold small-text rainbow_purple-blue">
                {moment(order?.created_at).format("LL")}
              </p>

              <h2 className="item-title bold-sub">Order {order?.order_id}</h2>

              <div className="order-item-details">
                Your personal prescription from Dr. Robin.
              </div>
            </div>
            <br />
            <br />
            {order?.status === "pending" && (
              <div className="order-item-body d-flex justify-content-start col-10">
                <div className="mb-3 text-center  width-200 save">
                  <button
                    className="custom-btn btn-standard-gradient"
                    onClick={() => handleSchedule()}
                  >
                    <p className="btn-text bold small-text rainbow_purple-blue">
                      We come to you
                    </p>
                  </button>
                </div>
                <div className="mb-3 text-center  width-200 save">
                  <button
                    className="custom-btn btn-standard-gradient"
                    onClick={() => handleNoPleaseAction()}
                  >
                    <p className="btn-text bold small-text rainbow_purple-blue">
                      You come to us
                    </p>
                  </button>
                </div>
              </div>
            )}

            {(order?.status === "ph_visit_scheduled_success" ||
              order?.status === "lab_order_create_success") && (
              <>
                <div className="sub-separator"></div>
                <p className=" black-small-bold-text bold small-text rainbow_purple-blue">
                  Instructions
                </p>
                <h2 className="item-title bold-sub">Prepare for your test.</h2>
                <p className="order-item-body">
                  Get ready for your test. Check out how to prepare for it.
                </p>
                <div className="mb-3 text-center  width-180">
                  <button
                    className="custom-btn btn-standard-gradient"
                    onClick={() => {
                      navigate(
                        "/how-to-get-prepared/" + base64Encode(order?.order_id)
                      );
                    }}
                  >
                    <p className="btn-text bold small-text rainbow_purple-blue">
                      Read more
                    </p>
                  </button>
                </div>
              </>
            )}
            {order?.status === "lab_results_available" && (
              <div className="mb-3 text-center  width-236">
                <img
                  // onClick={showPDF}
                  onClick={() => {
                    navigate("/order-PDF/" + order?.order_id);
                  }}
                  className="forward-icon download "
                  src={dwdPdf}
                  width="55"
                  height="55"
                  alt="download"
                />
              </div>
            )}

            {order?.status === "lab_results_available" && (
              <div className="mb-3 text-center  width-180">
                <button
                  className="custom-btn btn-standard-gradient"
                  onClick={() => {
                    navigate("/results/" + base64Encode(order?.order_id));
                  }}
                >
                  <p className="btn-text bold small-text rainbow_purple-blue">
                    {translated("cta.view_results")}
                  </p>
                </button>
              </div>
            )}

            {/* {order?.order_type === "at_home" &&
              order?.status === "lab_order_create_success" && (
                <div className="mb-3 text-center  width-236 save">
                  <button
                    className="custom-btn btn-standard-gradient"
                    onClick={() => handleSchedule()}
                  >
                    <p className="btn-text bold small-text rainbow_purple-blue">
                      {translated("cta.schedule")}
                    </p>
                  </button>
                </div>
              )} */}
          </div>
        </div>

        <div className="col-md-6">
          <div className="orders-wrapper">
            {order?.status === "ph_visit_scheduled_success" && (
              <>
                <p className=" black-small-bold-text bold small-text rainbow_purple-blue">
                  YOUR APPOINTMENT
                </p>
                {order?.booking && (
                  <div className="order-item-body shadow p-3 mb-5 bg-white rounded">
                    <p className="btn-text bold small-text rainbow_purple-blue d-flex">
                      {moment(order?.booking?.start_at).format("LL")}
                    </p>
                    <div className="order-item-details">
                      <p>
                        {moment(order?.booking?.start_at).format("LT") +
                          " - " +
                          moment(order?.booking?.end_at).format("LT")}
                      </p>
                      <p className="address-details">
                        <span>{order?.address?.street}, </span>
                        <span>{order?.address?.city}, </span>
                        <span>{order?.address?.state}, </span>
                        <span>{order?.address?.postal_code}, </span>
                        <span>{order?.address?.country}</span>
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}

            <p className=" black-small-bold-text bold small-text rainbow_purple-blue">
              TESTS INCLUDED
            </p>
            <h2 className="item-title bold-sub">You will find</h2>

            <ul>
              {order?.test_items.map((item: any, index: number) => (
                <li key={index}>
                  <p className="blue-small-bold-text col-6">
                    {item?.name}
                    <br />
                    <span className="text-dark">{item?.test_type}</span>
                  </p>

                  {/* <p className="float-left col-6">{item?.status}</p> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
