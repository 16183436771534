/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { hooks } from 'botframework-webchat-component';
import React, { useContext, useEffect, useState } from "react";
import {
  backArrow,
  FaceBad,
  FaceGood,
} from "@/config/svg";
import { Rangemeter } from "@/components/index";
import { AppContext } from "@/context/createContext";

import { getNewResultData } from "@/helper/result";
import { useParams } from "react-router-dom";
import Pages from "@/layouts/Pages";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ResultBoimarkerShimmer } from "@/components/shimmer";
import { getBiomarkerFaq } from "@/services/api/fireFunction";
const { useSendMessage } = hooks;
let isContinueData = new Map()

declare global {
  interface Window {
    azureBot: any;
    azureUser: any;
  }
}

const ResultBiomarker: React.FC = () => {
  const sendMessage = useSendMessage();
  const [resultData, setResultData] = useState<any>(null);
  const [loader, setLoader] = useState(false);
  const { translated, getResultData, showAZChatBot, setShowAZChatBot } = useContext(AppContext);
  const [bioMarker, setBoiMarker] = useState<any>(null);
  const [biomarkerFaq, setBiomarkerFaq] = useState<any>(null);
  const [faqQ, setFaqQ] = useState<string>("");
  const [content, setContent] = useState<any>(null);
  const params = useParams();
  let count = 1;

  useEffect(() => {
    // console.log('boimarker', boimarkerContent, bioMarker)

    if (bioMarker === null && params?.order_id && count === 1) {
      setLoader(true);
      // setTimeout(() => {
      count++;
      // console.log('boimarkerContent', boimarkerContent)
      const isCallingApi = isContinueData.get(String(params?.order_id))
      if (!isCallingApi) {
        isContinueData.set(String(params?.order_id), 'calling...')

        getResultData(atob(String(params?.order_id))).then(async (responseItem: any) => {
          isContinueData.set(String(params?.order_id), null)
          const data = responseItem?.result
          const boimarkerContent = responseItem?.boimarkerData
          if (boimarkerContent && data?.data?.fulfillment_orders && data?.data?.fulfillment_orders.length > 0) {
            if (data?.data?.fulfillment_orders[0].lab_test_results && data?.data?.fulfillment_orders[0].lab_test_results.length > 0) {
              const labItems = await data?.data?.fulfillment_orders[0].lab_test_results.
                filter((result: any) => result?.id === atob(String(params?.result_id)))
              // console.log('data', labItems)
              if (labItems.length > 0) {

                const labItem = await labItems[0].lab_test_result_items.
                  filter((labItem: any) => labItem?.id === atob(String(params?.lab_item_id)))
                // console.log('data2', labItem)

                if (labItem.length > 0) {
                  setBoiMarker(labItem[0]);
                  setResultData(getNewResultData(labItem[0]));
                  const biomarkerId = String(Number(labItem[0]?.result_code));
                  // console.log('data2', labItem)
                  if (biomarkerId) {
                    getBiomarkerFaq(biomarkerId, labItem[0]?.name ?? "", labItem[0]?.abnormal_flag).then((faqData) => {
                      console.log(faqData)
                      setBiomarkerFaq(faqData?.[Number(biomarkerId)])
                    })
                  }
                  if (biomarkerId && boimarkerContent &&
                    boimarkerContent.hasOwnProperty(biomarkerId) &&
                    boimarkerContent[biomarkerId]
                  ) {
                    setContent(boimarkerContent[biomarkerId]);
                  }
                  setLoader(false);
                } else {
                  setLoader(false);
                }
              } else {
                setLoader(false);
              }
            }
          }
        }).catch((err: any) => {
          isContinueData.set(String(params?.order_id), null)

          console.log('error', err)
          setLoader(false)
        });
      }
      // }, 3000);
    }
  }, [params]);

  useEffect(() => {
    document.body.className = "result-biomarker-details";

    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <Pages>
      <section className="biomarker-details-section">
        {loader ? (
          <ResultBoimarkerShimmer />
        ) : (
          <div className="two-columns-layout">
            <div className="half-col left-col background-lilla">

              <div className="biomarker-result-wrapper">
                {bioMarker && (
                  <div className="text-center">
                    <h2 className="text-36">{bioMarker?.name}</h2>
                    <div className="flex-center custom-mt-20">
                      <img
                        className="result-title-img"
                        width="28"
                        height="28"
                        src={bioMarker?.abnormal_flag ? FaceBad : FaceGood}
                        alt="Mood"
                      />

                      <p className="good-result-text custom-mb-0 custom-ml-10">
                        {bioMarker?.abnormal_flag ? "Out of" : "Within"} range
                      </p>
                    </div>
                  </div>
                )}
                {resultData && bioMarker && (
                  <Rangemeter
                    rangValues={{
                      min: 0,
                      max: 180,
                      minLabel: resultData?.minLabel,
                      maxLabel: resultData?.maxLabel,
                      step: 1,
                      value: resultData?.dotPositioning,
                      valueLabel: (
                        <>
                          {bioMarker?.result_value}
                          <span>{bioMarker?.units_of_measure}</span>
                        </>
                      ),
                      valueLabel2: <>{resultData?.optimalRange}</>,

                      pointer: true,
                      dotRange: resultData?.dotRange,
                      enableInputBox: false,
                      ranges: [...resultData?.arcSegments],
                    }}
                    unit={bioMarker?.units_of_measure}
                    title={bioMarker?.name}
                  />
                )}
              </div>
            </div>
            <div className="half-col right-col align-x-center">

              <PerfectScrollbar>
                <div className="width-500">
                  {content && content?.content && (
                    <div>
                      <span className="text-18 bold color-blue custom-mb-10">
                      </span>
                      <h2 className="text-36 custom-mb-20">{translated("app_copy.what_they_are")}</h2>
                      <p className="text-16">{content?.content}</p>
                    </div>
                  )}
                  {content &&
                    bioMarker?.abnormal_flag &&
                    content?.how_to_improve && (
                      <div className="custom-mt-40">
                        <h2 className="text-36 custom-mb-20">{translated("app_copy.how_to_improve")}</h2>
                        <p className="text-16">{content?.how_to_improve}</p>
                      </div>
                    )}
                  <br />
                  <br />
                  <div className="faq-box">
                    {
                      biomarkerFaq && biomarkerFaq?.map((question: any, index: number) => {
                        return (
                          // index < 2 &&
                          <label onClick={() => {
                            setFaqQ(question)
                            setShowAZChatBot(!showAZChatBot);
                            sendMessage(question);
                          }}
                            className={faqQ === question ? "faq-list-item chat-con-label selected text-12 custom-mb-20 custom-ml-20" : "faq-list-item chat-con-label text-12 custom-mb-20 custom-ml-20"} key={index}>
                            {question}
                          </label>
                        )
                      })
                    }
                  </div>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        )}
      </section>
    </Pages>
  );
};

export default ResultBiomarker;
