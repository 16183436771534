import { reducerType } from '@/config/configarableVariable';
import { AppState, ModulesType } from '@/interface/index'

export const appReducer = (state: AppState, action: any): AppState => {
    switch (action.type) {
        case reducerType?.IS_UI_CHANGE:
            return { ...state, ui_loaded: action.payload };
        case reducerType?.UPDATE_STATE:
            return { ...state, ...action.payload };
        case reducerType?.APP_CONFIG:
            return { ...state, config_state: action.payload };
        case reducerType?.UPDATE_MODULES:
            return {
                ...state,
                modules: { ...action.payload },
            };
        default:
            return state;
    }
}

export const modulesReducer = (state: ModulesType, action: any): ModulesType => {
    switch (action.type) {
        case reducerType?.UPDATE_MODULES:
            return {
                ...action.payload
            };
        default:
            return state;
    }
}

export const whitLabelReducer = (state: any, action: any): any => {
    switch (action.type) {
        case reducerType?.UPDATE_WHITELABELING:
            return {
                ...state, ...action.payload
            };
        default:
            return state;
    }
}

export const stepDataReducer = (state: any, action: any): any => {
    switch (action.type) {
        case reducerType?.AUTHENTICATION_STEP:
            return { ...state, authentication: action.payload };
        case reducerType?.ONBOARDING_STEP:
            return { ...state, onboarding: action.payload };
        case reducerType?.ESSENTIAL_STEP:
            return { ...state, essential: action.payload };
        case reducerType?.CONSENT_STEP:
            return { ...state, consent: action.payload };
        case reducerType?.FEEDBACK_STEP:
            return { ...state, feedback: action.payload };
        case reducerType?.ALL_STEP:
            return { ...state, allStep: action.payload };
        default:
            return state;
    }
}

export const userReducer = (state: any, action: any): any => {
    switch (action.type) {
        case reducerType?.USER_UPDATED:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}