/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import { chat } from "@/config/svg";
import { dialogFlowAUthToken } from "@/services/api/fireFunction";

const Dialogflow: React.FC<{}> = () => {
  const [messages, setMessages]: any = useState([]);
  const [usermessage, setUsermessage] = useState("");
  const [isChatActive, setIsChatActive] = useState<boolean>(false);
  const ESURL = "https://dialogflow.googleapis.com/v2/projects/claire-dev-87971/agent/sessions/123456:detectIntent"
  const CXURL = "https://us-central1-dialogflow.googleapis.com/v3/projects/claire-dev-87971/locations/us-central1/agents/bb84a4ef-b57e-42d4-9768-6603161545a9/sessions/test-session-123:detectIntent"
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false);
  const [isES, setIsES] = useState<boolean>(false);

  const chatConversation = useRef<HTMLDivElement>(null);

  const refreshToken = async () => {
    const response = await dialogFlowAUthToken();
    if ("token" in response) {
      localStorage.setItem("dAccessToken", response?.token);
      localStorage.setItem(
        "dTokenTimeOut",
        String(Math.floor(Date.now() / 1000) + Number(3600))
      );
    }
  };

  const handleBotSubmit = async () => {
    setMessages((current: any) => [...current, ["user", usermessage]]);
    const inputMessage = usermessage;
    setUsermessage("");

    const ESInput = {
      query_input: {
        text: {
          text: inputMessage,
          language_code: "en-US"
        }
      }
    };

    const CXInput = {
      queryInput: {
        text: {
          text: inputMessage,
        },
        languageCode: "en"
      }
    };

    const credentials = isES ? ESInput : CXInput;

    setIsBotTyping(true);

    const tokenTime = Number(localStorage.getItem("dTokenTimeOut"));

    if (tokenTime < Math.floor(Date.now() / 1000)) {
      await refreshToken();
      const botresponse = await fetch(
        isES ? ESURL : CXURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("dAccessToken"),
          },
          body: JSON.stringify(credentials),
        }
      )
        .then((response) => response.json())
        .catch((err: any) => {
          console.log("err", err);
        });

      setIsBotTyping(false);

      setMessages((current: any) => [...current, ["bot", isES ? botresponse?.queryResult?.fulfillmentText : botresponse?.queryResult?.responseMessages[0]?.text?.text[0]]]);
    } else {
      const botresponse = await fetch(
        isES ? ESURL : CXURL,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("dAccessToken"),
          },
          body: JSON.stringify(credentials),
        }
      )
        .then((response) => response.json())
        .catch((err: any) => {
          console.log("err", err);
        });
      setIsBotTyping(false);

      setMessages((current: any) => [...current, ["bot", isES ? botresponse?.queryResult?.fulfillmentText : botresponse?.queryResult?.responseMessages[0]?.text?.text[0]]]);
    }
  };

  const activateChat = () => {
    setIsChatActive(true);
    if (messages.length === 0) {
      refreshToken();
    }
  };

  const closeChat = () => {
    setIsChatActive(false);
    setMessages([]);
  };

  const minimizeChat = () => {
    setIsChatActive(false);
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleBotSubmit();
    }
  };

  useEffect(() => {
    if (chatConversation.current !== null) {
      const callback = function (mutationsList: any) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            (chatConversation.current as HTMLDivElement).scrollTop = (
              chatConversation.current as HTMLDivElement
            ).scrollHeight;
          }
        }
      };

      const observer = new MutationObserver(callback);

      const config = { childList: true };
      observer.observe(chatConversation.current, config);
    }
  }, [chatConversation.current]);

  return (
    <div className="dflow-chatbot">
      <div className={`chatbot-btn ${isChatActive ? "hidden" : ""}`}>
        <button
          className="small-btn"
          onClick={() => {
            activateChat();
          }}
        >
          DF
          {/* {isES ? "ES" : "CX"} */}
          {/* <img className="cursor-pointer" src={chat} alt="Expand Arrow" /> */}
        </button>
      </div>
      {isChatActive && (
        <div className="chatbot-chat-container">
          <div className="chat-header">
            <p className="text-14 bold color-white">DialogFlowCX
              {/* <button
                className="btn span-btn"
                onClick={() => {
                  setIsES(!isES)
                  setMessages([]);
                }}
              >{isES ? "ES" : "CX"}</button>  */}
            </p>
            <div className="d-flex justify-content-between">
              <span
                className="text-14 bold color-white cursor-pointer"
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginRight: "16px",
                }}
                onClick={() => minimizeChat()}
              >
                -
              </span>
              <span
                className="text-14 bold color-white cursor-pointer"
                onClick={() => closeChat()}
              >
                X
              </span>
            </div>
          </div>
          <div className="chat-body">
            <div className="chat-conversation" ref={chatConversation}>
              {messages.map((item: any, index: any) => {
                return (
                  <div key={index} className={"chat-message-item " + item[0]}>
                    <div className="chat-text">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item[1],
                        }}
                      ></p>
                    </div>
                  </div>
                );
              })}
              {isBotTyping && (
                <div className="chat-message-item bot-typing">
                  <div className="chat-text">
                    <span className="typing-dots"></span>
                    <span className="typing-dots"></span>
                    <span className="typing-dots"></span>
                  </div>
                </div>
              )}
            </div>
            <div className="chat-input">
              <input
                type="text"
                placeholder="Type your message here"
                name="chat-input"
                className="text-14 color-black"
                value={usermessage}
                onChange={(e) => setUsermessage(e.target.value)}
                onKeyDown={handleOnKeyDown}
              />
              <button
                className="text-14 color-black"
                onClick={() => handleBotSubmit()}
              >
                SEND
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialogflow;
