/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AttributeType } from "@/interface/index";
import { dataProcess, inputValidation } from "@/helper/common";
import { AppContext } from "@/context/createContext";

const ZipcodeInput: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  autoFocus?: boolean;
  submitData: (sContinue: boolean, key: string, value: string) => void;
}> = ({ attribute, oldSelection, autoFocus, submitData }) => {
  const [inputValue, setInputValue] = useState("");
  const { state } = useContext(AppContext);
  const configState = state?.config_state
  const [coords, setCoords] = useState<any>(null);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [watchMode, setWatchMode] = useState<boolean>(false);
  const [watchId, setWatchId] = useState<number>(0);
  const [deviceLocation, setDeviceLocation] = useState<any>({
    lat: null,
    long: null,
    zipCode: null,
  });
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  useEffect(() => {
    if (
      "geolocation" in navigator &&
      navigator?.permissions &&
      navigator?.permissions?.query
    ) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(({ state }) => {
          if (state === "denied") {
            setIsEnabled(false);
          } else {
            if (watchMode && watchId) {
              const watchId = navigator.geolocation.watchPosition(
                GeolocationPosition,
                GeolocationPositionError,
                { ...options }
              );
              setWatchId(watchId);
              setWatchMode(true);
            } else {
              navigator.geolocation.getCurrentPosition(
                GeolocationPosition,
                GeolocationPositionError,
                { ...options }
              );

              setIsEnabled(true);
            }
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    } else {
      setIsEnabled(false);
    }
  }, [state]);
  useEffect(() => {
    if (isEnabled && deviceLocation?.zipCode === null) {
      $.get({
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords?.latitude},${coords?.longitude}&key=${configState?.places_key?.web}`,
        success(data) {
          if (data.results.length > 0) {
            const locationDetails = getLocationData(
              data.results[0]["address_components"]
            );
            setDeviceLocation({
              lat: coords?.latitude,
              long: coords?.longitude,
              zipCode: locationDetails.postcode,
            });
            setInputValue(locationDetails?.postcode ?? "");
          }
        },
      });
    }
  }, [coords]);

  const getLocationData = (location: any) => {
    let allAddress = {
      street: [] as string[],
      city: null as null | string,
      state: null as null | string,
      postcode: null as null | string,
      country: null as null | string,
    };
    location.forEach((item: any) => {
      switch (item.types[0]) {
        case "postal_code":
          allAddress.postcode = item.long_name;
          break;
        case "country":
          allAddress.country = item.long_name;
          break;
        case "administrative_area_level_1":
          allAddress.state = item.long_name;
          break;
        case "administrative_area_level_2":
          allAddress.city = item.long_name;
          break;
        case "street_number":
          allAddress.street = [...allAddress.street, item.long_name as string];
          break;
        case "street_address":
          allAddress.street = [...allAddress.street, item.long_name as string];
          break;
        case "route":
          allAddress.street = [...allAddress.street, item.long_name as string];
          break;
        case "locality":
          allAddress.street = [...allAddress.street, item.long_name as string];
          break;
        default:
          allAddress = { ...allAddress };
      }
    });
    return allAddress;
  };

  const GeolocationPosition = useCallback(({ coords }: any) => {
    if (!!coords) {
      setCoords(coords);
    }
  }, []);

  const GeolocationPositionError = useCallback((error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setIsEnabled(false);
        break;
      case error.POSITION_UNAVAILABLE:
        setIsEnabled(false);
        break;
      case error.TIMEOUT:
        setIsEnabled(false);
        break;
      default:
        throw new Error("Position request got an error");
    }
  }, []);
  const updateInputValue = (newInputValue: string) => {
    if (attribute?.data_process?.length > 0) {
      attribute.data_process.forEach((element: string) => {
        setInputValue(dataProcess(element, newInputValue).trimStart());
      });
    } else {
      setInputValue(newInputValue);
    }
  };
  useEffect(() => {
    if (oldSelection !== "") {
      setInputValue(oldSelection);
    }
  }, [oldSelection]);
  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, inputValue),
      attribute.key,
      inputValue
    );
  }, [inputValue]);
  return (
    <>
      <input
        type="text"
        autoFocus={autoFocus}
        autoComplete="off"
        value={inputValue}
        onChange={(e) => updateInputValue(e.target.value)}
        placeholder={attribute.placeholder_text}
        className="form-control"
      />
    </>
  );
};

export default ZipcodeInput;
