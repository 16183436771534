/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { applyActionCode } from "firebase/auth";
import { auth as firbaseAuth } from "@/services/firebase";
import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ResetPassword,
  Spiner,
  EmailVerified,
  EmailNotVerified,
  RecoverEmail,
  CountryValidation,
} from "@/components/index";
import { AppContext } from "@/context/createContext";
import { verifyUserLocation } from "@/services/api/fireFunction";

const Action: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(true);
  const [actionCode, setActionCode] = useState("");
  const [mode, setMode] = useState("");
  const [isCountryValid, setIsCountryValid] = useState(true);

  const {
    mainLoader,
    settingsState,
    remainingLoaderTime,
    setRootLoader,
    unSetRootLoader,
    showToastMessage,
    translated,
  } = useContext(AppContext);
  useEffect(() => {
    const actionCode = searchParams.get("oobCode") as string;
    const actionMode = searchParams.get("mode") as string;
    //console.log("actionCode", searchParams.get("oobCode"));
    verifyUserLocation(settingsState?.location_api?.path).then((data) => {
      unSetRootLoader()
      if (data?.status === 200) {
        if (settingsState?.location_api?.allowed_country?.includes(data?.data?.data?.countryCode)) {
          setActionCode(actionCode);
          setMode(actionMode);
          setIsCountryValid(true)
          unSetRootLoader()
        } else {
          setIsCountryValid(false)
          unSetRootLoader()
        }
      }
    }).catch(() => {
      unSetRootLoader()
    })
  }, []);

  useEffect(() => {
    if (mode === "verifyEmail") {
      setRootLoader()
      applyActionCode(firbaseAuth, actionCode)
        .then((info) => {
          unSetRootLoader()
          showToastMessage(translated('error.email_verified_successful'));
          // setTimeout(() => {
          //   navigate(routes.login);
          // }, 3000);
        })
        .catch((error) => {
          unSetRootLoader()
          setIsVerified(false);
        });
    }
  }, [actionCode, mode]);
  useEffect(() => {
    document.body.className = "checkout-area";

    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <Pages>
      {mainLoader && remainingLoaderTime ? (
        <Spiner height="100px" width="100px" margin="auto" />
      ) : (
        <>
          {
            mode === "resetPassword" && <ResetPassword actionCode={actionCode} />}
          {mode === "verifyEmail" && isVerified && (
            <EmailVerified />
          )}
          {mode === "verifyEmail" && !isVerified && (
            <EmailNotVerified />
          )}
          {mode === "recoverEmail" && (
            <RecoverEmail />
          )}
          {isCountryValid === false && (
            <CountryValidation />
          )}
        </>)}
    </Pages>
  );
};

export default Action;
