/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { useNavigate } from "react-router-dom";
import routes from "@/config/routes";
import {
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { auth } from "@/services/firebase";
import { passwordValidations } from "@/helper/common";
import { ChangePasswordShimmer } from "@/components/shimmer";
import { password1, hidePassword } from "@/config/svg";
import { setUserData, updateUserData } from "@/services/firebase/fireStore";
import PerfectScrollbar from "react-perfect-scrollbar";

const Settings: React.FC = () => {
  let navigate = useNavigate();
  const {
    userInfo,
    mainLoader,
    remainingLoaderTime,
    showToastMessage,
    setRootLoader,
    unSetRootLoader,
    state,
    translated,
  } = useContext(AppContext);
  const configState = state?.config_state
  const [loader, setLoader] = useState(true);
  const [cpassword, setCpassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const [isApp, setIsApp] = useState(false);

  useEffect(() => {
    if (userInfo?.notification_preferences) {
      setIsApp(userInfo?.notification_preferences?.app ?? false);
    }
  }, [userInfo]);
  useEffect(() => {
    if (userInfo?.user_data) {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
    document.body.className = "user-area";

    return () => {
      document.body.className = "";
    };
  }, []);

  const handleAction = () => {
    if (cpassword.length === 0) {
      showToastMessage(
        translated("error.current_password_can_not_be_empty"),
        true
      );
    } else if (password.length === 0) {
      showToastMessage(translated("error.password_can_not_be_empty"), true);
    } else if (confirmPassword.length === 0) {
      showToastMessage(
        translated("error.current_password_can_not_be_empty"),
        true
      );
    } else if (confirmPassword !== password) {
      showToastMessage(
        translated("error.confirm_password_password_mismatch"),
        true
      );
    } else {
      setRootLoader();
      signInWithEmailAndPassword(
        auth,
        userInfo?.user_data?.email,
        cpassword
      )
        .then((userCredential: any) => {
          updatePassword(userCredential?.user, password)
            .then(() => {
              unSetRootLoader();
              showToastMessage(
                translated("error.password_updated_successfully")
              );
            })
            .catch((error) => {
              unSetRootLoader();

              if (error.code === "auth/requires-recent-login") {
                showToastMessage(
                  translated(
                    "error.Please_re_login_again_to_change_the_password"
                  ),
                  true
                );
                logout();
              }
              if (error.code === "auth/weak-password") {
                showToastMessage(
                  translated("error.Password_should_be_at_least_6_characters"),
                  true
                );
              }
            });
        })
        .catch((error) => {
          unSetRootLoader();

          if (error.code === "auth/weak-password") {
            showToastMessage(
              translated("error.Password_should_be_at_least_6_characters"),
              true
            );
          }
          if (error.code === "auth/wrong-password") {
            showToastMessage(
              translated("error.Current_password_is_wrong"),
              true
            );
          }
          if (error.code === "auth/user-not-found") {
            showToastMessage(
              translated("error.User_not_exist_Please_signup"),
              true
            );
          }
          if (error.code === "auth/email-already-in-use") {
            showToastMessage(translated("error.Email_Already_in_Use"), true);
          }
        });
    }
  };

  useEffect(() => {
    if (password.length > 0) {
      setIsPasswordValidate(
        passwordValidations(
          password,
          configState?.password_rules?.regex_validation
        )
      );
    } else {
      setIsPasswordValidate(false);
    }
  }, [password]);

  const logout = () => {
    signOut(auth)
      .then(async () => {
        // newAnonymousUser();
        localStorage.removeItem("authToken");
        localStorage.removeItem("ref");
        localStorage.removeItem("uid");
        localStorage.removeItem("isOtpVerify");
        localStorage.removeItem("isOtpSubmit");

        sessionStorage.removeItem("authUId");
        showToastMessage("Logout successfully!", true);
        navigate(routes.login);
        window.location.reload();
      })
      .catch((error) => {
        showToastMessage("Something went wrong!", true);
      });
  };

  // This variable determines whether password is shown or not
  const [isShownCurrent, setIsShownCurrent] = useState(true);

  // This function is called when the checkbox is checked or unchecked
  const toggleCurrentPassword = () => {
    setIsShownCurrent((isShownCurrent) => !isShownCurrent);
  };

  // This variable determines whether password is shown or not
  const [isShownNew, setIsShownNew] = useState(true);

  // This function is called when the checkbox is checked or unchecked
  const toggleNewPassword = () => {
    setIsShownNew((isShownNew) => !isShownNew);
  };

  // This variable determines whether password is shown or not
  const [isShownRepeat, setIsShownRepeat] = useState(true);

  // This function is called when the checkbox is checked or unchecked
  const toggleRepeatPassword = () => {
    setIsShownRepeat((isShownRepeat) => !isShownRepeat);
  };

  return (
    <PerfectScrollbar>
      <div className="settings-wrapper">
        {loader || mainLoader || remainingLoaderTime ? (
          <ChangePasswordShimmer />
        ) : (
          <>
            <h3 className="text-18 bold custom-mb-40">
              {" "}
              {translated("app_copy.notifications")}
            </h3>
            <p className="text-16 custom-mb-20">
              {translated(
                "app_copy.select_all_the_ways_you_would_like_hear_from_us"
              )}
            </p>

            <div className="d-flex justify-content-between position-relative">
              <span className="text-16">
                {translated("app_copy.label_contact_app")}
              </span>
              <div className="input-wrapper toggle-switch custom-mb-20">
                <input
                  type="checkbox"
                  autoComplete="off"
                  checked={isApp}
                  onChange={() => {
                    setLoader(true);
                    setIsApp(!isApp);
                    if (userInfo?.authUId) {
                      if (userInfo?.user_data) {
                        updateUserData(userInfo?.authUId, {
                          notification_preferences: {
                            ...userInfo?.notification_preferences,
                            app: !isApp,
                          },
                        }).then(() => {
                          setLoader(false);
                        });
                      } else {
                        setUserData(userInfo?.authUId, {
                          notification_preferences: {
                            app: !isApp,
                          },
                        }).then(() => {
                          setLoader(false);
                        });
                      }
                    }
                  }}
                  className=""
                  id="contact"
                />
                <label htmlFor="contact"></label>
              </div>
            </div>

            <div className="sub-separator custom-mb-30"></div>
            <p className="text-18 bold custom-mb-40">
              {" "}
              {translated("app_copy.change_password")}
            </p>
            <div className="row width-480">
              <div className="input-wrapper">
                <input
                  type={isShownCurrent ? "password" : "text"}
                  autoComplete="off"
                  value={cpassword}
                  onChange={(e) => setCpassword(e.target.value)}
                  name="currentPassword"
                  id="currentPassword"
                />{" "}
                <label
                  htmlFor="currentPassword"
                  className={cpassword !== "" ? "" : "hide"}
                >
                  {translated("app_copy.current_password")}
                </label>
                <span className="show-hide-psw" onClick={toggleCurrentPassword}>
                  <img
                    src={isShownCurrent ? password1 : hidePassword}
                    alt="hide"
                  ></img>
                </span>
              </div>
              <div className="input-wrapper">
                <input
                  type={isShownNew ? "password" : "text"}
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  id="password"
                />
                <label
                  htmlFor="password"
                  className={password !== "" ? "" : "hide"}
                >
                  {translated("app_copy.enter_new_password")}
                </label>

                <span className="show-hide-psw" onClick={toggleNewPassword}>
                  <img
                    src={isShownNew ? password1 : hidePassword}
                    alt="hide"
                  ></img>
                </span>
              </div>
              <div className="input-wrapper">
                <input
                  type={isShownRepeat ? "password" : "text"}
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  name="confirmPassword"
                  id="confirmPassword"
                />
                <label
                  htmlFor="confirmPassword"
                  className={confirmPassword !== "" ? "" : "hide"}
                >
                  {translated("app_copy.confirm_password")}
                </label>

                <span className="show-hide-psw" onClick={toggleRepeatPassword}>
                  <img
                    src={isShownRepeat ? password1 : hidePassword}
                    alt="hide"
                  ></img>
                </span>
              </div>
            </div>
            <div className="change_password_profile custom-mt-40">
              <button
                className="standard-btn"
                disabled={!isPasswordValidate || password !== confirmPassword}
                onClick={handleAction}
              >
                <p className="text-18 bold blue">
                  {translated("cta.change_password_profile")}
                </p>
              </button>
            </div>
          </>
        )}
      </div>
    </PerfectScrollbar>
  );
};

export default Settings;
