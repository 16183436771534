/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Pages from "@/layouts/Pages";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/createContext";
import { Outlet, Link, useLocation } from "react-router-dom";
import routes from "@/config/routes";
import {
  payments,
  SettingsDark,
  preferences,
  ProfileUser,
  ProfileUserDark,
  PaymentDark,
  back,
} from "@/config/svg";
const UserIndex: React.FC = () => {
  const [mobileProfileOpen, setMobileProfileOpen] = useState(true);

  const location = useLocation();
  const { userInfo, logoutUser, translated, addOrgCode } = useContext(AppContext);

  useEffect(() => {
    document.body.className = "user-area ";

    return () => {
      document.body.className = "";
    };
  }, []);

  const currentPath = () => {
    return location.pathname;
  };

  const toggleMobileMenu = () => {
    if (mobileProfileOpen) {
      setMobileProfileOpen(false);
    } else {
      setMobileProfileOpen(true);
    }
    setTimeout(() => {
      document.querySelectorAll(".section-title")[0].scrollIntoView();
    }, 800);
  };

  const currentProfileSectionTitle = () => {
    let title = "";

    if (currentPath() === routes.userProfile) {
      title = "Personal Info";
    } else if (currentPath() === routes.paymentEdit) {
      title = "Payment method";
    } else if (currentPath() === routes.settings) {
      title = "Settings";
    }

    return title;
  };

  return (
    <>
      <Pages>
        <section className="profile-area">
          <div
            className={`two-columns-layout ${mobileProfileOpen ? "menu-opened" : "menu-closed"
              }`}
          >
            <div className="profile background-lilla">
              <div className="profile-usertitle custom-mb-40">
                <h1 className="text-32">
                  Hello{" "}
                  {userInfo?.user_data?.first_name
                    ? userInfo?.user_data?.first_name
                    : ""}
                  {/*  +
                          " " +
                          userInfo?.user_data?.last_name
                        : userInfo?.user_data?.name */}
                </h1>
              </div>
              <div className="profile-usermenu left">
                <ul className="list-group">
                  <li
                    className={
                      currentPath() === routes.userProfile
                        ? "list-group-item selected"
                        : "list-group-item"
                    }
                  >
                    <Link
                      to={routes.userProfile}
                      onClick={() => toggleMobileMenu()}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          className="profile-icon"
                          src={
                            currentPath() === routes.userProfile
                              ? ProfileUser
                              : ProfileUserDark
                          }
                          width="20"
                          height="20"
                          alt="User"
                        />
                        <span className="text-18 bold">
                          {translated("app_copy.personal_info")}
                        </span>
                      </div>

                      <button className="go-back-button mobile-only">
                        <img
                          className="forward-icon cursor-pointer"
                          src={back}
                          alt="Next"
                        />
                      </button>
                    </Link>
                  </li>
                  <div className="sub-separator"></div>

                  <li
                    className={
                      currentPath() === routes.paymentEdit
                        ? "list-group-item selected"
                        : "list-group-item"
                    }
                  >
                    <Link
                      to={routes.paymentEdit}
                      onClick={() => toggleMobileMenu()}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          className="profile-icon"
                          src={
                            currentPath() === routes.paymentEdit
                              ? payments
                              : PaymentDark
                          }
                          width="20"
                          height="20"
                          alt="User"
                        />
                        <span className="text-18 bold">
                          {translated("app_copy.payments")}
                        </span>
                      </div>
                      <button className="go-back-button mobile-only">
                        <img
                          className="forward-icon cursor-pointer"
                          src={back}
                          alt="Next"
                        />
                      </button>
                    </Link>
                  </li>
                  <div className="sub-separator"></div>

                  <li
                    className={
                      currentPath() === routes.settings
                        ? "list-group-item selected"
                        : "list-group-item"
                    }
                  >
                    <Link
                      to={routes.settings}
                      onClick={() => toggleMobileMenu()}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          className="profile-icon"
                          src={
                            currentPath() === routes.settings
                              ? preferences
                              : SettingsDark
                          }
                          width="20"
                          height="20"
                          alt="User"
                        />
                        <span className="text-18  bold">Settings</span>
                      </div>
                      <button className="go-back-button mobile-only">
                        <img
                          className="forward-icon cursor-pointer"
                          src={back}
                          alt="Next"
                        />
                      </button>
                    </Link>
                  </li>
                  <div className="sub-separator"></div>
                </ul>
                <div
                  className="list-group-item sign-out cursor-pointer custom-mt-20"
                  onClick={() => {
                    addOrgCode(null)
                    logoutUser()
                  }}
                >
                  <div>
                    <span className="text-underline text-18 blue semibold ">
                      {translated("cta.sign_out")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="user-right-content">
              <div className="section-title mobile-only">
                <button
                  className="go-back-button"
                  onClick={() => toggleMobileMenu()}
                >
                  <img
                    className="forward-icon cursor-pointer"
                    src={back}
                    alt="Next"
                  />
                </button>
                <h3 className="text-18 bold">{currentProfileSectionTitle()}</h3>
              </div>
              <Outlet />
            </div>
          </div>
        </section>
      </Pages>
    </>
  );
};

export default UserIndex;
