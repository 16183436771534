/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { AttributeType } from "@/interface/index";
import { inputValidation } from "@/helper/common";
import DeviceCamera from "../profile_image/DeviceCamera";
import { camera, capturedImage as capturePhoto, gallery } from "@/config/svg";
import Modal from "../common/Modal";
import CropEasy from "../profile_image/CropEasy";
import {
  uploadImageToStorage,
  storageDownloadURL,
} from "@/services/firebase/fireStorage";
import { AppContext } from "@/context/createContext";
import Spiner from "../common/Spiner";

const CapturedImage: React.FC<{
  attribute: AttributeType;
  oldSelection: any;
  submitData: (sContinue: boolean, key: string, value: any) => void;
}> = ({ attribute, oldSelection, submitData }) => {
  const { userInfo, translated } = useContext(AppContext);
  const [photoURL, setPhotoURL] = useState("");
  const [fileSubmitURL, setFileSubmitURL] = useState("");
  const [ModalShow, setModalShow] = useState(false);
  const fileInput = useRef<any>();
  const [openCamera, setopenCamera] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    submitData(
      inputValidation(attribute.validation, fileSubmitURL),
      attribute.key,
      fileSubmitURL
    );
  }, [fileSubmitURL]);

  useEffect(() => {
    if (oldSelection !== "") {
      // const fileUrl = "users/" + userInfo?.authUId + "/"+attribute?.key
      setIsLoader(true);
      storageDownloadURL(oldSelection).then((url: any) => {
        setPhotoURL(url);
        setIsLoader(false);
      });
      setFileSubmitURL(oldSelection);
    }
  }, [oldSelection]);

  const handleFileChange = async (event: any) => {
    const picture = event.target.files[0];
    if (picture) {
      setPhotoURL(URL.createObjectURL(picture));
    }
    setModalShow(false);
    setOpenCrop(true);
  };

  const fileUpload = (file: any) => {
    if (userInfo?.authUId) {
      const fileUrl = "users/" + userInfo?.authUId + "/" + attribute?.key;
      uploadImageToStorage(file, fileUrl).on(
        "state_changed",
        async (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          setIsLoader(true);
          setFileSubmitURL("");
          setPhotoURL("");
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }

          if (progress === 100) {
            console.log("Uploaded");
            storageDownloadURL(fileUrl).then((url: any) => {
              setFileSubmitURL(fileUrl);
              setPhotoURL(url);
              setIsLoader(false);
            });
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }
      );
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <div className="mb-3 form-check">
      {openCamera ? (
        <div
          className="camera"
          style={{
            height: "220px",
          }}
        >
          <DeviceCamera
            {...{
              setopenCamera,
              setPhotoURL,
              setOpenCrop,
            }}
          />
        </div>
      ) : (
        <div role="button" className="pe-auto file-upload-preview">
          <div className="row">
            <div className="col-sm-12">
              {isLoader ? (
                <Spiner height="120px" width="120px" margin="auto" />
              ) : (
                <div className="preview justify-content-center align-items-center d-flex">
                  {photoURL === "" && (
                    <img
                      src={capturePhoto}
                      onClick={() => setModalShow(true)}
                      alt="CapturedImage"
                    />
                  )}
                  {ModalShow && (
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e)}
                      ref={fileInput}
                      accept="image/*"
                      hidden={true}
                    />
                  )}
                  <div className="profile-sidebar text-center">
                    {openCrop ? (
                      <CropEasy
                        {...{
                          photoURL,
                          setOpenCrop,
                          setPhotoURL,
                          fileUpload,
                        }}
                      />
                    ) : (
                      <div className="profile-userpic">
                        {photoURL !== "" && !openCamera && (
                          <div className="position-relative">
                            <div className="img-responsive outer">
                              <img src={photoURL} alt="user-photoUpload" />
                            </div>
                            <span
                              className="inner"
                              onClick={() => {
                                setModalShow(true);
                              }}
                            >
                              <img
                                src={camera}
                                className="img-responsive"
                                style={{
                                  height: "20px",
                                  width: "20px",
                                }}
                                alt=""
                              />
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {ModalShow && (
        <Modal
          modalTitle={"Upload image"}
          show={ModalShow}
          size={"sm"}
          handleClose={() => setModalShow(false)}
        >
          <div className="row justify-content-center image-capture-modal">
            <div className="image-capture-data row row-cols-1">
              <div
                className="col"
                onClick={() => {
                  setopenCamera(true);
                  setModalShow(false);
                }}
              >
                <div className="row row-cols-2">
                  <div className="col-5 custom-pr-0">
                    <div className="w-100 text-end">
                      <img className="custom-pr-24" src={camera} alt="User" />
                    </div>
                  </div>
                  <div className="col custom-pl-0">
                    <p
                      role="button"
                      className="pe-auto w-100 text-start black-basic-text custom-mb-0"
                    >
                      Take Photo
                    </p>
                  </div>
                </div>
              </div>
              <div className="col custom-mt-24 custom-mb-24">
                <div className="row row-cols-2">
                  <div className="col-5 custom-pr-0"> </div>
                  <div className="col custom-pl-0 sub-separator small"></div>
                </div>
              </div>
              <div className="col" onClick={handleClick}>
                <div className="row row-cols-2">
                  <div className="col-5 custom-pr-0">
                    <div className="w-100 text-end">
                      <img className="custom-pr-24" src={gallery} alt="User" />
                    </div>
                  </div>
                  <div className="col custom-pl-0">
                    <p
                      role="button"
                      className="pe-auto w-100 text-start black-basic-text custom-mb-0"
                    >
                      {translated('cta.photo_library')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CapturedImage;
