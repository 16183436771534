/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ShimmerTitle, ShimmerText, ShimmerButton } from "../../packages/Shimmer/index";

const ResultItemShimmer: React.FC = () => {
  return (
    <div className="mb-3 text-center">
      <ShimmerTitle line={1} gap={15} variant="primary" />
      <ShimmerText line={4} gap={10} />
      <ShimmerTitle line={1} gap={10} variant="primary" />
      <ShimmerText line={4} gap={10} />
      <div className="mb-3  text-center width-236">
        <ShimmerButton size="sm" />
      </div>
    </div>
  );
};

export default ResultItemShimmer;
