/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { RangAttribute, RangesProp } from "@/interface/index";
import "./range.scss";

const RangeInput: React.FC<{
  rangValues: RangAttribute;
  unit: string;
  title: string;
}> = ({ rangValues, unit = "", title = "" }) => {
  const [inputValue, setInputValue] = useState(rangValues.value);
  const [rangesValue, setrangesValue] = useState([]);
  const updateInputValue = (newInputValue: number) => {
    setInputValue(newInputValue);
  };
  const rangeValueMax = useRef(0);
  const rangeValueMin = useRef(0);
  useEffect(() => {
    let percentStart = 0;
    let percentEnd = 0;
    if (rangValues.ranges.length > 0) {
      if (rangValues.max !== undefined) rangeValueMax.current = rangValues.max;
      if (rangValues.min !== undefined) rangeValueMin.current = rangValues.min;
    }
    const ranges: any = rangValues.ranges.map(
      (value: RangesProp, index: number): RangesProp => {
        const percent = (value.value / rangeValueMax.current) * 100;
        percentStart = percentEnd;
        percentEnd += percent;

        return {
          ...value,
          percent,
          percentStart,
          percentEnd,
        };
      }
    );
    setrangesValue(ranges);
    // console.log({ ranges });
  }, [rangValues]);
  const CenterColor = () => {
    const percent = (inputValue / rangeValueMax.current) * 100;
    let backgroundColor = rangValues.ranges[0]?.color;
    const celectedRange: RangesProp[] = rangesValue.filter(
      (value: any) =>
        value?.percentStart <= percent && value?.percentEnd >= percent
    );
    if (celectedRange.length > 0) backgroundColor = celectedRange[0]?.color;
    // console.log({ celectedRange });
    //style={{left: `${percent}%` }}
    return (
      <div className="color-center-container" style={{ left: `${percent}%` }}>
        <div className="thumb-center-color" style={{ backgroundColor }}></div>
      </div>
    );
  };

  return (
    <>
      <div className="range-input-container">
        <div className="range-title">
          {rangValues?.enableInputBox ? (
            <input
              type="number"
              min={rangeValueMin.current}
              max={rangeValueMax.current}
              value={inputValue}
              disabled={rangValues.disabled}
              onChange={(e) =>
                updateInputValue(e.target.value as unknown as number)
              }
            />
          ) : (
            <span>{inputValue}</span>
          )}
          <div className="title-unit">{unit}</div>
          <div className="title-title">{title}</div>
        </div>
        <div className="input-container">
          <input
            type="range"
            min={rangeValueMin.current}
            max={rangeValueMax.current}
            step={rangValues.step}
            value={inputValue}
            disabled={rangValues.disabled}
            onChange={(e) =>
              updateInputValue(e.target.value as unknown as number)
            }
          />
          <div className="color-center-container-h">
            <CenterColor />
          </div>

          <div className="range-part-container">
            <svg
              width="185"
              height="128"
              viewBox="0 0 185 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M123.458 0C55.9224 0 0.96875 54.6341 0.96875 121.777C0.96875 125.216 3.76948 128 7.22795 128C10.6864 128 13.4872 125.216 13.4872 121.777C13.4659 61.4898 62.8181 12.4245 123.458 12.4245C143.297 12.4245 161.883 17.6981 177.966 26.853L184.204 16.0949C166.297 5.8853 145.567 0 123.458 0Z"
                fill="#2EC057"
              />
            </svg>
            {rangeValueMax.current > 0 &&
              rangesValue.map((value: RangesProp, index: number) => {
                const css = {
                  // backgroundColor: value.color,
                  width: `${value.percent}%`,
                };
                return (
                  <div key={index} className="range-part" style={css}>
                    <div
                      className="border-design"
                      style={{ borderColor: value.color }}
                    ></div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RangeInput;
