import {
  doc,
  addDoc,
  setDoc,
  getDoc,
  collection,
  where,
} from "@firebase/firestore";
import { fireStore } from "@/services/firebase/index";
import {
  deleteDoc,
  deleteField,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
export const getFireStoreDoc = async (collectionName: string) => {
  try {
    const q = doc(fireStore, collectionName);

    const querySnapshot = await getDoc(q);
    //console.log("querySnapshot", querySnapshot.data());
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    // });
    return querySnapshot.data();
  } catch (err) {
    //console.log(err);
    return false;
  }
};

export const getRTDBData = async (collectionName: string) => {
  try {
    return onSnapshot(doc(fireStore, collectionName), (doc: any) => {
      return doc.data();
    });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const writecheckOutData = async (uuid: string, data: any) => {
  try {
    return await addDoc(
      collection(fireStore, "customers/" + uuid + "/checkout_sessions"),
      { ...data }
    );
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const writeUserData = async (userCollection: string, data: any) => {
  try {
    return await addDoc(collection(fireStore, "users/" + userCollection), {
      ...data,
    });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setCollectionData = async (collectionName: string, data: any) => {
  try {
    const id = Math.floor(Math.random() * 1000) + "" + Date.now();
    const reference = doc(fireStore, "users/" + collectionName + "/" + id);
    return await setDoc(reference, { id: id, ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setAddressData = async (collectionName: string, data: any) => {
  try {
    const id = Math.floor(Math.random() * 1000) + "" + Date.now();
    const reference = doc(fireStore, "users/" + collectionName + "/" + id);
    return await setDoc(reference, { id: id, ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateAddressData = async (
  collectionName: string,
  data: any,
  oldId: string
) => {
  try {
    const id = oldId;
    const reference = doc(fireStore, "users/" + collectionName + "/" + id);
    return await updateDoc(reference, { ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setOrderCollection = async (collectionName: string, data: any) => {
  try {
    const reference = doc(fireStore, "users/" + collectionName);
    return await setDoc(reference, { ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateOrderCollection = async (
  collectionName: string,
  data: any
) => {
  try {
    const reference = doc(fireStore, "users/" + collectionName);
    return await updateDoc(reference, { ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setUserData = async (userCollection: string, data: any) => {
  try {
    return await setDoc(doc(fireStore, "users", userCollection), { ...data });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const updateUserData = async (userCollection: string, data: any) => {
  // console.log('data', data)
  try {
    return await updateDoc(doc(fireStore, "users", userCollection), {
      ...data,
    });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const removeUserCollection = async (userCollection: string) => {
  try {
    return await deleteDoc(doc(fireStore, "users", userCollection));
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const removeOrderBookingField = async (userCollection: string, ) => {
  try {
  // Remove the 'capital' field from the document
  return await updateDoc(doc(fireStore, "users", userCollection), {
    booking_intent: deleteField()
  });
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const emailExist = async (email: string) => {
  const users = collection(fireStore, "users");
  try {
    return await getDocs(query(users, where("user_data.email", "==", email)));
  } catch (err) {
    console.log(err);
    return false;
  }
};
